import { Component, Input, SimpleChanges } from '@angular/core';
import { ModuleEntityEnum } from '../../models';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import CustomStore from 'devextreme/data/custom_store';
import { Email, RequestOptions } from 'src/app/core';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/core/services/http.service';

@Component({
  selector: 'app-view-email-history',
  templateUrl: './view-email-history.component.html',
  styleUrls: ['./view-email-history.component.scss']
})
export class ViewEmailHistoryComponent {

  public email: Email & {
  } = {
    To: "",
    Cc: "",
    Bcc: "",
    Subject: "",
    Body: "",
    From:"",
    Attachments:[],
    ValueId: null,
    EntityTypeId: null
  }

  private readonly emailHistoryUrl = `${environment.baseApiUrl}/EmailHistory`;
  
  @Input()
  moduleType!: ModuleEntityEnum | any;

  @Input()
  valueId!: number;

  @Input()
  title:any;

  @Input()
  values!: [] | any;
  public popupVisibleViewEmailTemplate!:boolean;

  load = (loadOptions: any) => {
    let emailHistoryRequestOptions : RequestOptions = {
      skip: loadOptions.skip,
      take: loadOptions.take
    }
    if (loadOptions.sort) {
      emailHistoryRequestOptions.sortOrder = loadOptions.sort.desc ? "desc" : "asc";
      emailHistoryRequestOptions.sortBy = loadOptions.sort.selector
    }
    if (this.valueId) {
      return this.GetEmailHistoryByEntityTypeAndValueId(this.moduleType, this.valueId, emailHistoryRequestOptions)
        .then((response: any) => ({
          data: response.Data,
          totalCount: response.TotalRecords
        }));
    } else {
      return this.GetEmailHistoryByEntityTypeAndValues(this.moduleType, this.values, emailHistoryRequestOptions)
        .then((response: any) => ({
          data: response.Data,
          totalCount: response.TotalRecords
        }));
    }
  }
  isReadOnly: boolean = false;

  constructor(
    private httpService: HttpService,
    private spinnerService: SpinnerService ) { }

  public dataSource = new CustomStore({
    key: 'Id',
    load: this.load
  })

  displayTo(data: any) {
    if (!data || !data.To) {
        return '';
    }
    return this.formatToEmails(data.To);
 }

 displayCc(data: any) {
  if (!data || !data.Cc) {
      return '';
  }
  return this.formatToEmails(data.Cc);
}

displayBcc(data: any) {
  if (!data || !data.Bcc) {
      return '';
  }
  return this.formatToEmails(data.Bcc);
}

  formatToEmails(emails: string): string {
    if (!emails || emails.trim() === '') {
        return '';
    }
    return emails.split(';').map(email => email.trim()).join('. ');
  }

  GetEmailHistoryByEntityTypeAndValueId(entityTypeId: number, valueId: number, requestOptions: RequestOptions) {
    let auditTrailsUrl = `${this.emailHistoryUrl}/${entityTypeId}/${valueId}/EmailHistory`;
    return this.httpService.getPaginatedData(auditTrailsUrl, requestOptions);
  }

  GetEmailHistoryByEntityTypeAndValues(entityTypeId: number, values: number[], requestOptions: RequestOptions) {
    let auditTrailsUrl = `${this.emailHistoryUrl}/${entityTypeId}/EmailHistory`;
    auditTrailsUrl += values ? `?values=${values.join(',')}` : '';
    return this.httpService.getPaginatedData(auditTrailsUrl, requestOptions);
  }

  public openEmail = (obj: any) => {  
    this.email = obj.row.data;
    this.isReadOnly = true;
    this.popupVisibleViewEmailTemplate = true;
  }

  public onPopupClosed(): void {
    this.popupVisibleViewEmailTemplate = false;
  }
  
}
