import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from 'src/app/core/services/translation.service';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  constructor(private translationService: TranslationService){

  }

  transform(value: string, ...args: Array<string>): string {
    return this.translationService.translate(value, ...args);
  }

}
