import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from '../core/services/http.service';
import { FAVORITES_ODATA_URL, IFavoriteData } from './favorites-model';

@Injectable({
  providedIn: 'root'
})
export class FavoritesService {

  private readonly favoriteUrl = `${environment.baseApiUrl}/Favorite`;

  constructor(private httpService: HttpService) { }

  //#region favorites

  getFavorites(){
    return this.httpService.getData(this.favoriteUrl);
  }

  getFavoriteById(favoriteId : number){
    return this.httpService.getData(`${this.favoriteUrl}/${favoriteId}`);
  }

  getTopFavoritesByPriorityOrder(){
    let favoriteDetailUrl = `${this.favoriteUrl}/TopFavorites`;
    return this.httpService.getData(favoriteDetailUrl);
  }

  addFavorite(favorite: IFavoriteData){
    return this.httpService.post(this.favoriteUrl, favorite);
  }

  updateFavorite(favoriteId: number, favorite: IFavoriteData){
    let url = `${this.favoriteUrl}/${favoriteId}`;
    favorite.Id = favoriteId;
    return this.httpService.put(url, favorite);
  }
  
  deleteFavorite(favoriteId: number){
    let url = `${this.favoriteUrl}/${favoriteId}`;
    return this.httpService.delete(url);
  }

  checkIfPriorityOrderExists(priorityOrder: string, id: number, userName: string) : Promise<Boolean>{
    let filter = `PriorityOrder eq ${priorityOrder} and Username eq '${userName}'`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(FAVORITES_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  
  checkIfNameExists(name: any, id: number, userName: string) : Promise<Boolean>{
    let filter = `Name eq '${name}' and Username eq '${userName}'`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(FAVORITES_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  getUserNameById(userId: number){
    let url = `${environment.baseOdataUrl}/UserProfiles`;
    return this.httpService.getOData(url, {filter: `UserId eq ${userId}`, select: "FirstName, LastName " });
  }

  //#endregion
}
