<section class="title-section">
    <span class="page-title">{{title}}</span>
</section>
<ng-container *ngIf="showFilter">
    <app-grid-filter [dataModel]="filterModel" [filterItems]="filterItems" (onSearch)="onSearchClicked($event)"></app-grid-filter>
</ng-container>
<app-action-bar [actionItems]="actionItems" (onViewHistory)="onViewHistory($event)" [showHistoryItem]="showHistoryItem"></app-action-bar>
<section class="grid-container">
    <ng-content select="dx-tree-list"></ng-content>
</section>
