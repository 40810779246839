import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import DataSource from 'devextreme/data/data_source';
import { DxDataGridComponent } from 'devextreme-angular';
import { SelectionChangedEvent } from 'devextreme/ui/data_grid';
import { FileService } from 'src/app/core/services/file.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { GridActionClickEvent } from 'src/app/shared/models';
import { ClickEvent } from 'devextreme/ui/button';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-attachments',
  templateUrl: './view-attachments.component.html',
  styleUrls: ['./view-attachments.component.scss']
})
export class ViewAttachmentsComponent {

  @Input()
  dataSource!: DataSource | any;

  @Input()
  showAdd: boolean = false;

  @Output()
  onEditClicked: EventEmitter<GridActionClickEvent> = new EventEmitter<GridActionClickEvent>();

  @Output()
  onDeleteClicked: EventEmitter<GridActionClickEvent> = new EventEmitter<GridActionClickEvent>();
 
  @Output()
  onAddClicked: EventEmitter<any> = new EventEmitter();

  @ViewChild(DxDataGridComponent)
  grid!: DxDataGridComponent;

  @Output()
  onSelectionChanged: EventEmitter<SelectionChangedEvent> = new EventEmitter<SelectionChangedEvent>();

  constructor(private fileService: FileService, 
    public sanitizer: DomSanitizer, 
    private httpClient: HttpClient, 
    private spinnerService: SpinnerService,
    private router: Router) {}

  public selectionChangeHandler = (e: SelectionChangedEvent) => {
    this.onSelectionChanged?.emit(e);
  }

  public refreshGrid() {
    this.grid.instance.clearSelection();
    this.grid.instance.refresh();
  }

  public class: any;
  public popupVisible: boolean = false;
  public imageSource: any;
  public documentSource: any;
  public popupTitle!: any;
  public width: any;
  public height: any;

  fileIconMap: { [extension: string]: string } = {
    'pdf': 'dx-icon-pdffile',
    'doc': 'dx-icon-docfile',
    'docx': 'dx-icon-docxfile',
    'xls': 'dx-icon-xlsfile',
    'xlsx': 'dx-icon-xlsxfile',
    'pptx': 'dx-icon-pptxfile',
    'ppt': 'dx-icon-pptfile',
  };

  calculateCellValue(rowData: any) {
    return rowData.ImageString ? `${'data:image/png;base64,'}  ${rowData.ImageString}` : null;
  }

  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }

  public downloadAttachment = (obj: any) => {
    let fileName = obj.row.data.FileName;
    this.fileService.downloadFile(fileName);
  }

  public openAttachment = (obj: any) => {  
    let fileName = obj.row.data.FileName;
    let extension = fileName.split('.');
    let id = obj.row.data.Id;
    if(this.isFileType(extension[extension.length-1])){
      this.spinnerService.showSpinner();
      const downloadFileUrl = `${environment.baseApiUrl}/FileUpload/download?fileName=`;
      return this.httpClient
        .get(`${downloadFileUrl}${fileName}`, { responseType: 'blob', }).toPromise().then((res: any) => {
          this.spinnerService.hideSpinner();
            this.fileService.openFile(res,res.type, id.toString());
        });
      }
      return;
  }

  isFileType(source: any): boolean {
    return source && /\jpe?g|png|bmp|pdf$/i.test(source);
    }

  getClass = (attachment: any) => {
    let extension = attachment.data.FileName.split('.');
    var icon = this.fileIconMap[extension[extension.length-1]] ? this.fileIconMap[extension[extension.length-1]] : 'dx-icon-file';
    return `dx-icon-custom-style ${icon} dx-icon`;
  }

  onImageClick(imageData: any) {
    this.width = "auto";
    this.height = "auto";
    this.popupTitle = imageData.Name;
    this.documentSource = "";
    this.imageSource = '';
    this.spinnerService.showSpinner();
    const downloadFileUrl = `${environment.baseApiUrl}/FileUpload/download?fileName=`;
    return this.httpClient
      .get(`${downloadFileUrl}${imageData.FileName}`, { responseType: 'blob', }).toPromise().then((res: any) => {
        this.popupVisible = true;
        const url = URL.createObjectURL(res);
        let result = this.sanitizeImageUrl(url);
        this.spinnerService.hideSpinner();
        this.imageSource = result;
      });
  }

  onDocumentClick(document: any) {
    if (document.FileName.match(".pdf")) {
      this.width = "80%";
      this.height = "80%";
      this.popupTitle = document.Name;
      this.imageSource = '';
      this.documentSource = '';
      this.spinnerService.showSpinner();
      const downloadFileUrl = `${environment.baseApiUrl}/FileUpload/download?fileName=`;
      return this.httpClient
        .get(`${downloadFileUrl}${document.FileName}`, { responseType: 'blob', }).toPromise().then((res: any) => {
          this.popupVisible = true;
          this.spinnerService.hideSpinner();
          this.documentSource = res;
        });
    }
    return '';
  }

  editAttachmentDetail = (event: ClickEvent, rowData: any) => {
    event.event?.stopPropagation();
    if(rowData.key){
      this.onEditClicked?.emit({...event, rowData: rowData});
    }
  }
  deleteAttachmentDetail = (event: ClickEvent, rowData: any) => {
    event.event?.stopPropagation();
    if(rowData.key){
      this.onDeleteClicked?.emit({...event, rowData: rowData});
    }
  }

  onAddNewAttachment = () =>{
    this.onAddClicked?.emit();
  }
}
