import { Component, NgModule, Output, Input, EventEmitter, ViewChild, ElementRef, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { ItemClickEvent } from 'devextreme/ui/tree_view';
import { DxTreeViewModule, DxTreeViewComponent } from 'devextreme-angular/ui/tree-view';
import * as events from 'devextreme/events';
import { TranslationService } from 'src/app/core/services/translation.service';
import { CommonModule } from '@angular/common';
import { HttpService } from 'src/app/core/services/http.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'app-side-navigation-menu',
  templateUrl: './side-navigation-menu.component.html',
  styleUrls: ['./side-navigation-menu.component.scss']
})
export class SideNavigationMenuComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild(DxTreeViewComponent, { static: true })
  menu!: DxTreeViewComponent;

  @Output()
  selectedItemChanged = new EventEmitter<ItemClickEvent>();

  @Output()
  openMenu = new EventEmitter<any>();

  @Input()
  public visible: boolean = true;

  private _selectedItem!: String;
  @Input()
  set selectedItem(value: String) {
    this._selectedItem = value;
    if (!this.menu.instance) {
      return;
    }

    this.menu.instance.selectItem(value);
  }

  @Input()
  public menuItems!: DataSource;

  private _compactMode = false;
  @Input()
  get compactMode() {
    return this._compactMode;
  }
  set compactMode(val) {
    this._compactMode = val;

    if (!this.menu.instance) {
      return;
    }

    if (val) {
      this.menu.instance.collapseAll();
    } else {
      this.menu.instance.expandItem(this._selectedItem);
    }
  }

  constructor(private elementRef: ElementRef,
    private translationService: TranslationService) {
    
  }

  public selectNaivgationItem(url: string) {
    let currentItem = this.menu.items?.find(p => p.path === url);
    let selectedNodeKey = this.menu.instance.getSelectedNodeKeys()[0];
    if (currentItem) {
      if(selectedNodeKey != currentItem.Id){
        this.menu.instance.expandItem(currentItem.Id);
        this.menu.instance.selectItem(currentItem.Id);
      }
    }
    else{
      this.menu.instance.unselectAll();
    }
  }

  ngOnInit(): void {
  }

  getTransalatedMenu = (item: any) => {
    return this.translationService.translate(item.Page.Title);
  }

  onItemClick(event: ItemClickEvent) {
    this.selectedItemChanged.emit(event);
  }

  ngAfterViewInit() {
    events.on(this.elementRef.nativeElement, 'dxclick', (e: Event) => {
      this.openMenu.next(e);
    });
  }

  ngOnDestroy() {
    events.off(this.elementRef.nativeElement, 'dxclick');
  }
}

@NgModule({
  imports: [CommonModule, DxTreeViewModule],
  declarations: [SideNavigationMenuComponent],
  exports: [SideNavigationMenuComponent]
})
export class SideNavigationMenuModule { }
