import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslationService } from './services/translation.service';
import { HttpService } from './services/http.service';
import GlobalErrorHandler from './error-handling/global-error-handler';
import { ToastService } from './services/toast.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './error-handling/http-error-interceptor';
import { HttpLanguageInterceptor } from './http-interceptors/language.interceptor';
import { NaivgationService } from './services/navigation.service';
import { CalculateService } from './services/calculate.service';
import { SpinnerService } from './services/spinner.service';
import { PageInfoService } from './services/page-info.service';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule
  ],
  providers:[
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpLanguageInterceptor,
      multi: true
    },
    TranslationService,
    HttpService,
    ToastService,
    // NaivgationService,
    CalculateService,
    // SpinnerService,
    PageInfoService
  ]
})
export class CoreModule { }
