import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RequestOptions } from '../core';
import { HttpService } from '../core/services/http.service';
import { COMPANY_DEPARTMENT_ODATA_URL, COMPANY_INDUSTRIES_ODATA_URL, COMPANY_ODATA_URL, ICompanyBankAccount, ICompanyData, ICompanyDepartmentData, ICompanyIndustryData, ICreateEmployeeData, ILicenseAssignment, IRole, IRoleAssignment, IRoleConfiguration, ITenantAddressesData, ITenantContactNumber, ITenantContactsData, IUserData, IUserGroup, IUserGroupAssignment, LICENSE_ASSIGNMENT_ODATA_URL, LICENSE_PRODUCT_ODATA_URL, PRODUCT_INDUSTRY_IMPORT_ODATA_URL, ROLE_ASSIGNMENT_ODATA_URL, ROLE_CONFIGURATION_ODATA_URL, ROLE_ODATA_URL, TENANT_CONTACT_ODATA_URL, USER_GROUP_ASSIGNMENT_ODATA_URL, USER_GROUP_ODATA_URL, USER_PROFILE_ODATA_URL } from './admin-model';
import { Attachment } from '../shared/components/attach-document/add-edit-attachment/add-edit-attachment.component';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  private readonly tenantContactUrl = `${environment.baseApiUrl}/TenantContact`;
  private readonly tenantAddressUrl = `${environment.baseApiUrl}/TenantAddress`;
  private readonly tenantContactNumberUrl = `${environment.baseApiUrl}/TenantContactNumber`;
  private readonly postalCodeUrl = `${environment.baseApiUrl}/PostalCode`;
  private readonly companyInformationUrl = `${environment.baseApiUrl}/Company`;
  private readonly userUrl = `${environment.baseApiUrl}/User`; 
  private readonly userGroupUrl = `${environment.baseApiUrl}/UserGroup`; 
  private readonly roleUrl = `${environment.baseApiUrl}/Role`;
  private readonly roleConfigurationUrl = `${environment.baseApiUrl}/RoleConfiguration`; 
  private readonly roleAssignmentUrl = `${environment.baseApiUrl}/RoleAssignment`; 
  private readonly userGroupAssignmentUrl = `${environment.baseApiUrl}/UserGroupAssignment`;
  private readonly companyAttachmentUrl = `${environment.baseApiUrl}/CompanyAttachment`;
  private readonly licenseAssignmentUrl = `${environment.baseApiUrl}/LicenseAssignment`;
  private readonly companyDepartmentUrl = `${environment.baseApiUrl}/CompanyDepartment`;
  private readonly companyBankAccountUrl = `${environment.baseApiUrl}/BankAccount`;
  private readonly companyUrl = `${environment.baseApiUrl}/Company`;
  private readonly companyIndustryUrl = `${environment.baseApiUrl}/CompanyIndustry`;
  private readonly productIndustryImportUrl = `${environment.baseApiUrl}/ProductIndustryImport`;

  constructor(private httpService: HttpService) { }

   //#region Tenant address

   
  getCompanyAddresses(requestOptions: RequestOptions, id:number){
    let companyAddressesDetailUrl = `${this.companyInformationUrl}/${id}/TenantAddress`;
    return this.httpService.getPaginatedData(companyAddressesDetailUrl, requestOptions);
  }

  addCompanyAddress(id:any , customerAddressesData: ITenantAddressesData){
    let companyAddressesDetailUrl =`${this.companyInformationUrl}/${id}/TenantAddress`;
    return this.httpService.post(companyAddressesDetailUrl, customerAddressesData);
  }

  addAddressWithoutCompany(customerAddressesData: ITenantAddressesData){
    return this.httpService.post(this.tenantAddressUrl, customerAddressesData);
  }

  getCompanyAddressById(tenantAddressId:number){
    return this.httpService.getData(`${this.tenantAddressUrl}/${tenantAddressId}`);
  }

  addTenantAddress(tenantAddressData: ITenantAddressesData){
    return this.httpService.post(this.tenantAddressUrl, tenantAddressData);
  }

  updateCompanyAddress(tenantAddressId: number, tenantAddressData: ITenantAddressesData){
    let url = `${this.tenantAddressUrl}/${tenantAddressId}`;
    tenantAddressData.Id = tenantAddressId;
    return this.httpService.put(url, tenantAddressData);
  }

  deleteCompanyAddress(tenantAddressId: number){
    let url = `${this.tenantAddressUrl}/${tenantAddressId}`;
    return this.httpService.delete(url);
  }

  getDataByCountryZipcode(zipCode : string, countryId: number) {
    return this.httpService.getData(`${this.postalCodeUrl}/${countryId}/${zipCode}`);
  }
  
  //#endregion

  //#region Tenant contacts
  checkIfDepartmentFunctionExistsForDepartment( departmentFunctionId: any, departmentId: any, id:number, addressId: number | null) : Promise<Boolean>{
    let filter = `DepartmentFunctionId eq ${departmentFunctionId} and DepartmentId eq ${departmentId} and TenantAddressId eq ${addressId}`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(TENANT_CONTACT_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  } 

  getTenantContacts(requestOptions: RequestOptions, id:number){
    let tenantContactsDetailUrl = `${this.tenantAddressUrl}/${id}/TenantContact`;
    return this.httpService.getPaginatedData(tenantContactsDetailUrl, requestOptions);
  }

  addTenantContact(id:number | null, tenantContactsData: ITenantContactsData){
    let tenantContactsDetailUrl =`${this.tenantAddressUrl}/${id}/TenantContact`;
    return this.httpService.post(tenantContactsDetailUrl, tenantContactsData);
  }

  addTenantContactwithoutTenantAddress(tenantContactsData: ITenantContactsData){
    let tenantContactUrl =`${this.tenantContactUrl}/Contact`;
    return this.httpService.post(tenantContactUrl, tenantContactsData);
  }

  getTenantContactById(tenantContactId: number){
    return this.httpService.getData(`${this.tenantContactUrl}/${tenantContactId}`);
  }

  updateTenantContact(tenantContactId: number, tenantContactsData: ITenantContactsData){
    let url = `${this.tenantContactUrl}/${tenantContactId}`;
    tenantContactsData.Id = tenantContactId;
    return this.httpService.put(url, tenantContactsData);
  }

  deleteTenantContact(tenantContactId: number){
    let url = `${this.tenantContactUrl}/${tenantContactId}`;
    return this.httpService.delete(url);
  }

  linkContactToTenantAddress(companyId:any  , tenantAddressData: ITenantAddressesData){
    let tenantAddressUrl =`${this.tenantAddressUrl}/${companyId}/LinkAddress`;
    return this.httpService.post(tenantAddressUrl, tenantAddressData);
  }

  linkCompanyToContact(companyId:any  , companyData: ICompanyData){
    let tenantAddressUrl =`${this.companyUrl}/${companyId}/LinkCompany`;
    return this.httpService.post(tenantAddressUrl, companyData);
  }
  
  //#endregion

  //#region Tenant contact number
  getTenantContactNumbers(requestOptions: RequestOptions, id:number){
    let tenantContactNumbersDetailUrl = `${this.tenantContactUrl}/${id}/TenantContactNumber`;
    return this.httpService.getPaginatedData(tenantContactNumbersDetailUrl, requestOptions);
  }

  addTenantContactNumber(id:number, tenantContactNumberData: ITenantContactNumber){
    let tenantContactNumbersDetailUrl =`${this.tenantContactUrl}/${id}/TenantContactNumber`;
    return this.httpService.post(tenantContactNumbersDetailUrl, tenantContactNumberData);
  }

  getTenantContactNumberById(tenantContactNumberId: number){
    return this.httpService.getData(`${this.tenantContactNumberUrl}/${tenantContactNumberId}`);
  }

  updateTenantContactNumber(tenantContactNumberId: number, tenantContactNumberData: ITenantContactNumber){
    let url = `${this.tenantContactNumberUrl}/${tenantContactNumberId}`;
    tenantContactNumberData.Id = tenantContactNumberId;
    return this.httpService.put(url, tenantContactNumberData);
  }

  deleteTenantContactNumber(tenantContactNumberId: number){
    let url = `${this.tenantContactNumberUrl}/${tenantContactNumberId}`;
    return this.httpService.delete(url);
  }
  //#endregion

  //#region company information
  getCompanyInformation(){
    return this.httpService.getData(this.companyInformationUrl);
  }

  getCompanyInformationById(companyId: number ){
    return this.httpService.getData(`${this.companyInformationUrl}/${companyId}`);
  }

  updateCompanyInformation(companyId :number,companyData: ICompanyData){
    let url = `${this.companyInformationUrl}/${companyId}`;
    companyData.Id = companyId;
    return this.httpService.put(url, companyData);
  }

  checkIfCompanyKeyForCompanyTypeExists(key: string, id: number | any, companyTypeId: number | any) : Promise<Boolean>{
    let filter = `Key eq '${key}' and CompanyTypeId eq ${companyTypeId}`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(COMPANY_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  checkICompanyNameForCompanyTypeExists(name: string, id: number | any, companyTypeId: number | any) : Promise<Boolean>{
    let filter = `Name eq '${name}' and CompanyTypeId eq ${companyTypeId}`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(COMPANY_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  getCustomerCompanyNumber(){
    let url = `${this.companyInformationUrl}/Generate/Number`;
    return this.httpService.getText(url);
  }

  getSupplierCompanyNumber(){
    let url = `${this.companyInformationUrl}/Generate/SupplierNumber`;
    return this.httpService.getText(url);
  }

  getManufacturerCompanyNumber(){
    let url = `${this.companyInformationUrl}/Generate/ManufacturerNumber`;
    return this.httpService.getText(url);
  }

  checkIfCompanyNumberForCompanyTypeExists(companyNumber: string, id: number | any,companyTypeId: number | any) : Promise<Boolean>{
    let filter = `CompanyNumber eq '${companyNumber}' and CompanyTypeId eq ${companyTypeId}`;
    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(COMPANY_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }


  //#endregion

  //#region company attachment

  getCompanyAttachments(requestOptions: RequestOptions, id: number){
    let companyAttachmentsDetailUrl = `${this.companyInformationUrl}/${id}/CompanyAttachments`;
    return this.httpService.getPaginatedData(companyAttachmentsDetailUrl, requestOptions);
  }

  addCompanyAttachment(companyId: number, attachment: Attachment){
    let companyAttachmentsDetailUrl = `${this.companyInformationUrl}/${companyId}/CompanyAttachments`;
    return this.httpService.post(companyAttachmentsDetailUrl, { CompanyId: companyId, ...attachment });
  }

  getCompanyAttachmentById(companyAttachmentId:number){
    return this.httpService.getData(`${this.companyAttachmentUrl}/${companyAttachmentId}`);
  }


  updateCompanyAttachment(companyAttachmentId: number, companyId: number, attachment: Attachment){
    let url = `${this.companyAttachmentUrl}/${companyAttachmentId}`;
    return this.httpService.put(url, { Id: companyAttachmentId, companyId: companyId, ...attachment });
  }

  deleteCompanyAttachment(companyAttachmentId: number){
    let url = `${this.companyAttachmentUrl}/${companyAttachmentId}`;
    return this.httpService.delete(url);
  }

  //#endregion

  //#region Company departments
    checkIfDepartmentNameExists( departmentId: any, companyDepartmentId: number, id:number) : Promise<Boolean>{
      let filter = `CRMDepartmentId eq ${departmentId} and CompanyId eq ${id}`;
  
      if(companyDepartmentId){
        filter+=` and Id ne ${companyDepartmentId}`;
      }
      return this.httpService
                .getODataCount(COMPANY_DEPARTMENT_ODATA_URL, { filter: filter })
                .then(count => !(count > 0));
    }
  
    getCompanyDepartments(requestOptions: RequestOptions, id:number){
      let companyDepartmentsDetailUrl = `${this.companyInformationUrl}/${id}/CompanyDepartments`;
      return this.httpService.getPaginatedData(companyDepartmentsDetailUrl, requestOptions);
    }
  
    addCompanyDepartment(id:number, companyDepartmentsData: ICompanyDepartmentData){
      let companyDepartmentsDetailUrl =`${this.companyInformationUrl}/${id}/CompanyDepartments`;
      return this.httpService.post(companyDepartmentsDetailUrl, companyDepartmentsData);
    }
  
    getCompanyDepartmentById(companyDepartmentId: number){
      return this.httpService.getData(`${this.companyDepartmentUrl}/${companyDepartmentId}`);
    }
  
    updateCompanyDepartment(companyDepartmentId: number, companyDepartmentsData: ICompanyDepartmentData){
      let url = `${this.companyDepartmentUrl}/${companyDepartmentId}`;
      companyDepartmentsData.Id = companyDepartmentId;
      return this.httpService.put(url, companyDepartmentsData);
    }
  
    deleteCompanyDepartment(companyDepartmentId: number){
      let url = `${this.companyDepartmentUrl}/${companyDepartmentId}`;
      return this.httpService.delete(url);
    }
    //#endregion

     //#region Company bank accounts
  
    getCompanyBankAccounts(requestOptions: RequestOptions, id:number){
      let companyBankAccountsDetailUrl = `${this.companyInformationUrl}/${id}/CompanyBankAccounts`;
      return this.httpService.getPaginatedData(companyBankAccountsDetailUrl, requestOptions);
    }
  
    addCompanyBankAccount(id:number, companyBankAccountsData: ICompanyBankAccount){
      let companyBankAccountsDetailUrl =`${this.companyInformationUrl}/${id}/CompanyBankAccounts`;
      return this.httpService.post(companyBankAccountsDetailUrl, companyBankAccountsData);
    }
  
    getCompanyBankAccountById(companyBankAccountId: number){
      return this.httpService.getData(`${this.companyBankAccountUrl}/${companyBankAccountId}`);
    }
  
    updateCompanyBankAccount(companyBankAccountId: number, companyBankAccountsData: ICompanyBankAccount){
      let url = `${this.companyBankAccountUrl}/${companyBankAccountId}`;
      companyBankAccountsData.Id = companyBankAccountId;
      return this.httpService.put(url, companyBankAccountsData);
    }
  
    deleteCompanyBankAccount(companyBankAccountId: number){
      let url = `${this.companyBankAccountUrl}/${companyBankAccountId}`;
      return this.httpService.delete(url);
    }
    //#endregion

  //#region User
  getUser(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.userUrl, requestOptions);
  }

  addUser(UserData :IUserData){return this.httpService.post(this.userUrl,UserData)}

  updateUser(UserId: number, UserData: IUserData){
    let url = `${this.userUrl}/${UserId}`;
    UserData.Id = UserId;
    return this.httpService.put(url, UserData);
  }

  deleteUser(UserId: number){
    let url = `${this.userUrl}/${UserId}`;
    return this.httpService.delete(url);
  }

  getUserById(UserId: number){
    return this.httpService.getData(`${this.userUrl}/${UserId}`);
  }

  checkifUserExist(UserId: any,MiddleName:any,id:number) : Promise<Boolean>{
    let filter = `FirstName eq '${UserId}' and MiddleName eq '${MiddleName}'`;
    if(id){
    filter += `and Id ne ${id}`;
    }
    return this.httpService
       .getODataCount(USER_PROFILE_ODATA_URL, { filter: filter })
       .then(count => !(count > 0));
  }

  checkIfUserMailExists(EmailId: any,FirstName: any,id:number) : Promise<Boolean>{
    let filter = `Email eq '${EmailId}' and FirstName eq '${FirstName}' and UserId eq null `;
    if(id){
    filter += `and UserId ne ${id}`;
    }
    return this.httpService
       .getODataCount(USER_PROFILE_ODATA_URL, { filter: filter })
       .then(count => !(count > 0));
  }

  checkIfUserProfileExists(EmailId: any,FirstName: any,id:number) : Promise<Boolean>{
   let filter = `Email eq '${EmailId}' and FirstName eq '${FirstName}' and UserId ne null `;
   if(id){
   filter += `and UserId ne ${id}`;
   }
   return this.httpService
      .getODataCount(USER_PROFILE_ODATA_URL, { filter: filter })
      .then(count => !(count > 0));
 }

  //#end region

  //#region User group
  getUserGroup(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.userGroupUrl, requestOptions);
  }

  addUserGroup(userGroupData :IUserGroup){return this.httpService.post(this.userGroupUrl,userGroupData)}

  updateUserGroup(UserGroupId: number, userGroupData: IUserGroup){
    let url = `${this.userGroupUrl}/${UserGroupId}`;
    userGroupData.Id = UserGroupId;
    return this.httpService.put(url, userGroupData);
  }

  deleteUserGroup(UserGroupId: number){
    let url = `${this.userGroupUrl}/${UserGroupId}`;
    return this.httpService.delete(url);
  }

  getUserGroupById(UserGroupId: number){
    return this.httpService.getData(`${this.userGroupUrl}/${UserGroupId}`);
  }

  checkifUserGroupExist(userGroupId: any,id:number) : Promise<Boolean>{
    let filter = `Name eq '${userGroupId}'`;
    if(id){
    filter += `and Id ne ${id}`;
    }
    return this.httpService
       .getODataCount(USER_GROUP_ODATA_URL, { filter: filter })
       .then(count => !(count > 0));
  }
  //#end region

  //#region User Group Assignment
  getUserGroupAssignment(userGroupId: number, requestOptions: RequestOptions){
    let url = `${this.userGroupUrl}/${userGroupId}/UserGroupAssignment`;
    return this.httpService.getPaginatedData(url, requestOptions);
  }

  getUserGroupAssignmentById(id: number){
    let url = `${this.userGroupAssignmentUrl}/${id}`;
    return this.httpService.getData(url);
  }

  addUserGroupAssignment(userGroupAssignment: IUserGroupAssignment,userGroupId: number){
    let url = `${this.userGroupUrl}/${userGroupId}/UserGroupAssignment`;
    return this.httpService.post(url, userGroupAssignment);
  }

  updateUserGroupAssignment(userGroupAssignmentId: number, userGroupAssignment: IUserGroupAssignment){
    let url = `${this.userGroupAssignmentUrl}/${userGroupAssignmentId}`;
    userGroupAssignment.Id = userGroupAssignmentId;
    return this.httpService.put(url, userGroupAssignment);
  }

  deleteUserGroupAssignment(userGroupAssignmentId: number){
    let url = `${this.userGroupAssignmentUrl}/${userGroupAssignmentId}`;
    return this.httpService.delete(url);
  }

  checkIfUserExists(userId: any, id: number,userGroupId:number) : Promise<Boolean>{
    let filter = `UserId eq ${userId} and userGroupId eq ${userGroupId}`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(USER_GROUP_ASSIGNMENT_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }
  //#end region

  //#region Role
   getRole(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.roleUrl, requestOptions);
  }

  addRole(RoleData :IRole){return this.httpService.post(this.roleUrl,RoleData)}

  updateRole(RoleId: number, RoleData :IRole){
    let url = `${this.roleUrl}/${RoleId}`;
    RoleData.Id = RoleId;
    return this.httpService.put(url, RoleData);
  }

  deleteRole(RoleId: number){
    let url = `${this.roleUrl}/${RoleId}`;
    return this.httpService.delete(url);
  }

  getRoleById(RoleId: number){
    return this.httpService.getData(`${this.roleUrl}/${RoleId}`);
  }

  checkifRoleExist(RoleId: any,id:number) : Promise<Boolean>{
    let filter = `Name eq '${RoleId}'`;
    if(id){
    filter += `and Id ne ${id}`;
    }
    return this.httpService
       .getODataCount(ROLE_ODATA_URL, { filter: filter })
       .then(count => !(count > 0));
  }
  //#end region

  //#region Role Configuration
  getRoleConfiguration(roleId: number, requestOptions: RequestOptions){
    let url = `${this.roleUrl}/${roleId}/RoleConfiguration`;
    return this.httpService.getPaginatedData(url, requestOptions);
  }

  getRoleConfigurationById(id: number){
    let url = `${this.roleConfigurationUrl}/${id}`;
    return this.httpService.getData(url);
  }

  addRoleConfiguration(roleConfiguration: IRoleConfiguration,roleId: number){
    let url = `${this.roleUrl}/${roleId}/RoleConfiguration`;
    return this.httpService.post(url, roleConfiguration);
  }

  updateRoleConfiguration(roleConfigurationId: number, roleConfiguration: IRoleConfiguration){
    let url = `${this.roleConfigurationUrl}/${roleConfigurationId}`;
    roleConfiguration.Id = roleConfigurationId;
    return this.httpService.put(url, roleConfiguration);
  }

  deleteRoleConfiguration(roleConfigurationId: number){
    let url = `${this.roleConfigurationUrl}/${roleConfigurationId}`;
    return this.httpService.delete(url);
  }

  checkifUserConfigurationExist(value: any,id:number,configurationTypeId:any,roleId:any) : Promise<Boolean>{
    let filter = `Value eq ${value} and RoleConfigurationTypeId eq ${configurationTypeId} and RoleId eq ${roleId}`;
    if(id){
    filter += `and Id ne ${id}`;
    }
    return this.httpService
       .getODataCount(ROLE_CONFIGURATION_ODATA_URL, { filter: filter })
       .then(count => !(count > 0));
  }

  checkifUserGroupConfigurationExist(value: any,id:number,configurationTypeId:any,roleId:any) : Promise<Boolean>{
    let filter = `Value eq ${value} and RoleConfigurationTypeId eq ${configurationTypeId} and RoleId eq ${roleId}`;
    if(id){
    filter += `and Id ne ${id}`;
    }
    return this.httpService
       .getODataCount(ROLE_CONFIGURATION_ODATA_URL, { filter: filter })
       .then(count => !(count > 0));
  }
  //#endregion

  //#region Role Assignment
  getRoleAssignment(roleId: number, requestOptions: RequestOptions){
    let url = `${this.roleUrl}/${roleId}/RoleAssignment`;
    return this.httpService.getPaginatedData(url, requestOptions);
  }

  getRoleAssignmentById(id: number){
    let url = `${this.roleAssignmentUrl}/${id}`;
    return this.httpService.getData(url);
  }

  addRoleAssignment(roleAssignment: IRoleAssignment,roleId: number){
    let url = `${this.roleUrl}/${roleId}/RoleAssignment`;
    return this.httpService.post(url, roleAssignment);
  }

  updateRoleAssignment(roleAssignmentId: number, roleAssignment: IRoleAssignment){
    let url = `${this.roleAssignmentUrl}/${roleAssignmentId}`;
    roleAssignment.Id = roleAssignmentId;
    return this.httpService.put(url, roleAssignment);
  }

  deleteRoleAssignment(roleAssignmentId: number){
    let url = `${this.roleAssignmentUrl}/${roleAssignmentId}`;
    return this.httpService.delete(url);
  }


  checkifCombinationExist(accessTypeId:any,roleId:number,id:number,accessFieldId:any,value:any) : Promise<Boolean>{
    let filter = `Value eq ${value} and AccessFieldId eq ${accessFieldId} and AccessTypeId eq ${accessTypeId} and RoleId eq ${roleId} `;
    if(id){
    filter += `and Id ne ${id}`;
    }
    return this.httpService
       .getODataCount(ROLE_ASSIGNMENT_ODATA_URL, { filter: filter })
       .then(count => !(count > 0));
  }
  //#endregion

  //#region Create employee

  addEmployeeByUserId(id:number,createEmployeeData: ICreateEmployeeData){
    let salesOrderLinesDetailUrl =`${this.userUrl}/${id}/CreateEmployee`;
    return this.httpService.post(salesOrderLinesDetailUrl, createEmployeeData);
  }

  getEmployeeIdByUserProfileId(userProfileId: number){
    let url = `${environment.baseOdataUrl}/UserProfiles/${userProfileId}`;
    return this.httpService.getOData(url, {select: "EmployeeId"});
  }
  //#endregion

  //#region Licensing

  getAssignedLicenses(){
    return this.httpService.getData(this.licenseAssignmentUrl);
  }

  getAvailableLicenses(productId: number){
    let url = `${this.licenseAssignmentUrl}/available/${productId}`;
    return this.httpService.getData(url);
  }

  getTenantProducts(){
    let url = `${this.licenseAssignmentUrl}/products`;
    return this.httpService.getData(url);
  }

  addLicenseAssignment(licenseAssignment: ILicenseAssignment){
    return this.httpService.post(this.licenseAssignmentUrl, licenseAssignment);
  }

  deleteLicenseAssignment(userId: number){
    let url = `${this.licenseAssignmentUrl}/${userId}`;
    return this.httpService.delete(url);
  }

  getUserLicenses(userId: number){
    let url = `${this.userUrl}/${userId}/licenses`;
    return this.httpService.getData(url);
  }

  checkDuplicateWorkstation(productId: number|null, assignmentType: number|null, workStationId: number|null){
    let filter = `LicenseProductId eq ${productId} and LicenseAssignmentType eq MES.CloudApplication.Domain.Enums.LicenseAssignmentTypeEnum'WorkStation' and AssignmentValueId eq ${workStationId}`;
    
    return this.httpService
       .getODataCount(LICENSE_ASSIGNMENT_ODATA_URL, { filter: filter })
       .then(count => !(count > 0));
  }

  checkDuplicateUser(productId: number|null, assignmentType: number|null, userId: number|null){
    let filter = `LicenseProductId eq ${productId} and LicenseAssignmentType eq MES.CloudApplication.Domain.Enums.LicenseAssignmentTypeEnum'User' and AssignmentValueId eq ${userId}`;
    
    return this.httpService
       .getODataCount(LICENSE_ASSIGNMENT_ODATA_URL, { filter: filter })
       .then(count => !(count > 0));
  }
  //#endregion

  //#region Company Industry

  checkIfProductCategoryExists(productCategoryId: any, companyIndustryId: number, id: number): Promise<boolean> {
    let filter = `ProductCategoryId eq ${productCategoryId} and CompanyId eq ${id}`;
  
    if (companyIndustryId) {
      filter += ` and Id ne ${companyIndustryId}`;
    }
  
    return this.httpService
      .getODataCount(COMPANY_INDUSTRIES_ODATA_URL, { filter: filter })
      .then(count => !(count > 0));
  }

  getCompanyIndustry(requestOptions: RequestOptions, id:number){
    let companyIndustriesDetailUrl = `${this.companyInformationUrl}/${id}/CompanyIndustries`;
    return this.httpService.getPaginatedData(companyIndustriesDetailUrl, requestOptions);
  }

  addCompanyIndustry(id:number, companyIndustriesData: ICompanyIndustryData){
    let companyIndustriesDetailUrl =`${this.companyInformationUrl}/${id}/CompanyIndustries`;
    return this.httpService.post(companyIndustriesDetailUrl, companyIndustriesData);
  }

  getCompanyIndustryById(companyIndustryId: number){
    return this.httpService.getData(`${this.companyIndustryUrl}/${companyIndustryId}`);
  }

  updateCompanyIndustry(companyIndustryId: number, companyIndustriesData: ICompanyIndustryData){
    let url = `${this.companyIndustryUrl}/${companyIndustryId}`;
    companyIndustriesData.Id = companyIndustryId;
    return this.httpService.put(url, companyIndustriesData);
  }

  deleteCompanyIndustry(companyIndustryId: number){
    let url = `${this.companyIndustryUrl}/${companyIndustryId}`;
    return this.httpService.delete(url);
  }
  //#endregion

  //#region Product industry import history

  getImportedProductIndustryData(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.productIndustryImportUrl, requestOptions);
  }

  AddProductIndustryImportByIndustryIdAsync(industryId:number |any,productIndustryImport: ICompanyIndustryData ){
    let companyIndustriesDetailUrl =`${this.productIndustryImportUrl}/${industryId}/ProductIndustryImports`;
    return this.httpService.post(companyIndustriesDetailUrl, productIndustryImport);
  }

  checkIfProductIndustryExists(industryId: string) : Promise<Boolean>{
    let filter = `ProductIndustryId eq ${industryId}`;

    return this.httpService
              .getODataCount(PRODUCT_INDUSTRY_IMPORT_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

   //#endregion

  }

  
  

