import { environment } from "src/environments/environment";
import { IAuditableEntity, IEntityBase } from "../shared/models";

export const PART_ODATA_URL = `${environment.baseOdataUrl}/Parts`;
export const PART_TYPE_ODATA_URL = `${environment.baseOdataUrl}/PartTypes`;
export const PART_DETAIL_ODATA_URL = `${environment.baseOdataUrl}/PartDetails`;
export const PART_CATEGORY_ODATA_URL = `${environment.baseOdataUrl}/PartCategories`;
export const PART_SOURCE_TYPE_ODATA_URL = `${environment.baseOdataUrl}/PartSourceTypes`;
export const PART_CATEGORY_DETAIL_ODATA_URL = `${environment.baseOdataUrl}/PartCategoryDetails`;
export const PART_ATTRIBUTE_ODATA_URL = `${environment.baseOdataUrl}/PartAttributes`;
export const PART_ATTRIBUTE_OPTION_ODATA_URL = `${environment.baseOdataUrl}/PartAttributeOptions`;
export const PART_ATTRIBUTE_MEASURE_ODATA_URL = `${environment.baseOdataUrl}/PartAttributeMeasures`;
export const PART_BOM_ODATA_URL = `${environment.baseOdataUrl}/PartBOMs`;
export const UNIT_OF_MEASURE_ODATA_URL = `${environment.baseOdataUrl}/UnitOfMeasures`;
export const TAX_SLAB_ODATA_URL = `${environment.baseOdataUrl}/TaxSlabs`;
export const PART_IMAGE_ODATA_URL = `${environment.baseOdataUrl}/PartImages`;
export const MANUFACTURERS_ODATA_URL = `${environment.baseOdataUrl}/Manufacturers`;
export const PART_MANUFACTURERS_ODATA_URL = `${environment.baseOdataUrl}/PartManufacturers`;
export const PACKAGE_TYPES_ODATA_URL = `${environment.baseOdataUrl}/PackageTypes`;
export const PART_PACKAGE_DETAIL_ODATA_URL = `${environment.baseOdataUrl}/PartPackageDetails`;
export const PART_PACKAGE_ODATA_URL = `${environment.baseOdataUrl}/PartPackages`;
export const PART_SPARE_ODATA_URL = `${environment.baseOdataUrl}/PartSpares`;
export const PART_STORAGE_ODATA_URL =  `${environment.baseOdataUrl}/PartDefaultInventoryStorages`;
export const COMPANY_ODATA_URL = `${environment.baseOdataUrl}/Companies`;
export const PART_CONVERSIONS_ODATA_URL = `${environment.baseOdataUrl}/PartConversions`;

export interface IPartCategoryFilter {
    Name: string,
    Key: string,
    CreatedDate: Date | any,
}

export interface IPartAttributeFilter {
  Id: number | null
}

export interface IPartAttributeMeasureFilter  {
  Id: number |null,
};

export interface IPartFilter {
    PartNumber: string,
    Name: string,
    PartCategoryId: number | null,
    PartTypeId: number | null,
    Key:string
}

export interface IBulkPartsFilter {
  PartNumber: string,
  Name: string,
  PartCategoryId: number | null,
  PartTypeId: number | null,
  Key:string,
  HSNCode: string,
  PartSourceTypeId: number| null
}

export interface IPart extends IEntityBase, IAuditableEntity {
    PartNumber: string,
    Name: string,
    Description: string,
    PartCategoryId: number | null,
    PartSourceTypeId: number | null,
    PartTypeId: number | null,
    UnitPriceToBuy:number | null,
    UnitPriceToSell:number | null,
    TaxSlabId:number | null,
    Discount:number | null,
    DefaultUnitOfMeasureId:number | null,
    MinimumInventoryQuantity: number | null,
    MaximumInventoryQuantity: number | null,
    //UnitOfMeasureId:number | null,
    AdditionalDescription:string,
    HSNCode:string,
    PartLink:string,
    CopyAttributeToDescription: boolean,
    IsMaster: boolean,
}

export interface IPartData extends IEntityBase, IAuditableEntity {
  Name: string,
  Description: string,
  CreatedBy: string,
  UpdatedBy: string,
  PartNumber: string,
  PartCategoryId: number | null,
  PartTypeId: number | null,

}

export interface IPartCategory extends IEntityBase, IAuditableEntity{
    Name: string,
    Description: string,
    CopyAttributeToDescription: boolean
}

export interface IPartCategoryDetail extends IEntityBase, IAuditableEntity{
    PartCategoryId: number | null,
    PartAttributeId: number | null,
    Value: any | null,
    UnitOfMeasureId: number | null,
    PartAttributeOptionId: number | null,
}

export interface IPartDetail extends IEntityBase, IAuditableEntity {
  PartId?:number | null,
  PartAttributeId: number | null,
  Value: any | null,
  UnitOfMeasureId:number | null,
  PartAttributeOptionId: number | null,
}

export interface IPartAttribute extends IEntityBase, IAuditableEntity{
  Name: string,
  Description: string,
}

export interface IPartAttributeMeasure extends IEntityBase, IAuditableEntity{
  Name: string,
  Description: string,
};

export interface IPartAttributeMeasuresDetailData extends IEntityBase, IAuditableEntity{
  PartAttributeMeasureId?:number,
  UnitOfMeasureId:number | null,
  IsDefault: boolean
}

export interface IPartBomFilter {
  ParentPartId: null,
  ChildPartId: null
}

export interface IPartBom extends IEntityBase, IAuditableEntity{
  PartBomId?:number,
  ChildPartId:number | null,
  UnitOfMeasureId: null,
  Quantity: number | null,
  AdditionalDescription: string,
  Remarks: string,
}

export interface IPartImage {
  Id?: number,
  Name: string,
  IsDefault: boolean,
}

export interface IClonePart {
  Name: string,
  PartNumber: string,
}

export interface IAttributeOptions  extends IEntityBase, IAuditableEntity{
  PartAttributeOptionId? : number,
  DisplayValue : "",
  Value: any | null,
  UnitOfMeasureId: null,
  IsDefault: false
}

export interface IClonePartCategory {
  Name: string,
  Key: string
}

export interface IPartSourceTypeFilter {
  Name : string, 
  Key : string,
}

export interface IPartSourceType extends IEntityBase,IAuditableEntity{
  Id? : number,
  Name : string,
  Description : string,
  Key : string
}

export interface IPartManufacturer {
  Id? : number,
  PartId : number | null,
  ManufacturerId : number | null,
  Remarks : string
}

export interface IPartPrice  extends IEntityBase,IAuditableEntity{
  PartIds :any,
  PartFieldId: number | null,
  OperatorId: number | null,
  Value: number | null,
  TypeOfValueId: number | null,
}

export interface IPackageTypeFilter {
  Name : string,   
}

export interface IPackageType extends IEntityBase,IAuditableEntity{
  Id? : number,
  Name : string,
  Description : string,
}

export interface IPartPackage {
  Id? : number,
  PartId : number | null,
  PackagePartId : number | null,
  PackageTypeId : number | null,
  UnitOfMeasureId : number | null,
  Quantity : number,
  Remarks : string
}

export interface IPartPackageDetail extends IEntityBase, IAuditableEntity {
  PartPackageId?:number | null,
  PartAttributeId: number | null,
  Value: any | null,
  UnitOfMeasureId:number | null,
  PartAttributeOptionId: number | null,
}

export interface IUpdateCategory extends IEntityBase, IAuditableEntity {
  CategoryId: number | null
}

export interface IPartStorage extends IEntityBase, IAuditableEntity {
  LocationTypeId: number | null,
  LocationId:number | null,
  RackId:number | null,
  RackCellId:number | null
};

export interface IPartConversion {
  Id? : number,
  PartId : number | null,
  PartDetailId : number | null,
  //Remarks : string
}

export interface IPartSpare {
  Id? : number,
  PartId : number | null,
  SparePartId : number | null,
  PackQuantity : number,
  UnitOfMeasureId : number | null,
}

export enum PartType {
  Part = 1,
  Machine = 2,
  Tool = 3,
  Service = 4,
  SparePart =5,
  Transportation = 6,
}
