import { Injectable } from '@angular/core';
import notify from "devextreme/ui/notify";
import { Properties } from 'devextreme/ui/toast';

@Injectable()
export class ToastService {

  private defaultToastOptions: Properties = {

    position: {
      at: {
        x: 'center',
        y: 'top'
      },
      offset:{
        y: 75
      }
    },
    width: "60%",
    closeOnSwipe: true,
    closeOnOutsideClick: false,
    closeOnClick: true
  }

  constructor() { }

  public success(message: string) {
    let options = {
      ...this.defaultToastOptions,
      type: 'success',
      visible: true,
      message: message,
      displayTime: 5000 
    }
    notify(options);
  }
  
  public warning(message: string) {
    let options = {
      ...this.defaultToastOptions,
      type: 'warning',
      visible: true,
      message: message,
      displayTime: 7000
    };
    notify(options);
  }

  public error(message: string) {
    let options = {
      ...this.defaultToastOptions,
      type: 'error',
      visible: true,
      message: message,
      displayTime: 30000 
    };
    notify(options);
  }
}
