import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ODataOptions, RequestOptions } from '..';

@Injectable()
export class HttpService {

  constructor(private httpClient : HttpClient) { }

  getPaginatedData(url: string, requestOptions: RequestOptions){
    
    let params = new HttpParams()
                      .set("Skip", requestOptions.skip ? requestOptions.skip : 0)
                      .set("Take", requestOptions.take ? requestOptions.take : 5000);
    if(requestOptions.filter){
      params = params.set("Filter", requestOptions.filter);
    }

    if (requestOptions.sortBy) {
      params = params.set("SortBy", requestOptions.sortBy)
    }

    if(requestOptions.sortOrder){
      params = params.set("SortOrder", requestOptions.sortOrder);
    }

    return this.httpClient.get(url, { params })
      .toPromise();
  }
  
  getData(url: string, params?: HttpParams): Promise<object>{
    return this.httpClient.get(url, {params: params})
      .toPromise();
  }

  get<T>(url: string, params?: HttpParams): Observable<T>{
    return this.httpClient.get<T>(url, {params: params});
  }

  getText(url: string, params?: HttpParams): Observable<string>{
    return this.httpClient.get(url, {params: params, responseType: "text"});
  }

  getOData(url: string, options: ODataOptions){
    let params = new HttpParams();
    params = this.addToHttpParamIfExists(params, "$filter", options.filter);
    params = this.addToHttpParamIfExists(params, "$select", options.select);
    params = this.addToHttpParamIfExists(params, "$expand", options.expand);
    params = this.addToHttpParamIfExists(params, "$top", options.top);
    params = this.addToHttpParamIfExists(params, "$skip", options.skip);

    return this.httpClient.get(url, {params: params})
      .toPromise();
  }

  getODataCount(url: string, options: ODataOptions){
    let countUrl = `${url}/$count`;
    return this.getOData(countUrl, options);
  }

  post(url: string, data: object){
    return this.httpClient.post(url, data)
      .toPromise();
  }

  put(url: string, data: object){
    return this.httpClient.put(url, data)
      .toPromise();
  }

  delete(url: string){
    return this.httpClient.delete(url)
    .toPromise();
  }

  private addToHttpParamIfExists(httpParams: HttpParams, paramName: string, value: any) : HttpParams {
    if(value != null && value.toString().trim() !== ""){
      return httpParams.set(paramName, value);
    }
    return httpParams;
  }
}
