import { environment } from "src/environments/environment";

export const REMAINDER_ODATA_URL = `${environment.baseOdataUrl}/Remainders`;
export const REMAINDER_TYPE_ODATA_URL = `${environment.baseOdataUrl}/RemainderTypes`;
export const REMAINDER_ACTIVITY_ODATA_URL = `${environment.baseOdataUrl}/RemainderActivities`;
export const REMAINDER_STATUS_ODATA_URL = `${environment.baseOdataUrl}/RemainderStatuses`;

export interface IRemainderData {
    Id?:number |null,
    Title : string,
    Description :string,
    ParentRemainderId: number |null,
    RemainderTypeId :number |null
    RemainderDateTime :Date | any,
    ModuleId:number |null
    ModuleTypeId :number |null
    DeputyId :number |null
    PersonInChargeId:number |null
    RemainderStatusId:number |null
}

export interface IRemainderActivity {
  Id?:number |null,
  Name : string,
  Description :string,
  ActivityDate :Date |any,
  LastActionOn :Date |any,
  NextActivity :string,
  NextActivityDateTime :Date |any
}

export interface IRemainderFilter  {
    RemainderTypeId:number |null,
    RemainderDate: Date |any,
    RemainderTime: Date |any,
    NextRemainderDateTime: Date |any,
    DeputyId: number |null,
    RemindId: number |null,
    PersonInChargeId: number |null,
    RemainderStatusId: number |null,
    ModuleTypeId: number| null
  }

export interface IRemainderType {
    Id?:number |null,
    Name : string,
    Description :string,
}

export interface IRemainderTypeFilter {
    Name: string,
  }