import { RequestOptions } from 'src/app/core';

export function createRequestOptions(loadOptions:any,filter:string) {
    
    let requestOptions : RequestOptions = {
        skip: loadOptions.skip,
        take: loadOptions.take,
        filter: filter
      }     
      if(loadOptions.sort && !(loadOptions.sort[0].selector instanceof Function)) {
        requestOptions.sortOrder = loadOptions.sort[0].desc ? "desc" : "asc";
        requestOptions.sortBy = loadOptions.sort[0].selector
      }
    return requestOptions;
}