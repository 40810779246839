<dx-autocomplete
  placeholder="Search..."
  valueExpr="value"
  [dataSource]="dataSource"
  [showClearButton]="true"
  [grouped]="true"
  stylingMode="filled"
  [minSearchLength]='3'
  (onItemClick)="navigate($event)">
  <dxi-button location="after"
    name="search"
    [options]="{
        stylingMode: 'text',
        icon: 'find'
    }"></dxi-button>
    <div *dxTemplate="let item of 'item'">
      <span>{{ item.text }}</span>
    </div>
    
</dx-autocomplete>
