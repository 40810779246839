import { environment } from 'src/environments/environment';
import { IAuditableEntity, IEntityBase } from '../shared/models';

export const LEAD_ODATA_URL = `${environment.baseOdataUrl}/Leads`;
export const LEAD_PART_ODATA_URL = `${environment.baseOdataUrl}/LeadParts`;
export const LEAD_COMPETITOR_ODATA_URL = `${environment.baseOdataUrl}/LeadCompetitors`;
export const LEAD_PHASE_ODATA_URL = `${environment.baseOdataUrl}/LeadPhases`;
export const LEAD_SOURCE_ODATA_URL = `${environment.baseOdataUrl}/LeadSources`;
export const LEAD_STATUS_ODATA_URL = `${environment.baseOdataUrl}/LeadStatuses`;
export const LEAD_ATTACHMENT_ODATA_URL = `${environment.baseOdataUrl}/LeadAttachments`;
export const LEAD_TERMS_ODATA_URL = `${environment.baseOdataUrl}/LeadTerms`;
export const LEAD_CONFIGURATION_ODATA_URL = `${environment.baseOdataUrl}/LeadConfigurations`;
export const LEAD_ATTRIBUTE_ODATA_URL = `${environment.baseOdataUrl}/LeadAttributes`;
export const LEAD_ATTRIBUTE_OPTION_ODATA_URL = `${environment.baseOdataUrl}/LeadAttributeOptions`;
export const LEAD_FOLLOW_UP_ODATA_URL = `${environment.baseOdataUrl}/LeadFollowUps`;
export const LEAD_FOLLOW_UP_TYPE_ODATA_URL = `${environment.baseOdataUrl}/LeadFollowUpTypes`;
export const LEAD_COMPETITOR_TYPE_ODATA_URL = `${environment.baseOdataUrl}/LeadCompetitorTypes`;
export const LEAD_ADDRESS_ODATA_URL = `${environment.baseOdataUrl}/LeadCustomerAddresses`;
export const LEAD_CUSTOMER_CONTACT_ODATA_URL = `${environment.baseOdataUrl}/LeadCustomerContacts`;
export const PROJECT_ODATA_URL= `${environment.baseOdataUrl}/Projects`;
export const COMPANY_ODATA_URL = `${environment.baseOdataUrl}/Companies`;
export const TENANT_ADDRESS_ODATA_URL = `${environment.baseOdataUrl}/TenantAddresses`;
export const EMPLOYEE_ODATA_URL = `${environment.baseOdataUrl}/Employees`;
export const COMPANY_TYPE_ODATA_URL = `${environment.baseOdataUrl}/CompanyTypes`;
export const LEAD_FOLLOW_UP_STATUS_ODATA_URL = `${environment.baseOdataUrl}/LeadFollowUpStatuses`;

export interface ILeadFilter {
  LeadNumber: number | any,
  LeadSourceId: number | null,
  LeadPhaseId: number | null,
  CustomerId: number | null,
  GeneratedDate: Date | any,
  ValidTillDate: Date | any,
  NextFollowUpDate: Date | any,
  LeadInChargeId: number |null,
  GeneratedById: number |null,
  AssignedToId: number |null,
  LeadStatusId: number | any,
}

export interface ILeadPhaseFilter {
  Name: string,
}

export interface ILeadSourceFilter {
  Name: string,
}

export interface ILead {
  Id?: number,
  LeadNumber: string,
  LeadSourceId: number | null,
  LeadPhaseId: number | null,
  CustomerId: number | null,
  CustomerName?: string,
  CustomerKey:string,
  GeneratedDate: Date | any,
  ValidTillDate: Date | any,
  NextFollowUpDate: Date | any,
  Probability: any,
  GeneratedById:number | null,
  AssignedToId:number | null,
  LeadInChargeId:number | null,
  LeadStatusId: number | any,
  Reamrks: string,
  BaseCurrencyCode: string,
  ProjectId : number | any,
  CompanyAddressId: number | null,
}

export interface ILeadFollowUp extends IEntityBase, IAuditableEntity {
  Id?: number,
  LeadId:number |null,
  FollowUpDate: Date | any,
  FollowUpById: number |any,
  FollowUpTypeId: number | any,
  Comments: string,
  NextFollowUpDate: Date|any,
  SetReminder:boolean,
  ReminderSummary: string,
  ReminderDescription: string,
  RemainderTypeId: number |null,
  RemainderDateTime: Date|any,
  ModuleTypeId:number |null,
  DeputyId :number |null,
  RemindId : number |null,
  PersonInChargeId :number |null,
  RemainderStatusId: number | null,
  TenantContactId: number | null,
  FollowUpStatusId: number | null,
}

export interface ILeadCompetitor {
  Id?: number,
  LeadId:number |null,
  CompetitorName:string,
  CompetitorProduct:string,
  CompetitorTypeId: number | any,
  Comments: string
}

export interface ILeadPhase {
  Id?: number,
  Name: string,
  Description: string
}

export interface ILeadFollowUpType {
  Id?: number,
  Name: string,
  Description: string
}

export interface ILeadCompetitorType {
  Id?: number,
  Name: string,
  Description: string
}

export interface ILeadSource {
  Id?: number,
  Key: string,
  Name: string,
  Description: string
}

export interface ILeadTerm {
  Id?: number,
  TermId: number | any,
  PriorityOrder: number | any
  Value: string,
}

export interface ILeadConfiguration extends IEntityBase, IAuditableEntity {
  Id?: number,
  LeadId: number | null,
  LeadAttributeId: number | null,
  Value: number | null,
  LeadAttributeOptionId: number | null
}

export interface ILeadPart extends IEntityBase, IAuditableEntity {
  Id?: number,
  PartId?: number | null,
  PartCategoryId: number | null,
  PartTypeId: number | null,
  PartSourceTypeId: number | null,
  PartName?: string,
  Quantity: number | null,
  UnitOfMeasureId: number | null,
  TaxSlabId: number | null,
  AdditionalDescription: string,
  UnitPrice: number,
  NetAmount: number,
  Discount: number | null,
  DiscountedAmount: number | null,
  Tax: number,
  GrossAmount: number,
  Remarks: string
  ContributionMargin : number |null,
  ContributionRelative : number |null,
  HSNCode:string,
  PartNumber: string,
}

export interface ILeadAttribute extends IEntityBase, IAuditableEntity {
  Name: string,
  Description: string
}

export interface ILeadAttributeOption extends IEntityBase, IAuditableEntity {
  Id?: number,
  DisplayValue: string,
  Value: string,
  IsDefault: false
}

export interface ILeadAttributeFilter {
  Name: string
}

export interface ILeadFollowUpTypeFilter {
  Name: string
}

export interface ILeadCompetitorTypeFilter {
  Name: string
}

export interface ILeadAddressesData {
  Id? : number,
  AddressTypeId : number | null,
  AddressLine1 : string,
  AddressLine2 : string,
  Alias : "",
  IsDefault: false,
  City : string,
  State: string,
  Country: string,
  ZipCode: string,
  Email: string,
  PhoneNumber: number | null,
  MobileNumber: number | null,
  FaxNumber: number | null,
  Website: string,
}

export interface ILeadCustomerContactsData {
  Id? : number,
  TitleTypeId : number | null,
  DepartmentId: number | null,
  DepartmentFunctionId: number | null,
  ContactPerson: string,
  IsDefault: false,
  PositionId: number | null,
  Email:string
}

export interface ILeadCustomerContactNumber {
  Id? : number,
  ContactTypeId : number | null,
  ContactNumber: number | null,
  Extension: string,
  CountryCode: string,
  IsDefault: false,
}

export interface IConfirmLeadQuotation {
QuotationNumber: string,
LeadNumber: string,
CustomerConfirmationNumber: string,
QuotationStatusId: number,
ShippingAddressId : null,
BillingAddressId:null,
ConfirmationDate: any |Date,
Remarks: string
}

export interface ILeadPhaseDetailData extends IEntityBase, IAuditableEntity{
  leadId?:number,
  leadPhaseId:number | null,
  IsDefault: boolean
}

export interface ILeadSourceDetailData extends IEntityBase, IAuditableEntity{
  sourceId?:number,
  leadSourceId:number | null,
  IsDefault: boolean
}

export interface ILeadDetailData extends IEntityBase, IAuditableEntity{
  leadId?:number,
  leadPhaseId:number | null,
  IsDefault: boolean
}

export interface ILeadStatusFilter  {
  Name: string
}

export interface ILeadStatus {
  Id? : number,
  Name : string,
  Description : string,
  IsDefault: false,
}

export interface IWorkflowStep {
  Id? : number,
  NextStatusId : number | null,
  NextStatusName : string,
  IsCurrent: false
}

export enum CompanyType {
  Tenant = 1,
  Customer = 2,
  Supplier = 3,
  Maufacturer = 4,
  Lead = 5
}

export interface ILeadCustomerFilter {
  CompanyId : number | null,
  CompanyNumber : string,
  SupplierNumber : string,
  GstNumber : string,
  TaxNumber : String,
  CompanyStatusId : number | null,
  CompanyTypeId : number | null
}

export interface ILinkCompanyToContact {
  ContactId : number | null,
  CompanyId : number | null,
  AddressId : number | null,
}

export interface IContactFilter {
  ContactPerson: string,
  DepartmentId: number | null,
  PositionId: number | null,
  DepartmentFunctionId: number | null,
}

export interface ILeadFollowUpStatusFilter  {
  Name: string
}

export interface ILeadFollowUpStatus {
  Id? : number,
  Name : string,
  Description : string,
}

export interface IMoveContactFormData {
  ContactId : number | null,
  AddressId : number | null,
}