import { CommonModule } from '@angular/common';
import { AfterContentInit, AfterViewInit, Component, ContentChild, EventEmitter, Input, NgModule, OnInit, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent, DxFormComponent, DxToolbarComponent } from 'devextreme-angular';
import { defaultGridOptions, routes } from '../../models';
import { SharedModule } from '../../shared.module';
import { ActionBarComponent } from '../action-bar/action-bar.component';
import { GridFilterComponent } from '../grid-filter/grid-filter.component';
import { ActionBarModule, GridFilterModule } from './../index';
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import * as saveAs from 'file-saver';
import { PageInfoService } from 'src/app/core/services/page-info.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import { Router} from '@angular/router';
import { ViewAuditTrailsComponent } from '../view-audit-trails/view-audit-trails.component';

@Component({
  selector: 'app-grid-view',
  templateUrl: './grid-view.component.html',
  styleUrls: ['./grid-view.component.scss']
})
export class GridViewComponent implements OnInit, AfterViewInit, AfterContentInit {

  @ViewChild(ActionBarComponent)
  public actionBarComponent!: ActionBarComponent;
  @ViewChild(GridFilterComponent)
  private gridFilterComponent!: GridFilterComponent;
  @ViewChild(ViewAuditTrailsComponent)
  public trailsComponent!: ViewAuditTrailsComponent;
  @ContentChild(DxDataGridComponent)
  private dataGrid!: DxDataGridComponent;
  @Input()
  public filterModel: any;
  @Input()
  public filterItems: any;
  @Input()
  public actionItems: any;
  @Input()
  public showFilter: boolean = true;
  @Output()
  public onSearch = new EventEmitter();
  public actionBar!: DxToolbarComponent;
  public filter!: DxFormComponent;
  public showExportItems: boolean = true;
  public showHistoryItem: boolean = true;
  public currentPageUrl!: string;
  public selectedRowKeys: any[] = [];

  constructor( private pageInfoService: PageInfoService,
    private translationService: TranslationService,private router: Router) { }
    
  ngAfterContentInit(): void {
    this.dataGrid.instance.option(defaultGridOptions);
  }
  ngAfterViewInit(): void {
    this.actionBar = this.actionBarComponent.toolBar;
    this.filter = this.gridFilterComponent?.form;
  
    this.dataGrid.onSelectionChanged.subscribe((e: any) => {
      this.selectedRowKeys = e.selectedRowKeys;
       console.log(this.selectedRowKeys);
    });
  }

  ngOnInit(): void {
  }

  onSearchClicked(e: any){
    this.dataGrid?.instance.clearSelection();
    this.onSearch.emit(e);
  }

  exportToPdf = (e: any) =>{
    const doc = new jsPDF();
    exportDataGridToPdf({
      jsPDFDocument: doc,
      component: this.dataGrid?.instance,
      customizeCell: ({gridCell, pdfCell}) => {
        if(pdfCell?.font) {
          pdfCell.font.size = 8;
          pdfCell.wordWrapEnabled = true;
        } 
      },
    }).then(() => {
      let title = this.pageInfoService.getCurrentPageTitle();
      doc.save(title);
    });
  }

  exportToExcel = (e: any) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(this.translationService.translate("dialog_message_data"),);

    exportDataGrid({
      component: this.dataGrid?.instance,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        let title = this.pageInfoService.getCurrentPageTitle();
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), title+'.xlsx');
      });
    });
    e.cancel = true;
  }

  
  onViewHistory = (e: any) =>{
    this.currentPageUrl = this.router.url;
    let currentModel : any = routes.get(this.currentPageUrl);
    if(currentModel){
      if (this.selectedRowKeys.length === 1) {
        let selectedRowId = this.selectedRowKeys[0];
        this.router.navigate(['/auditTrails', currentModel], { state: { selectedRowId }  });
      } else {
        this.router.navigate(['/auditTrails', currentModel]);
      }
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    ActionBarModule,
    GridFilterModule,
    SharedModule
  ],
  declarations: [ GridViewComponent ],
  exports: [ GridViewComponent ]
})
export class GridViewModule {

}
