import { environment } from "src/environments/environment";
import { IAuditableEntity, IEntityBase } from "../shared/models";

export const FAVORITES_ODATA_URL = `${environment.baseOdataUrl}/Favorites`;

export interface IFavoriteData extends IEntityBase,IAuditableEntity {
    Id? : number,
    Name : string,
    Url: string,
    PriorityOrder: string,
    Remarks: string
}
