import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, NgModule, OnInit, Output, ViewChild } from '@angular/core';
import { DxFormComponent, DxToolbarComponent } from 'devextreme-angular';
import { FormActionEvent, FormItem } from '..';
import { ActionBarModule, ActionBarComponent } from '../action-bar/action-bar.component';
import { FormComponent, FormModule } from '../form/form.component';
import { NaivgationService } from 'src/app/core/services/navigation.service';


@Component({
  selector: 'app-form-view',
  templateUrl: './form-view.component.html',
  styleUrls: ['./form-view.component.scss']
})
export class FormViewComponent implements OnInit, AfterViewInit {

  @Input()
  public formDataModel!: any;
  @Input()
  public formItems!: Array<FormItem>;
  @Input()
  public actionItems!: Array<any>;
  @Input()
  public showSave: boolean = true;
  @Input()
  public showCancel: boolean = true;
  @Input()
  public showUpdate: boolean = false;
  @Input()
  public showAddMore: boolean = false;
  @Input()
  public readOnly: boolean = false;
  @Input()
  public fullScreen: boolean = false;

  @Output()
  public onSave: EventEmitter<FormActionEvent> = new EventEmitter<FormActionEvent>();

  @Output()
  public onCancel: EventEmitter<FormActionEvent> = new EventEmitter<FormActionEvent>();

  @Output()
  public onUpdate: EventEmitter<FormActionEvent> = new EventEmitter<FormActionEvent>();

  @Output()
  public onAddMore: EventEmitter<FormActionEvent> = new EventEmitter<FormActionEvent>();

  @Output()
  public onInitialized: EventEmitter<DxFormComponent> = new EventEmitter<DxFormComponent>();

  @ViewChild(ActionBarComponent)
  private toolBar!: ActionBarComponent

  @ViewChild(FormComponent)
  private formComponent!: FormComponent

  public actionBar!: DxToolbarComponent;
  public form!: DxFormComponent;

  constructor(private navigationService: NaivgationService) { }
  ngAfterViewInit(): void {
    this.actionBar = this.toolBar.toolBar;
    this.form = this.formComponent.form;
    this.onInitialized && this.onInitialized.emit(this.form);
  }

  ngOnInit(): void {
    if(!this.showUpdate){
      this.navigationService.isForm = true;
    }
  }

  public onSaveClicked = (e: FormActionEvent) =>{
    this.onSave?.emit(e);
  }

  public onCancelClicked = (e: FormActionEvent) =>{
    this.onCancel?.emit();
  }

  public onUpdateClicked = (e: FormActionEvent) =>{
    this.onUpdate?.emit();
  }

  public onAddMoreClicked = (e: FormActionEvent) =>{
    this.onAddMore?.emit(e);
  }
}

@NgModule({
  imports: [
    CommonModule,
    ActionBarModule,
    FormModule
  ],
  declarations: [ FormViewComponent ],
  exports: [ FormViewComponent ]
})
export class FormViewModule {

}
