import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from '../core/services/http.service';
import { ODataOptions, RequestOptions } from '../core';
import {ILead,ILeadAttribute,ILeadAttributeOption,ILeadFollowUpType,ILeadConfiguration,ILeadPart,ILeadPhase,ILeadSource,ILeadTerm,LEAD_ATTRIBUTE_ODATA_URL,LEAD_ATTRIBUTE_OPTION_ODATA_URL,LEAD_CONFIGURATION_ODATA_URL,LEAD_FOLLOW_UP_TYPE_ODATA_URL,LEAD_ODATA_URL,LEAD_PHASE_ODATA_URL,LEAD_SOURCE_ODATA_URL,LEAD_TERMS_ODATA_URL,LEAD_COMPETITOR_TYPE_ODATA_URL,ILeadCompetitorType, ILeadFollowUp, ILeadCompetitor, ILeadAddressesData, LEAD_CUSTOMER_CONTACT_ODATA_URL, ILeadCustomerContactsData, ILeadCustomerContactNumber, IConfirmLeadQuotation, LEAD_PART_ODATA_URL, LEAD_FOLLOW_UP_ODATA_URL, LEAD_COMPETITOR_ODATA_URL, LEAD_STATUS_ODATA_URL, ILeadStatus, IWorkflowStep, ILinkCompanyToContact, ILeadFollowUpStatus, LEAD_FOLLOW_UP_STATUS_ODATA_URL, COMPANY_ODATA_URL,} from './lead-management-model';
import { HttpParams } from '@angular/common/http';
import { Attachment } from '../shared/components/attach-document/add-edit-attachment/add-edit-attachment.component';
import { Cost } from '../shared/components/additional-costs/add-edit-additional-cost/add-edit-cost.component';
import { isNumber, isString } from 'lodash';
import { IPartDetail, PART_ATTRIBUTE_OPTION_ODATA_URL, PART_ODATA_URL } from '../part/model';

@Injectable({
  providedIn: 'root',
})
export class LeadManagementService {
  private readonly leadUrl = `${environment.baseApiUrl}/Lead`;
  private readonly companyInformationUrl = `${environment.baseApiUrl}/Company`;
  private readonly leadQuotationConfirmationUrl = `${environment.baseApiUrl}/LeadQuotationConfirmation`;
  private readonly leadPhaseUrl = `${environment.baseApiUrl}/LeadPhase`;
  // private readonly leadDetailUrl = `${environment.baseApiUrl}/LeadDetail`;
  private readonly leadSourceUrl = `${environment.baseApiUrl}/LeadSource`;
  private readonly leadStatusUrl = `${environment.baseApiUrl}/LeadStatus`;
  private readonly leadTermUrl = `${environment.baseApiUrl}/LeadTerm`;
  private readonly leadPartUrl = `${environment.baseApiUrl}/LeadPart`;
  private readonly leadAttachmentUrl = `${environment.baseApiUrl}/LeadAttachment`;
  private readonly leadAttributeUrl = `${environment.baseApiUrl}/LeadAttribute`;
  private readonly leadAttributeOptionUrl = `${environment.baseApiUrl}/LeadAttributeOption`;
  private readonly leadConfigurationUrl = `${environment.baseApiUrl}/LeadConfiguration`;
  private readonly partUrl = `${environment.baseApiUrl}/Part`;
  private readonly leadAdditionalCostUrl = `${environment.baseApiUrl}/LeadAdditionalCost`;
  private readonly leadFolowUpTypeUrl = `${environment.baseApiUrl}/LeadFollowUpType`;
  private readonly leadCompetitorTypeUrl = `${environment.baseApiUrl}/LeadCompetitorType`;
  private readonly leadFollowUpUrl = `${environment.baseApiUrl}/LeadFollowUp`;
  private readonly leadCompetitorUrl = `${environment.baseApiUrl}/LeadCompetitor`;
  private readonly leadAddressUrl = `${environment.baseApiUrl}/LeadCustomerAddress`;
  private readonly postalCodeUrl = `${environment.baseApiUrl}/PostalCode`;
  private readonly leadCustomerContactUrl = `${environment.baseApiUrl}/LeadCustomerContact`;
  private readonly tenantContactUrl = `${environment.baseApiUrl}/TenantContact`;
  private readonly tenantAddressUrl = `${environment.baseApiUrl}/TenantAddress`;
  private readonly leadCustomerContactNumberUrl = `${environment.baseApiUrl}/LeadCustomerContactNumber`;
  private readonly quotationUrl = `${environment.baseApiUrl}/Quotation`;
  private readonly partDetailUrl = `${environment.baseApiUrl}/PartDetail`;
  private readonly partCategoryDetailUrl = `${environment.baseApiUrl}/PartCategoryDetail`;
  private readonly linkCompanyToContactUrl = `${environment.baseApiUrl}/TenantAddress`;
  private readonly customerInformationUrl = `${environment.baseApiUrl}/Company/company`;
  private readonly leadFollowUpReportUrl = `${environment.baseApiUrl}/LeadFollowUp`;
  private readonly tenantContactNumberUrl = `${environment.baseApiUrl}/TenantContactNumber`;
  private readonly leadFolowUpStatusUrl = `${environment.baseApiUrl}/LeadFollowUpStatus`;

  constructor(private httpService: HttpService) {}

  //#region lead

  getLeads(requestOptions: RequestOptions) {
    return this.httpService.getPaginatedData(this.leadUrl, requestOptions);
  }

  getLeadStatuses(){
    return this.httpService.getOData(LEAD_STATUS_ODATA_URL, {});
  }

  addLead(lead: ILead) {
    return this.httpService.post(this.leadUrl, lead);
  }

  getLeadById(leadId: number) {
    return this.httpService.getData(`${this.leadUrl}/${leadId}`);
  }

  updateLead(leadId: number, lead: ILead) {
    let url = `${this.leadUrl}/${leadId}`;
    lead.Id = leadId;
    return this.httpService.put(url, lead);
  }

  deleteLead(leadId: number) {
    let url = `${this.leadUrl}/${leadId}`;
    return this.httpService.delete(url);
  }

  getLeadNumber(leadSourceId: number,leadPhaseId:number,customerId:number) {
    let params = new HttpParams().append('leadSourceId', leadSourceId).append('leadPhaseId',leadPhaseId).append('customerId',customerId==null?0:customerId);
    let url = `${this.leadUrl}/Generate/Number`;
    return this.httpService.getText(url, params);
  }

  checkIfLeadNumberExists(
    leadNumber: string,
    leadId: number
  ): Promise<Boolean> {
    let filter = `LeadNumber eq '${leadNumber}'`;
    if (leadId) {
      filter += ` and Id ne ${leadId}`;
    }
    return this.httpService
      .getODataCount(LEAD_ODATA_URL, { filter: filter })
      .then((count) => !(count > 0));
  }


  checkIfLeadNameExists(customerId: any, id: number) : Promise<Boolean>{
    
    let  filter= `CustomerName eq '${customerId}'`;
    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(LEAD_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  checkIfLeadKeyExists(key: string, id: number) : Promise<Boolean>{
    
    let  filter= `CustomerKey eq '${key}'`;
    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(LEAD_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  getLeadTaxRule(leadId: number){
    return this.httpService.getData(`${this.leadUrl}/${leadId}/taxrule`);
  }

  getLeadNextWorkflowSteps(leadId: number){
    let url = `${this.leadUrl}/${leadId}/workflow-steps`;
    return this.httpService.getData(url);
  }

  UpdateTransitionLeadAsync(leadId: number, workflowStepDTO: IWorkflowStep){
    let url = `${this.leadUrl}/${leadId}/workflow/transition`;
    return this.httpService.post(url,workflowStepDTO);
  }

  // getLeadCustomerById(leadId: number) {
  //   return this.httpService.getData(`${this.leadUrl}/GetCustomer/${leadId}`);
  // }

  getCustomerById(customerId: number){
    let options : ODataOptions = {
      filter: `Id eq ${customerId}`,
      select: "Id, Name, GstNumber, BaseCountryCode, State"
    };
    return new Promise(resolve =>{
      this.httpService.getOData(COMPANY_ODATA_URL, options)
      .then((response: any) =>{
        resolve(response.value[0]);
      })
    }) 
  }

  //#endregion

  //#region lead phase

  getLeadPhases(requestOptions: RequestOptions) {
    return this.httpService.getPaginatedData(this.leadPhaseUrl, requestOptions);
  }

  addLeadPhase(leadPhase: ILeadPhase) {
    return this.httpService.post(this.leadPhaseUrl, leadPhase);
  }

  getLeadPhaseById(leadPhaseId: number) {
    return this.httpService.getData(`${this.leadPhaseUrl}/${leadPhaseId}`);
  }

  updateLeadPhase(leadPhaseId: number, leadPhase: ILeadPhase) {
    let url = `${this.leadPhaseUrl}/${leadPhaseId}`;
    leadPhase.Id = leadPhaseId;
    return this.httpService.put(url, leadPhase);
  }

  deleteLeadPhase(leadPhaseId: number) {
    let url = `${this.leadPhaseUrl}/${leadPhaseId}`;
    return this.httpService.delete(url);
  }

  checkIfLeadPhaseNameExists(name: string, id: number): Promise<Boolean> {
    let filter = `Name eq '${name}'`;

    if (id) {
      filter += ` and Id ne ${id}`;
    }
    return this.httpService
      .getODataCount(LEAD_PHASE_ODATA_URL, { filter: filter })
      .then((count) => !(count > 0));
  }

  checkIfLeadPhaseKeyExists( key: any,id: number): Promise<Boolean> {
    let filter = `Key eq '${key}'`;

    if (id) {
      filter += ` and Id ne ${id}`;
    }
    return this.httpService
      .getODataCount(LEAD_PHASE_ODATA_URL, { filter: filter })
      .then((count) => !(count > 0));
  }
  //#endregion

  //#region lead source

  getLeadSources(requestOptions: RequestOptions) {
    return this.httpService.getPaginatedData(
      this.leadSourceUrl,
      requestOptions
    );
  }

  addLeadSource(leadSource: ILeadSource) {
    return this.httpService.post(this.leadSourceUrl, leadSource);
  }

  getLeadSourceById(leadSourceId: number) {
    return this.httpService.getData(`${this.leadSourceUrl}/${leadSourceId}`);
  }

  updateLeadSource(leadSourceId: number, leadSource: ILeadSource) {
    let url = `${this.leadSourceUrl}/${leadSourceId}`;
    leadSource.Id = leadSourceId;
    return this.httpService.put(url, leadSource);
  }

  deleteLeadSource(leadSourceId: number) {
    let url = `${this.leadSourceUrl}/${leadSourceId}`;
    return this.httpService.delete(url);
  }

  checkIfLeadSourceNameExists(name: string, id: number): Promise<Boolean> {
    let filter = `Name eq '${name}'`;

    if (id) {
      filter += ` and Id ne ${id}`;
    }
    return this.httpService
      .getODataCount(LEAD_SOURCE_ODATA_URL, { filter: filter })
      .then((count) => !(count > 0));
  }

  checkIfLeadSourceKeyExists(key: any, id: number): Promise<Boolean> {
    let filter = `Key eq '${key}'`;

    if (id) {
      filter += ` and Id ne ${id}`;
    }
    return this.httpService
      .getODataCount(LEAD_SOURCE_ODATA_URL, { filter: filter })
      .then((count) => !(count > 0));
  }
  //#endregion

  //#region Lead additional costs
  getLeadAdditionalCosts(requestOptions: RequestOptions, leadId: number){
    let leadAdditionalCostDetailUrl = `${this.leadUrl}/${leadId}/LeadAdditionalCost`;
    return this.httpService.getPaginatedData(leadAdditionalCostDetailUrl, requestOptions);
  }

  getLeadAdditionalCostById(leadAdditionalCostId: number) {
    return this.httpService.getData(`${this.leadAdditionalCostUrl}/${leadAdditionalCostId}`);
  }

  addLeadAdditionalCost(leadId: number, cost: Cost){
    let leadAdditionalCostDetailUrl = `${this.leadUrl}/${leadId}/LeadAdditionalCost`;
    return this.httpService.post(leadAdditionalCostDetailUrl, { LeadId: leadId, ...cost });
  }

  updateLeadAdditionalCost(leadAdditionalCostId: number, leadId: number, cost: Cost){
    let url = `${this.leadAdditionalCostUrl}/${leadAdditionalCostId}`;
    return this.httpService.put(url, { Id: leadAdditionalCostId, leadId: leadId, ...cost });
  }

  deleteLeadAdditionalCost(leadAdditionalCostId: number){
    let url = `${this.leadAdditionalCostUrl}/${leadAdditionalCostId}`;
    return this.httpService.delete(url);
  }
  //#endregion

  //#region Lead tax slabs
  getLeadTaxSlabs(requestOptions: RequestOptions, leadId: number) {
    let leadTaxSlabsUrl = `${this.leadUrl}/${leadId}/TaxSlabs`;
    return this.httpService.getPaginatedData(leadTaxSlabsUrl, requestOptions);
  }
  //#endregion

  //#region lead followUp type

  getLeadFollowUpType(requestOptions: RequestOptions) {
    return this.httpService.getPaginatedData(
      this.leadFolowUpTypeUrl,
      requestOptions
    );
  }

  addLeadFollowUpType(leadFollowUpType: ILeadFollowUpType) {
    return this.httpService.post(this.leadFolowUpTypeUrl, leadFollowUpType);
  }

  getLeadFollowUpTypeById(leadFollowUpTypeId: number) {
    return this.httpService.getData(
      `${this.leadFolowUpTypeUrl}/${leadFollowUpTypeId}`
    );
  }

  updateLeadFollowUpType(
    leadFollowUpTypeId: number,
    leadFollowUpType: ILeadFollowUpType
  ) {
    let url = `${this.leadFolowUpTypeUrl}/${leadFollowUpTypeId}`;
    leadFollowUpType.Id = leadFollowUpTypeId;
    return this.httpService.put(url, leadFollowUpType);
  }

  deleteLeadFollowUpType(leadFollowUpTypeId: number) {
    let url = `${this.leadFolowUpTypeUrl}/${leadFollowUpTypeId}`;
    return this.httpService.delete(url);
  }

  checkIfLeadFollowUpTypeNameExists(
    name: string,
    id: number
  ): Promise<Boolean> {
    let filter = `Name eq '${name}'`;

    if (id) {
      filter += ` and Id ne ${id}`;
    }
    return this.httpService
      .getODataCount(LEAD_FOLLOW_UP_TYPE_ODATA_URL, { filter: filter })
      .then((count) => !(count > 0));
  }
  //#endregion

  //#region lead competitor type

  getLeadCompetitorType(requestOptions: RequestOptions) {
    return this.httpService.getPaginatedData(
      this.leadCompetitorTypeUrl,
      requestOptions
    );
  }

  addLeadCompetitorType(leadCompetitorType: ILeadCompetitorType) {
    return this.httpService.post(
      this.leadCompetitorTypeUrl,
      leadCompetitorType
    );
  }

  getLeadCompetitorTypeById(leadCompetitorTypeId: number) {
    return this.httpService.getData(
      `${this.leadCompetitorTypeUrl}/${leadCompetitorTypeId}`
    );
  }

  updateLeadCompetitorType(
    leadCompetitorTypeId: number,
    leadCompetitorType: ILeadCompetitorType
  ) {
    let url = `${this.leadCompetitorTypeUrl}/${leadCompetitorTypeId}`;
    leadCompetitorType.Id = leadCompetitorTypeId;
    return this.httpService.put(url, leadCompetitorType);
  }

  deleteLeadCompetitorType(leadCompetitorTypeId: number) {
    let url = `${this.leadCompetitorTypeUrl}/${leadCompetitorTypeId}`;
    return this.httpService.delete(url);
  }

  checkIfLeadCompetitorTypeNameExists(
    name: string,
    id: number
  ): Promise<Boolean> {
    let filter = `Name eq '${name}'`;

    if (id) {
      filter += ` and Id ne ${id}`;
    }
    return this.httpService
      .getODataCount(LEAD_COMPETITOR_TYPE_ODATA_URL, { filter: filter })
      .then((count) => !(count > 0));
  }
  //#endregion

  //#region Lead terms

  checkIfLeadTermNameExists(
    termId: any,
    LeadTermId: number,
    id: number
  ): Promise<Boolean> {
    let filter = `TermId eq ${termId} and LeadId eq ${id}`;

    if (LeadTermId) {
      filter += ` and Id ne ${LeadTermId}`;
    }
    return this.httpService
      .getODataCount(LEAD_TERMS_ODATA_URL, { filter: filter })
      .then((count) => !(count > 0));
  }

  checkIfLeadPriorityOrderExists(
    priorityOrder: string,
    LeadTermId: number,
    id: number
  ): Promise<Boolean> {
    let filter = `PriorityOrder eq ${priorityOrder} and LeadId eq ${id}`;

    if (LeadTermId) {
      filter += ` and Id ne ${LeadTermId}`;
    }
    return this.httpService
      .getODataCount(LEAD_TERMS_ODATA_URL, { filter: filter })
      .then((count) => !(count > 0));
  }

  getLeadTerms(requestOptions: RequestOptions, id: number) {
    let LeadTermsDetailUrl = `${this.leadUrl}/${id}/LeadTerms`;
    return this.httpService.getPaginatedData(
      LeadTermsDetailUrl,
      requestOptions
    );
  }

  getLeadTermById(LeadTermId: number) {
    return this.httpService.getData(`${this.leadTermUrl}/${LeadTermId}`);
  }

  addLeadTerms(id: number | any, LeadTerm: ILeadTerm) {
    let url = `${this.leadUrl}/${id}/LeadTerms`;
    return this.httpService.post(url, LeadTerm);
  }

  updateLeadTerm(LeadTermId: number, LeadTerm: ILeadTerm) {
    let url = `${this.leadTermUrl}/${LeadTermId}`;
    LeadTerm.Id = LeadTermId;
    return this.httpService.put(url, LeadTerm);
  }

  deleteLeadTerm(LeadTermId: number) {
    let url = `${this.leadTermUrl}/${LeadTermId}`;
    return this.httpService.delete(url);
  }
  //#endregion

  //#region Lead attachments
  getLeadAttachments(requestOptions: RequestOptions, id: number) {
    let LeadAttachmentsDetailUrl = `${this.leadUrl}/${id}/LeadAttachments`;
    return this.httpService.getPaginatedData(
      LeadAttachmentsDetailUrl,
      requestOptions
    );
  }

  getLeadAttachmentById(LeadAttachmentId: number) {
    return this.httpService.getData(
      `${this.leadAttachmentUrl}/${LeadAttachmentId}`
    );
  }

  addLeadAttachment(LeadId: number, attachment: Attachment) {
    let LeadAttachmentsDetailUrl = `${this.leadUrl}/${LeadId}/LeadAttachments`;
    return this.httpService.post(LeadAttachmentsDetailUrl, {
      LeadId: LeadId,
      ...attachment,
    });
  }

  updateLeadAttachment(
    LeadAttachmentId: number,
    LeadId: number,
    attachment: Attachment
  ) {
    let url = `${this.leadAttachmentUrl}/${LeadAttachmentId}`;
    return this.httpService.put(url, {
      Id: LeadAttachmentId,
      LeadId: LeadId,
      ...attachment,
    });
  }

  deleteLeadAttachment(LeadAttachmentId: number) {
    let url = `${this.leadAttachmentUrl}/${LeadAttachmentId}`;
    return this.httpService.delete(url);
  }
  //#endregion

  //#region lead configuration
  checkIfLeadAttributeExistForLeadConfiguration(
    LeadAttributeId: any,
    LeadConfigurationId: number,
    LeadId: number
  ): Promise<Boolean> {
    let filter = `LeadAttributeId eq ${LeadAttributeId} and LeadId eq ${LeadId}`;
    if (LeadConfigurationId) {
      filter += ` and Id ne ${LeadConfigurationId}`;
    }

    return this.httpService
      .getODataCount(LEAD_CONFIGURATION_ODATA_URL, { filter: filter })
      .then((count) => !(count > 0));
  }

  getLeadConfigurations(requestOptions: RequestOptions, LeadId: number) {
    let url = `${this.leadUrl}/${LeadId}/LeadConfigurations`;
    return this.httpService.getPaginatedData(url, requestOptions);
  }

  getLeadConfigurationById(LeadConfigurationId: number) {
    let url = `${this.leadConfigurationUrl}/${LeadConfigurationId}`;
    return this.httpService.getData(url);
  }

  addLeadConfiguration(
    LeadId: number | any,
    LeadConfiguration: ILeadConfiguration
  ) {
    let url = `${this.leadUrl}/${LeadId}/LeadConfigurations`;
    return this.httpService.post(url, LeadConfiguration);
  }

  updateLeadConfiguration(
    LeadConfigurationId: number,
    LeadConfiguration: ILeadConfiguration
  ) {
    let url = `${this.leadConfigurationUrl}/${LeadConfigurationId}`;
    LeadConfiguration.Id = LeadConfigurationId;
    return this.httpService.put(url, LeadConfiguration);
  }

  deleteLeadConfiguration(LeadConfigurationId: number) {
    let url = `${this.leadConfigurationUrl}/${LeadConfigurationId}`;
    return this.httpService.delete(url);
  }
  //#endregion

  //#region Lead part
  getLeadParts(requestOptions: RequestOptions, id: number) {
    let LeadPartsDetailUrl = `${this.leadUrl}/${id}/LeadParts`;
    return this.httpService.getPaginatedData(
      LeadPartsDetailUrl,
      requestOptions
    );
  }

  
  getPartDetails(partId: number, requestOptions: RequestOptions){
    let url = `${this.partUrl}/${partId}/PartDetails`;
    return this.httpService.getPaginatedData(url, requestOptions);
  }

  getLeadPartById(LeadPartId: number) {
    return this.httpService.getData(`${this.leadPartUrl}/${LeadPartId}`);
  }

  addLeadParts(id: number | any, LeadPart: ILeadPart) {
    let url = `${this.leadUrl}/${id}/LeadParts`;
    return this.httpService.post(url, LeadPart);
  }

  updateLeadPart(LeadPartId: number, LeadPart: ILeadPart) {
    let url = `${this.leadPartUrl}/${LeadPartId}`;
    LeadPart.Id = LeadPartId;
    return this.httpService.put(url, LeadPart);
  }

  deleteLeadPart(LeadPartId: number) {
    let url = `${this.leadPartUrl}/${LeadPartId}`;
    return this.httpService.delete(url);
  }

  checkIfLeadPartExists(name: any, leadId:number,id: number) : Promise<Boolean>{
    let filter;
    if(isString(name)){
      filter= `PartName eq '${name}' and LeadId eq ${leadId}`;
    }
    else {
      filter = `PartId eq ${name} and LeadId eq ${leadId}`;
    }
    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(LEAD_PART_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  checkifPartNameExists(partName: string, partId: number) : Promise<Boolean>{
    let filter = `Name eq '${partName}'`;
    if(partId){
      filter+=` and Id ne ${partId}`;
    }
    return this.httpService
              .getODataCount(PART_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }
  //#endregion

    //#region Lead followUp
    getLeadFollowUp(requestOptions: RequestOptions, id: number) {
      let LeadFollowUpUrl = `${this.leadUrl}/${id}/LeadFollowUp`;
      return this.httpService.getPaginatedData(LeadFollowUpUrl,requestOptions);
    }
  
    getLeadFollowUpById(LeadFollowUpId: number) {
      return this.httpService.getData(`${this.leadFollowUpUrl}/${LeadFollowUpId}`);
    }
  
    addLeadFollowUp(id: number | any, LeadFollowUp: ILeadFollowUp) {
      let url = `${this.leadUrl}/${id}/LeadFollowUp`;
      return this.httpService.post(url, LeadFollowUp);
    }
  
    updateLeadFollowUp(LeadFollowUpId: number, LeadFollowUp: ILeadFollowUp) {
      let url = `${this.leadFollowUpUrl}/${LeadFollowUpId}`;
      LeadFollowUp.Id = LeadFollowUpId;
      return this.httpService.put(url, LeadFollowUp);
    }
  
    deleteLeadFollowUp(LeadFollowUpId: number) {
      let url = `${this.leadFollowUpUrl}/${LeadFollowUpId}`;
      return this.httpService.delete(url);
    }

    getFollowUpDateByLeadId(leadId: number){
      let url = `${environment.baseOdataUrl}/LeadFollowUps`;
      let filter = `LeadId eq ${leadId}`;
      return this.httpService.getOData(url, {select:"FollowUpDate",filter:filter});
    }

    checkIfFollowUpExists(LeadFollowUpId:number,FollowUpTypeId:any,date:any,LeadId:number,odataFollowUp:any): Promise<Boolean> {
      let followUp_date = new Date(date).toLocaleDateString();
      let odata_follow_up_date= new Date(odataFollowUp).toLocaleDateString();
      let filter = `'${odata_follow_up_date}' eq '${followUp_date}' and FollowUpTypeId eq ${FollowUpTypeId}and LeadId eq ${LeadId}`;
      if (LeadFollowUpId) {
        filter += ` and Id ne ${LeadFollowUpId}`;
      }

      return this.httpService
        .getODataCount(LEAD_FOLLOW_UP_ODATA_URL, { filter: filter })
        .then((count) => !(count > 0));
    }

    getContactPersonByLeadId(leadId: number) {
      let projectTeamUrl = `${this.leadFollowUpReportUrl}/${leadId}/ContactPerson`;
      return this.httpService.getData(projectTeamUrl);
    }

    getLastFiveLeadFollowUp(requestOptions: RequestOptions, id: number) {
      let LeadFollowUpUrl = `${this.leadUrl}/${id}/LastFiveLeadFollowUp`;
      return this.httpService.getPaginatedData(LeadFollowUpUrl,requestOptions);
    }
  
    //#endregion

      //#region Lead competitor
      getLeadCompetitor(requestOptions: RequestOptions, id: number) {
        let LeadCompetitorUrl = `${this.leadUrl}/${id}/LeadCompetitor`;
        return this.httpService.getPaginatedData(LeadCompetitorUrl,requestOptions);
      }
    
      getLeadComeptitorById(LeadCompetitorId: number) {
        return this.httpService.getData(`${this.leadCompetitorUrl}/${LeadCompetitorId}`);
      }
    
      addLeadCompetitor(id: number | any, LeadCompetitor: ILeadCompetitor) {
        let url = `${this.leadUrl}/${id}/LeadCompetitor`;
        return this.httpService.post(url, LeadCompetitor);
      }
    
      updateLeadCompetitor(LeadCompetitorId: number, LeadCompetitor: ILeadCompetitor) {
        let url = `${this.leadCompetitorUrl}/${LeadCompetitorId}`;
        LeadCompetitor.Id = LeadCompetitorId;
        return this.httpService.put(url, LeadCompetitor);
      }
    
      deleteLeadCompetitor(LeadCompetitorId: number) {
        let url = `${this.leadCompetitorUrl}/${LeadCompetitorId}`;
        return this.httpService.delete(url);
      }

      checkIfCompetitorExists(competitorName:any,competitorProduct:any,leadId:number,id:any): Promise<Boolean> {
        let filter = `CompetitorName eq '${competitorName}' and CompetitorProduct eq '${competitorProduct}' and LeadId eq ${leadId}`;
        if (id) {
          filter += ` and Id ne ${id}`;
        }
        return this.httpService
          .getODataCount(LEAD_COMPETITOR_ODATA_URL, { filter: filter })
          .then((count) => !(count > 0));
      }
      //#endregion

  //#region Lead attribute
  checkifLeadAttributeExist(
    LeadAttributeId: any,
    id: number
  ): Promise<Boolean> {
    let filter = `Name eq '${LeadAttributeId}'`;
    if (id) {
      filter += `and Id ne ${id}`;
    }
    return this.httpService
      .getODataCount(LEAD_ATTRIBUTE_ODATA_URL, { filter: filter })
      .then((count) => !(count > 0));
  }

  getLeadAttributes(requestOptions: RequestOptions) {
    return this.httpService.getPaginatedData(
      this.leadAttributeUrl,
      requestOptions
    );
  }

  getLeadAttributeById(LeadAttributeId: number) {
    return this.httpService.getData(
      `${this.leadAttributeUrl}/${LeadAttributeId}`
    );
  }

  addLeadAttribute(LeadAttribute: ILeadAttribute) {
    return this.httpService.post(this.leadAttributeUrl, LeadAttribute);
  }

  updateLeadAttribute(LeadAttributeId: number, LeadAttribute: ILeadAttribute) {
    let url = `${this.leadAttributeUrl}/${LeadAttributeId}`;
    LeadAttribute.Id = LeadAttributeId;
    return this.httpService.put(url, LeadAttribute);
  }

  deleteLeadAttribute(LeadAttributeId: number) {
    let url = `${this.leadAttributeUrl}/${LeadAttributeId}`;
    return this.httpService.delete(url);
  }
  //#endregion

  //#region Lead attribute option
  checkIfDisplayValueExist(
    LeadAttributeOptionId: any,
    LeadAttributeId: number,
    id: number
  ): Promise<Boolean> {
    let filter = `DisplayValue eq '${LeadAttributeOptionId}' and LeadAttributeId eq ${LeadAttributeId}`;
    if (id) {
      filter += `and Id ne ${id}`;
    }
    return this.httpService
      .getODataCount(LEAD_ATTRIBUTE_OPTION_ODATA_URL, { filter: filter })
      .then((count) => !(count > 0));
  }

  checkIfLeadAtributeOptionsExist(LeadAttributeId: number): Promise<Boolean> {
    let filter = `LeadAttributeId eq ${LeadAttributeId}`;
    return this.httpService
      .getODataCount(LEAD_ATTRIBUTE_OPTION_ODATA_URL, { filter: filter })
      .then((count) => count > 0);
  }

  getLeadAttributeOptions(requestOptions: RequestOptions, id: number) {
    let LeadAttributeOptionDetailUrl = `${this.leadAttributeUrl}/${id}/LeadAttributeOption`;
    return this.httpService.getPaginatedData(
      LeadAttributeOptionDetailUrl,
      requestOptions
    );
  }

  getLeadAttributeOptionById(LeadAttributeOptionId: number) {
    return this.httpService.getData(
      `${this.leadAttributeOptionUrl}/${LeadAttributeOptionId}`
    );
  }

  addLeadAttributeOption(
    id: number | any,
    LeadAttributeOption: ILeadAttributeOption
  ) {
    let url = `${this.leadAttributeUrl}/${id}/LeadAttributeOption`;
    return this.httpService.post(url, LeadAttributeOption);
  }

  updateLeadAttributeOption(
    LeadAttributeOptionId: number,
    LeadAttributeOption: ILeadAttributeOption
  ) {
    let url = `${this.leadAttributeOptionUrl}/${LeadAttributeOptionId}`;
    LeadAttributeOption.Id = LeadAttributeOptionId;
    return this.httpService.put(url, LeadAttributeOption);
  }

  deleteLeadAttributeOption(LeadAttributeOptionId: number) {
    let url = `${this.leadAttributeOptionUrl}/${LeadAttributeOptionId}`;
    return this.httpService.delete(url);
  }
  //#endregion

    //#region lead addresses

    getLeadAddresses(requestOptions: RequestOptions, id:number){
      let leadAddressesDetailUrl = `${this.companyInformationUrl}/${id}/TenantAddress`;
      return this.httpService.getPaginatedData(leadAddressesDetailUrl, requestOptions);
    }
  
    addLeadAddress(id:number, leadAddressesData: ILeadAddressesData){
      let leadAddressesDetailUrl =`${this.leadUrl}/${id}/LeadAddress`;
      return this.httpService.post(leadAddressesDetailUrl, leadAddressesData);
    }
  
    getLeadAddressById(leadAddressId: number){
      return this.httpService.getData(`${this.leadAddressUrl}/${leadAddressId}`);
    }
  
    updateLeadAddress(leadAddressId: number, leadAddressesData: ILeadAddressesData){
      let url = `${this.leadAddressUrl}/${leadAddressId}`;
      leadAddressesData.Id = leadAddressId;
      return this.httpService.put(url, leadAddressesData);
    }
  
    deleteLeadAddress(leadAddressId: number){
      let url = `${this.leadAddressUrl}/${leadAddressId}`;
      return this.httpService.delete(url);
    }
  
    getDataByCountryZipcode(zipCode : string, countryId: number) {
      return this.httpService.getData(`${this.postalCodeUrl}/${countryId}/${zipCode}`);
    }
    //#endregion

     //#region Customer contacts
  checkIfDepartmentFunctionExistsForDepartment( departmentFunctionId: any, departmentId: any, id:number) : Promise<Boolean>{
    let filter = `DepartmentFunctionId eq ${departmentFunctionId} and DepartmentId eq ${departmentId}`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(LEAD_CUSTOMER_CONTACT_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  } 

  getLeadCustomerContacts(requestOptions: RequestOptions, id:number){
    let leadCustomerContactsDetailUrl = `${this.leadAddressUrl}/${id}/LeadContact`;
    return this.httpService.getPaginatedData(leadCustomerContactsDetailUrl, requestOptions);
  }

  addLeadCustomerContact(id:number | any, leadCustomerContactsData: ILeadCustomerContactsData){
    let leadCustomerContactsDetailUrl =`${this.leadAddressUrl}/${id}/LeadContact`;
    return this.httpService.post(leadCustomerContactsDetailUrl, leadCustomerContactsData);
  }

  getLeadCustomerContactById(customerContactId: number){
    return this.httpService.getData(`${this.tenantContactUrl}/${customerContactId}`);
  }

  updateLeadCustomerContact(customerContactId: number, customerContactsData: ILeadCustomerContactsData){
    let url = `${this.tenantContactUrl}/${customerContactId}`;
    customerContactsData.Id = customerContactId;
    return this.httpService.put(url, customerContactsData);
  }

  deleteLeadCustomerContact(customerContactId: number){
    let url = `${this.leadCustomerContactUrl}/${customerContactId}`;
    return this.httpService.delete(url);
  }

  getAllTenantContacts(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.tenantContactUrl, requestOptions);
  }

  linkCompanyToContact(linkCompanyToContactData: ILinkCompanyToContact){
    let url = `${this.linkCompanyToContactUrl}/LinkCompanyToContact`;
    return this.httpService.post(url, linkCompanyToContactData);
  }

  //#endregion

  //#region lead Customer contact number
  getLeadCustomerContactNumbers(requestOptions: RequestOptions, id:number){
    let leadContactNumbersDetailUrl = `${this.tenantContactUrl}/${id}/TenantContactNumber`;
    return this.httpService.getPaginatedData(leadContactNumbersDetailUrl, requestOptions);
  }

  addLeadCustomerContactNumber(id:number, customerContactNumberData: ILeadCustomerContactNumber){
    let customerContactNumbersDetailUrl =`${this.tenantContactUrl}/${id}/TenantContactNumber`;
    return this.httpService.post(customerContactNumbersDetailUrl, customerContactNumberData);
  }

  getLeadCustomerContactNumberById(customerContactNumberId: number){
    return this.httpService.getData(`${this.tenantContactNumberUrl}/${customerContactNumberId}`);
  }

  updateLeadCustomerContactNumber(customerContactNumberId: number, customerContactNumberData: ILeadCustomerContactNumber){
    let url = `${this.tenantContactNumberUrl}/${customerContactNumberId}`;
    customerContactNumberData.Id = customerContactNumberId;
    return this.httpService.put(url, customerContactNumberData);
  }

  deleteLeadCustomerContactNumber(customerContactNumberId: number){
    let url = `${this.tenantContactNumberUrl}/${customerContactNumberId}`;
    return this.httpService.delete(url);
  }
  //#endregion

  //# Lead Detail

  // deleteLeadDetail(leadDetailId: number){
  //   let url = `${this.leadDetailUrl}/${leadDetailId}`;
  //   return this.httpService.delete(url);
  // }
  //#endlead Detail

  //#confirm lead quotation
  
  confirmQuotation(id: number,confirmLeadQuotation: IConfirmLeadQuotation)
  {
    let confirmQuotationDetailUrl =`${this.leadUrl}/${id}/confirm`;
    return this.httpService.post(confirmQuotationDetailUrl, confirmLeadQuotation);
  }

  confirmLead(id: number,lead:ILead)
  {
    let confirmLeadUrl =`${this.leadUrl}/${id}/confirm`;
    return this.httpService.post(confirmLeadUrl, lead);
  }

  getQuotationById(quotationId:number){
    return this.httpService.getData(`${this.quotationUrl}/${quotationId}`);
  }

  getQuotationNumber(customerId: number){
    let params = new HttpParams()
                        .append("customerId", customerId);
    let url = `${this.quotationUrl}/Generate/Number`;
    return this.httpService.getText(url, params);
  }

  getCustomerIdByLeadId(leadId: number){
    let url = `${environment.baseOdataUrl}/Leads/${leadId}`;
    return this.httpService.getOData(url, {select: "CustomerId" });
  }

  getSourceIdByLeadId(leadId: number){
    let url = `${environment.baseOdataUrl}/Leads/${leadId}`;
    return this.httpService.getOData(url, {select: "SourceId" });
  }
  //#endregion

  //#region Lead status

  checkIfLeadStatusNameExists(name: string, id: number) : Promise<Boolean>{
    let filter = `Name eq '${name}'`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(LEAD_STATUS_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  getLeadStatus(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.leadStatusUrl, requestOptions);
  }

  getLeadStatusById(leadStatusId:number){
    return this.httpService.getData(`${this.leadStatusUrl}/${leadStatusId}`);
  }

  addLeadStatus(leadStatus: ILeadStatus){
    return this.httpService.post(this.leadStatusUrl, leadStatus);
  }

  updateLeadStatus(leadStatusId: number, leadStatus: ILeadStatus){
    let url = `${this.leadStatusUrl}/${leadStatusId}`;
    leadStatus.Id = leadStatusId;
    return this.httpService.put(url, leadStatus);
  }

  deleteLeadStatus(leadStatusId: number)
  {
    let url = `${this.leadStatusUrl}/${leadStatusId}`;
    return this.httpService.delete(url);
  }

  //#endregion

  getLeadQuotations(requestOptions: RequestOptions, id: number){
    let quotationurl = `${this.leadQuotationConfirmationUrl}/${id}/LeadQuotations`;
    return this.httpService.getPaginatedData(quotationurl, requestOptions);
  }

  getLeadQuotationConfirmations(requestOptions: RequestOptions, id: number){
    let quotationConfirmationurl = `${this.leadQuotationConfirmationUrl}/${id}/LeadQuotationConfirmations`;
    return this.httpService.getPaginatedData(quotationConfirmationurl, requestOptions);
  }

  getQuotationIdByLeadId(leadId: number){
    let url = `${environment.baseOdataUrl}/LeadQuotationConfirmations`;
    let filter = `LeadId eq ${leadId}`;
    return this.httpService.getOData(url, {select:"QuotationId",filter:filter});
  }

  //#region Part attribute for lead part
  getPartAttributeDetailsByPartId(partId: number){
    let partBomDetailUrl = `${this.partDetailUrl}/${partId}/PartAttributes`;
    return this.httpService.getData(partBomDetailUrl);
  }

  CheckPartAttributeDetailsConflictsByPartId(categoryId: number, partDetail: IPartDetail){
    let partBomDetailUrl = `${this.partDetailUrl}/${categoryId}/Conflict`;
    return this.httpService.put(partBomDetailUrl, partDetail);
  }

  getPartCategoryDetailsByPartCategoryId(partCategoryId: number | null){
    let partBomDetailUrl = `${this.partCategoryDetailUrl}/${partCategoryId}/PartCategoryDetails`;
    return this.httpService.getData(partBomDetailUrl);
  }

  getPartAttributeOptions(){
    let url = PART_ATTRIBUTE_OPTION_ODATA_URL;
    return this.httpService.getOData(url, {expand: "UnitOfMeasure"});
  }

  getPartNumber(partCategoryId: number, partSourceTypeId: number){
    let params = new HttpParams()
                        .append("partCategoryId", partCategoryId)
                        .append("partSourceTypeId", partSourceTypeId);
    let url = `${this.partUrl}/Generate/Number`;
    return this.httpService.getText(url, params);
  }

  updatePartAttributeForPart(partId: number, leadPartId: number, partDetail: IPartDetail) {
    let url = `${this.partDetailUrl}/${partId}/${leadPartId}/UpdateAttributes`;
    partDetail.PartId = partId;
    return this.httpService.put(url, partDetail);
  }
  //#endregion

  private readonly auditLogUrl = `${environment.baseApiUrl}/Audit`;

  getAuditTrailsById(moduleName: string, id: number) {  //
    let auditTrailsUrl = `${this.auditLogUrl}/Histories/${moduleName}/${id}`;
    return this.httpService.getData(auditTrailsUrl);
  }

  reviseLeadQuotation(id: number| any, lead: ILead){
    let url = `${this.leadUrl}/${id}/reviseLeadQuotation`;
    return this.httpService.post(url, lead);
  }

  getCustomers(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.customerInformationUrl, requestOptions);
  }

  deleteCompany(companyId: number){
    let url = `${this.companyInformationUrl}/${companyId}`;
    return this.httpService.delete(url);
  }

  //#region Follow up status

  getLeadFollowUpStatus(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.leadFolowUpStatusUrl, requestOptions);
  }

  getLeadFollowUpStatusById(leadFollowUpStatusId:number){
    return this.httpService.getData(`${this.leadFolowUpStatusUrl}/${leadFollowUpStatusId}`);
  }

  addLeadFollowUpStatus(leadFollowUpStatus: ILeadFollowUpStatus){
    return this.httpService.post(this.leadFolowUpStatusUrl, leadFollowUpStatus);
  }

  updateLeadFollowUpStatus(leadFollowUpStatusId: number, leadFollowUpStatus: ILeadFollowUpStatus){
    let url = `${this.leadFolowUpStatusUrl}/${leadFollowUpStatusId}`;
    leadFollowUpStatus.Id = leadFollowUpStatusId;
    return this.httpService.put(url, leadFollowUpStatus);
  }

  deleteLeadFollowUpStatus(leadFollowUpStatusId: number)
  {
    let url = `${this.leadFolowUpStatusUrl}/${leadFollowUpStatusId}`;
    return this.httpService.delete(url);
  }

  checkIfLeadFollowUpStatusNameExists(name: string, id: number) : Promise<Boolean>{
    let filter = `Name eq '${name}'`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(LEAD_FOLLOW_UP_STATUS_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  //#endregion

  //#region Kanban Board

  getKanBanBoardLeads(requestOptions: RequestOptions) {
    let url = `${this.leadUrl}/KanbanBoard`;
    return this.httpService.getPaginatedData(url, requestOptions);
  }

  //#endregion

}
 