import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import devextremeAjax from 'devextreme/core/utils/ajax';
import { AppComponent } from './app.component';
import { SideNavOuterToolbarModule, SingleCardModule } from './layouts';
import { FooterModule } from './shared/components';
import { ScreenService, AppInfoService, AuthService } from './shared/services';
import { UnauthenticatedContentModule } from './unauthenticated-content';
import { AppRoutingModule } from './app-routing.module';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { NaivgationService } from './core/services/navigation.service';
import { AuthHttpInterceptor, AuthModule, AuthService as Auth0Service } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { sendRequestFactory } from 'src/app/core/helpers/ng-http-client-helper';
import { LoginComponent } from './login/login.component';
import { AuthorizationService } from './core/services/authorization.service';
import { ajaxSetup } from '@devexpress/analytics-core/analytics-utils';
import { SharedModule } from './shared/shared.module';
import { PlatformService } from './core/services/platform.service';
import { ToastService } from './core/services/toast.service';
import { BrowserTransferStateModule } from '@angular/platform-browser';
import { ViewLeadQuotationsConfirmComponent } from './view-lead-quotations-confirm/view-lead-quotations-confirm.component';//import { ViewPartWorkstationsComponent } from './part/workstations/view-part-workstations/view-part-workstations.component';


function initializePages(navigationService: NaivgationService,
  platformService: PlatformService,
  toast: ToastService,
  authorizationService: AuthorizationService,
  auth0: Auth0Service) {
  return () => {
    auth0.isAuthenticated$.subscribe(isAuthenticated => {
      if (isAuthenticated) {
        authorizationService.isAuthenticated = true;
        
          authorizationService.getUserAccess().then(p => {
            authorizationService.getUserDetails().then(() => {
              authorizationService.setAuthenticated(isAuthenticated);
              auth0.getAccessTokenSilently().subscribe(token => {
                ajaxSetup.ajaxSettings = {
                  headers: {
                    'Authorization': 'Bearer ' + token
                  }
                };
              });
              platformService.checkDatabaseMigrations().then((response: any) => {
                if (!response.Success) {
                  toast.error(response.Message);
                }
              })
            })
          });
      }
    })
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ViewLeadQuotationsConfirmComponent,
  ],
  imports: [
    AuthModule.forRoot({
      ...environment.auth0,
      httpInterceptor: {
        allowedList: [
          `${environment.baseApiUrl}/*`,
          `${environment.baseOdataUrl}/*`
        ],
      },
    }),
    BrowserModule,
    BrowserTransferStateModule,
    SideNavOuterToolbarModule,
    SingleCardModule,
    FooterModule,
    UnauthenticatedContentModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    SharedModule
  ],
  providers: [
    AuthService,
    ScreenService,
    AppInfoService,
    {
      provide: APP_INITIALIZER, useFactory: initializePages, deps: [NaivgationService, PlatformService, ToastService, AuthorizationService, Auth0Service], multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(httpClient: HttpClient) {
    devextremeAjax.inject({ sendRequest: sendRequestFactory(httpClient) });
  }
}
