export const environment = {
  auth0:{
    domain: 'dev-setumes.eu.auth0.com',
    clientId: '4CwAVNK4YNdJn4nUQnmiZgi6HfZVbnAn',
    redirectUri: window.location.origin,
    audience: "api.dev.setumes.com"
  },
  production: false,
  baseUrl: "https://api.dev.setumes.com/",
    baseApiUrl: "https://api.dev.setumes.com/api",
    baseOdataUrl: "https://api.dev.setumes.com/odata"
  };
  