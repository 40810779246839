import { environment } from "src/environments/environment";
import { IAuditableEntity, IEntityBase } from "../shared/models";

export const TERM_TYPES_ODATA_URL = `${environment.baseOdataUrl}/TermTypes`;
export const TERM_ODATA_URL = `${environment.baseOdataUrl}/Terms`;
export const TERM_MAPPING_ODATA_URL = `${environment.baseOdataUrl}/TermMappings`;
export const CONTACT_TYPE_ODATA_URL = `${environment.baseOdataUrl}/ContactTypes`;
export const ADDRESS_TYPES_ODATA_URL = `${environment.baseOdataUrl}/AddressTypes`;
export const AUTO_ASSIGNMENT_FIELD_ODATA_URL = `${environment.baseOdataUrl}/AutoAssignmentFields`;
export const AUTO_ASSIGNMENT_ODATA_URL = `${environment.baseOdataUrl}/AutoAssignments`;
export const PAYMENT_CATEGORY_ODATA_URL = `${environment.baseOdataUrl}/PaymentCategories`;
export const UNIT_OF_MEASURE_ODATA_URL = `${environment.baseOdataUrl}/UnitOfMeasures`;
export const PRODUCT_INDUSTRY_ODATA_URL = `${environment.baseOdataUrl}/ProductCategories`;
export const UNIT_OF_MEASURE_TYPES_ODATA_URL = `${environment.baseOdataUrl}/UnitOfMeasureTypes`;
export const EMAIL_TEMPLATE_ODATA_URL = `${environment.baseOdataUrl}/EmailTemplates`;
export const LANGUAGE_ODATA_URL = `${environment.baseOdataUrl}/Languages`;

export interface ITermFilter {
    Name : string,   
}

export interface ITerm {
    Id? : number,
    Name : string,
    Value: string,
}

export interface ITermMapping  extends IEntityBase,IAuditableEntity{ 
    TermId : number | null,
    TermTypeId : number | null,  
}

export interface IAutoAssignmentFilter  {
    AutoAssignmentFieldId:  number | null,
}

export interface IAutoAssignment {
    Id? : number,
    AutoAssignmentFieldId : number | null,
    Value: string,
    IsActive : false,  
}

export interface IPaymentCategoryFilter  {
    Name: string
}

export interface IPaymentCategory extends IEntityBase,IAuditableEntity {
    Id? : number,
    Name : string,
    PaymentCategoryType: number | any,
    ParentCategoryId: number | null,
    Description: string,
}
export interface IUnitOfMeasureFilter {
    Name : string,
    UnitOfMeasureTypeId : number | null, 
}

export interface IUnitOfMeasure extends IEntityBase,IAuditableEntity{
    Id? : number,
    UnitOfMeasureTypeId: null, 
    Name : string,
    Description : string,
    Key : string
}

export interface ITenantAddressFilter
{
    AddressTypeId: number | null,
    City : string,
    State: string,
    Country: string,
}

export interface ITenantAddressesData {
    Id? : number,
    AddressTypeId : number | null,
    AddressLine1 : string,
    AddressLine2 : string,
    IsDefault: false,
    City : string,
    State: string,
    Country: string,
    ZipCode: string,
    Email: string
}

export interface ITenantContactsData {
    Id? : number,
    ContactTypeId : number | null,
    Department: string,
    ContactPerson: string,
    ContactNumber: number | null,
    IsDefault: false,
    Extension: string,
    CountryCode: string,
}

export interface ICompanyData {
    Id : number| null,
    CompanyName : string,
    Director: string,
    RegistrationNumber: string,
    VatNumber: string,
    TaxNumber: string,
    FaxNumber: string,
    Email: string,
    ContactNumber: string,
    IECNumber: string
}

export interface IPlantLocationFilter {
    LocationTypeId : number | any,
    Name : string,   
}

export interface IPlantLocation {
    Id?: number | null,
    ParentLocationId: number | null,
    LocationTypeId: number | null,
    Name: string,
    Description: string,
    TenantAddressId: number | null,
  };

export interface IContactTypeFilter  {
    Name: string
}

export interface IContactType extends IEntityBase,IAuditableEntity {
    Id? : number,
    Name : string,
    Description: string,
}

export interface IAddressTypeFilter  {
    Name: string
}

export interface IAddressType extends IEntityBase,IAuditableEntity {
    Id? : number,
    Name : string,
    Description: string,
}

export interface IProductIndustryFilter  {
    Name: string
}

export interface IProductIndustry extends IEntityBase,IAuditableEntity {
    Id? : number,
    Name : string,
    ParentCategoryId: number | null,
    Description: string,
}

export interface IUnitOfMeasureConversionFilter {
    FromUnitOfMeasureId : number | null,
    ToUnitOfMeasureId: number | null,
    UnitOfMeasureTypeId: number | null,
}

export interface IPartUnitOfMeasureConversion extends IEntityBase,IAuditableEntity {
    FromUnitOfMeasureId : number | null,
    ToUnitOfMeasureId : number | null,
    ConversionValue : number | null,
}

export interface IUnitOfMeasureTypeFilter  {
    Name: string
}

export interface IUnitOfMeasureType extends IEntityBase,IAuditableEntity {
    Id? : number,
    Name : string,
    Description: string,
}

export interface IEmailFilter {
    EntityType : string,  
    BaseCountryCode: string  
}

export interface IEmailTemplate extends IEntityBase,IAuditableEntity {
    Id? : number,
    EntityType : any,
    Template: string,
    BaseCountryCode: string 
}

