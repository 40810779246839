<section class="form-container filter-form">
    <dx-form
        id="form"
        colCount="1"
        [formData]="dataModel"
        [showColonAfterLabel]="true"
        [minColWidth]='300'
        labelLocation="left">
        <dxi-item
            itemType="group"
            colCount="auto">
                <dxi-item *ngFor="let item of filterItems"
                    [itemType]="item.itemType"
                    [id]="item.dataField"
                    [dataField]="item.dataField"
                    [name]="getFieldName(item)"
                    [editorType]="item.editorType"
                    [label]="item.label"
                    [editorOptions]="item.editorOptions"
                    [validationRules]="item.validationRules"></dxi-item>
        </dxi-item>
        <dxi-item [visible]="filterItems != null && filterItems.length > 0" itemType="group">
            <div *dxTemplate>
                <dx-toolbar class="grid-toolbar-container toolbar-container">
                    <dxi-item 
                        location="left" 
                        widget="dxButton" 
                        [options]="searchOptions">
                    </dxi-item>
                    <dxi-item 
                        location="left" 
                        widget="dxButton" 
                        stylingMode="text"
                        [options]="defaultOptions">
                    </dxi-item>
                    <dxi-item 
                        location="left" 
                        widget="dxButton" 
                        stylingMode="text"               
                        [options]="clearDefaultOptions">
                    </dxi-item>
                </dx-toolbar>
            </div>
        </dxi-item>
      </dx-form>
</section>

