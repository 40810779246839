import { Component, HostBinding, HostListener } from '@angular/core';
import { ScreenService, AppInfoService, AuthService } from './shared/services';

import { TranslationService } from './core/services/translation.service';
import { loadMessages, locale } from 'devextreme/localization';
import * as deMessages from "devextreme/localization/messages/de.json";
import * as de from "../i18/de.json";
import * as en from "../i18/en.json";
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { AuthorizationService } from './core/services/authorization.service';
import { CurrencyService } from './core/services/currency.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NaivgationService } from './core/services/navigation.service';
import { ToastService } from './core/services/toast.service';
import { filter } from 'rxjs/operators';
import { RouterService } from './core/services/router.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes).filter(cl => this.screen.sizes[cl]).join(' ');
  }
 

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
      if(this.navigationService.isFormDirty){
        event.preventDefault();
        return false;
      }
      return true;
  }

  @HostListener("window:popstate", ["$event"]) backHandler(event: Event) {
    // this.navigationService.popHistory();
    // let route = this.navigationService.popHistory();
    // let url = location.origin + route;
    event.preventDefault();
    event.stopPropagation();
    // // history.replaceState(null, document.title, url);
    // if(route)
    //   this.locationService.go(route);
    // return false;
    // // history.pushState(null, document.title, url);
    if(!this.navigationService.isForm){
      // window.history.forward();
      this.locationService.forward();
    }
    // window.history.go(route.navigation.navigationId);
}

  public isOnline = true;
  public isAuthenticated = false;
  constructor(private screen: ScreenService,
    private currencyService: CurrencyService,
    private locationService: Location,
    public appInfo: AppInfoService,
    private translationService: TranslationService,
    private navigationService: NaivgationService,
    private auth0: Auth0Service,
    private authService: AuthorizationService,
    private toastService: ToastService,
    private router: Router,
    private routerService: RouterService,
    private route: ActivatedRoute) {

    loadMessages(deMessages);
    loadMessages(de);
    loadMessages(en);

    this.route.queryParams.subscribe(params => {
      let invitationId = params["invitation"];
      let organizationId = params["organization"];
      if (invitationId && organizationId) {
        this.auth0.loginWithRedirect({ invitation: invitationId, organization: organizationId });
      }
    })

    authService.isAuthenticatedObservable.subscribe(isAuthenticated => {
      if (isAuthenticated) {
        navigationService.getAllPages();
        navigationService.getAllNavigations().then(p => {
          this.currencyService.setDefaultCompanyCurrency().then(() => {
            locale(authService.CurrentUser.Language);
            this.isAuthenticated = isAuthenticated;
          });
        });
      }
    })

    setInterval(() => {
      if (navigator.onLine) {
        if (!this.isOnline) {
          location.reload();
        }
        this.isOnline = true;
      }
      else {
        this.isOnline = false;
      }
    }, 3000)
  }
}
