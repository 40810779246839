import { environment } from "src/environments/environment";
import { IAuditableEntity, IEntityBase } from "../shared/models";

export const CUSTOMERS_ODATA_URL = `${environment.baseOdataUrl}/Customers`;
export const ACCOUNTS_RECEIVABLE_INVOICE_STATUS_URL = `${environment.baseOdataUrl}/AccountsReceivableInvoiceStatuses`;
export const ACCOUNTS_PAYABLE_INVOICE_STATUS_URL = `${environment.baseOdataUrl}/AccountsPayableInvoiceStatuses`;
export const TAX_SLAB_ODATA_URL = `${environment.baseOdataUrl}/TaxSlabs`;
export const UNIT_OF_MEASURE_ODATA_URL = `${environment.baseOdataUrl}/UnitOfMeasures`;
export const CUSTOMER_PARTS_ODATA_URL = `${environment.baseOdataUrl}/CustomerParts`;
export const TERM_ODATA_URL = `${environment.baseOdataUrl}/Terms`;
export const TERM_MAPPING_ODATA_URL = `${environment.baseOdataUrl}/TermMappings`;
export const SUPPLIERS_ODATA_URL = `${environment.baseOdataUrl}/Suppliers`;
export const SUPPLIER_PARTS_ODATA_URL = `${environment.baseOdataUrl}/SupplierParts`;
export const BANK_ODATA_URL = `${environment.baseOdataUrl}/Banks`;
export const ACCOUNT_STATUS_URL = `${environment.baseOdataUrl}/AccountStatuses`;
export const ACCOUNT_TYPE_URL = `${environment.baseOdataUrl}/AccountTypes`;
export const TRANSACTION_TYPE_ODATA_URL = `${environment.baseOdataUrl}/TransactionTypes`;
export const TRANSACTION_STATUS_URL = `${environment.baseOdataUrl}/TransactionStatuses`;
export const PAYMENT_MODE_ODATA_URL = `${environment.baseOdataUrl}/PaymentModes`;
export const PAYMENT_CATEGORY_ODATA_URL = `${environment.baseOdataUrl}/PaymentCategories`;
export const BANK_ACCOUNT_ODATA_URL = `${environment.baseOdataUrl}/BankAccounts`;
export const ACCOUNTS_RECEIVABLE_INVOICE_TERM_ODATA_URL = `${environment.baseOdataUrl}/AccountsReceivableInvoiceTerms`;
export const ACCOUNTS_PAYABLE_INVOICE_TERM_ODATA_URL = `${environment.baseOdataUrl}/AccountsPayableInvoiceTerms`;
export const CUSTOMER_ADDRESS_ODATA_URL = `${environment.baseOdataUrl}/CustomerAddresses`;
export const TENANT_ADDRESS_ODATA_URL = `${environment.baseOdataUrl}/TenantAddresses`;
export const COSTING_ODATA_URL = `${environment.baseOdataUrl}/Costings`;
export const CURRENCY_ODATA_URL = `${environment.baseOdataUrl}/Currencies`;
export const PROJECT_ODATA_URL= `${environment.baseOdataUrl}/Projects`;
export const COMPANY_ODATA_URL = `${environment.baseOdataUrl}/Companies`;
export const EMPLOYEE_ODATA_URL = `${environment.baseOdataUrl}/Employees`;
export const TRANSACTION_ODATA_URL = `${environment.baseOdataUrl}/Transactions`;
export const ACCOUNTS_PAYABLE_INVOICE_ODATA_URL = `${environment.baseOdataUrl}/AccountsPayableInvoices`;
export const ACCOUNTS_RECEIVABLE_INVOICE_ODATA_URL = `${environment.baseOdataUrl}/AccountsReceivableInvoices`;

export interface IAccountsReceivableInvoiceFilter {
    CustomerId : number | any,
    AccountsReceivableInvoiceStatusId : number | any,
    InvoiceNumber : string,
    PaymentDateFrom : string,
    PaymentDateTo : string,
}

export interface IAccountsReceivableInvoice {
    Id? : number,
    CustomerId : number | any,
    AssignedToId : number | any,
    InvoiceNumber : string,
    InvoiceDate :  Date | any,
    DueDate :  Date | any,
    CurrencyCode?: string,
    AccountsReceivableInvoiceStatusId : number | any,
    BillingAddressId : number | any,
    ShippingAddressId : number | any,
    Remarks : string,
    AccountsReceivableInvoiceDetail?: IAccountsReceivableInvoiceDetail,
    InvoiceType: number | any,
    ProjectId : number | any,
}

export interface IAccountsReceivableInvoiceDetail {
    GrossAmount : string,
    Discount? : string,
    DiscountedAmount? : string,
    Tax : number|any,
    NetAmount : string   
}

export interface IAccountsReceivableInvoiceLine {
    Id? : number,
    PartId : number | null,
    CustomerPartNumber : string,
    AdditionalDescription: string,
    Quantity : number,
    UnitOfMeasureId : number | null,
    UnitPrice : number,
    NetAmount : number,
    Discount : number | null,
    DiscountedAmount: number | null,
    TaxSlabId: number | null,
    Tax: number,
    GrossAmount : number,
    Remarks : string 
}

export interface IAccountsReceivableInvoiceTerm{
    Id? : number,
    TermId: number | any,
    PriorityOrder: number | any,
    Value: string
}

export interface IAccountsPayableInvoiceFilter {
    SupplierId : number | any,
    AccountsPayableInvoiceStatusId : number | any,
    InvoiceNumber : string,
    PaymentDateFrom : string,
    PaymentDateTo : string,  
}

export interface IAccountsPayableInvoice {
    Id? : number,
    SupplierId : number | any,
    AssignedToId: number | any,
    InvoiceNumber : string,
    InvoiceDate :  Date | any,
    DueDate :  Date | any,
    CurrencyCode?: string,
    AccountsPayableInvoiceStatusId : number | any,
    BillingAddressId : number | any,
    ShippingAddressId : number | any,
    ProjectId : number | any,
    Remarks : string,
    AccountsPayableInvoiceDetail?: IAccountsPayableInvoiceDetail   
}

export interface IAccountsPayableInvoiceDetail {
    GrossAmount : string,
    Discount? : string,
    DiscountedAmount? : string,
    Tax : number|any,
    NetAmount : string   
}

export interface IAccountsPayableInvoiceLine {
    Id? : number,
    PartId : number | null,
    SupplierPartNumber : string,
    AdditionalDescription: string,
    Quantity : number,
    UnitOfMeasureId : number | null,
    UnitPrice : number,
    NetAmount : number,
    Discount : number | null,
    DiscountedAmount: number | null,
    TaxSlabId: number | null,
    Tax: number,
    GrossAmount : number,
    Remarks : string 
}

export interface IAccountsPayableInvoiceTerm{
    Id? : number,
    TermId: number | any,
    PriorityOrder: number | any,
    Value: string
}

export interface IBankFilter {
    BankId : number | any,
}

export interface IBank extends IEntityBase,IAuditableEntity{
    Id? : number,
    Name : string,
    Description : string,
}

export interface IBankAccountFilter {
    BankId : number | any,
    AccountTypeId : number | any,
    AccountStatusId : number | any,
    AccountNumber : string,   
}

export interface IBankAccount {
    Id? : number,
    CompanyId : number | any,
    BankId : number | any,
    AccountTypeId : number | any,
    AccountStatusId : number | any,
    AccountNumber : string,  
    Code : string,  
    BranchName : string,
    BranchNumber : string,     
}

export interface ITransactionFilter {
    BankId : number | any,
    AccountNumber : string,   
    TransactionTypeId : number | any,
    TransactionStatusId : number | any,
    PaymentCategoryId : number | any,
    PaymentModeId : number | any,
    TransactionNumber : string,
    TransactionDateFrom : string,
    TransactionDateTo : string,   
}

export interface ITransaction {
    Id? : number,
    InvoiceIds :any,
    BankAccountId : number | any,
    TransactionTypeId : number | any,
    TransactionStatusId : number | any,
    PaymentCategoryId : number | any,
    PaymentModeId : number | any,
    TransactionNumber : string, 
    TransactionDate : Date |any,  
    Amount : number,   
    Remarks : string,
    ProjectId : number | any
}

export interface ICostingFilter {
    Name : string  
}

export interface ICosting extends IEntityBase,IAuditableEntity {
    Id? : number,
    Name : string,
    Description: string,
}

export interface ITaxSlabFilter {
    Name : string  
}

export interface ITaxSlab extends IEntityBase,IAuditableEntity {
    Id? : number,
    Name : string,
    Description: string,
    TaxPercentage : number | any;
}

export interface IAccountsPayableInvoiceStatusFilter  {
    Name: string
}

export interface IAccountsPayableInvoiceStatus {
    Id? : number,
    Name : string,
    Description : string,
    IsDefault: false,
}

export interface IAccountsReceivableInvoiceStatusFilter  {
    Name: string
}

export interface IAccountsReceivableInvoiceStatus {
    Id? : number,
    Name : string,
    Description : string,
    IsDefault: false,
}

export interface IWorkflowStep {
    Id? : number,
    NextStatusId : number | null,
    NextStatusName : string,
    IsCurrent: false
}

export enum AccountsPayableInvoiceStatusEnum {
    Received = 1,
    Approved = 2,
    PartiallyPaid = 3,
    Paid = 4,
    Overdue = 5,
    ReminderRecieved = 6,
    Default = 7,
    Canceled = 8
}

export enum AccountsReceivableInvoiceStatusEnum {
    Created = 1,
    Sent = 2,
    Approved = 3,
    PartialSettled = 4,
    Settled = 5,
    Overdue = 6,
    ReminderSent = 7,
    Default = 8,
    Canceled = 9
}

export enum TransactionStatusEnum {
    InProcess = 1,
    Successful = 2,
    Reverted = 3,
    Failed = 4
}

export enum InvoiceTypeEnum {
    AccountsReceivableInvoice = 1,
    ProformaInvoice = 2,
    CreditNote = 3,
    AccountsPayableInvoice = 4,
}