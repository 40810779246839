import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PriceBreakdown, PriceItem } from '../model';
import { HttpService } from './http.service';

@Injectable()
export class CalculateService {

  private readonly baseUrl = `${environment.baseApiUrl}/calculate`
  constructor(private httpService: HttpService) { }

  public calculatePrice(item: PriceItem) : Promise<PriceBreakdown>{
    let url = `${this.baseUrl}/price`;
    return this.httpService.post(url, item) as Promise<PriceBreakdown>;
  }
}
