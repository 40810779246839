import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { LoginComponent } from './login/login.component';
import { UnauthorizedComponent } from './shared/components/unauthorized/unauthorized.component';
import { ViewAuditTrailsComponent } from './shared/components/view-audit-trails/view-audit-trails.component';
import { ForbiddenComponent } from './shared/components/forbidden/forbidden.component';
const routes: Routes = [
  {
    path: 'contractManagement',
    loadChildren: () => import('./contract-management/contract-management.module').then(p => p.ContractManagementModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'productionDashboard',
    redirectTo: 'production/dashboard'
  },
  {
    path: 'remainder',
    loadChildren: () => import('./remainder/remainder.module').then(p => p.RemainderModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'print',
    loadChildren: () => import('./print/print.module').then(p => p.PrintModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'lead',
    loadChildren: () => import('./lead-management/lead-management.module').then(p => p.LeadManagementModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'projectManagement',
    loadChildren: () => import('./project-management/project-management.module').then(p => p.ProjectManagementModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'shipping',
    loadChildren: () => import('./shipping/shipping.module').then(p => p.ShippingModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(p => p.AdminModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'jobRequest',
    loadChildren: () => import('./job-request/job-request.module').then(p => p.JobRequestModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'manufacturer',
    loadChildren: () => import('./manufacturer/manufacturer.module').then(p => p.ManufacturerModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'serviceOrder',
    loadChildren: () => import('./service-order/service-order.module').then(p => p.ServiceOrderModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'accounting',
    loadChildren: () => import('./accounting/accounting.module').then(p => p.AccountingModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'planning',
    loadChildren: () => import('./planning/planning.module').then(p => p.PlanningModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'resource',
    loadChildren: () => import('./resource/resource.module').then(p => p.ResourceModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'controlPlan',
    loadChildren: () => import('./control-plan/control-plan.module').then(p => p.ControlPlanModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'quality',
    loadChildren: () => import('./quality/quality.module').then(p => p.QualityModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'transportation',
    loadChildren: () => import('./transportation/transportation.module').then(p => p.TransportationModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'configuration',
    loadChildren: () => import('./configuration/configuration.module').then(p => p.ConfigurationModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'humanResource',
    loadChildren: () => import('./human-resource/human-resource.module').then(p => p.HumanResourceModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'workingDays',
    loadChildren: () => import('./working-days/working-days.module').then(p => p.WorkingDaysModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'lot',
    loadChildren: () => import('./lot/lot.module').then(p => p.LotModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'production',
    loadChildren: () => import('./production/production.module').then(p => p.ProductionModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'supplier',
    loadChildren: () => import('./supplier/supplier.module').then(p => p.SupplierModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'inventory',
    loadChildren: () => import('./inventory/inventory.module').then(p => p.InventoryModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'purchasing',
    loadChildren: () => import('./purchasing/purchasing.module').then(p => p.PurchasingModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'sales',
    loadChildren: () => import('./sales/sales.module').then(p => p.SalesModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'customer',
    loadChildren: () => import('./customer/customer.module').then(p => p.CustomerModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'customerFeedback',
    loadChildren: () => import('./customer-feedback/customer-feedback.module').then(p => p.CustomerFeedbackModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'parts',
    loadChildren: () => import('./part/part.module').then(p => p.PartModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'favorites',
    loadChildren: () => import('./favorites/favorites.module').then(p => p.FavoritesModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(p => p.DashboardModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: 'forbidden',
    component: ForbiddenComponent
  },
  {
    path: 'auditTrails/:moduleName',
    component: ViewAuditTrailsComponent
  },
  {
    path: 'action',
    loadChildren: () => import('./module-action/module-action.module').then(p => p.ModuleActionModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'workflow',
    loadChildren: () => import('./workflow/workflow.module').then(p => p.WorkflowModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: 'report',
    loadChildren: () => import('./report/report.module').then(p => p.ReportModule),
    canActivate: [ AuthGuard ]
  },
  {
    path: '**',
    redirectTo: 'dashboard'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
