import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { SelectionChangedEvent } from 'devextreme/ui/button_group';

@Component({
  selector: 'app-view-tax-slabs',
  templateUrl: './view-tax-slabs.component.html',
  styleUrls: ['./view-tax-slabs.component.scss']
})
export class ViewTaxSlabsComponent {

  @Input()
  dataSource!: DataSource | any;

  @Input()
  breakdownProperty!: string;

  @ViewChild(DxDataGridComponent)
  grid!: DxDataGridComponent;
  
  @Output()
  onSelectionChanged: EventEmitter<SelectionChangedEvent> = new EventEmitter<SelectionChangedEvent>();
 
  constructor() { }
  
  public selectionChangeHandler = (e: SelectionChangedEvent) => { 
    this.onSelectionChanged?.emit(e);
  }

  public refreshGrid(){
    this.grid.instance.clearSelection();
    this.grid.instance.refresh();
  }
  
}
