import { environment } from "src/environments/environment";
import { IAuditableEntity, IEntityBase } from "../shared/models";

export const PLANT_LOCATION_ODATA_URL = `${environment.baseOdataUrl}/PlantLocations`;
export const WORKSTATION_STATUS_ODATA_URL = `${environment.baseOdataUrl}/WorkStationStatuses`;
export const WORKSTATION_EMPLOYEE_ODATA_URL = `${environment.baseOdataUrl}/WorkStationEmployees`;
export const WORKSTATIONS_ODATA_URL = `${environment.baseOdataUrl}/WorkStations`;
export const RESOURCE_ODATA_URL  = `${environment.baseOdataUrl}/Resources`;
export const RESOURCE_TYPE_ODATA_URL  = `${environment.baseOdataUrl}/ResourceTypes`;
export const RESOURCE_STATUS_ODATA_URL  = `${environment.baseOdataUrl}/ResourceStatuses`;
export const TOOL_ODATA_URL  = `${environment.baseOdataUrl}/Tools`;
export const TOOL_TYPE_ODATA_URL  = `${environment.baseOdataUrl}/ToolTypes`;
export const TOOL_STATUS_ODATA_URL  = `${environment.baseOdataUrl}/ToolStatuses`;
export const MACHINE_TYPE_ODATA_URL = `${environment.baseOdataUrl}/MachineTypes`;
export interface  IWorkstationFilter {

    Name:string,
    Key:string,
    PlantLocationId:number |null,
    WorkStationStatusId: number |null,
  }

  export interface IWorkStation extends IEntityBase,IAuditableEntity {
    Id? : number,
    PlantLocationId : number | null,
    Name : string,
    Description: string,
    WorkStationStatusId: number | null,
}

export interface IWorkStationConfiguration extends IEntityBase,IAuditableEntity {
  Id?:number ,
  PartId:number | null,
  PartTypeId:number | null,
  Quantity:number | null,
  UnitOfMeasureId:number | null,
  Remarks:string
}

export interface IworkstationDetail extends IEntityBase,IAuditableEntity {
  Name:string,
  Key:string,
  PlantLocationId:null,
  WorkStationStatusId: null,
}

export interface IworkstationEmployeeDetail extends IEntityBase,IAuditableEntity {
  Name:string,
  Key:string,
  PlantLocationId:null,
  WorkStationStatusId: null,
}

export interface IWorkStationEmployee extends IEntityBase,IAuditableEntity {
  Id?:number ,
  EmployeeId:number | null,
  FromDate:Date |any,
  ToDate: Date | any
}

export interface IResourceFilter  {
  PartId: number |null,
  ResourceTypeId: number |null,
  ResourceStatusId: number |null,
}

export interface IResource extends IEntityBase,IAuditableEntity {
  Id? : number,
  ResourceTypeId : number | null,
  PartId: number | null,
  ResourceStatusId: number |null,
  DailyCapacity :string
}

export interface IResourceTypeFilter  {
  Name : string,
  
}

export interface IResourceType extends IEntityBase,IAuditableEntity {
  Id? : number,
  Name : string,
  Description: string,
}

export interface IResourceDetail extends IEntityBase,IAuditableEntity {
  PartId:number |null,
  ResourceTypeId:number |null,
  ResourceStatusId: number |null,
}

export interface IResourceConfiguration extends IEntityBase,IAuditableEntity {
  Id?:number ,
  ToolId: number|any,
 // PartId: number|any,
  Quantity:number |null,
  UnitOfMeasureId:number |null,
  Remarks:string
}

export interface IToolFilter {

  PartId: number |null,
  ToolTypeId: number |null,
  ToolStatusId: number |null, 
}

export interface ITool extends IEntityBase,IAuditableEntity {
  Id? : number,
  ToolTypeId : number | null,
  PartId: number | null,
  ToolStatusId: number |null,
  OperatingCapacity: string
}

export interface IToolTypeFilter  {
  Name : string, 
}

export interface IToolType extends IEntityBase,IAuditableEntity {
  Id? : number,
  Name : string,
  Description: string,
}

export interface IToolAllocationFilter  {

  ResourceId: number |null,
  ToolId:number | null,
  AttachedBy: string,
  ReleasedBy: string,
  DateAttached: Date | any,
  DateReleased: Date | any
  
}

export interface IToolAllocation  {
  Id?:number,
  ResourceId: number |null,
  ToolId:number | null,
  AttachedBy: string,
  ReleasedBy: string,
  DateAttached: Date |any,
  DateReleased: Date |any,
  Quantity: number | null,
  UnitOfMeasureId: number| null,
}


export interface IWorkStationCalender{
  WorkStationId:number | null,
  WorkStationName: string,
  JobNumber: string,
  StartDate: Date |any,
  EndDate: Date |any
}

export interface IResourceMaintenance extends IEntityBase,IAuditableEntity {
  ResourceId: number | null,
  MaintenanceId: number | null,
  Notes: string,
}

export interface IResourceMaintenanceFilter  {
  ResourceId: number | null,
  MaintenanceId: number | null
}

export interface IMaintenanceStepPart extends IEntityBase,IAuditableEntity {
  Id?:number,
  PartId: number | null,
  Quantity:number | null,
  UnitOfMeasureId: number | null
}

export interface IMaintenanceStepPartFilter  {
  PartId: null
}

export interface IResourceMaintenanceDetail extends IEntityBase,IAuditableEntity {
  ResourceMaintenanceId: number | null,
  ChecksheetStepId : number | null,
  Value: string,
  Remarks: string,
  Tolerance: number | null,
  MinimumValue: number | null,
  MaximumValue: number | null
}

export interface IMaintenanceFeedback extends IEntityBase,IAuditableEntity {
  Summary: string,
  Description: string,
  FeedbackById: null,
}

export interface IMaintenanceFeedbackFilter {
  FeedbackById: null
}
