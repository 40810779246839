import { formatMessage } from "devextreme/localization"
import { ClickEvent } from "devextreme/ui/button"
import { Properties as DataGridOptions } from "devextreme/ui/data_grid"
import { Properties as TreeGridOptions } from "devextreme/ui/tree_list"

export var GST_REGEX = "^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$";

export interface IEntityBase{
    Id?: number
}

export interface IModuleReminder{
  ModuleId :number|any,
  ModuleTypeId :number|any
}

export type GridActionClickEvent = ClickEvent & {
  rowData: any
}

export interface IAuditableEntity{
    CreatedBy?: string,
    CreatedDate?: Date | string,
    UpdatedBy?: string,
    UpdatedDate?: Date | string
}

export enum PopupType {
    Confirm,
    Information,
    Warning
}

export type PopupBase = {
    visible: boolean,
    title?: string,
    type?: PopupType,
    message?: string
}

export type ConfirmPopup = PopupBase & {
    yesButtonText?: string,
    noButtonText?: string,
    onYesClicked?: ((e: ClickEvent) => void),
    onNoClicked?: ((e: ClickEvent) => void)
}

export type InformationPopup = PopupBase & {
    onOkClicked?: ((e: ClickEvent) => void)
}

export type WarningPopup = PopupBase & {
  onOkClicked?: ((e: ClickEvent) => void)
}

export type Popup = ConfirmPopup | InformationPopup | WarningPopup;

export var defaultGridOptions: DataGridOptions = {
    allowColumnResizing: true,
    allowColumnReordering: true,
    columnAutoWidth: true,
    columnHidingEnabled: false,
    showColumnLines:true,
    showRowLines:true,
    rowAlternationEnabled:true, 
    columnChooser: {
      enabled: false,
      mode: "select",
      sortOrder: "desc"
    },
    pager: {
      visible: true,
      showInfo: true,
      showPageSizeSelector: true,
      allowedPageSizes: [10, 20, 50]
    },
    paging: {
      enabled: true,
      pageSize: 20
    },
    filterRow: {
      visible: false
    },
    remoteOperations: {
      paging: true,
      sorting: true
    },
    noDataText: formatMessage("grid_no_data_text")
  }

  export var defaultTreeGridOptions: TreeGridOptions = {

  showRowLines:true,
  columnAutoWidth:true,
  autoExpandAll:true,
  rowAlternationEnabled:true
  
  }

  export enum ModuleEntityEnum{
    Order = 1,
    Quotation = 2,
    AccountsReceivableInvoice = 3,
    AccountsPayableInvoice = 4,
    PurchaseOrder = 5,
    JobRequest = 6,
    ServiceOrder = 7,
    Reminder = 8,
    ProformaInvoice = 9,
    SupplierRequest = 10,
    CreditNote = 11,
    ProductionOrder = 12,
    OrderConfirmation = 13,
  }

  export var routes = new Map([
    ["/parts", "Part"],
    ["/parts/attributes","PartAttribute"],
    ["/parts/categories","PartCategory"],
    ["/parts/partSourceTypes","PartSourceType"],
    ["/parts/packageTypes","PackageType"],
    ["/parts/bulkOperations","Part"],
    ["/inventory","InventoryItem"],
    ["/inventory/stock","Stock"],
    ["/inventory/release","Release"],
    ["/inventory/receive","Receive"],
    ["/inventory/racks","Rack"],
    ["/sales/orders", "Order"],
    ["/sales/quotation", "Quotation"],
    ["/sales/returnReasons","ReturnReason"],
    ["/sales/orderTypes","OrderType"],
    ["/sales/orderSourceTypes","OrderSourceType"],
    ["/purchasing/orders","PurchaseOrder"],
    ["/purchasing/requests","SupplierRequest"],
    ["/customer","Company"],
    ["/customer/parts","CustomerPart"],
    ["/customer/departments","CRMDepartment"],
    ["/customer/positions","CRMPosition"],
    ["/supplier","Company"],
    ["/supplier/parts","SupplierPart"],
    ["/manufacturer","Company"],
    ["/production/process","Process"],
    ["/production/process/routing","ProcessRouting"],
    ["/production/operation","Operation"],
    ["/production/shifts","Shift"],
    ["/production/shiftEmployees","ShiftEmployee"],
    ["/humanResource/employees","Employee"],
    ["/humanResource/department","HRDepartment"],
    ["/humanResource/types","EmployeeType"],
    ["/humanResource/holidays","Holiday"],
    ["/humanResource/holidayTypes","HolidayType"],
    ["/humanResource/teams","Team"],
    ["/humanResource/leaveTypes","LeaveType"],
    ["/humanResource/leaveGroups","LeaveGroup"],
    ["/humanResource/leaves","EmployeeLeave"],
    ["/workingDays/workLoads","WorkLoad"],
    ["/workingDays/workLoadGroups","WorkLoadGroup"],
    ["/humanResource/timeLogging","TimeLogging"],
    ["/humanResource/positions","HRPosition"],
    ["/configuration/terms", "Term"],
    ["/configuration/autoAssignments", "AutoAssignment"],
    ["/configuration/unitOfMeasures", "UnitOfMeasure"],
    ["/configuration/paymentCategories", "PaymentCategory"],
    ["/configuration/plantLocation", "PlantLocation"],
    ["/configuration/contactTypes", "ContactType"],
    ["/configuration/addressTypes", "AddressType"],
    ["/resource", "Resource"],
    ["/resource/workstation", "WorkStation"],
    ["/resource/type", "ResourceType"],
    ["/resource/tool", "Tool"],
    ["/resource/toolType", "ToolType"],
    ["/resource/toolAllocation", "ToolAllocation"],
    ["/quality/checksheet", "Checksheet"],
    ["/controlPlan/types", "ControlPlanType"],
    ["/controlPlan/formats", "ControlPlanFormat"],
    ["/controlPlan/workDescriptions", "WorkDescription"],
    ["/controlPlan/products", "ControlPlanProduct"],
    ["/controlPlan/process", "ControlPlanProcess"],
    ["/controlPlan/characteristics", "SpecialCharacteristic"],
    ["/controlPlan/methods", "ControlMethod"],
    ["/controlPlan/reactionPlans", "ReactionPlan"],
    ["/controlPlan/techniques", "MeasurementTechnique"],
    ["/controlPlan/correctiveActions", "CorrectiveAction"],
    ["/controlPlan/plans", "ControlPlan"],
    ["/planning/schedule", "Schedule"],
    ["/accounting/accountsReceivableInvoices", "AccountsReceivableInvoice"],
    ["/accounting/accountsPayableInvoices", "AccountsPayableInvoice"],
    ["/accounting/banks", "Bank"],
    ["/accounting/bankAccounts", "BankAccount"],
    ["/accounting/transactions", "Transaction"],
    ["/accounting/costings", "Costing"],
    ["/accounting/taxSlabs", "TaxSlab"],
    ["/admin/user", "User"],
    ["/admin/userGroup", "UserGroup"],
    ["/admin/role", "Role"],
    ["/serviceOrder/serviceOrders", "ServiceOrder"],
    ["/serviceOrder/attributes", "ServiceOrderAttribute"],
    ["/serviceOrder/categories", "ServiceOrderCategory"],
    ["/serviceOrder/types", "ServiceOrderType"],
    ["/serviceOrder/partAttributes", "ServiceOrderPartAttribute"],
    ["/serviceOrder/partConfigurations", "ServiceOrderPartConfiguration"],
    ["/serviceOrder/issues", "ServiceOrderIssue"],
    ["/serviceOrder/diagnosis", "ServiceOrderDiagnosis"],
    ["/serviceOrder/issueConfigurations", "ServiceOrderIssueConfiguration"],
    ["/jobRequest/jobRequests", "JobRequest"],
    ["/jobRequest/categories", "JobRequestCategory"],
    ["/jobRequest/types", "JobRequestType"],
    ["/shipping/types", "ShipmentType"],
    ["/shipping/companies", "ShippingCompany"],
    ["/shipping/shipments", "Shipment"],
    ["/projectManagement/categories", "ProjectCategory"],
    ["/projectManagement/projects", "Project"],
    ["/projectManagement/taskTypes", "TaskType"],
    ["/projectManagement/projectTasks", "ProjectTask"],
    ["/lead", "Lead"],
    ["/lead/leadPhase", "LeadPhase"],
    ["/lead/leadSource", "LeadSource"],
    ["/lead/attributes", "LeadAttribute"],
    ["/lead/followUpTypes", "LeadFollowUpType"],
    ["/lead/competitorTypes", "LeadCompetitorType"],
    ["/print/templates", "CustomerTemplate"],
    ["/print/rules","Rule"],
    ["/remainder","Remainder"],
    ["/remainder/types","RemainderType"],
    ["/sales/orderReturns", "OrderReturn"],
    ["/accounting/proformaInvoices", "AccountsReceivableInvoice"],
    ["/accounting/creditNotes", "AccountsReceivableInvoice"],
    ["/production", "Job"],
    ["/production/maintenance","Maintenance"],
    ["/production/maintenanceType","MaintenanceType"],
    ["/production/resourceMaintenance","ResourceMaintenance"],
    ["/controlPlan/techniques","MeasurementTechnique"],
    ["/admin/licenses", "LicenseAssignment"],
    ["/planning/schedulePlan", "ScheduleJob"],
    ["/configuration/productCategories", "ProductCategory"],
    ["/accounting/receivableInvoices/status","AccountsReceivableInvoiceStatus"],
    ["/accounting/payableInvoices/status","AccountsPayableInvoiceStatus"],
    ["/purchasing/orderStatus", "PurchaseOrderStatus"],
    ["/purchasing/supplierRequest/Status", "SupplierRequestStatus"],
    ["/sales/orderStatus","OrderStatus"],
    ["/serviceOrder/status", "ServiceOrderStatus"],
    ["/humanResource/leave/status", "EmployeeLeaveStatus"],
    ["/sales/quotation/status", "QuotationStatus"],
    ["/jobRequest/status", "JobRequestStatus"],
    ["/projectManagement/taskStatus", "TaskStatus"],
    ["/lead/leadStatus", "LeadStatus"],
    ["/transportation/transportations", "Transportation"],
    ["/transportation/types", "TransportationType"],
    ["/transportation/transactionRequest", "TransactionRequest"],
    ["/contractManagement/contracts", "Contract"],
    ["/humanResource/weekOffPolicy","WeekOffPolicy"],
    ["/configuration/unitOfMeasureConversions", "PartUnitOfMeasureConversion"],
    ["/action/1", "ModuleAction"],
    ["/action/2", "ModuleAction"],
    ["/action/3", "ModuleAction"],
    ["/action/4", "ModuleAction"],
    ["/action/5", "ModuleAction"],
    ["/action/6", "ModuleAction"],
    ["/action/7", "ModuleAction"],
    ["/configuration/emailTemplates", "EmailTemplate"],
    ["/lead/contacts", "TenantContact"],
    ["/workflow", "Workflow"],
    ["/lead/company", "company"],
    ["/configuration/unitOfMeasureTypes", "UnitOfMeasureType"],
    ["/lead/followUpStatus", "LeadFollowUpStatus"],
  ]);