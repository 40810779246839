import { Injectable } from '@angular/core';
import { Subject } from "rxjs";

@Injectable({providedIn: "root" })
export class SpinnerService {
  private showSpinner$ = new Subject<boolean>();
  public showSpinnerObservable = this.showSpinner$.asObservable();
  constructor() { }

  public showSpinner(){
    this.showSpinner$.next(true);
  }

  public hideSpinner(){
    this.showSpinner$.next(false);
  }
}
