export type RequestOptions = {
    take: number;
    skip: number;
    filter?: string;
    sortBy?: string;
    sortOrder?: string;
}

export type ODataOptions = {
    top?: number;
    skip?: number;
    filter?: string;
    select?: string;
    expand?: string;
}

export type PriceBreakdown = {
    NetAmount: number;
    TaxSlabId: number;
    Discount?: number;
    DiscountedAmount: number;
    Tax: number;
    GrossAmount: number;
}

export type PriceItem = {
    Quantity: number;
    UnitOfMeasureId: number | null;
    UnitPrice: number;
    TaxSlabId: number;
    Discount?: number;
}

export type Email = {
    To: string,
    Cc:  string,
    Bcc:  string,
    From: string;
    Body: string;
    Subject: string,
    Attachments : [],
    ValueId?: number | any,
    EntityTypeId? : any
}

export enum AccessType {
    View = 1,
    Update = 2
}

export type MESUser = {
    Id: number,
    Email: string,
    Language: string,
    RefreshTimer: number,
    EmployeeId: number | null,
}

export type DatabaseCheck = {
    Success: boolean,
    Message: string
}

export enum AutoAssignmentFieldEnum {
    BatchNumber = 1,
    SerialNumber = 2,
    PurchaseOrderNumber = 3,
    OrderNumber = 4,
    QuotationNumber = 5,
    SupplierRequestNumber = 6,
    SupplierRequestQuotationNumber = 7,
    PartNumber = 8,
    EmployeeCode = 9,
    InvoiceNumber = 10,
    ServiceOrderNumber = 11,
    CustomerNumber = 12,
    SupplierNumber = 13,
    OrderConfirmationNumber = 14,
    SupplierBatchNumber = 15,
    JobRequestNumber = 16,
    ShipmentNumber = 17,
    LeadNumber = 18,
    JobNumber = 19,
    ProformaInvoiceNumber = 20,
    OrderReturnNumber = 21,
    ManufacturerNumber = 22,
    ContractNumber = 23,
    ModuleActionNumber = 24,
    ProductionOrderNumber = 26,
    ProjectNumber = 25,
    TransactionRequestNumber = 27
}