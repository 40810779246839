import { Component, OnInit } from '@angular/core';
import { TranslationService } from 'src/app/core/services/translation.service';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss']
})
export class UnauthorizedComponent implements OnInit {

  public title = "";
  public description = ""
  constructor(private translationService: TranslationService) { 
    this.title = translationService.translate("title_unauthorized");
    this.description = translationService.translate("description_unauthorized");
  }

  ngOnInit(): void {
  }

}
