import { Component, EventEmitter, Injectable, Input, Output } from '@angular/core';
import { IMoveContactFormData, TENANT_ADDRESS_ODATA_URL } from 'src/app/lead-management/lead-management-model';
import ODataStore from 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source';
import { FormItem } from 'src/app/shared/components';
import { Location } from '@angular/common';
import { TranslationService } from 'src/app/core/services/translation.service';
import { Properties as SelectBoxProperties } from 'devextreme/ui/select_box';
import { formatAddress } from 'src/app/Utils/utils';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/core/services/http.service';

@Component({
  selector: 'app-move-contact-to-address',
  templateUrl: './move-contact-to-address.component.html',
  styleUrls: ['./move-contact-to-address.component.scss']
})
@Injectable({providedIn: 'root'})
export class MoveContactToAddressComponent {
  @Input()
  public popupVisibleMoveContact!:boolean

  @Input()
  public currentCustomerId!:number

  @Input()
  public selectedContactId!:number

  @Output()
  public onSave: EventEmitter<number> = new EventEmitter();

  @Output()
  public onCancel: EventEmitter<unknown> = new EventEmitter();

  public moveContactFormItems: Array<FormItem> = [];

  ngOnInit(): void {
    if (this.currentCustomerId) {
      this.moveContactFormData.ContactId =this.selectedContactId;
      this.initializeFormItems();
    }
  }

  public moveContactFormData: IMoveContactFormData = {
    ContactId : null,
    AddressId : null,
  }

  private initializeFormItems(): void {
    this.moveContactFormItems = [
      {
        itemType: 'group',
        caption: this.translationService.translate('dialog_message_move_contact'),
        items: [
          {
            dataField: "AddressId",
            editorType: "dxSelectBox",
            label: {
              text: this.translationService.translate("dialog_message_address")
            },
            validationRules: [
              { type: "required", message: this.translationService.translate("validation_message_required", this.translationService.translate("dialog_message_schedule")) },
            ],
            editorOptions: <SelectBoxProperties>{
              dataSource: new DataSource({
                store: new ODataStore({
                  url: TENANT_ADDRESS_ODATA_URL,
                  key: 'Id',
                  version: 4,
                }),
                select: ["Id", "AddressLine1", "AddressLine2", "City", "State", "Country", "ZipCode", "IsDefault"],
                filter: ["CompanyId", "=", this.currentCustomerId],
              }),
              searchEnabled: true,
              searchMode: 'contains',
              showClearButton: true,
              displayExpr: function(obj: any) {
                return obj && `${formatAddress(obj)}`;
              },
              valueExpr: "Id",
            }
          },
        ]
      }
    ];
  }

  public onSaveClicked = (e: any) => {
    this.showSpinner();
    return this.httpService.getData(`${environment.baseApiUrl}/TenantContact/${this.selectedContactId}/move-contact/${e.data.AddressId}`)
    .then((response : any) => {
      this.spinnerService.hideSpinner();
      let toastMessage = this.translationService.translate(
        'dialog_message_contact_move'
      );
      this.toastService.success(toastMessage);
      this.onSave?.emit();
    })
  }

  public onCancelClicked = (e: any) => {
    console.log(this.currentCustomerId);
    if (this.onCancel.observers.length > 0) {
      this.onCancel?.emit();
    } else {
      this.location.back();
    }
  };

  constructor(
    private location: Location,
    public translationService: TranslationService,
    private spinnerService: SpinnerService,
    private toastService: ToastService,
    private router: Router,
    private httpService: HttpService,
  ){}

  private showSpinner() {
    this.spinnerService.showSpinner();
  }

}
