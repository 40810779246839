import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map } from "rxjs/operators";
import { AccessType } from "src/app/core";
import { AuthorizationService } from "src/app/core/services/authorization.service";
import { HttpService } from "src/app/core/services/http.service";
import { environment } from "src/environments/environment";
import { PageSearch, SearchResponse, SearchResult, SearchResultItem } from "./model";

@Injectable()
export class SearchService {
    private readonly _searchUrl: string = `${environment.baseApiUrl}/search`;
    constructor(private httpService: HttpService, private authorizationService: AuthorizationService){

    }

    public search(searchString: string){
        let params = new HttpParams().set("searchString", searchString); 
          return this.httpService.get<SearchResponse>(this._searchUrl, params)
            .pipe(map(data =>{
                let result = Array<SearchResult>();
                data.Pages?.length > 0 && result.push({
                    key: "Pages", 
                    items: data.Pages
                            .filter(p => this.authorizationService.hasPageAccess([p.Url], AccessType.View))
                            .map(p => <SearchResultItem>{ text: p.Title, url: p.Url })
                });
                data.PurchaseOrders?.length > 0 && this.authorizationService.hasPageAccess(["/purchasing/orders"], AccessType.View) && result.push({
                    key: "Purchase Orders", 
                    items: data.PurchaseOrders.map(p => <SearchResultItem>{ text: p.PurchaseOrderNumber, value: p.Id.toString(), url: "/purchasing/orders/{0}/details" })
                });
                data.Orders?.length > 0 && this.authorizationService.hasPageAccess(["/sales/orders"], AccessType.View) && result.push({
                    key: "Sales Orders", 
                    items: data.Orders.map(p => <SearchResultItem>{ text: p.OrderNumber, value: p.Id.toString(), url: "/sales/orders/{0}/details" })
                });
                data.Parts?.length > 0 && this.authorizationService.hasPageAccess(["/parts"], AccessType.View) && result.push({
                    key: "Parts", 
                    items: data.Parts.map(p => <SearchResultItem>{ text: p.PartNumber, value: p.Id.toString(), url: "/parts/{0}/details" })
                });
                data.AccountsReceivableInvoices?.length > 0 && this.authorizationService.hasPageAccess(["/accounting/accountsReceivableInvoices"], AccessType.View) && result.push({
                    key: "Accounts Receivable Invoices", 
                    items: data.AccountsReceivableInvoices.map(p => <SearchResultItem>{ text: p.InvoiceNumber, value: p.Id.toString(), url: "/accounting/accountsReceivableInvoices/{0}/details" })
                });
                data.AccountsPayableInvoices?.length > 0 && this.authorizationService.hasPageAccess(["/accounting/accountsPayableInvoices"], AccessType.View) && result.push({
                    key: "Accounts Payable Invoices", 
                    items: data.AccountsPayableInvoices.map(p => <SearchResultItem>{ text: p.InvoiceNumber, value: p.Id.toString(), url: "/accounting/accountsPayableInvoices/{0}/details" })
                });
                data.ServiceOrders?.length > 0 && this.authorizationService.hasPageAccess(["/serviceOrder/serviceOrders"], AccessType.View) && result.push({
                    key: "Service orders", 
                    items: data.ServiceOrders.map(p => <SearchResultItem>{ text: p.ServiceOrderNumber, value: p.Id.toString(), url: "/serviceOrder/serviceOrders/{0}/details" })
                });
                data.JobRequests?.length > 0 && this.authorizationService.hasPageAccess(["/jobRequest/jobRequests"], AccessType.View) && result.push({
                    key: "Job requests", 
                    items: data.JobRequests.map(p => <SearchResultItem>{ text: p.JobRequestNumber, value: p.Id.toString(), url: "/jobRequest/jobRequests/{0}/details" })
                });
                data.Leads?.length > 0 && this.authorizationService.hasPageAccess(["/lead"], AccessType.View) && result.push({
                    key: "Leads", 
                    items: data.Leads.map(p => <SearchResultItem>{ text: p.LeadNumber, value: p.Id.toString(), url: "/lead/{0}/details" })
                });
                data.Quotations?.length > 0 && this.authorizationService.hasPageAccess(["/sales/quotation"], AccessType.View) && result.push({
                    key: "Quotations", 
                    items: data.Quotations.map(p => <SearchResultItem>{ text: p.QuotationNumber, value: p.Id.toString(), url: "/sales/quotation/{0}/details" })
                });
                data.Contracts?.length > 0 && this.authorizationService.hasPageAccess(["/contractManagement/contracts"], AccessType.View) && result.push({
                    key: "Contracts", 
                    items: data.Contracts.map(p => <SearchResultItem>{ text: p.ContractNumber, value: p.Id.toString(), url: "/contractManagement/contracts/{0}/details" })
                });
                data.ProformaInvoices?.length > 0 && this.authorizationService.hasPageAccess(["/accounting/proformaInvoices"], AccessType.View) && result.push({
                    key: "Proforma invoices", 
                    items: data.ProformaInvoices.map(p => <SearchResultItem>{ text: p.InvoiceNumber, value: p.Id.toString(), url: "/accounting/proformaInvoices/{0}/details" })
                });
                data.CreditNotes?.length > 0 && this.authorizationService.hasPageAccess(["/accounting/creditNotes"], AccessType.View) && result.push({
                    key: "Credit notes", 
                    items: data.CreditNotes.map(p => <SearchResultItem>{ text: p.InvoiceNumber, value: p.Id.toString(), url: "/accounting/creditNotes/{0}/details" })
                });
                return result;
            })).toPromise();
    }
}