import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from '../services/http.service';
import { ToastService } from '../services/toast.service';
import { TranslationService } from '../services/translation.service';
import { AuthorizationService } from '../services/authorization.service';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { SpinnerService } from '../services/spinner.service';

export type Error = {
  Message: string
}

@Injectable()
export default class GlobalErrorHandler implements ErrorHandler {

  error: Error = {
    Message: ""
  }
  
  private readonly errorUrl = `${environment.baseApiUrl}/Error/Log`;
  private isRefreshing = false;
  @Inject(DOCUMENT) private doc!: Document;
  constructor(private toastService: ToastService, 
    private spinnerService: SpinnerService,
    private translationService: TranslationService, 
    private zone: NgZone, 
    private httpService: HttpService,
    private authorizationService: AuthorizationService,
    private auth0: AuthService) {
  }

  handleError = (error: any): void => {
    // Check if it's an error from an HTTP response
    if (!(error instanceof HttpErrorResponse) && error.rejection) {
      error = error.rejection; // get the error object
    }

    this.zone.run(() =>{
      // TODO: Log errors to server in our client error logs.
      // let errorMessage = environment.production ? 
      //                     this.translationService.translate("error_global")
      //                     : error?.message || error || 'Undefined client error';
      let errorMessage = this.translationService.translate("error_C3004") + " " + this.translationService.translate("error_contact_support");
      if(error?.message == "Login required" || error == "Login required"){
        if (!this.isRefreshing) {
          this.isRefreshing = true;
          // location.origin
          // this.auth0.logout({ returnTo: location.origin });
        }
      }
      this.toastService.error(errorMessage)
      this.error.Message = error?.message || error;
      return this.httpService.post(this.errorUrl, this.error);
    });
    this.spinnerService.hideSpinner();
    console.error('Error from global error handler', error);
  }
  
}
