import { Injectable } from '@angular/core';

@Injectable()
export class AppInfoService {
  constructor() {}

  public get title() {
    return 'SetuMES';
  }

  public get company() {
    return 'Parsetu GmbH';
  }

  public get currentYear() {
    return new Date().getFullYear();
  }
}
