<div class="custom-form-container">
    <section class="content-block dx-card responsive-paddings form-section" [ngClass]="isLargeScreen ? 'form-large-screen' : 'form-small-screen'">
        <dx-form id="form" colCount="1" [formData]="attachment" [showColonAfterLabel]="true" labelLocation="left" (onFieldDataChanged)="onFormValueChanged($event)">
            <dxi-item itemType="group">
                <dxi-item itemType="group" [caption]='"dialog_message_attachment" | translate'>
                    <dxi-item
                        dataField="Name"
                        [editorOptions]="{
                            showClearButton: true
                        }">
                        <dxo-label [text]='"form_name" | translate' ></dxo-label>
                        <dxi-validation-rule type="required" [message]='"validation_message_required" | translate:("form_name" | translate)'>
                        </dxi-validation-rule>
                        <dxi-validation-rule type="stringLength" [max]="nameMaxLength" [message]='"validation_string_length" | translate:("form_name" | translate): nameMaxLength.toString()'>
                        </dxi-validation-rule>
                    </dxi-item>
                    <dxi-item
                    dataField="Description"
                    editorType="dxHtmlEditor"
                    [editorOptions]="editorOptions">
                    <dxo-label [text]='"form_description" | translate'></dxo-label>
                    <dxi-validation-rule type="stringLength" [max]="additionalDescriptionMaxLength" [message]='"validation_string_length" | translate:("form_description" | translate): additionalDescriptionMaxLength.toString()'>
                    </dxi-validation-rule>
                </dxi-item>
                    <dxi-item
                        dataField="IsDefault"
                        editorType="dxCheckBox"
                        editorOptions="{
                            onInitialized: onCheckBoxInitialized($event)
                        }">
                        <dxo-label [text]='"form_is_default" | translate' ></dxo-label>
                    </dxi-item>
                    <dxi-item *ngIf="isEditedValue">
                        <div *dxTemplate>
                            <div id="dropzone-external" class="flex-box"
                                [ngClass]="['dx-theme-accent-as-border-color', 'dropzone-active']">
                                <img id="dropzone-image" [src]="documentSource" *ngIf="documentSource" alt="" />
                                <i [class]='class' class=' dx-icon-custom-style' *ngIf="documentSource && !isImageType(documentSource)"></i>
                                <div id="dropzone-text" class="flex-box" *ngIf="textVisible">
                                    <span>Drag & Drop the desired file</span>
                                    <span>…or click to browse for a file instead.</span>
                                </div>
                            </div>
                            <dx-file-uploader [uploadFile]="uploadFile" dialogTrigger="#dropzone-external"
                                dropZone="#dropzone-external" [visible]="false" [maxFileSize]="5000000"
                                [multiple]="false" accept="*"
                                uploadMode="instantly"></dx-file-uploader>
                        </div>
                    </dxi-item>
                    <dxi-item><dxo-label [text]='"validation_file_size" | translate' [visible]="isEditedValue"></dxo-label></dxi-item>
                </dxi-item>
                <dxi-item [visible]="true" itemType="group">
                    <div *dxTemplate>
                        <dx-toolbar class="toolbar-container">
                            <dxi-item cssClass="form-button" location="after" [visible]="true" widget="dxButton" [options]="saveButtonOptions">
                            </dxi-item>
                            <dxi-item
                                cssClass="form-button"
                                location="left"
                                widget="dxButton"
                                [options]="cancelButtonOptions"></dxi-item>
                        </dx-toolbar>
                    </div>
                </dxi-item>
            </dxi-item>
        </dx-form>
    </section>
</div>
