import { NgModule } from "@angular/core";
import { DxAccordionModule, DxAutocompleteModule, DxButtonModule, DxDataGridModule, DxDateBoxModule, DxDateRangeBoxModule, DxFileUploaderModule, DxFormModule, DxGanttModule, DxHtmlEditorModule, DxLoadPanelModule, DxNumberBoxModule, DxPopupComponent, DxPopupModule, DxScrollViewModule, DxSelectBoxModule, DxTextAreaModule, DxTextBoxModule, DxToastModule, DxToolbarModule, DxTreeListModule, DxValidatorModule } from "devextreme-angular";
import { TranslatePipe } from './pipes/translate.pipe';
import { FormModule } from "./components/form/form.component";
import { PopupComponent } from './components/popup/popup.component';
import { SearchComponent } from './components/search/search.component';
import { TitleBarComponent } from './components/title-bar/title-bar.component';
import { FormViewModule } from "./components/form-view/form-view.component";
import { ViewAttachmentsComponent } from "./components/attach-document/view-attachments/view-attachments.component";
import { TreeGridModule } from "./components/tree-grid/tree-grid.component";
import { ViewAdditionalCostsComponent } from "./components/additional-costs/view-additional-costs/view-additional-costs.component";
import { AddEditCostComponent } from "./components/additional-costs/add-edit-additional-cost/add-edit-cost.component";
import { ViewTaxSlabsComponent } from "./components/tax-slab/view-tax-slabs/view-tax-slabs.component";
import { AddEditAttachmentComponent } from "./components/attach-document/add-edit-attachment/add-edit-attachment.component";
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { SingleCardModule } from "../layouts";
import { CurrencyPipe } from "./pipes/currency.pipe";
import { UpdatePriceComponent } from './components/update-price/update-price.component';
import { ViewAuditTrailsComponent } from './components/view-audit-trails/view-audit-trails.component';
import { CommonModule, DatePipe } from "@angular/common";
import { DateTimePipe } from "./pipes/date.pipe";
import { GridFilterModule } from "./components/grid-filter/grid-filter.component";
import { ViewModuleRemaindersComponent } from './components/view-module-remainders/view-module-remainders.component';
import { NgxExtendedPdfViewerModule } from "ngx-extended-pdf-viewer";
import { DataGridComponent } from "./components/data-grid/data-grid.component";
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { AddEditTenantAddressComponent } from "./components/add-edit-tenant-address/add-edit-tenant-address.component";
import { AddEditAddressTypeComponent } from "./components/add-edit-address-type/add-edit-address-type.component";
import { ViewTemplateForEmailComponent } from './components/view-template-for-email/view-template-for-email.component';
import { ViewEmailHistoryComponent } from './components/view-email-history/view-email-history.component';
import { MoveContactToAddressComponent } from './components/move-contact-to-address/move-contact-to-address.component';
import { AddEditCRMDepartmentComponent } from "./components/add-edit-crmdepartment/add-edit-crmdepartment.component";
import { AddEditCustomerPositionComponent } from "./components/add-edit-customer-position/add-edit-customer-position.component";


@NgModule({
    imports: [
        DxAccordionModule,
        DxAutocompleteModule,
        DxDataGridModule,
        DxFormModule,
        DxToolbarModule,
        DxButtonModule,
        DxSelectBoxModule,
        DxDateBoxModule,
        DxToastModule,
        DxLoadPanelModule,
        DxDateRangeBoxModule,
        FormModule,
        FormViewModule,
        DxTextAreaModule,
        DxValidatorModule,
        DxNumberBoxModule,
        DxPopupModule,
        DxScrollViewModule,
        DxTreeListModule,
        DxFileUploaderModule,
        TreeGridModule,
        SingleCardModule,
        GridFilterModule,
        CommonModule,
        NgxExtendedPdfViewerModule,
        DxTextBoxModule,
        DxHtmlEditorModule,
    ],
    exports: [
        DxAutocompleteModule,
        DxDataGridModule,
        DxFormModule,
        DxToolbarModule,
        DxButtonModule,
        DxSelectBoxModule,
        DxDateBoxModule,
        DxPopupModule,
        DxScrollViewModule,
        DxTextAreaModule,
        DxLoadPanelModule,
        DxDateRangeBoxModule,
        FormModule,
        FormViewModule,
        TranslatePipe,
        CurrencyPipe,
        DateTimePipe,
        PopupComponent,
        SearchComponent,
        TitleBarComponent,
        DataGridComponent,
        ViewAdditionalCostsComponent,
        AddEditCostComponent,
        ViewTaxSlabsComponent,
        AddEditAttachmentComponent,
        ViewAttachmentsComponent,
        DxTreeListModule,
        TreeGridModule,
        DxButtonModule,
        UpdatePriceComponent,
        DxHtmlEditorModule,
        DxAccordionModule,
        CommonModule,
        ViewModuleRemaindersComponent,
        AddEditTenantAddressComponent,
        AddEditAddressTypeComponent,
        ViewTemplateForEmailComponent,
        ViewEmailHistoryComponent,
        MoveContactToAddressComponent,
        AddEditCRMDepartmentComponent,
        AddEditCustomerPositionComponent
    ],
    declarations: [
        TranslatePipe,
        CurrencyPipe,
        DateTimePipe,
        PopupComponent,
        SearchComponent,
        TitleBarComponent,
        DataGridComponent,
        ViewAdditionalCostsComponent,
        AddEditCostComponent,
        ViewTaxSlabsComponent,
        ViewAttachmentsComponent,
        AddEditAttachmentComponent,
        UnauthorizedComponent,
        UpdatePriceComponent,
        ViewAuditTrailsComponent,
        ViewModuleRemaindersComponent,
        ForbiddenComponent,
        AddEditTenantAddressComponent,
        AddEditAddressTypeComponent,
        ViewTemplateForEmailComponent,
        ViewEmailHistoryComponent,
        MoveContactToAddressComponent,
        AddEditCRMDepartmentComponent,
        AddEditCustomerPositionComponent
    ]
})
export class SharedModule {

}