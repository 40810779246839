import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DxButtonModule, DxFormComponent, DxFormModule, DxToolbarModule, DxTooltipModule, DxValidationSummaryModule } from 'devextreme-angular';
import { formatMessage } from 'devextreme/localization';
import { ButtonItem, SimpleItem } from 'devextreme/ui/form';
import { ClickEvent, Properties } from 'devextreme/ui/button';
import { ActionBarModule } from '../action-bar/action-bar.component';
import { FormActionEvent, FormItem } from './model';
import { NavigationStart, Router } from '@angular/router';
import { NaivgationService } from 'src/app/core/services/navigation.service';
import { IsNullOrEmtpy } from 'src/app/Utils/utils';
import { ScreenService } from '../../services';
import { InitializedEvent, ContentReadyEvent } from 'devextreme/ui/select_box';
import { PopupService } from 'src/app/core/services/popup.service';
import { AdditionalDescriptionLength } from 'src/app/Utils/constant';
import { TranslationService } from 'src/app/core/services/translation.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { CoreModule } from 'src/app/core/core.module';
// test comments
@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit, OnChanges {

  
  @Input()
  public id!: string;

  @Input()
  public readOnly: boolean = false;

  @Input()
  public showSave: boolean = true;

  @Input()
  public showUpdate: boolean = false;

  @Input()
  public showCancel: boolean = true;


  @Input()
  public showAddMore: boolean = false;

  @Input()
  public formItems: Array<any> = [];

  @Input()
  public dataModel!: any;

  @Input()
  public fullScreen: boolean = false;

  @Output()
  public onSave: EventEmitter<FormActionEvent> = new EventEmitter<FormActionEvent>();

  @Output()
  public onCancel: EventEmitter<FormActionEvent> = new EventEmitter<FormActionEvent>();

  @Output()
  public onAddMore: EventEmitter<FormActionEvent> = new EventEmitter<FormActionEvent>();

  @Output()
  public onUpdate: EventEmitter<FormActionEvent> = new EventEmitter<FormActionEvent>();

  @ViewChild(DxFormComponent)
  public form!: DxFormComponent;

  public target!: any;
  public isVisible: boolean = false;
  public itemContent: string = "";
  public isLarge!: boolean;
  public multipleColumn!: boolean;
  private originalDataModel: any;
  private fieldChanges: Map<string, boolean> = new Map();
  public colCountByScreen!: Object;
  public formatText(text: string) {
    return formatMessage(text);
  }

  public saveButtonOptions: Properties = {
    text: formatMessage("form_save"),
    type: 'success',
    onClick: (e: ClickEvent) => {
      this.validateAndCall(e, this.onSaveClicked);
    }
  }

  public cancelButtonOptions: Properties = {
    text: formatMessage("form_cancel"),
    type: 'danger',
    onClick: (e: ClickEvent) => {
      this.onCancelClicked(e);
    }
  }

  addMoreButtonOptions: Properties = {
    text: formatMessage("form_add_more"),
    type: 'success',
    onClick: (e: ClickEvent) => {
      this.validateAndCall(e, this.onAddMoreClicked);
    }
  }

  onFormValueChanged = (e: any) => {
    if (e != null && this.originalDataModel != null) {
      if ((IsNullOrEmtpy(e.value) && IsNullOrEmtpy(this.originalDataModel[e.dataField]) === IsNullOrEmtpy(e.value)) || this.originalDataModel[e.dataField] === e.value) {
        if (this.fieldChanges.has(e.dataField)) {
          this.fieldChanges.delete(e.dataField);
        }
      }
      else {
        this.fieldChanges.set(e.dataField, true);
      }
      this.navigationService.isFormDirty = this.fieldChanges.size > 0;
    }
  }

  constructor(private router: Router,
    private toastService: ToastService,
    private navigationService: NaivgationService,
    private screenService: ScreenService,
    private translationService: TranslationService,
    private popupService: PopupService) {
    
    this.screenService.changed.subscribe(() => this.updateForm());
  }

  ngOnInit(): void {
    this.updateForm();
    this.navigationService.isFormDirty = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.dataModel?.currentValue != null) {
      this.originalDataModel = { ...changes.dataModel.currentValue };
    }

    if (changes?.formItems?.currentValue != null) {
      this.updateColCount(changes.formItems.currentValue);
      this.updateNumberBoxEditorOptions(changes.formItems.currentValue);
    }
  }

  updateColCount = (formItems: Array<any>) =>{
    let hasGroup = false;
    formItems.forEach(item => {
      if(item.itemType == "group" && !item.isAddNewGroup){
        hasGroup = this.updateColCount(item.items);
        this.multipleColumn = this.multipleColumn || item.items.length > 10;
        if(!hasGroup && this.multipleColumn){
          item.colCountByScreen = {
                lg: 2,
                md: 2,
                sm: 2,
                xs: 1
          }
        }
        hasGroup = true;
      }
    });
    return hasGroup;
  }

  public onSaveClicked = (e: ClickEvent) => {
    this.navigationService.isFormDirty = false;
    let data = { ...this.dataModel };
    this.updateDateValue(data);
    this.onSave?.emit({ data: data, form: this.form, ...e });
  }

  private updateDateValue = (data: any) =>{
    for(let key of Object.keys(data)){
      if(data[key] instanceof Date){
        data[key] = new Date(data[key].valueOf() + data[key].getTimezoneOffset() * 60 * 1000 * -1); 
      }
      else if(data[key] instanceof Object){
        this.updateDateValue(data[key]);
      }
    }
  }

  public onCancelClicked = (e: ClickEvent) => {
    if(this.navigationService.isFormDirty){
      let textMessage = this.translationService.translate('popup_message_unsave_changes_confirmation');
      this.popupService.confirm({
        message: textMessage,
        visible: true,
        onNoClicked: () => {
          this.popupService.close();
        },
        onYesClicked: (e: any) => {
          this.popupService.close();
          this.navigationService.isFormDirty = false;
          this.onCancel?.emit({ form: this.form, ...e });
      },
      title: this.translationService.translate('popup_title_confirm'),
      });
    }
    else{
      this.onCancel?.emit({ form: this.form, ...e });
    }
  }

  public onAddMoreClicked = (e: ClickEvent) => {
    this.onAddMore?.emit({ data: this.dataModel, form: this.form, ...e });
  }

  public onUpdateClicked = (e: ClickEvent) => {
    this.onUpdate?.emit({ data: this.dataModel, form: this.form, ...e });
  }

  public validateAndCall = (e: ClickEvent, handler: Function) => {
    let validationResult = this.form.instance.validate();
    if (validationResult.status === "pending") {
      validationResult.complete?.then((asyncValidationResult) => {
        if(asyncValidationResult.isValid){
          handler(e);
        }
        else{
          var validationMessage = asyncValidationResult.brokenRules?.map(rule => rule.message).join("\n");
          this.toastService.warning(validationMessage || "");
        }
      });
    }
    else {
      // validationResult.isValid && handler(e);
      if(validationResult.isValid){
        handler(e);
      }
      else{
        var validationMessage = validationResult.brokenRules?.map(rule => rule.message).join("\n");
        this.toastService.warning(validationMessage || "");
      }
    }
  }



  updateNumberBoxEditorOptions = (formItems: Array<any>) => {
    formItems.forEach(item => {
      if (item.items) {
        this.updateNumberBoxEditorOptions(item.items);
      }
      else {
        //item.editorOptions = this.getExtendedNumberBoxEditorOptions(item);
        this.getSelectBoxEditorOptions(item);
        this.getExtendedNumberBoxEditorOptions(item);
        this.getExtendedHtmlEditorEditorOptions(item, formItems.length);
        this.getExtendedTextAreaEditorOptions(item, formItems.length);
      }
    });
  }

  getSelectBoxEditorOptions = (item: any) => {
    let editor = item as SimpleItem;
    if (editor?.editorType == 'dxSelectBox' || editor?.editorType == 'dxTextBox' 
          || editor?.editorType == 'dxTextArea'
          || editor?.editorType == undefined || editor?.editorType == 'dxNumberBox') {
      // if (!editor.editorOptions?.height) {
        if (!editor.editorOptions) {
          editor.editorOptions = {};
        }
        editor.editorOptions["onContentReady"] = (e: ContentReadyEvent) =>{
          //@ts-ignore
          if(!e.component.isNotFirstLoad) {
            //@ts-ignore
            e.component.isNotFirstLoad = true;
            e.element?.addEventListener("mouseover", () =>{
              this.target = e.element;
              this.itemContent = e.component?.option("text") || "";
              this.isVisible = true;
            })
            e.element?.addEventListener("mouseout", () =>{
              this.target = null;
              this.isVisible = false;
            })
          }
        };
      // }
    }
    return item?.editorOptions;
  }

  getExtendedTextAreaEditorOptions = (item: any, totalItemsCount: number) => {
    let editor = item as SimpleItem;
    if (editor?.editorType == 'dxTextArea') {
      if (!editor.editorOptions?.height) {
        if (!editor.editorOptions) {
          editor.editorOptions = {};
        }
        if(item.autoAlign != false){
          editor.visibleIndex = totalItemsCount - 1;          
        }
        editor.editorOptions["height"] = "100px";
      }
    }
    return item?.editorOptions;
  }

  getExtendedNumberBoxEditorOptions = (item: any) => {
    let editor = item as SimpleItem;
    if (editor?.editorType == 'dxNumberBox') {
      if (!editor.editorOptions?.format) {
        if (!editor.editorOptions) {
          editor.editorOptions = {};
        }
        editor.editorOptions["format"] = "decimal";
      }
    }
    return item?.editorOptions;
  }

  getExtendedHtmlEditorEditorOptions = (item: any, totalItemsCount: number) => {
    let editor = item as SimpleItem;
    if (editor?.editorType == 'dxHtmlEditor') {
      editor.colSpan = 2;
      if(totalItemsCount%2 == 0){
        editor.visibleIndex = totalItemsCount - 2;
      }
      else{
        editor.visibleIndex = totalItemsCount - 1;
      }
      if (editor?.editorType == 'dxHtmlEditor') {
        if (!editor.editorOptions?.height) {
          if (!editor.editorOptions) {
            editor.editorOptions = {};
          }
          editor.editorOptions["height"] = "300px";
        }
      }
      
      let existingStringLengthRule = editor.validationRules && editor.validationRules.some(rule => rule.type === "stringLength");

      if(editor.validationRules && editor.validationRules.length > 0){
        if (!existingStringLengthRule) {
          editor.validationRules.push({
              type: "stringLength",
              max: AdditionalDescriptionLength,
              message: this.translationService.translate("validation_string_length", editor.label?.text || "", AdditionalDescriptionLength.toString())
          });
        }
      }
      else{
        editor.validationRules = [
          { type: "stringLength", max: AdditionalDescriptionLength, message: this.translationService.translate("validation_string_length", editor.label?.text || "", AdditionalDescriptionLength.toString()) }
        ];
      }

      if (!editor.editorOptions?.format) {
        if (!editor.editorOptions) {
          editor.editorOptions = {};
        }
        editor.editorOptions["showClearButton"] = true;
        editor.editorOptions["toolbar"] = {
          items: [  
            "undo", "redo", "separator",  
            {  
              name: "header",
              acceptedValues: [false, 1, 2, 3, 4, 5]  
            },  
            {  
              name: "size",
              options: { value: '8pt' },
              acceptedValues: ['8pt', '10pt', '12pt', '14pt', '16pt','18pt', '20pt','24pt', '26pt','28pt','36pt', '48pt', '72pt']
            }, 
            {  
              name: "font",
              acceptedValues: ['Arial','Cambria','Calibri','Courier New','Georgia','Helvetica',
                              'Impact','Tahoma','Times New Roman','Trebuchet MS','Verdana']
            }, 
            "separator",  
            "bold", "italic", "strike", "underline", "separator",  
            'cellProperties', 'insertTable', 'tableProperties', 'insertHeaderRow', 'insertRowAbove', 'insertRowBelow', 'insertColumnLeft', 'insertColumnRight', 'deleteColumn', 'deleteRow', 'deleteTable',
            "alignLeft", "alignCenter", "alignRight", "alignJustify", "separator", "orderedList","bulletList","separator",
            "color", "background","separator","clear","codeBlock","separator","link",
          ]  
        };
        editor.editorOptions.onContentReady = (e: any) => {
          e.component.instance().format("size", "8pt")
        }
      }
    }
    return item?.editorOptions;
  }

  updateForm = () => {
    if(this.fullScreen){
      this.isLarge = false;
    }
    else{
      if (this.fullScreen || this.screenService.sizes["screen-x-large"] || this.screenService.sizes["screen-large"]) {
        this.isLarge = true;
      }
      else {
        this.isLarge = false;
      }
    }
  }


}

@NgModule({
  imports: [
    CommonModule,
    ActionBarModule,
    DxToolbarModule,
    DxFormModule,
    DxButtonModule,
    DxTooltipModule,
    DxValidationSummaryModule,
    CoreModule
  ],
  declarations: [FormComponent],
  exports: [FormComponent]
})
export class FormModule {

}
