import { Component } from '@angular/core';
import { TranslationService } from 'src/app/core/services/translation.service';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss']
})
export class ForbiddenComponent {
  public title = "";
  public description = "";
  constructor(private translationService: TranslationService) { 
    this.title = translationService.translate("title_forbidden");
    this.description = translationService.translate("description_forbidden");
  }
}
