import { Component, OnInit, NgModule, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { CommonModule } from "@angular/common";
import { DxButtonModule, DxToolbarComponent, DxToolbarModule } from 'devextreme-angular';
import { TranslationService } from 'src/app/core/services/translation.service';
import { ClickEvent, Properties } from 'devextreme/ui/button';

@Component({
  selector: 'app-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss']
})
export class ActionBarComponent implements OnInit {
  @Input()
  public actionItems: any;

  @Input()
  public visible: boolean = true;
  
  @Input()
  public showExportItems: boolean = false;

  @Input()
  public showHistoryItem: boolean = false;

  @Output()
  public onExportToPdf: EventEmitter<ClickEvent> = new EventEmitter<ClickEvent>();

  @Output()
  public onExportToExcel: EventEmitter<ClickEvent> = new EventEmitter<ClickEvent>();

  @Output()
  public onViewHistory: EventEmitter<ClickEvent> = new EventEmitter<ClickEvent>();

  @ViewChild(DxToolbarComponent)
  public toolBar!: DxToolbarComponent

  constructor(public translationService: TranslationService) { }

  ngOnInit(): void {
  }
 
  public exportItems: any = [
    {
      location: 'before',
      widget: 'dxMenu',
      options: {
        orientation: 'horizontal',
        showFirstSubmenuMode: "onHover",
        items: [
          {
            text: this.translationService.translate("action_export"),
            items: [
              {
                text: this.translationService.translate("dialog_message_export_to_pdf"),
                onClick: (e: ClickEvent) => {
                  this.onExportToPdf?.emit(e);
                },
              },
              {
                text: this.translationService.translate("dialog_message_export_to_excel"),
                onClick: (e: ClickEvent) => {
                  this.onExportToExcel?.emit(e);
                },
              }
            ],
          }
        ],
      },
    },
  ]

  public historyActionItem: Properties = {
    text: this.translationService.translate("action_view_history"),
    type: 'normal',
    onClick: (e: ClickEvent) => {
      this.onViewHistory?.emit(e);
    }
  }

}


@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    DxToolbarModule
  ],
  declarations: [ ActionBarComponent ],
  exports: [ ActionBarComponent ]
})
export class ActionBarModule {

}