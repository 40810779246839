import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IModuleReminder } from '../../models';
import { createRequestOptions } from 'src/app/core/helpers/request-option-helper';
import CustomStore from 'devextreme/data/custom_store';
import { ModuleRemainderService } from '../../services/module-remainder.service';
import { Router } from '@angular/router';
import { getReminderDetailsLink } from 'src/app/core/helpers/link';
import { DxTreeListComponent } from 'devextreme-angular';
import { GridActionClickEvent } from 'src/app/shared/models';
import { ClickEvent } from 'devextreme/ui/button';
import { SelectionChangedEvent } from 'devextreme/ui/tree_list';

@Component({
  selector: 'app-view-module-remainders',
  templateUrl: './view-module-remainders.component.html',
  styleUrls: ['./view-module-remainders.component.scss']
})
export class ViewModuleRemaindersComponent {

  @Input()
  title:any;

  @Input()
  moduleReminder: IModuleReminder = {
    ModuleId: null,
    ModuleTypeId: null,
  }

  @ViewChild(DxTreeListComponent)
  grid!: DxTreeListComponent;

  @Output()
  onEditClicked: EventEmitter<GridActionClickEvent> = new EventEmitter<GridActionClickEvent>();

  @Output()
  onDeleteClicked: EventEmitter<GridActionClickEvent> = new EventEmitter<GridActionClickEvent>();
 
  @Output()
  onAddClicked: EventEmitter<any> = new EventEmitter();
  dataFilter='';
 
  constructor(private moduleReminderService: ModuleRemainderService,private router: Router) { }

  @Output()
  onSelectionChanged: EventEmitter<SelectionChangedEvent> = new EventEmitter<SelectionChangedEvent>();
  
  load = (loadOptions: any) => {
    let remainderRequestOptions = createRequestOptions(loadOptions,this.dataFilter)
    return this.moduleReminderService
              .getRemainders(this.moduleReminder,remainderRequestOptions)
              .then((response: any) => ({
                data: response.Data,
                totalCount: response.TotalRecords
              }));
  }
 
  public dataSource = new CustomStore({
    key: 'Id',
    load: this.load
  })

  public getDetailsLink (id : number) {
    this.router.navigate([getReminderDetailsLink(id)]);
  }

  getPersonInChargeName(rowData: any) {
    if (rowData.PersonInCharge != null && rowData.PersonInCharge.UserProfile != null) {
      return `${rowData.PersonInCharge?.UserProfile?.FirstName}  ${rowData?.PersonInCharge?.UserProfile?.MiddleName ? rowData?.PersonInCharge?.UserProfile?.MiddleName : ''} ${rowData.PersonInCharge?.UserProfile?.LastName}`;
    } else {
      return;
    }
  }

  getDeputyName(rowData: any) {
    if (rowData.Deputy != null && rowData.Deputy.UserProfile != null) {
      return `${rowData.Deputy?.UserProfile?.FirstName}  ${rowData?.Deputy?.UserProfile?.MiddleName ? rowData?.Deputy?.UserProfile?.MiddleName : ''} ${rowData.Deputy?.UserProfile?.LastName}`;
    } else {
      return;
    }
  }

  getRemindName(rowData: any) {
    if (rowData.Remind != null && rowData.Remind.UserProfile != null) {
      return `${rowData.Remind?.UserProfile?.FirstName}  ${rowData?.Remind?.UserProfile?.MiddleName ? rowData?.Remind?.UserProfile?.MiddleName : ''} ${rowData.Remind?.UserProfile?.LastName}`;
    } else {
      return;
    }
  }

  public selectionChangeHandler = (e: SelectionChangedEvent) => { 
    this.onSelectionChanged?.emit(e);
  }

  public refreshGrid(){
    this.grid.instance.clearSelection();
    this.grid.instance.refresh();
  }
  onAddNew = () =>{
    this.onAddClicked?.emit();
  }

  editLeadsRemainder = (event: ClickEvent, rowData: any) => {
    event.event?.stopPropagation();
    if(rowData.key){
      this.onEditClicked?.emit({...event, rowData: rowData});
    }
  }
  deleteLeadsRemainder = (event: ClickEvent, rowData: any) => {
    event.event?.stopPropagation();
    if(rowData.key){
      this.onDeleteClicked?.emit({...event, rowData: rowData});
    }
  }


}
