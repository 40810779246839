import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private route: ActivatedRoute, public auth: AuthService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params =>{
      let invitationId = params["invitation"];
      let organizationId = params["organization"];
      console.log(invitationId, organizationId);
      this.login(invitationId, organizationId);
    })
  }

  login(invitationId: string, organizationId: string){
    this.auth.loginWithRedirect({ invitation: invitationId, organization: organizationId });
  }
}
