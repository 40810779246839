import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';
import { SpinnerService } from './spinner.service';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor( private httpClient: HttpClient,private spinnerService: SpinnerService) {}

  public openFile(file: any, type: string, fileName: string) {
    const blob = new Blob([file], { type: type });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  public downloadFile(fileName: string) {
    this.spinnerService.showSpinner();
    const downloadFileUrl = `${environment.baseApiUrl}/FileUpload/download?fileName=`;
    return this.httpClient
      .get(`${downloadFileUrl}${fileName}`, {responseType: 'blob',}).toPromise().then((res: any) => {
        this.spinnerService.hideSpinner();
        this.saveFile(res, fileName);
      });
  }

  public saveFile(file: Blob, fileName: string){
    return fileName ? saveAs(file, fileName) : saveAs(file);
  }
}
