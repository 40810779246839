import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CalculateService } from 'src/app/core/services/calculate.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import { FormActionEvent, FormItem } from '../form/model';
import { Location } from '@angular/common';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import dxNumberBox, {InitializedEvent as NumberBoxInitializedEvent,Properties as NumberBoxProperties} from 'devextreme/ui/number_box';
import dxSelectBox, {InitializedEvent,SelectionChangedEvent, ValueChangedEvent} from 'devextreme/ui/select_box';
import {disableComponent,enableComponent} from 'src/app/Utils/dev-extreme.helper';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { PART_ODATA_URL } from 'src/app/part/model';
import { LoadOptions } from 'devextreme/data';

export type Price = {
  PartIds: any;
  PartFieldId: number | null;
  OperatorId: number | null;
  Value: number | null;
  TypeOfValueId: number | null;
};

@Component({
  selector: 'app-update-price',
  templateUrl: './update-price.component.html',
  styleUrls: ['./update-price.component.scss'],
})
export class UpdatePriceComponent {
  private valueNumberBox!: dxNumberBox;
  public SelectedItemsCount: string = '0';
  private isDiscount:boolean=false;
  private typeOfValueSelectBox!: dxSelectBox;

  @Input()
  price: Price = {
    PartIds: [],
    PartFieldId: null,
    OperatorId: null,
    Value: null,
    TypeOfValueId: null,
  };

  @Output()
  onSaveClicked: EventEmitter<FormActionEvent> =
    new EventEmitter<FormActionEvent>();

  @Output()
  onCancelClicked: EventEmitter<FormActionEvent> =
    new EventEmitter<FormActionEvent>();

  @Input()
  public Field: any = [];

  constructor(
    private location: Location,
    public translationService: TranslationService,
    public calculateService: CalculateService,
    private spinnerService: SpinnerService
  ) {}

  public saveClickHandler = (e: FormActionEvent) => {
    this.onSaveClicked?.emit(e);
  };

  public cancelClickHandler = (e: FormActionEvent) => {
    this.location.back();
  };

  public Operator: any = [
    {
      Id: 1,
      Text: this.translationService.translate('form_increment'),
    },
    {
      Id: 2,
      Text: this.translationService.translate('form_decrement'),
    },
  ];

  public typeOfValue: any = [
    {
      Id: 1,
      Text: this.translationService.translate('form_numeric'),
    },
    {
      Id: 2,
      Text: this.translationService.translate('form_percentage'),
    },
  ];

  private showSpinner() {
    this.spinnerService.showSpinner();
  }

  ngOnInit(): void {
    this.showSpinner();
    this.SelectedItemsCount = this.price.PartIds?.length?.toString();
    this.price.PartIds = this.price.PartIds?.slice(0, 10);
    this.formItems = this.getFormData();
  }

  public formItems: Array<FormItem> = [];

  private getFormData(): FormItem[] {
    return [
      {
        itemType: 'group',
        caption: this.translationService
          .translate('navigation_update_price')
          .concat(
            ' ',
            this.translationService.translate(
              'form_title_parts_selected',
              this.SelectedItemsCount
            )
          ),
        items: [
          {
            dataField: 'PartIds',
            editorType: 'dxTagBox',
            label: {
              text: this.translationService.translate('form_part_name'),
            },
            editorOptions: {
              dataSource: new DataSource({
                store: new ODataStore({
                  url: PART_ODATA_URL,
                  key: 'Id',
                  version: 4,
                  onLoaded: (e: LoadOptions) => {
                    this.spinnerService.hideSpinner();
                  },
                }),
                select: ['Name', 'Id'],
              }),
              readOnly: true,
              searchEnabled: true,
              showClearButton: true,
              searchMode: 'contains',
              displayExpr: 'Name',
              valueExpr: 'Id',
            },
            validationRules: [
              {
                type: 'required',
                message: this.translationService.translate(
                  'validation_message_required',
                  this.translationService.translate('form_part')
                ),
              },
            ],
          },
          {
            dataField: 'PartFieldId',
            editorType: 'dxSelectBox',
            label: {
              text: this.translationService.translate('form_part_field'),
            },
            editorOptions: {
              dataSource: this.Field,
              searchEnabled: true,
              showClearButton: true,
              searchMode: 'contains',
              displayExpr: 'Text',
              valueExpr: 'Id',
              onSelectionChanged: (e: SelectionChangedEvent) => {
                if(e.selectedItem != null){
                this.price.Value = null; 
                if (
                  e.selectedItem?.Text ==
                  this.translationService.translate('form_discount')
                ) {
                  this.isDiscount=true;
                  // this.price.TypeOfValueId = 1;
                  enableComponent(this.typeOfValueSelectBox);
                  // disableComponent(this.typeOfValueSelectBox);
                } else {
                  this.isDiscount=false;
                  enableComponent(this.typeOfValueSelectBox);
                 
                }
              }
              },
            },
            validationRules: [
              {
                type: 'required',
                message: this.translationService.translate(
                  'validation_message_required',
                  this.translationService.translate('form_part_field')
                ),
              },
            ],
          },
          {
            dataField: 'OperatorId',
            editorType: 'dxSelectBox',
            label: {
              text: this.translationService.translate('form_update_by'),
            },
            editorOptions: {
              dataSource: this.Operator,
              searchEnabled: true,
              showClearButton: true,
              searchMode: 'contains',
              displayExpr: 'Text',
              valueExpr: 'Id',
            },
            validationRules: [
              {
                type: 'required',
                message: this.translationService.translate(
                  'validation_message_required',
                  this.translationService.translate('form_update_by')
                ),
              },
            ],
          },
          {
            dataField: 'Value',
            label: {
              text: this.translationService.translate('form_value'),
            },
            validationRules: [
              {
                type: 'required',
                message: this.translationService.translate(
                  'validation_message_required',
                  this.translationService.translate('form_value')
                ),
              },
              {
                type: 'custom',
                reevaluate:true,
                validationCallback: (obj: any) => {
                  if (this.isDiscount==false) {
                    if (obj.value <= 0) {
                      obj.rule.message = this.translationService.translate(
                        'validation_mesasge_quantity',
                        this.translationService.translate('form_value'),
                        '0'
                      );
                      return false;
                    }
                  } else if (this.isDiscount==true) {
                    if (obj.value <= 100 && obj.value >0) {
                      return true;
                    }
                    obj.rule.message = this.translationService.translate(
                      'validation_mesasge_between',
                      this.translationService.translate('form_discount'),
                      '0',
                      '100'
                    );
                    return false;
                  }
                  return true;
                },
              },
            ],
            editorType: 'dxNumberBox',
            editorOptions: <NumberBoxProperties>{
              showClearButton: true,
              format: 'decimal',
              onInitialized: (e: NumberBoxInitializedEvent) => {
                if (e.component) {
                  this.valueNumberBox = e.component;
                }
              },
            },
          },
          {
            dataField: 'TypeOfValueId',
            editorType: 'dxSelectBox',
            label: {
              text: this.translationService.translate('form_unit_type'),
            },
            editorOptions: {
              dataSource: this.typeOfValue,
              searchEnabled: true,
              showClearButton: true,
              searchMode: 'contains',
              displayExpr: 'Text',
              valueExpr: 'Id',
              disabled: true,
              onInitialized: (e: InitializedEvent) => {
                if (e.component) {
                  this.typeOfValueSelectBox = e.component;
                }
              },
            },
            validationRules: [
              {
                type: 'required',
                message: this.translationService.translate(
                  'validation_message_required',
                  this.translationService.translate('form_unit_type')
                ),
              },
            ],
          },
        ],
      },
    ];
  }
}
