import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { DxAutocompleteComponent } from 'devextreme-angular';
import { LoadOptions } from 'devextreme/data';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { ItemClickEvent } from 'devextreme/ui/autocomplete';
import { FormatString } from 'src/app/Utils/utils';
import { SearchService } from './search.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [SearchService]
})
export class SearchComponent implements OnInit {

  @ViewChild(DxAutocompleteComponent)
  private searchBox!: DxAutocompleteComponent;

  public dataSource!: DataSource;

  constructor(private router: Router, 
    private searchService: SearchService,
    private auth: AuthService) { }

  ngOnInit(): void {
    this.auth.isAuthenticated$.subscribe(isAuthenticated =>{
      if(isAuthenticated){
        this.dataSource = new DataSource({
          group: "key",
          store: new CustomStore({
            key: "value",
            load: (e: LoadOptions) => {
              if (e.searchValue) {
                return this.searchService.search(e.searchValue).then((result: any) => {
                  return {
                    data: result,
                    totalCount: result.length
                  }
                });
              }
              return [];
            }
          })
        });
      }
    });
  }

  
  public navigate(e: ItemClickEvent) {
    e.component.reset();
    e.component.blur();
    let routeUrl = FormatString(e.itemData.url, e.itemData.value);
    this.router.navigateByUrl(routeUrl);
  }
}
