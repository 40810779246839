import { Component, NgModule, Input, Output, EventEmitter, OnInit, ViewChild, Inject } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { TranslationService } from 'src/app/core/services/translation.service';
import { AuthService, User } from '@auth0/auth0-angular';
import { DxButtonModule, DxContextMenuComponent, DxContextMenuModule, DxListModule, DxToolbarModule } from 'devextreme-angular';
import { UserPanelModule } from '../user-panel/user-panel.component';
import { SharedModule } from '../../shared.module';
import { FavoritesService } from 'src/app/favorites/favorites.service';
import { environment } from 'src/environments/environment';
import { HttpService } from 'src/app/core/services/http.service';
import { NaivgationService } from 'src/app/core/services/navigation.service';
import { SUPPPORT_URL } from 'src/app/Utils/constant';
import { AuthorizationService } from 'src/app/core/services/authorization.service';

export type History = {
  Id?: number,
  Name: string,
  Url: string,
}

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  history: History = {
    Name: "",
    Url : "",
  }

  @Output()
  menuToggle = new EventEmitter<boolean>();

  @Input()
  menuToggleEnabled = false;

  @Input()
  title!: string;

  @ViewChild("favouriteMenu") favouriteMenu!: DxContextMenuComponent;
  favorites: any = [];
  favoritesDataSource: any = [];
  historyDataSource: any = [];
  private readonly historyUrl = `${environment.baseApiUrl}/History`;

  public selectedLanguage!: string;
  public user!: User | null | undefined;
  public tenantName!: string;
  userMenuItems = [{
    text: 'Profile',
    icon: 'user',
    onClick: () => {
      this.router.navigate([`admin/user/edit/${this.authorizationService.CurrentUser.Id}`]);
    }
  },
  {
    text: 'Logout',
    icon: 'runner',
    onClick: () => {
      this.logout();
    }
  },
];

  feedBackMenuItems = [{
    text : this.translationService.translate("header_help"),
    onClick: () => {
      window.open(SUPPPORT_URL, "_blank");
    }
  },
  {
    text : this.translationService.translate("form_title_feedback"),
    onClick: () => {
      this.router.navigate(['/customerFeedback/feedbacks/add']);
    }
  },
  {
    text: this.translationService.translate("header_view_feedback"),
    onClick: () => {
      this.router.navigate(['/customerFeedback/feedbacks']);
    }
  },
];

  onItemClick (e: any) {
    this.router.navigate([e.itemData.Url]);
  }
 
  viewFavorites = () => {
    this.router.navigate(['/favorites']);
  }

  ViewTopFavorites = () => {
      return this.favoritesService.getTopFavoritesByPriorityOrder()
              .then((response: any) => {
               this.favoritesDataSource = [];
               this.favoritesDataSource.push({ favorites: response});
               this.favouriteMenu.instance.show();
              })
  }  

  addFavorites = () => {
    let currentPageUrl = { Url: this.router.url };
    this.router.navigate(['/favorites/add'], {state: currentPageUrl}); 
  }

  viewHistory = () => {
    return this.httpService.getData(`${environment.baseApiUrl}/History`)
    .then((response: any) => {
      this.historyDataSource = [];
      this.historyDataSource = response;
    });
  }

  languageItems = [{
    text: this.translationService.translate("language_english"),
    locale: "en-CH"
  },
  {
    text: this.translationService.translate("language_german"),
    locale: "de-CH"
  }];

  public onLanguageClick(item : any){
    this.translationService.setLocale(item.itemData.locale);
    this.selectedLanguage = item.itemData.text;
    parent.document.location.reload();
  }

  constructor(public auth: AuthService, 
    @Inject(DOCUMENT) private doc: Document,
    private router: Router, 
    private translationService: TranslationService,
    private favoritesService: FavoritesService,
    private httpService: HttpService,
    private authorizationService: AuthorizationService,
    private navigationService: NaivgationService) {
      this.auth.isAuthenticated$.subscribe(result =>{
        if(!result){
          this.loginWithRedirect();
        }
      })
      this.auth.user$.subscribe(user =>{
        this.tenantName = user?.["https://setumes.com/claims/current_tenant"];
        this.user = user;
      })
      this.router.events.subscribe((event) => {
       if (event instanceof NavigationEnd){
        let currentPage : any = navigationService.pages?.find(p => p.RelativePath === event.url);
        this.history.Name = currentPage ? currentPage.Title : event.url;
         this.history.Url = event.url;
         this.httpService.post(this.historyUrl, this.history);
      }
      })
    }

  ngOnInit() {
    console.log("header");
    let currentLanguage = this.languageItems.find(item => item.locale === this.translationService.getLocale());
    this.selectedLanguage = currentLanguage ? currentLanguage.text : this.translationService.translate("language_english");
  }

  toggleMenu = () => {
    this.menuToggle.emit();
  }

  loginWithRedirect = () => {
    this.auth.loginWithRedirect({ scope: "openid profile email"});
  }

  logout() {
    this.auth.logout({ returnTo: this.doc.location.origin });
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule,
    DxListModule,
    DxContextMenuModule,
    SharedModule
  ],
  declarations: [ HeaderComponent ],
  exports: [ HeaderComponent ]
})
export class HeaderModule { }
