<app-tree-grid [title]=title  [showAdd]="true" (onAdd)="onAddNew()">
<dx-tree-list class="dx-card wide-card" id="dataSource" [dataSource]="dataSource" keyExpr="Id" parentIdExpr="ParentRemainderId" dataStructure="plain"  (onSelectionChanged)="selectionChangeHandler($event)">
      <dxo-paging [pageSize]="10" [enabled]="true" ></dxo-paging>
      <dxo-pager [showPageSizeSelector]="true" [showInfo]="true" [visible]="true"></dxo-pager>
      <dxo-filter-row [visible]="false"></dxo-filter-row>
      <dxo-remote-operations [paging]="true" [sorting]="true"></dxo-remote-operations>
      <dxo-scrolling mode="standard" showScrollbar="always"></dxo-scrolling>
      <dxo-selection mode="single" showCheckBoxesMode="always"></dxo-selection>
      <dxi-column [caption]='"grid_column_actions"| translate' cellTemplate="cellTemplate" width="100px" [visible]="onEditClicked || onDeleteClicked">
      <div *dxTemplate="let data of 'cellTemplate'">
          <dx-button style="margin-right: 10px;" stylingMode="outlined" type="default" icon="edit" (onClick)="editLeadsRemainder($event, data)"> </dx-button>
          <dx-button style="margin-right: 10px;" icon="trash" stylingMode="outlined" type="danger" (onClick)="deleteLeadsRemainder($event, data)"></dx-button>
      </div>
  </dxi-column>
      <dxi-column dataField="Title" [caption]='"grid_column_title" | translate' cellTemplate="cellTemplateValue"></dxi-column>
      <div *dxTemplate="let row of 'cellTemplateValue'" >
        <a class="grid-cell-detail-link" (click)="getDetailsLink(row.data.Id)" [text]="row.value"></a>  
    </div> 
      <dxi-column dataField="Description" [caption]='"grid_column_description" | translate' type="htmlEditor"></dxi-column>
      <dxi-column dataField="RemainderType.Name" [caption]='"grid_column_remainder_type" | translate'></dxi-column>
      <dxi-column dataField="RemainderDateTime" [caption]='"grid_column_remainder_date" | translate'  dataType="datetime"></dxi-column>
      <dxi-column dataField="ModuleType" [caption]='"grid_column_module_type" | translate'></dxi-column>
      <dxi-column dataField="PersonInCharge" [caption]='"grid_column_person_in_charge" | translate' [width]="120" [calculateDisplayValue]="getPersonInChargeName"></dxi-column>
      <dxi-column dataField="Remind" [caption]='"grid_column_remind" | translate' [width]="120" [calculateDisplayValue]="getRemindName"></dxi-column>
      <dxi-column dataField="Deputy" [caption]='"grid_column_deputy" | translate' [width]="120" [calculateDisplayValue]="getDeputyName"></dxi-column>
      <dxi-column dataField="RemainderStatus.Name" [caption]='"grid_column_status" | translate'></dxi-column>
      <dxi-column dataField="CreatedDate" [caption]='"grid_column_created_date" | translate' dataType="date"></dxi-column>
      <dxi-column dataField="UpdatedDate" [caption]='"grid_column_updated_date" | translate' dataType="date"></dxi-column>
    </dx-tree-list>
</app-tree-grid>