import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  private readonly PLATFORM_URL = `${environment.baseApiUrl}/Platform`;

  constructor(private httpService: HttpService) { }

  public checkDatabaseMigrations(){
    let url = `${this.PLATFORM_URL}/database/check`
    return this.httpService.getData(url);
  }
}
