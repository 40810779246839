<header>
  <dx-toolbar class="header-toolbar">
    <dxi-item
      *ngIf="menuToggleEnabled"
      location="before"
      widget="dxButton"
      cssClass="menu-button"
      [options]="{
        icon: 'menu',
        stylingMode: 'text',
        onClick: toggleMenu
      }">
    </dxi-item>
    <dxi-item
      location="before"
      cssClass="header-title"
      *ngIf="title"
      [text]="title">
    </dxi-item>
    <dxi-item
      location="before"
      cssClass="tenant-separator"
      *ngIf="tenantName"
      text="|">
    </dxi-item>
    <dxi-item
      location="before"
      cssClass="tenant-name"
      *ngIf="tenantName"
      [text]="tenantName">
    </dxi-item>
    <dxi-item
     location="after"
     locateInMenu="auto">
     <div *dxTemplate="let data of 'item'">
       <app-search></app-search>
     </div>
   </dxi-item>
     
   <dxi-item
   location="after"
   locateInMenu="auto">
   <div *dxTemplate="let data of 'item'">
    <dx-button
      class="favorite-button"
      icon="favorites"
      width="40px"
      height="46px"
      [hint]= '"favorite_icon" | translate'
      stylingMode="text" (onClick)="ViewTopFavorites()">
      <div *dxTemplate="let data of 'content'">
        <span class="material-icons-round" style="font-size: 32px; color: rgb(87, 112, 112)">
          star_border
          </span>
        <dx-context-menu target=".favorite-button" [dataSource]="favoritesDataSource" #favouriteMenu 
         [position]="{
          my: 'top',
          at: 'bottom'
        }">
      <div *dxTemplate="let data of 'item'">
        <div class="list-container">
          <dx-list class="favourite-list" [dataSource]="data.favorites" (onItemClick)="onItemClick($event)">
            <div *dxTemplate="let item of 'item'">
              <span class="favourite-item">{{item.Name}}</span>
            </div>
           </dx-list>
        </div>
        <div class="dx-field">
          <div class="dx-field-value">
          <dx-button
          stylingMode="contained"
          text="Manage favorites"
          type="default"
          [width]="120" (onClick)="viewFavorites()">
          </dx-button>
      </div>
          <dx-button
            stylingMode="contained"
            text="Add to favorites"
            type="default"
            [width]="120" (onClick)="addFavorites()">
          </dx-button>
      </div>
      </div>
      </dx-context-menu>
      </div>
    </dx-button>
  </div>
</dxi-item>
<dxi-item
   location="after"
   locateInMenu="auto">
   <div *dxTemplate="let data of 'item'">
    <dx-button
      class="history-button"
      icon="history_icon"
      width="40px"
      height="40px"
      [hint]= '"history_icon" | translate'
      stylingMode="text"  (onClick)="viewHistory()">
      <div *dxTemplate="let data of 'content'">
        <span class="material-icons-round"  style="font-size: 29px; color: rgb(87, 112, 112)">
          history
          </span>
      <dx-context-menu 
        target=".history-button"
        showEvent="dxclick" [dataSource]="historyDataSource" [width]="150" displayExpr="Title" (onItemClick)="onItemClick($event)"
        [position]="{
          my: 'top',
          at: 'bottom'
        }">
      </dx-context-menu>
      </div>
    </dx-button>
  </div>
</dxi-item>
   <dxi-item
   location="after"
   locateInMenu="auto">
   <div *dxTemplate="let data of 'item'">
    <dx-button
      class="help-button"
      icon="help"
      width="35px"
      height="38px"
      [hint]= '"feedback_icon" | translate'
      stylingMode="text">
      <div *dxTemplate="let data of 'content'">
        <span class="material-icons-round" style="font-size: 28px; color: rgb(87, 112, 112)">
          help_outline
          </span>
        <dx-context-menu 
        target=".help-button"
        showEvent="dxclick"
        [items]="feedBackMenuItems" 
        displayExpr="text" 
        [position]="{
          my: 'top',
          at: 'bottom'
        }"></dx-context-menu>
      </div>
    </dx-button>
  </div>
</dxi-item>
   <dxi-item
    location="after"
    locateInMenu="auto"
    widget="dxButton"
    *ngIf="(auth.isAuthenticated$ | async) === false"
    [options]="{
      text: 'Login',
      stylingMode: 'text',
      onClick: loginWithRedirect
    }"></dxi-item>
    <dxi-item
      *ngIf="auth.user$ | async"
      location="after"
      locateInMenu="auto"
      menuItemTemplate="menuItem">
      <div *dxTemplate="let data of 'item'">
        <dx-button
          class="user-button authorization"
          width="210px"
          height="100%"
          stylingMode="text">
          <div *dxTemplate="let data of 'content'">
            <app-user-panel [user]="user" [menuItems]="userMenuItems" menuMode="context"></app-user-panel>
          </div>
        </dx-button>
      </div>
    </dxi-item>
    <div *dxTemplate="let data of 'menuItem'">
      <app-user-panel [user]="user" [menuItems]="userMenuItems" menuMode="list"></app-user-panel>
    </div>
  </dx-toolbar>
</header>




<!-- <div class="fields-container">
  <div class="dx-field">
    <div class="dx-field-value">
      <dx-button
        icon="check"
        type="success"
        text="Done"
        
      >
      </dx-button>
    </div>
  </div>
  <div class="dx-field">
    <div class="dx-field-value">
      <dx-button
        icon="images/icons/weather.png"
        text="Weather"
      >
      </dx-button>
    </div>
  </div> -->