import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { filter } from 'rxjs/operators';
import { NaivgationService } from './navigation.service';
import { TranslationService } from './translation.service';

@Injectable()
export class PageInfoService implements OnDestroy {

  private routerSubscription: Subscription;
  private pagesSubscription!: Subscription;

  private title = new BehaviorSubject<string>("");
  public titleObservable = this.title.asObservable();

  constructor(private router: Router, 
    private translationService: TranslationService,
    private navigationService: NaivgationService) { 
    this.routerSubscription = this.router.events
    .pipe(filter(p => p instanceof NavigationEnd))
    .subscribe(event =>{
      let currentUrl = (event as NavigationEnd).url;
      if(navigationService.pages){
        this.setCurrentPage(navigationService.pages, currentUrl);
      }
      this.pagesSubscription = navigationService.pagesObservable.subscribe(pages =>{
        this.setCurrentPage(pages, currentUrl);
      });
    });
  }

  private setCurrentPage = (pages: Record<string, unknown>[], currentUrl: string) =>{
    let currentMenuItem = pages.find(p => p.RelativePath === currentUrl);
    this.title.next(this.translationService.translate(currentMenuItem?.PageTitle as string));
  }

  getCurrentPageTitle() {
    return this.title.getValue();
  }
  
  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.pagesSubscription.unsubscribe();
  }
}
