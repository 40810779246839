import { Injectable } from '@angular/core';
import { formatMessage } from 'devextreme/localization';

@Injectable()
export class TranslationService {
  constructor() { }
  private readonly LOCALE_KEY: string = "locale"; 
  public translate(key: string, ...values: Array<string>) : string{
    return formatMessage(key, ...values);
  }

  public setLocale(locale: string){
    localStorage.setItem(this.LOCALE_KEY, locale);
  }

  public getLocale(): string{
    let locale = localStorage.getItem(this.LOCALE_KEY)
    return locale ? locale : "en";
  }
}
