import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { MESUser, PriceBreakdown, PriceItem } from '../model';
import { HttpService } from './http.service';
import { TranslationService } from './translation.service';
import { Subject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class AuthorizationService {
    public userAccess!: Record<string, unknown>[];
    public userAccessPromise!: Promise<any>;
    public userLicensePromise!: Promise<any>;
    public isAccessLoaded!: boolean;
    public isAuthenticated!: boolean;
    public isLicensed!: boolean;
    private isAuthenticatedSubject$ = new Subject<boolean>();
    public isAuthenticatedObservable = this.isAuthenticatedSubject$.asObservable();
    private isLicensedUserSubject$ = new Subject<boolean>();
    public isLicensedUserObservable = this.isLicensedUserSubject$.asObservable();
    public CurrentUser!: MESUser;
    constructor(private httpService: HttpService, 
        
        private auth0Service: AuthService,
        private translationService: TranslationService){

    }

    checkUserLicense = (pages: any) =>{
        return new Promise((resolve, reject) =>{
            if(pages?.length > 0){
                //@ts-ignore 
                let test = pages;
                this.httpService.getData(`${environment.baseApiUrl}/User/Userlicense`, new HttpParams().append("pageUrl",pages)).then((license: any) =>{
                this.isLicensed = license;
                this.isLicensedUserSubject$.next(license);
                    resolve(license);
                })
            }
            else{
                resolve(false);
            }
        });
    }

    getUserAccess(){
        this.userAccessPromise = new Promise(resolve =>{
            this.httpService.getData(`${environment.baseApiUrl}/User/access`)
                .then((response: any) =>{
                    this.userAccess = response;
                    this.isAccessLoaded = true;
                    resolve(response);
                });
        })
        return this.userAccessPromise; 
    }

    getUserLicense(){
        this.userLicensePromise = new Promise(resolve =>{
        this.httpService.getData(`${environment.baseApiUrl}/User/license`)
            .then((response: any) =>{
                this.isLicensed = response;
                this.isLicensedUserSubject$.next(response);
                resolve(response);
            });
        })
        return this.userLicensePromise; 
    }

    getUserDetails(){
        console.log("auth service - before");
        return new Promise(resolve =>{
            this.auth0Service.getUser().subscribe(user =>{
                let params = new HttpParams()
                                .append("email", user?.email || "");
                this.httpService.getData(`${environment.baseApiUrl}/User/Email`, params)
                    .then((response: any) =>{
                        this.CurrentUser = {
                            Email : response.Data.Email,
                            Id: response.Data.UserId,
                            Language: response.Data.BaseLanguageCode,
                            EmployeeId: response.Data?.EmployeeId,
                            RefreshTimer: response.Data?.RefreshTimer || 10000
                        }
                        this.translationService.setLocale(response.Data.BaseLanguageCode);
                        console.log("auth service - after");
                        resolve(null);
                    });
            });
        });
    }

    hasPageAccess(pageUrl: Array<string>, minimumAccessType: number){
        let page = this.userAccess.find((p: any) => pageUrl.indexOf(p.Page.RelativePath) != -1 && p.AccessTypeId >= minimumAccessType);
        return page != null;
    }

    setAuthenticated(isAuthenticated: boolean){
        this.isAuthenticated = isAuthenticated;
        this.isAuthenticatedSubject$.next(isAuthenticated);
    }
}

