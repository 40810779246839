import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ValidationCallbackData } from 'devextreme/ui/validation_rules';
import { FormActionEvent, FormItem } from 'src/app/shared/components';
import { DescriptionMaxLength, NameMaxLength } from 'src/app/Utils/constant';
import { Router, ActivatedRoute } from '@angular/router';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import { Location } from '@angular/common';
import { CustomerService } from '../../../customer/customer.service';
import { ICRMDepartment } from '../../../customer/customer-model';

@Component({
  selector: 'app-add-edit-crmdepartment',
  templateUrl: './add-edit-crmdepartment.component.html',
  styleUrls: ['./add-edit-crmdepartment.component.scss']
})
export class AddEditCRMDepartmentComponent implements OnInit {

  @Input()
  public popupVisible:boolean = false;

  @Output()
  public onSave: EventEmitter<number> = new EventEmitter();

  @Output()
  public onCancel: EventEmitter<unknown> = new EventEmitter();

  public crmDepartmentData: ICRMDepartment = {
    Name : "",
    Description: "",
    Code: ""
  }

  private validateName = (obj: ValidationCallbackData) =>{
    return obj.value ? this.customerService.checkIfCRMDepartmentNameExists(obj.value?.toString(), this.id) : Promise.resolve(false);
  }

  private validateDepartmentCode = (obj: ValidationCallbackData) =>{
    return this.customerService.checkIfDepartmentCodeExist(obj.value, this.id);
  }

  public crmDepartmentFormItems: Array<FormItem> = [
    {
      itemType: "group",
      caption: this.translationService.translate("dialog_message_customer_department"),
      items: [
        {
          dataField: "Name",
          label: {
            text: this.translationService.translate("form_name")
          },
          editorOptions: { 
            showClearButton: true 
          },
          validationRules: [
            { type: "required", message: this.translationService.translate("validation_message_required", this.translationService.translate("form_name")) },
            { type: "stringLength", max: NameMaxLength, message: this.translationService.translate("validation_string_length", this.translationService.translate("form_name"), NameMaxLength.toString()) },
            { type: 'async', validationCallback: this.validateName, message: this.translationService.translate("validation_message_exists", this.translationService.translate("form_name") ) }
          ]
        },
        {
          dataField: "Code",
          label: {
            text: this.translationService.translate("grid_column_code")
          },
          editorOptions:{showClearButton:true},
          validationRules: [
            { type: 'required', message: this.translationService.translate("validation_message_required", this.translationService.translate("grid_column_code") )},
            {
               type: "stringLength", max: 100, message:this.translationService.translate("validation_string_length", this.translationService.translate("grid_column_code"), "100")
            },
            { type: 'async', validationCallback: this.validateDepartmentCode, message: this.translationService.translate("validation_department_exists") },
          ],
        },
        {
          dataField: "Description",
          editorType: "dxTextArea",
          label: {
            text: this.translationService.translate("form_description")
          },
          validationRules: [
            { type: "stringLength", max: DescriptionMaxLength, message: this.translationService.translate("validation_string_length", this.translationService.translate("form_description"), DescriptionMaxLength.toString()) }
          ]
        }
      ]
    }
  ]

  private id: number | any;
  public showAddMore: boolean = true;

  constructor(private router: Router, 
    private location: Location, 
    private route: ActivatedRoute, 
    private customerService: CustomerService,
    public translationService: TranslationService,
    private toastService: ToastService,
    private spinnerService: SpinnerService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = +params['departmentId']; 
      if(this.id  && this.popupVisible== false){
        this.showAddMore = false;
        this.getData();
      }
    }); 
  }

  public onSaveClicked = (e: FormActionEvent) =>{

    if(!this.popupVisible && this.id) {
      this.showSpinner();
      return this.customerService.updateCRMDepartment(this.id, e.data)
      .then((response: any) => {
        this.spinnerService.hideSpinner();
        let message = this.translationService.translate("toast_message_updated" , this.translationService.translate("dialog_message_customer_department"));
        this.toastService.success(message);
        this.router.navigateByUrl(`/customer/departments`);
      });

    }
    else {
      this.showSpinner();
      return this.customerService.addCRMDepartment(e.data)
      .then((response: any) => {
        this.spinnerService.hideSpinner();
        let message = this.translationService.translate("toast_message_added" , this.translationService.translate("dialog_message_customer_department"));
        this.toastService.success(message);
        if (this.onSave.observers.length > 0) {
          this.onSave?.emit(response.Data.Id);
        } else {
          this.router.navigateByUrl(`/customer/departments`);
        }
       
      });
    }  
}

  public onAddMoreClicked = (e: FormActionEvent) =>{
  this.showSpinner();
  return this.customerService.addCRMDepartment(e.data)
    .then((response: any) =>{
      this.spinnerService.hideSpinner();
      let message = this.translationService.translate("toast_message_added" , this.translationService.translate("dialog_message_customer_department"));
      this.toastService.success(message);
      e.form.instance.resetValues();  
    });
 }

  private showSpinner(){
    this.spinnerService.showSpinner();
  } 

  public onCancelClicked = (e: FormActionEvent) =>{
    if (this.onCancel.observers.length > 0) {
      this.onCancel?.emit();
    } else {
      this.location.back();
    }
  }

  public getData() {
    this.customerService.getCRMDepartmentById(this.id)
                        .then((response: any) => {
                          this.crmDepartmentData = response.Data;
                        });  
  }

}
