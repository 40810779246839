import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from 'rxjs/operators';
import { AuthorizationService } from "../services/authorization.service";
import { AuthService } from "@auth0/auth0-angular";
import { DOCUMENT } from "@angular/common";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    private isRefreshing = false;
    @Inject(DOCUMENT) private doc!: Document;
    constructor(private authorizationService: AuthorizationService, private auth: AuthService){

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)
            .pipe(catchError(error => {
                if (error instanceof HttpErrorResponse &&
                    error.status === 401) {
                    return this.handle401Error(error, next);
                  }          
                let errorMessage = '';
                // if (error.error instanceof ErrorEvent) {
                //     // client-side error
                //     errorMessage = `Error: ${error.error.message}`;
                // } else {
                //     // server-side error
                //     errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
                // }
                // console.log(errorMessage);
                return throwError(error);
            })
            )
    }

    private handle401Error(request: any, next: HttpHandler) {
        if (!this.isRefreshing) {
          this.isRefreshing = true;
    
          if (this.authorizationService.isAuthenticated) {
            this.auth.logout({ returnTo: this.doc.location.origin });
          }
        }
    
        return next.handle(request);
      }
    

}