import { Component, OnInit, Sanitizer, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import { HttpService } from 'src/app/core/services/http.service';
import { PopupService } from 'src/app/core/services/popup.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import { environment } from 'src/environments/environment';
import { GridFilterComponent } from '../grid-filter/grid-filter.component';
import dxSelectBox, { InitializedEvent, Properties as SelectBoxProperties, SelectionChangedEvent } from 'devextreme/ui/select_box';
import { createRequestOptions } from 'src/app/core/helpers/request-option-helper';
import { RequestOptions } from 'src/app/core';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { USER_ODATA_URL } from 'src/app/admin/admin-model';
import { DateRangeFilter } from 'src/app/Utils/filter-builder';
import { DomSanitizer } from '@angular/platform-browser';
import { DecimalFormatter } from 'src/app/Utils/utils';


export type IAuditTrailFilter = {
  Type: string,
  UserId: string,
  DateTime: Date | any,
  Identifier: string
}
@Component({
  selector: 'app-view-audit-trails',
  templateUrl: './view-audit-trails.component.html',
  styleUrls: ['./view-audit-trails.component.scss']
})
export class ViewAuditTrailsComponent implements OnInit {

  private readonly auditLogUrl = `${environment.baseApiUrl}/Audit`;

  @ViewChild(DxDataGridComponent)
  grid!: DxDataGridComponent;
  @ViewChild(GridFilterComponent)
  private gridFilterComponent!: GridFilterComponent;
  private dataFilter = "";
  array: any[] = [
    { Id: 1, Name: 'Create' },
    { Id: 2, Name: 'Update' },
    { Id: 3, Name: 'Delete' },
  ];

  private currentPageUrl: string | any;

  loadAuditTrails = (loadOptions: any) => {
    let auditTrailsRequestOptions = createRequestOptions(loadOptions, this.dataFilter)
    if (this.selectedRowId) {

      return this.getAuditTrailsById(this?.currentPageUrl, this.selectedRowId, auditTrailsRequestOptions)
        .then((response: any) => {
          return this.handleAuditResponse(response);
        });
    }
    else {
      return this.getAuditTrails(this?.currentPageUrl, auditTrailsRequestOptions)
        .then((response: any) => { return this.handleAuditResponse(response) });
    }
  }

  handleAuditResponse = (response: any) => {
    if (response.Data) {
      let data = response.Data.map(this.formatDataFields);
      return {
        data: data,
        totalCount: response.TotalRecords
      }
    }
    return {
      data: [],
      totalCount: 0
    }
  }

  parseJson(data: string) {
    return JSON.parse(data);
  }
  public auditTrails = new CustomStore({
    key: 'Id',
    load: this.loadAuditTrails
  })

  public auditTrailFilter: IAuditTrailFilter = {
    Type: "",
    UserId: "",
    DateTime: null,
    Identifier: ""
  }

  searchClicked = (e: any) => {
    this.dataFilter = e.filterString;
    this.grid.instance.refresh();
  }


  public filterItems: any = [
    {
      dataField: "Type",
      editorType: 'dxSelectBox',
      label: {
        text: this.translationService.translate("grid_column_operation")
      },
      editorOptions: <SelectBoxProperties>{
        dataSource: this.array,
        searchEnabled: true,
        showClearButton: true,
        searchMode: "contains",
        displayExpr: "Name",
        valueExpr: "Name",
      },
      filterPropertyName: "Type"
    },
    {
      dataField: 'UserId',
      label: {
        text: this.translationService.translate('form_user'),
      },
      editorType: 'dxSelectBox',
      editorOptions: {
        dataSource: new DataSource({
          store: new ODataStore({
            url: USER_ODATA_URL,
            key: 'Id',
            version: 4,
          }),
          expand: ['UserProfile($select=FirstName,Id,LastName)'],
          select: ['Id', 'UserProfile'],
          filter: ["UserProfile", "<>", null]
        }),
        searchEnabled: true,
        showClearButton: true,
        searchMode: 'contains',
        valueExpr: function (obj: any) {
          return (
            obj && obj.UserProfile?.FirstName + ' ' + obj.UserProfile?.LastName
          );
        },
        displayExpr: function (obj: any) {
          return (
            obj && obj.UserProfile?.FirstName + ' ' + obj.UserProfile?.LastName
          );
        },
      },
      filterPropertyName: 'UserId',
    },
    {
      dataField: "Identifier",
      label: {
        text: this.translationService.translate("grid_column_identifier")
      },
      editorOptions: {
        showClearButton: true
      },
      filterPropertyName: "Identifier"
    },
    {
      dataField: 'DateTime',
      editorType: 'dxDateRangeBox',
      label: {
        text: this.translationService.translate('form_date'),
      },
      editorOptions: {
        showClearButton: true,
      },
      filterPropertyName: 'DateTime',
      formatter: DateRangeFilter,
    },
  ]

  getTableNameWithIdentifier(rowData: any) {
    if (rowData.Identifier != null) {
      return rowData.TableName + " : " + rowData.Identifier;
    }
    return rowData.TableName;
  }

  constructor(private router: Router,
    private popupService: PopupService,
    private toastService: ToastService,
    public translationService: TranslationService,
    private spinnerService: SpinnerService,
    private httpService: HttpService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer) { }

  selectedRowId: string | any;

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.currentPageUrl = params['moduleName'];
    });
    this.selectedRowId = history.state.selectedRowId;
    console.log(this.selectedRowId);
  }

  getAuditTrails(moduleName: string, requestOptions: RequestOptions) {
    let auditTrailsUrl = `${this.auditLogUrl}/History/${moduleName}`;
    return this.httpService.getPaginatedData(auditTrailsUrl, requestOptions);
  }

  getAuditTrailsById(moduleName: string, id: number, requestOptions: RequestOptions) {  //
    let auditTrailsUrl = `${this.auditLogUrl}/History/${moduleName}/${id}`;
    return this.httpService.getPaginatedData(auditTrailsUrl, requestOptions);
  }

  formatDataFields = (audit: any) => {
    let newFields = JSON.parse(audit.NewValue) as Array<any>;
    let oldFields = JSON.parse(audit.OldValue) as Array<any>;
    let allFields: Array<any> = [];
    if(audit.Type == "Delete"){
      allFields = oldFields.map(field => {
        let isHtml = this.isHTML(field.Value);
        let value = isHtml ? this.sanitizeHtml(field.Value) : this.formatNumericValue(field.Value);
        return { key: field.Key, newValue: '', oldValue: value, isHtml: isHtml } 
      });
    }
    else{
    if (newFields) {
      allFields = newFields.map(newField => {
        let isHtml = this.isHTML(newField.Value);
        let value = isHtml ? this.sanitizeHtml(newField.Value) : this.formatNumericValue(newField.Value);
        return { key: newField.Key, newValue: value, oldValue: '', isHtml: isHtml } 
      });
      oldFields?.forEach(oldField => {
        let currentField = allFields.find(p => p.key == oldField.Key);
        if (currentField) {
          let isHtml = this.isHTML(oldField.Value);
          let value = isHtml ? this.sanitizeHtml(oldField.Value) : this.formatNumericValue(oldField.Value);
          currentField["oldValue"] = value;
          currentField.isHtml = currentField.isHtml || isHtml;
        }
      });
    }
  }
    audit["properties"] = allFields;
    return audit;
  }

  isHTML(str: string) {
    var doc = new DOMParser().parseFromString(str, "text/html");
    return Array.from(doc.body.childNodes).some(node => node.nodeType === 1);
  }

  sanitizeHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  formatNumericValue(value: string) {
    if (value && this.isNumeric(value)) {
      return DecimalFormatter(value);
    }
    
    return value;
  }

  isNumeric(str: any) {
    if (typeof str != "string") return false // we only process strings!  
    // @ts-ignore 
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }
}
