import {differenceInCalendarDays} from 'date-fns'

export function GetTotalDaysBetweenDates(endDate: Date | any, startDate: Date | any ) {
    let totalDays = differenceInCalendarDays(endDate , startDate) + 1;
    return totalDays;
} 

export function ToISODate(isoDate : Date | any){
    var startDate = new Date(isoDate.valueOf() + isoDate.getTimezoneOffset() * 60 * 1000 * -1);
    return startDate;
}