import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslationService } from 'src/app/core/services/translation.service';
import { ScreenService } from '../../services/screen.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { EmailService } from 'src/app/core/services/email.service';
import { Location } from '@angular/common';
import { ToastService } from 'src/app/core/services/toast.service';
import { Router } from '@angular/router';
import { Email } from 'src/app/core/model';
import { DxFormComponent } from 'devextreme-angular';
import { ClickEvent } from 'devextreme/ui/button';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export type IEmail = {
 To: string[],
 Cc: string[],
 Bcc: string[],
 Subject: string,
 Body: string
}

@Component({
  selector: 'app-view-template-for-email',
  templateUrl: './view-template-for-email.component.html',
  styleUrls: ['./view-template-for-email.component.scss']
})

export class ViewTemplateForEmailComponent implements OnInit {

  @Input()
  public email: Email = {
   To: "",
   Cc: "",
   Bcc: "",
   Subject: "",
   Body: "",
   From: "",
   Attachments: [],
   ValueId: null,
   EntityTypeId: null
  }

  @Output() popupClosed: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(DxFormComponent)
  public form!: DxFormComponent;
  
  to: string = '';
  cc: string = '';
  bcc: string = '';
  subject: string = '';
  messageBody: string = '';
  showBcc: boolean = false;
  @Input()
  public popupVisible!: boolean;
  @Input()
  public isReadOnly!: boolean;
  public isLargeScreen: boolean = false;
  isMultiline = true;
  attachments: { Name: string, File: File }[] = [];

  constructor(  
    public translationService: TranslationService,
    private screenService: ScreenService, private spinnerService: SpinnerService,
    private emailService: EmailService,private location: Location,private toastService: ToastService,private router: Router,
    private httpClient: HttpClient) { 
      this.isLargeScreen = this.screenService.isLargeScreen();
      this.screenService.isLargeScreenChanged.subscribe(isLargeScreen =>{
        this.isLargeScreen = isLargeScreen;
      })
    }

    public formatEmailArray(emailArray: string[] | string): string {
      return emailArray && emailArray instanceof(Array) ? emailArray.join('; ') : '';
    }
    
    public parseEmailString(emailString: string[] | string): string[] {
      return emailString ? (String(emailString).split(';').map(email => email.trim())) : [];
    }

    uploadFile = (file: File) => {
        this.attachments.push({
          Name: file.name,
          File: file
        });
    };

    private createMultiPartForm() {
      const formData = new FormData();
      this.attachments.forEach(attachment => {
        formData.append('Attachments', attachment.File, attachment.Name);
      });
      formData.append('To', this.email.To);
      formData.append("Cc", this.email.Cc);
      formData.append("Bcc", this.email.Bcc);
      formData.append("Subject", this.email.Subject);
      formData.append("Body", this.email.Body);
      formData.append("From", this.email.From);
      formData.append("ValueId", this.email.ValueId);
      formData.append("EntityTypeId", this.email.EntityTypeId);

      return formData;
    }

    removeAttachment(index: number) {
      this.attachments.splice(index, 1);
    }

    onContentReady (e : any) {
      e.component.instance().format("size", "12pt");
      e.component.instance().format("font", "Calibri");
    }

    private validateAndCall = (e: ClickEvent, handler: Function) => {
      let validationResult = this.form.instance.validate();
      if (validationResult.status === "pending") {
        validationResult.complete?.then((asyncValidationResult) => {
          asyncValidationResult.isValid && handler(e);
        });
      }
      else {
        validationResult.isValid && handler(e);
      }
    }

    
  OnSendClick = (e: ClickEvent) => {
    this.validateAndCall(e, this.sendEmail);
  }

  emailToSend : any;

  sendEmail= (e: ClickEvent) =>{
    this.isReadOnly = true;
    this.spinnerService.showSpinner();
    const formData = this.createMultiPartForm();
    return this.httpClient.post(`${environment.baseApiUrl}/Email/send`, formData)
    .toPromise()
    .then((res: any) => {
      this.spinnerService.hideSpinner();
      let toastMessage = this.translationService.translate("email_send_toast_message");
      this.toastService.success(toastMessage);
      this.popupClosed.emit();
    });
  }

  ngOnInit(): void {
    if(!this.isReadOnly){
      this.email.To = this.formatEmailArray(this.email.To);
      this.email.Cc = this.formatEmailArray(this.email.Cc);
      this.email.Bcc = this.formatEmailArray(this.email.Bcc);
    }
    this.email.Body = this.email.Body.replace(/<p><\/p>/gi, '');
  }

  toggleBcc() {
    this.showBcc = !this.showBcc;
  }

  customEmailValidation = (e: any): boolean => {
    return multipleEmailValidator(e.value);
  }
  
}

function multipleEmailValidator(emailString: string): boolean {
  if (!emailString) return false;

  if (emailString.includes(',')) return false;

  const emails = emailString.split(';').map(email => email.trim());
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return emails.every(email => emailPattern.test(email));
}
