import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from 'devextreme/localization';
import { CurrencyService } from 'src/app/core/services/currency.service';

@Pipe({
  name: 'currency'
})
export class CurrencyPipe implements PipeTransform {

  constructor(private currencyService: CurrencyService){

  }

  transform(value: number, currencyCode: string): string {
    if(value != null){
      return formatNumber(value, { type: "currency", currency: currencyCode ? currencyCode : this.currencyService.companyCurrency , precision: 2 });
    }
    return value;
  }
}
