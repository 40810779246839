<app-data-grid id="attachments" [title]='"grid_title_attachments" | translate' [showAdd]="showAdd" (onAdd)="onAddNewAttachment()">
    <dx-data-grid class="dx-card wide-card" [dataSource]="dataSource" [showBorders]="false" [columnAutoWidth]="true"
        [columnHidingEnabled]="true" (onSelectionChanged)="selectionChangeHandler($event)">
        <dxo-selection mode="single" showCheckBoxesMode="always"></dxo-selection>
        <dxi-column [caption]='"grid_column_actions"| translate' cellTemplate="cellTemplate" width="85px"
            [visible]="onEditClicked || onDeleteClicked">
            <div *dxTemplate="let data of 'cellTemplate'">
                <dx-button style="margin-right: 10px;" stylingMode="outlined" type="default" icon="edit"
                    (onClick)="editAttachmentDetail($event, data)"> </dx-button>
                <dx-button icon="trash" stylingMode="outlined" type="danger" (onClick)="deleteAttachmentDetail($event, data)">
                </dx-button>
            </div>
        </dxi-column>
        <dxi-column dataField="ImageString" [width]="100" [caption]='"grid_column_part_image" | translate'
            cellTemplate="cell" [calculateCellValue]="calculateCellValue">
            <div *dxTemplate="let data of 'cell'">
                <img *ngIf="data.value" class="imageClass" [src]="sanitizeImageUrl(data.value)"
                    (click)="onImageClick(data.data)" />
                <i [ngClass]='getClass(data)' *ngIf="!data.value" (click)="onDocumentClick(data.data)"></i>
            </div>
        </dxi-column>
        <dxi-column dataField="Name" [caption]='"grid_column_name" | translate'>
        </dxi-column>
        <dxi-column dataField="Description" type="htmlEditor" [caption]='"grid_column_description" | translate'>
        </dxi-column>
        <dxi-column dataField="FileName" [caption]='"grid_column_file_name" | translate'>
        </dxi-column>
        <dxi-column dataField="IsDefault" [caption]='"grid_column_is_default"| translate'>
        </dxi-column>
        <dxi-column type="buttons" [caption]='"grid_column_download"| translate'>
            <dxi-button name="Download" icon="download" hint="Download file" [onClick]="downloadAttachment">
            </dxi-button>
        </dxi-column>
        <dxi-column type="buttons" [caption]='"grid_column_open_attachment"| translate'>
            <dxi-button name="Open in new tab" icon="material-icons-round icon_open_new_tab" hint="Open attachment"
                [onClick]="openAttachment">
            </dxi-button>
        </dxi-column>

        <dxi-column 
        dataField="CreatedDate" 
        [caption]='"grid_column_created_date" | translate' 
        dataType="date">
        </dxi-column>
        <dxi-column 
        dataField="UpdatedDate" 
        [caption]='"grid_column_updated_date" | translate' 
        dataType="date">
</dxi-column>
    </dx-data-grid>
</app-data-grid>
<dx-popup
[width]="width"
[height]="height"
[showTitle]="true"
[closeOnOutsideClick]="true"
[title]="popupTitle"
[showCloseButton]="true"
[dragEnabled]="false"
container=".dx-viewport"
[(visible)]="popupVisible"

>
<div class="popup-content">
    <img [src]="imageSource" class="center" *ngIf="imageSource" />
    <ngx-extended-pdf-viewer [src]="documentSource" [useBrowserLocale]="true" *ngIf="documentSource" [showUnverifiedSignatures]="true"></ngx-extended-pdf-viewer>
  </div>
</dx-popup>


