import { Component, OnInit, NgModule, Input, ViewChild, OnDestroy } from '@angular/core';
import { SideNavigationMenuModule, HeaderModule, SideNavigationMenuComponent } from '../../shared/components';
import { ScreenService } from '../../shared/services';
import { ItemClickEvent } from 'devextreme/ui/tree_view';
import { DxDrawerModule } from 'devextreme-angular/ui/drawer';
import { DxScrollViewModule, DxScrollViewComponent } from 'devextreme-angular/ui/scroll-view';
import { CommonModule } from '@angular/common';

import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { filter } from 'rxjs/operators';
import { NaivgationService } from 'src/app/core/services/navigation.service';
import { AuthService } from '@auth0/auth0-angular';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { Subscription } from 'rxjs';
import DataSource from 'devextreme/data/data_source';
import { AuthorizationService } from 'src/app/core/services/authorization.service';

@Component({
  selector: 'app-side-nav-outer-toolbar',
  templateUrl: './side-nav-outer-toolbar.component.html',
  styleUrls: ['./side-nav-outer-toolbar.component.scss']
})
export class SideNavOuterToolbarComponent implements OnInit, OnDestroy {
  @ViewChild(DxScrollViewComponent, { static: true }) scrollView!: DxScrollViewComponent;
  @ViewChild(SideNavigationMenuComponent) navigationMenuComponent!: SideNavigationMenuComponent;

  selectedRoute = '';

  menuOpened!: boolean;
  temporaryMenuOpened = false;
  showMenu = false;
  isLicensed = true;

  @Input()
  title!: string;
  public menuItems!: Array<any>;
  public dataSource!: DataSource;
  menuMode = 'shrink';
  menuRevealMode = 'expand';
  minMenuSize = 0;
  shaderEnabled = false;
  public showSpinner = false;
  private currentUrl!: string;
  
  private spinnerSubscription!: Subscription;
  private licenseSubscription!: Subscription;
  
  constructor(private screen: ScreenService,
    private router: Router, 
    private navigationService: NaivgationService,
    private authService: AuthorizationService,
    private spinnerService: SpinnerService) { 
    if(this.authService.isAuthenticated){
      this.showMenu = true;
      this.isLicensed = this.authService.isLicensed;
      this.updateDrawer();
    }  
    console.log(`navigation subscription`);
    
    this.getNavigationMenus(navigationService.navigations);
    
    this.router.events
    .pipe(filter(p => p instanceof NavigationStart))
    .subscribe(event =>{
      this.spinnerService.showSpinner();
    });

    this.router.events
    .pipe(filter(p => p instanceof NavigationEnd))
    .subscribe(event =>{
      this.currentUrl = (event as NavigationEnd).url;
      this.spinnerService.hideSpinner();
      this.navigationMenuComponent?.selectNaivgationItem(this.currentUrl);
    });

    this.spinnerSubscription = this.spinnerService.showSpinnerObservable.subscribe(showSpinner =>{
      this.showSpinner = showSpinner;
    })

    this.licenseSubscription = this.authService.isLicensedUserObservable.subscribe(isLicensed =>{
      this.isLicensed = isLicensed;
    })
  }
  ngOnDestroy(): void {
    this.spinnerSubscription.unsubscribe();
    this.licenseSubscription.unsubscribe();
  }

  ngOnInit() {
    this.menuOpened = this.screen.sizes['screen-large'];

    this.screen.changed.subscribe(() => this.updateDrawer());
  }

  onBackClick = () => {
    let history = this.navigationService.popHistory();
    this.router.navigateByUrl(history?.url);
   }

  getNavigationMenus(navigations: any){
    this.menuItems = navigations.map((item: any) => {
      if (item.Page.RelativePath && !(/^\//.test(item.Page.RelativePath))) {
        item.path = `/${item.Page.RelativePath}`;
      }
      else {
        item.path = item.Page.RelativePath;
      }
      let expand = item.path == this.currentUrl;
      if (item.Page.Icon) {
        return { ...item, icon: `material-symbols-rounded ${item.Page.Icon}`, expanded: expand, selected: expand }
      }
      return { ...item, expanded: expand, selected: expand }
    });
    this.dataSource = new DataSource({
      store: this.menuItems,
      sort: "DisplayOrder"
    })
  }

  updateDrawer() {
    const isXSmall = this.screen.sizes['screen-x-small'];
    const isLarge = this.screen.sizes['screen-large'];

    this.menuMode = isLarge ? 'shrink' : 'overlap';
    this.menuRevealMode = isXSmall ? 'slide' : 'expand';
    this.minMenuSize = isXSmall ? 0 : 60;
    this.shaderEnabled = !isLarge;
  }

  get hideMenuAfterNavigation() {
    return this.menuMode === 'overlap' || this.temporaryMenuOpened;
  }

  get showMenuAfterClick() {
    return !this.menuOpened;
  }

  navigationChanged(event: ItemClickEvent) {
    const path = (event.itemData as any).path;
    const pointerEvent = event.event;

    if (path && this.menuOpened) {
      if (event.node?.selected && event.node.itemData?.Page.RelativePath == this.router.url) {
        pointerEvent?.preventDefault();
      } else {
        this.router.navigate([path]);
        this.scrollView.instance.scrollTo(0);
      }

      if (this.hideMenuAfterNavigation) {
        this.temporaryMenuOpened = false;
        this.menuOpened = false;
        pointerEvent?.stopPropagation();
      }
    } 
    else {
      pointerEvent?.preventDefault();
    }
  }

  navigationClick() {
    if (this.showMenuAfterClick) {
      this.temporaryMenuOpened = true;
      this.menuOpened = true;
    }
  }
}

@NgModule({
  imports: [ SideNavigationMenuModule, SharedModule, DxDrawerModule, HeaderModule, DxScrollViewModule, CommonModule ],
  exports: [ SideNavOuterToolbarComponent ],
  declarations: [ SideNavOuterToolbarComponent ]
})
export class SideNavOuterToolbarModule { }
