import { CommonModule } from '@angular/common';
import { AfterContentInit, Component, ContentChild, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular/ui/data-grid';
import { defaultGridOptions } from '../../models';
import { DxButtonModule } from 'devextreme-angular';
import { ClickEvent } from 'devextreme/ui/button';
import { TranslatePipe } from '../../pipes/translate.pipe';

@Component({
  selector: 'app-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss']
})
export class DataGridComponent implements OnInit, AfterContentInit {

  @Input()
  public id!: string;

  @Input()
  public title!: string;

  @Input()
  public showAdd!: boolean;

  @Input()
  public open: boolean = false;

  @Output()
  public onAdd: EventEmitter<ClickEvent> = new EventEmitter<ClickEvent>();

  @ContentChild(DxDataGridComponent)
  private dataGrid!: DxDataGridComponent;

  constructor() { }
  public grids: Array<any> = [];
  public selectedIndex = -1;
  ngOnInit(): void {
    this.selectedIndex = this.open ? 0 : -1;
    this.grids = [
      {
        title: this.title
      }
    ]
  }

  ngAfterContentInit(): void {
    this.dataGrid.instance.option(defaultGridOptions);
  }

  onAddClicked = (event: ClickEvent) =>{
    this.onAdd && this.onAdd.emit(event);
  }
}
