import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { FormActionEvent } from 'src/app/shared/components';
import { ConfirmPopup, InformationPopup, Popup, PopupType, WarningPopup } from 'src/app/shared/models';
import { TranslationService } from './translation.service';
import { ClickEvent } from 'devextreme/ui/button';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  public popup = new Subject<Popup>();
  constructor(public translationService: TranslationService) { }

  close(){
    this.popup.next({
      visible: false
    })
  }

  confirm(popup: ConfirmPopup){
    this.popup.next({...popup, visible: true, type: PopupType.Confirm});
  }

  inform(popup: InformationPopup){
    this.popup.next({...popup, visible: true, type: PopupType.Information});
  }

  warning(popup: WarningPopup){
    this.popup.next({...popup, visible: true, type: PopupType.Warning});
  }

  public showPopup = (event: FormActionEvent | ClickEvent, text: any) =>{
    this.inform({
      message: this.translationService.translate("popup_message_row_selection", text, text),
      visible: true,
      onOkClicked: () => {
        this.close();
      },
    title: this.translationService.translate('popup_title_row_selection'),
    });
  }

  public showWarningPopup = (event: ClickEvent | any, text: any) =>{
    this.warning({
      message: this.translationService.translate("popup_message_configure", text),
      visible: true,
      onOkClicked: () => {
        this.close();
      },
    title: this.translationService.translate('popup_title_warning'),
    });
  }

  public showPrintPopup = (text: any, onYesClick: Function, onNoClick: Function) => {
    this.confirm({
      message: text,
      visible: true,
      onYesClicked: () => {
        this.close();
        onYesClick && onYesClick();
      },
      onNoClicked: () => {
        this.close();
        onNoClick && onNoClick();
      },
    title: this.translationService.translate('popup_title_print_current'),
    });
  }

  public showAcceptPopup = (text: any, onOkClick: Function) => {
    this.warning({
      message: text,
      visible: true,
      onOkClicked: () => {
        this.close();
      },
    title: this.translationService.translate('popup_title_accept_quotation'),
    });
  }

}
