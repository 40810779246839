<ng-container>
  <section class="offline-section" *ngIf="!isOnline">
    <h3>{{ "message_offline" | translate}}</h3>
  </section>
  <app-side-nav-outer-toolbar title="{{appInfo.title}}" *ngIf="isAuthenticated">
    <router-outlet ></router-outlet>
    <app-footer>
      Copyright © {{appInfo.currentYear}} {{appInfo.company}}
      <br/>
    </app-footer>
  </app-side-nav-outer-toolbar>
</ng-container>
