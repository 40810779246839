<app-data-grid [title]="title">
    <dx-data-grid class="dx-card wide-card" [dataSource]="dataSource" [wordWrapEnabled]="true" 
    [showBorders]="false" [columnAutoWidth]="true"
    [columnHidingEnabled]="true">
    <dxo-selection mode="single" showCheckBoxesMode="always"></dxo-selection>
    <dxi-column 
            dataField="Date" 
            [caption]='"form_date" | translate' 
            dataType="datetime"
            format="shortDateShortTime">
    </dxi-column>
    <dxi-column
            dataField="To"
            [caption]='"form_To" | translate'
            cellTemplate="toCellTemplate">
    <div *dxTemplate="let row of 'toCellTemplate'">
        <span>
            {{displayTo(row.data)}} 
        </span>  
    </div>
    </dxi-column>   
    <dxi-column
            dataField="Cc"
            [caption]='"form_cc" | translate'
            cellTemplate="ccCellTemplate">
            <div *dxTemplate="let row of 'ccCellTemplate'">
                <span>
                    {{displayCc(row.data)}} 
                </span>  
            </div>
    </dxi-column>
    <dxi-column
            dataField="Bcc"
            [caption]='"form_bcc" | translate' 
            cellTemplate="bccCellTemplate">
            <div *dxTemplate="let row of 'bccCellTemplate'">
                <span>
                    {{displayBcc(row.data)}} 
                </span>  
            </div>
    </dxi-column>
    <dxi-column
            dataField="Subject"
            [caption]='"dialog_message_subject" | translate'>
    </dxi-column>
    <dxi-column type="buttons" [caption]='"dialog_message_meassage_body"| translate'>
        <dxi-button name="Open in new tab" icon="material-icons-round icon_open_new_tab" hint="Open mail body"
            [onClick]="openEmail" >
        </dxi-button>
    </dxi-column>
    <dxi-column 
            dataField="CreatedDate"  
            [caption]='"grid_column_created_date" | translate' 
            dataType="date">
    </dxi-column>
    <dxi-column
           dataField="CreatedBy"
           [caption]='"grid_column_created_by" | translate'>
    </dxi-column>
    </dx-data-grid>
    </app-data-grid>

    <dx-popup
    height="100%"
    width="100%"
    [showTitle]="true"
    [dragEnabled]="false"
    [showCloseButton]="true"
    container=".dx-viewport"
    [(visible)]="popupVisibleViewEmailTemplate"
    *ngIf="popupVisibleViewEmailTemplate">
    <dx-scroll-view width="100%" height="100%">
    <div class="popup-content">
        <app-view-template-for-email
        [popupVisible]="popupVisibleViewEmailTemplate"
        [email]="email"
        [isReadOnly]="isReadOnly"
        (popupClosed)="onPopupClosed()">
        </app-view-template-for-email>
    </div>
    </dx-scroll-view>
</dx-popup>
<!--#endregion email template popup  -->
        
    
    
