import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationCallbackData } from 'devextreme/ui/validation_rules';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import { FormItem } from 'src/app/shared/components';
import { DescriptionMaxLength, NameMaxLength } from 'src/app/Utils/constant';
import { IAddressType } from '../../../configuration/configuration-model';
import { Location } from '@angular/common';
import { ConfigurationService } from '../../../configuration/configuration.service';

@Component({
  selector: 'app-add-edit-address-type',
  templateUrl: './add-edit-address-type.component.html',
  styleUrls: ['./add-edit-address-type.component.scss']
})
export class AddEditAddressTypeComponent implements OnInit {

  @Input()
  public popupVisibleAddressType:boolean = false;
  @Output()
  public onSave: EventEmitter<number> = new EventEmitter();

  @Output()
  public onCancel: EventEmitter<unknown> = new EventEmitter();

  public addressType: IAddressType = {
    Name: '',
    Description: '',
  };

  private validateAddressType = (obj: ValidationCallbackData) =>{
    return this.configurationService.checkIfAddressTypeExist(obj.value,this.id);
  }

  public addressTypeFormItems: Array<FormItem> = [
    {
      itemType: 'group',
      caption: this.translationService.translate('dialog_message_address_type'),
      items: [
        {
          dataField: "Name",
          label: {
            text: this.translationService.translate("form_name")
          },
          editorOptions: { 
            showClearButton: true 
          },
          validationRules: [
            { type: "required", message: this.translationService.translate("validation_message_required", this.translationService.translate("form_name")) },
            { type: "stringLength", max: NameMaxLength, message: this.translationService.translate("validation_string_length", this.translationService.translate("form_name"), NameMaxLength.toString()) },
            { type: 'async', validationCallback: this.validateAddressType, message: this.translationService.translate("validation_message_exists", this.translationService.translate("form_name") ) }
          ]
        },
        {
          dataField: "Description",
          editorType: "dxTextArea",
          label: {
            text: this.translationService.translate("form_description")
          },
          validationRules: [
            { type: "stringLength", max: DescriptionMaxLength, message: this.translationService.translate("validation_string_length", this.translationService.translate("form_description"), DescriptionMaxLength.toString()) }
          ]
        },        
      ],
    },
  ];

  private id!: number;

  constructor(
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    public translationService: TranslationService,
    private configurationService: ConfigurationService, 
    private toastService: ToastService,
    private spinnerService: SpinnerService) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = +params['id'];
      if(this.id && this.popupVisibleAddressType== false){
        this.getData();
      }
    });
  }

  public onSaveClicked = (e: any) => {
    if (!this.popupVisibleAddressType && this.id) {
    this.showSpinner();
    return this.configurationService.updateAddressType(this.id, e.data)
    .then((response: any) =>{
      this.spinnerService.hideSpinner();
      this.toastService.success(this.translationService.translate("toast_message_updated", this.translationService.translate("dialog_message_address_type")));
      this.router.navigateByUrl('/configuration/addressTypes');
    });
    } else {
      this.showSpinner();
      return this.configurationService.addAddressType(e.data)
      .then((response: any) =>{
        this.spinnerService.hideSpinner();
        this.toastService.success(this.translationService.translate("toast_message_added", this.translationService.translate("dialog_message_address_type")));
        if (this.onSave.observers.length > 0) {
          this.onSave?.emit(response.Data.Id);
        } else {
          this.router.navigateByUrl('/configuration/addressTypes');
        }
      
       
      });
    }
  };

  private showSpinner(){
    this.spinnerService.showSpinner();
  }

  public onCancelClicked = (e: any) => {
    if (this.onCancel.observers.length > 0) {
      this.onCancel?.emit();
    } else {
      this.location.back();
    }
  };

  public getData() {
    return this.configurationService.getAddressTypeById(this.id)
      .then((response: any) => {
        this.addressType = response.Data;
      });
  }

}
