import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RequestOptions } from '../core';
import { HttpService } from '../core/services/http.service';
import { PLANT_LOCATION_ODATA_URL } from '../resource/resource-model';
import { AUTO_ASSIGNMENT_ODATA_URL, IAutoAssignment, IPaymentCategory, IPlantLocation, ITerm, IUnitOfMeasure, PAYMENT_CATEGORY_ODATA_URL, UNIT_OF_MEASURE_ODATA_URL, CONTACT_TYPE_ODATA_URL, IContactType, ADDRESS_TYPES_ODATA_URL, IAddressType, ITermMapping, TERM_MAPPING_ODATA_URL, TERM_ODATA_URL, IProductIndustry, PRODUCT_INDUSTRY_ODATA_URL, IPartUnitOfMeasureConversion, UNIT_OF_MEASURE_TYPES_ODATA_URL, IUnitOfMeasureType, EMAIL_TEMPLATE_ODATA_URL, IEmailTemplate } from './configuration-model';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private readonly termUrl = `${environment.baseApiUrl}/Term`; 
  private readonly termMappingUrl = `${environment.baseApiUrl}/TermMapping`; 
  private readonly autoAssignmentUrl = `${environment.baseApiUrl}/AutoAssignment`; 
  private readonly paymentCategoryUrl = `${environment.baseApiUrl}/PaymentCategory`;
  private readonly unitOfMeasureUrl = `${environment.baseApiUrl}/UnitOfMeasure`; 
  private readonly plantLocationUrl = `${environment.baseApiUrl}/PlantLocation`;
  private readonly contactTypeUrl = `${environment.baseApiUrl}/ContactType`;
  private readonly addressTypeUrl = `${environment.baseApiUrl}/AddressType`;
  private readonly productIndustryUrl = `${environment.baseApiUrl}/ProductCategory`;
  private readonly partUnitOfMeasureConversionUrl = `${environment.baseApiUrl}/PartUnitOfMeasureConversion`; 
  private readonly unitOfMeasureTypeUrl = `${environment.baseApiUrl}/UnitOfMeasureType`;
  private readonly emailTemplateUrl = `${environment.baseApiUrl}/EmailTemplate`; 

  constructor(private httpService: HttpService) { }

  //#region term
  checkIfTermNameExists(name: string, id: number) : Promise<Boolean>{
    let filter = `Name eq '${name}'`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(TERM_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  getTerms(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.termUrl, requestOptions);
  }

  addTerm(term: ITerm){
    return this.httpService.post(this.termUrl, term);
  }

  getTermById(termId: number){
    return this.httpService.getData(`${this.termUrl}/${termId}`);
   }

  updateTerm(termId: number, term: ITerm){
    let url = `${this.termUrl}/${termId}`;
    term.Id = termId;
    return this.httpService.put(url, term);
  }

  deleteTerm(termId: number){
    let url = `${this.termUrl}/${termId}`;
    return this.httpService.delete(url);
  }
  //#endregion

  //#region term mapping
  checkIfTermTypeNameExists( termTypeId: any, termMappingId: number, id:number) : Promise<Boolean>{
    let filter = `TermTypeId eq ${termTypeId} and TermId eq ${id}`;

    if(termMappingId){
      filter+=` and Id ne ${termMappingId}`;
    }
    return this.httpService
              .getODataCount(TERM_MAPPING_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  getTermMappings(requestOptions: RequestOptions, id: number){
    let termMappingsDetailUrl = `${this.termUrl}/${id}/TermMappings`;
    return this.httpService.getPaginatedData(termMappingsDetailUrl, requestOptions);
  }

  addTermMapping(id: number| any, termMapping: ITermMapping){
    let url = `${this.termUrl}/${id}/TermMappings`;
    return this.httpService.post(url, termMapping);
  }

  getTermMappingById(termMappingId: number){
    return this.httpService.getData(`${this.termMappingUrl}/${termMappingId}`);
  }

  updateTermMapping(termMappingId: number, termMapping: ITermMapping){
    let url = `${this.termMappingUrl}/${termMappingId}`;
    termMapping.Id = termMappingId;
    return this.httpService.put(url, termMapping);
  }

  deleteTermMapping(termMappingId: number){
    let url = `${this.termMappingUrl}/${termMappingId}`;
    return this.httpService.delete(url);
  }
  //#endregion

  //#region autoAssignment
  getAutoAssignment(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.autoAssignmentUrl, requestOptions);
  }

  addAutoAssignment(autoAssignment: IAutoAssignment){
    return this.httpService.post(this.autoAssignmentUrl, autoAssignment);
  }

  getAutoAssignmentById(autoAssignmentId: number){
    return this.httpService.getData(`${this.autoAssignmentUrl}/${autoAssignmentId}`);
   }

  updateAutoAssignment(autoAssignmentId: number, autoAssignment: IAutoAssignment){
    let url = `${this.autoAssignmentUrl}/${autoAssignmentId}`;
    autoAssignment.Id = autoAssignmentId;
    return this.httpService.put(url, autoAssignment);
  }

  deleteAutoAssignment(autoAssignmentId: number){
    let url = `${this.autoAssignmentUrl}/${autoAssignmentId}`;
    return this.httpService.delete(url);
  }

  checkIfAutoAssignmentExists(autoAssignmentFieldId: string, id:number ) : Promise<Boolean>{
    let filter = `AutoAssignmentFieldId eq ${autoAssignmentFieldId}`;

    if(id) {
      filter += `and Id ne ${id}`;
      }
    return this.httpService
              .getODataCount(AUTO_ASSIGNMENT_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }
  //#endregion

  //#region unitOfMeasure

  getUnitOfMeasures(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.unitOfMeasureUrl, requestOptions);
  }

  addUnitOfMeasure(unitOfMeasure: IUnitOfMeasure){
    return this.httpService.post(this.unitOfMeasureUrl, unitOfMeasure);
  }

  getUnitOfMeasureById(unitOfMeasureId: number){
    return this.httpService.getData(`${this.unitOfMeasureUrl}/${unitOfMeasureId}`);
  }

  updateUnitOfMeasure(unitOfMeasureId: number, unitOfMeasure: IUnitOfMeasure){
    let url = `${this.unitOfMeasureUrl}/${unitOfMeasureId}`;
    unitOfMeasure.Id = unitOfMeasureId;
    return this.httpService.put(url, unitOfMeasure);
  }

  deleteUnitOfMeasure(unitOfMeasureId: number){
    let url = `${this.unitOfMeasureUrl}/${unitOfMeasureId}`;
    return this.httpService.delete(url);
  }

  checkIfUnitOfMeasureNameExists(name: string, id: number) : Promise<Boolean>{
    let filter = `Name eq '${name}'`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(UNIT_OF_MEASURE_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  checkIfUnitOfMeasureKeyExists(key: string, id: number) : Promise<Boolean>{
    let filter = `Key eq '${key}'`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(UNIT_OF_MEASURE_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }
  //#endregion
  checkIfCategoryNameExists(name: string, id: number) : Promise<Boolean>{
    let filter = `Name eq '${name}'`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(PAYMENT_CATEGORY_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  getPaymentCategories(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.paymentCategoryUrl, requestOptions);
  }

  addPaymentCategory(paymentCategory: IPaymentCategory){
    return this.httpService.post(this.paymentCategoryUrl, paymentCategory);
  }

  getPaymentCategoryById(paymentCategoryId: number){
    return this.httpService.getData(`${this.paymentCategoryUrl}/${paymentCategoryId}`);
   }

  updatePaymentCategory(paymentCategoryId: number, paymentCategory: IPaymentCategory){
    let url = `${this.paymentCategoryUrl}/${paymentCategoryId}`;
    paymentCategory.Id = paymentCategoryId;
    return this.httpService.put(url, paymentCategory);
  }

  deletePaymentCategory(paymentCategoryId: number){
    let url = `${this.paymentCategoryUrl}/${paymentCategoryId}`;
    return this.httpService.delete(url);
  }
  //#endregion

  //#region plant location
  getPlantLocation(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.plantLocationUrl, requestOptions);
  }

  addPlantLocation(plantLocation: IPlantLocation){
    return this.httpService.post(this.plantLocationUrl, plantLocation);
  }

  getPlantLocationById(plantLocationId: number){
    return this.httpService.getData(`${this.plantLocationUrl}/${plantLocationId}`);
   }

  updatePlantLocation(plantLocationId: number, plantLocation: IPlantLocation){
    let url = `${this.plantLocationUrl}/${plantLocationId}`;
    plantLocation.Id = plantLocationId;
    return this.httpService.put(url, plantLocation);
  }

  deletePlantLocation(plantLocationId: number){
    let url = `${this.plantLocationUrl}/${plantLocationId}`;
    return this.httpService.delete(url);
  }

  checkIfPlantLocationExists(name: string, id: number) : Promise<Boolean>{
    let filter = `Name eq '${name}'`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(PLANT_LOCATION_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  //#endregion

  //#region contact types
  checkIfContactTypeExist(name: any, id: number) : Promise<Boolean>{
    let filter = `Name eq '${name}'`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(CONTACT_TYPE_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  getContactTypes(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.contactTypeUrl, requestOptions);
  }

  addContactType(contactType: IContactType){
    return this.httpService.post(this.contactTypeUrl, contactType);
  }

  getContactTypeById(contactTypeId: number){
    return this.httpService.getData(`${this.contactTypeUrl}/${contactTypeId}`);
   }

   updateContactType(contactTypeId: number, contactType: IContactType){
    let url = `${this.contactTypeUrl}/${contactTypeId}`;
    contactType.Id = contactTypeId;
    return this.httpService.put(url, contactType);
  }

  deleteContactType(contactTypeId: number){
    let url = `${this.contactTypeUrl}/${contactTypeId}`;
    return this.httpService.delete(url);
  }
  //#endregion

  //#region address types
  checkIfAddressTypeExist(name: any, id: number) : Promise<Boolean>{
    let filter = `Name eq '${name}'`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(ADDRESS_TYPES_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  getAddressTypes(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.addressTypeUrl, requestOptions);
  }

  addAddressType(addressType: IAddressType){
    return this.httpService.post(this.addressTypeUrl, addressType);
  }

  getAddressTypeById(addressTypeId: number){
    return this.httpService.getData(`${this.addressTypeUrl}/${addressTypeId}`);
  }

  updateAddressType(addressTypeId: number, addressType: IAddressType){
    let url = `${this.addressTypeUrl}/${addressTypeId}`;
    addressType.Id = addressTypeId;
    return this.httpService.put(url, addressType);
  }

  deleteAddressType(addressTypeId: number){
    let url = `${this.addressTypeUrl}/${addressTypeId}`;
    return this.httpService.delete(url);
  }
  //#endregion

  //#region product industry
  checkIfProductIndustryNameExists(name: string, id: number) : Promise<Boolean>{
    let filter = `Name eq '${name}'`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(PRODUCT_INDUSTRY_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  getProductIndustries(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.productIndustryUrl, requestOptions);
  }

  addProductIndustry(productIndustry: IProductIndustry){
    return this.httpService.post(this.productIndustryUrl, productIndustry);
  }

  getProductIndustryById(productIndustryId: number){
    return this.httpService.getData(`${this.productIndustryUrl}/${productIndustryId}`);
   }

  updateProductIndustry(productIndustryId: number, productIndustry: IProductIndustry){
    let url = `${this.productIndustryUrl}/${productIndustryId}`;
    productIndustry.Id = productIndustryId;
    return this.httpService.put(url, productIndustry);
  }

  deleteProductIndustry(productIndustryId: number){
    let url = `${this.productIndustryUrl}/${productIndustryId}`;
    return this.httpService.delete(url);
  }
  //#endregion

  //#region part unitOfMeasure conversion
  getPartUnitOfMeasureConversions(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.partUnitOfMeasureConversionUrl, requestOptions);
  }

  addPartUnitOfMeasureConversion(unitOfMeasureConversion: IPartUnitOfMeasureConversion){
    return this.httpService.post(this.partUnitOfMeasureConversionUrl, unitOfMeasureConversion);
  }

  getPartUnitOfMeasureConversionById(unitOfMeasureConversionId: number){
    return this.httpService.getData(`${this.partUnitOfMeasureConversionUrl}/${unitOfMeasureConversionId}`);
  }

  updatePartUnitOfMeasureConversion(unitOfMeasureConversionId: number, unitOfMeasureConversion: IPartUnitOfMeasureConversion){
    let url = `${this.partUnitOfMeasureConversionUrl}/${unitOfMeasureConversionId}`;
    unitOfMeasureConversion.Id = unitOfMeasureConversionId;
    return this.httpService.put(url, unitOfMeasureConversion);
  }

  deletePartUnitOfMeasureConversion(unitOfMeasureConversionId: number){
    let url = `${this.partUnitOfMeasureConversionUrl}/${unitOfMeasureConversionId}`;
    return this.httpService.delete(url);
  }
  //#endregion

  //#region unitOfMeasure types
  checkIfUnitOfMeasureTypeExist(name: any, id: number) : Promise<Boolean>{
    let filter = `Name eq '${name}'`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(UNIT_OF_MEASURE_TYPES_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  getUnitOfMeasureTypes(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.unitOfMeasureTypeUrl, requestOptions);
  }

  addUnitOfMeasureType(unitOfMeasureType: IUnitOfMeasureType){
    return this.httpService.post(this.unitOfMeasureTypeUrl, unitOfMeasureType);
  }

  getUnitOfMeasureTypeById(unitOfMeasureTypeId: number){
    return this.httpService.getData(`${this.unitOfMeasureTypeUrl}/${unitOfMeasureTypeId}`);
  }

  updateUnitOfMeasureType(unitOfMeasureTypeId: number, unitOfMeasureType: IUnitOfMeasureType){
    let url = `${this.unitOfMeasureTypeUrl}/${unitOfMeasureTypeId}`;
    unitOfMeasureType.Id = unitOfMeasureTypeId;
    return this.httpService.put(url, unitOfMeasureType);
  }

  deleteUnitOfMeasureType(unitOfMeasureTypeId: number){
    let url = `${this.unitOfMeasureTypeUrl}/${unitOfMeasureTypeId}`;
    return this.httpService.delete(url);
  }
  //#endregion

  //#region email templates
  
  getEmailTemplates(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.emailTemplateUrl, requestOptions);
  }

  addEmailTemplate(emailTemplate: IEmailTemplate){
    return this.httpService.post(this.emailTemplateUrl, emailTemplate);
  }

  getEmailTemplateById(emailTemplateId: number){
    return this.httpService.getData(`${this.emailTemplateUrl}/${emailTemplateId}`);
   }

  updateEmailTemplate(emailTemplateId: number, emailTemplate: IEmailTemplate){
    let url = `${this.emailTemplateUrl}/${emailTemplateId}`;
    emailTemplate.Id = emailTemplateId;
    return this.httpService.put(url, emailTemplate);
  }

  deleteEmailTemplate(emailTemplateId: number){
    let url = `${this.emailTemplateUrl}/${emailTemplateId}`;
    return this.httpService.delete(url);
  }

  checkIfTypeExistForEmailTemplate(entityTypeName: string, emailTemplateId: number) : Promise<Boolean>{
    let filter = `EntityType eq MES.CloudApplication.Domain.Enums.ModuleEntityEnum'${entityTypeName}'` ;
    if(emailTemplateId){
    filter += `and Id ne ${emailTemplateId}`;
    }
    return this.httpService
       .getODataCount(EMAIL_TEMPLATE_ODATA_URL, { filter: filter })
       .then(count => !(count > 0));
   }
  //#endregion

}
