import { Injectable, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthorizationService } from "./authorization.service";
import { HttpService } from "./http.service";
import { NavigationStart, Router } from "@angular/router";
import { Location } from "@angular/common";
import { DashboardService } from "src/app/dashboard/dashboard.service";

@Injectable({providedIn: "root" })
export class NaivgationService {
    private history: any[] = [];
    public isForm = false;
    // window.addEventListener('popstate', function (event)
    // {
    //     history.pushState(null, document.title, location.href);
    // });

    constructor(private httpService: HttpService,
        private authorizationService: AuthorizationService,
        private dashboardService: DashboardService,
        private location: Location,
        private router: Router,
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                let lastHistory = this.history[this.history.length - 1];
                if(this.isForm){
                    if(event?.url == lastHistory?.url){
                        this.history.pop();
                    }
                    this.isForm = false;
                }
                else {
                    if(this.history.length == 0 || lastHistory?.url != this.router.url){
                       this.history.push({ url: this.router.url, navigation: location.getState()});
                    }
                }
                }
            });      
        }
   
    public isFormDirty: boolean = false;
    private pages$ = new Subject<Record<string, unknown>[]>();
    private navigations$ = new Subject<Record<string, unknown>[]>();
    public pagesObservable = this.pages$.asObservable();
    public navigationsObservable = this.navigations$.asObservable();
    public pages!: Record<string, unknown>[];
    public navigations!: Record<string, unknown>[];
    public UserLicenses: any;

    popHistory() {
       let popElement = this.history.pop();
        return popElement; 
    }

    getAllNavigations() {
        return new Promise((resolve, reject) => {

            this.httpService.getOData(`${environment.baseOdataUrl}/Navigations`, {
                expand: "Page",
                select: "Id,ParentNavigationId,DisplayOrder,Page"
            }).then((result: any) => {
                console.log(`navigation before - ${result.value.length}`)
                
                this.dashboardService.getDashboardTabPanelLicenses().then((response:any) =>{
                    this.UserLicenses = response;
                    let userNavigations = new Array<any>();
                    result.value.forEach((navigation: any) => {
                    let access = this.authorizationService.userAccess.find((access: any) => {
                        return access.Page.Id == navigation.Page.Id
                    });

                    let isUserLicense = this.CheckUserLicense(navigation);

                    if (access != null && isUserLicense == true) {
                        this.setNavigation(result.value, userNavigations, navigation);
                    };
                })
                console.log(`navigation after - ${userNavigations.length}`)
                this.navigations$.next(userNavigations);
                this.navigations = userNavigations;
                resolve(userNavigations);

                })

            });
        })

    }

      public CheckUserLicense(checkLicense: any): boolean {
        if (this.UserLicenses && this.UserLicenses.length > 0) {

            return this.UserLicenses.some((license: any) => {
                const isModuleIdMatch = license.Value === checkLicense.Page.ModuleId && license.AccessField == 1;
                const isPageIdMatch = license.Value === checkLicense.Page.Id && license.AccessField == 2;
                return isModuleIdMatch || isPageIdMatch;
              });
        }
        return false; 
      }

    private setNavigation = (allNavigations: Array<any>, userNavigations: Array<any>, currentNavigation: any) => {

        let exists = userNavigations.find(p => p.Id == currentNavigation.Id);
        if(!exists){
            userNavigations.push(currentNavigation);
            if(currentNavigation.ParentNavigationId != null){
                let parent = allNavigations.find(p => p.Id == currentNavigation.ParentNavigationId);
                if(parent){
                    this.setNavigation(allNavigations, userNavigations, parent);
                }
            }
        }
    }

    getAllPages() {
        return this.httpService.getOData(`${environment.baseOdataUrl}/Pages`, {
            select: "Id,Title,RelativePath,PageTitle"
        }).then((response: any) => {
            this.pages = response.value;
            this.pages$.next(response.value);
        });;
    }
}