import { CommonModule } from '@angular/common';
import { Component, ContentChild, EventEmitter, Input, NgModule, OnInit, Output, ViewChild } from '@angular/core';
import { DxFormComponent, DxToolbarComponent, DxTreeListComponent } from 'devextreme-angular';
import { defaultTreeGridOptions, routes } from '../../models';
import { SharedModule } from '../../shared.module';
import { ActionBarComponent, ActionBarModule } from '../action-bar/action-bar.component';
import { GridFilterComponent, GridFilterModule } from '../grid-filter/grid-filter.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tree-grid-view',
  templateUrl: './tree-grid-view.component.html',
  styleUrls: ['./tree-grid-view.component.scss']
})
export class TreeGridViewComponent implements OnInit {

@ViewChild(ActionBarComponent)
  private actionBarComponent!: ActionBarComponent;
  @ViewChild(GridFilterComponent)
  private gridFilterComponent!: GridFilterComponent;
  @ContentChild(DxTreeListComponent)
  private treeGrid!: DxTreeListComponent;
  @Input()
  public filterModel: any;
  @Input()
  public filterItems: any;
  @Input()
  public actionItems: any;
  @Input()
  public showFilter: boolean = true;
  @Input()
  public title!: string;
  @Output()
  public onSearch = new EventEmitter();
  public actionBar!: DxToolbarComponent;
  public filter!: DxFormComponent;
  @Input() public showHistoryItem: boolean = true;
  public currentPageUrl!: string;

  constructor(private router: Router) { }
  ngAfterContentInit(): void {
    this.treeGrid.instance.option(defaultTreeGridOptions);
  }
  ngAfterViewInit(): void {
    this.actionBar = this.actionBarComponent.toolBar;
    this.filter = this.gridFilterComponent?.form;
  }

  ngOnInit(): void {
  }

  onSearchClicked(e: any){
    this.treeGrid?.instance.clearSelection();
    this.onSearch.emit(e);
  }

  onViewHistory = (e: any) =>{
    this.currentPageUrl = this.router.url;
    let currentModel : any = routes.get(this.currentPageUrl);
    this.router.navigate([`/auditTrails/${currentModel}`]);
   }

}

@NgModule({
  imports: [
    CommonModule,
    ActionBarModule,
    GridFilterModule,
    SharedModule
  ],
  declarations: [ TreeGridViewComponent ],
  exports: [ TreeGridViewComponent ]
})
export class TreeGridViewModule {

}

