import { Injectable } from '@angular/core';
import { RequestOptions } from 'src/app/core'
import { environment } from 'src/environments/environment';
import { HttpService } from '../core/services/http.service';
import { CRM_DEPARTMENT_FUNCTION_ODATA_URL, CRM_DEPARTMENT_ODATA_URL, CUSTOMERS_ODATA_URL, CUSTOMER_CONTACT_ODATA_URL, CUSTOMER_DEPARTMENT_ODATA_URL, CUSTOMER_PARTS_ODATA_URL, CUSTOMER_POSITION_ODATA_URL, ICRMDepartment, ICRMDepartmentFunction, ICustomerAddressesData, ICustomerContactNumber, ICustomerContactsData, ICustomerData, ICustomerDepartmentData, ICustomerPartPrice, ICustomerPartsData, ICustomerPosition } from './customer-model';
import { Attachment } from '../shared/components/attach-document/add-edit-attachment/add-edit-attachment.component';


@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private readonly customersUrl = `${environment.baseApiUrl}/Customer`;
  private readonly customerPartsUrl = `${environment.baseApiUrl}/CustomerPart`;
  private readonly customerAddressUrl = `${environment.baseApiUrl}/CustomerAddress`;
  private readonly postalCodeUrl = `${environment.baseApiUrl}/PostalCode`;
  private readonly customerContactUrl = `${environment.baseApiUrl}/CustomerContact`;
  private readonly customerContactNumberUrl = `${environment.baseApiUrl}/CustomerContactNumber`;
  private readonly customerPositionUrl = `${environment.baseApiUrl}/CRMPosition`;
  private readonly crmDepartmentUrl = `${environment.baseApiUrl}/CRMDepartment`;
  private readonly crmDepartmentFunctionUrl = `${environment.baseApiUrl}/CRMDepartmentFunction`;
  private readonly customerDepartmentUrl= `${environment.baseApiUrl}/CustomerDepartment`;
  private readonly customerAttachmentUrl = `${environment.baseApiUrl}/CustomerAttachment`;
  private readonly companyInformationUrl = `${environment.baseApiUrl}/Company/customer`;
  private readonly companyUrl = `${environment.baseApiUrl}/Company`;
  
  constructor(private httpService: HttpService) { }

  //#region customer
  checkICustomerNameExists(name: string, id: number) : Promise<Boolean>{
    let filter = `Name eq '${name}'`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(CUSTOMERS_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  deleteCompany(customerId: number){
    let url = `${this.companyUrl}/${customerId}`;
    return this.httpService.delete(url);
  }

  addCustomer(customerData: ICustomerData){
    return this.httpService.post(this.customersUrl, customerData);
  }

  getCustomerById(customerId:number){
    return this.httpService.getData(`${this.customersUrl}/${customerId}`);
  }

  getCustomerNumber(){
    let url = `${this.customersUrl}/Generate/Number`;
    return this.httpService.getText(url);
  }

  checkIfCustomerNumberExists(customerNumber: string, id: number) : Promise<Boolean>{
    let filter = `CustomerNumber eq '${customerNumber}'`;
    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(CUSTOMERS_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  updateCustomer(customerId: number, customerData: ICustomerData){
    let url = `${this.customersUrl}/${customerId}`;
    customerData.Id = customerId;
    return this.httpService.put(url, customerData);
  }

  deleteCustomer(customerId: number){
    let url = `${this.customersUrl}/${customerId}`;
    return this.httpService.delete(url);
  }

  checkIfCustomerKeyExists(key: string, id: number) : Promise<Boolean>{
    let filter = `Key eq '${key}'`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(CUSTOMERS_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  getCustomers(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.companyInformationUrl, requestOptions);
  }

  //#endregion

  //#region Customer parts

  getCustomerParts(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.customerPartsUrl, requestOptions);
  }

  getCustomerPartById(customerPartId:number){
    return this.httpService.getData(`${this.customerPartsUrl}/${customerPartId}`);
  }

  addCustomerParts(customerPartsData: ICustomerPartsData){
    return this.httpService.post(this.customerPartsUrl, customerPartsData);
  }

  updateCustomerPart(customerPartId: number, customerPartsData: ICustomerPartsData){
    let url = `${this.customerPartsUrl}/${customerPartId}`;
    customerPartsData.Id = customerPartId;
    return this.httpService.put(url, customerPartsData);
  }

  deleteCustomerPart(customerPartId: number){
    let url = `${this.customerPartsUrl}/${customerPartId}`;
    return this.httpService.delete(url);

  }

  checkIfCustomerPartNumberExists(customerPartNumber: string, id: number) : Promise<Boolean>{
    let filter = `CustomerPartNumber eq '${customerPartNumber}'`;

    if(id) {
      filter+=` and Id ne ${id}`;
    }

    return this.httpService
              .getODataCount(CUSTOMER_PARTS_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  //#endregion

   //#region Supplier Part Price

   updateCustomerPartPrice(partPrice: ICustomerPartPrice){
    let url = `${this.customerPartsUrl}/UpdatePrice`;
    return this.httpService.put(url, partPrice);
  }
    //#endregion

  //#region Customer addresses

  getCustomerAddresses(requestOptions: RequestOptions, id:number){
    let customerAddressesDetailUrl = `${this.customersUrl}/${id}/CustomerAddress`;
    return this.httpService.getPaginatedData(customerAddressesDetailUrl, requestOptions);
  }

  addCustomerAddress(id:any , customerAddressesData: ICustomerAddressesData){
    let customerAddressesDetailUrl =`${this.customersUrl}/${id}/CustomerAddress`;
    return this.httpService.post(customerAddressesDetailUrl, customerAddressesData);
  }

  getCustomerAddressById(customerAddressId: number){
    return this.httpService.getData(`${this.customerAddressUrl}/${customerAddressId}`);
  }

  updateCustomerAddress(customerAddressId: number, customerAddressesData: ICustomerAddressesData){
    let url = `${this.customerAddressUrl}/${customerAddressId}`;
    customerAddressesData.Id = customerAddressId;
    return this.httpService.put(url, customerAddressesData);
  }

  deleteCustomerAddress(customerAddressId: number){
    let url = `${this.customerAddressUrl}/${customerAddressId}`;
    return this.httpService.delete(url);
  }

  getDataByZipcode(zipCode : string) {
    return this.httpService.getData(`${this.postalCodeUrl}/${zipCode}`);
  }
  //#endregion

  //#region Customer contacts
  checkIfDepartmentFunctionExistsForDepartment( departmentFunctionId: any, departmentId: any, id:number) : Promise<Boolean>{
    let filter = `DepartmentFunctionId eq ${departmentFunctionId} and DepartmentId eq ${departmentId}`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(CUSTOMER_CONTACT_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  } 

  getCustomerContacts(requestOptions: RequestOptions, id:number){
    let customerContactsDetailUrl = `${this.customerAddressUrl}/${id}/CustomerContact`;
    return this.httpService.getPaginatedData(customerContactsDetailUrl, requestOptions);
  }

  addCustomerContact(id:number, customerContactsData: ICustomerContactsData){
    let customerContactsDetailUrl =`${this.customerAddressUrl}/${id}/CustomerContact`;
    return this.httpService.post(customerContactsDetailUrl, customerContactsData);
  }

  getCustomerContactById(customerContactId: number){
    return this.httpService.getData(`${this.customerContactUrl}/${customerContactId}`);
  }

  updateCustomerContact(customerContactId: number, customerContactsData: ICustomerContactsData){
    let url = `${this.customerContactUrl}/${customerContactId}`;
    customerContactsData.Id = customerContactId;
    return this.httpService.put(url, customerContactsData);
  }

  deleteCustomerContact(customerContactId: number){
    let url = `${this.customerContactUrl}/${customerContactId}`;
    return this.httpService.delete(url);
  }
  //#endregion

  //#region Customer contact number
  getCustomerContactNumbers(requestOptions: RequestOptions, id:number){
    let customerContactNumbersDetailUrl = `${this.customerContactUrl}/${id}/CustomerContactNumber`;
    return this.httpService.getPaginatedData(customerContactNumbersDetailUrl, requestOptions);
  }

  addCustomerContactNumber(id:number, customerContactNumberData: ICustomerContactNumber){
    let customerContactNumbersDetailUrl =`${this.customerContactUrl}/${id}/CustomerContactNumber`;
    return this.httpService.post(customerContactNumbersDetailUrl, customerContactNumberData);
  }

  getCustomerContactNumberById(customerContactNumberId: number){
    return this.httpService.getData(`${this.customerContactNumberUrl}/${customerContactNumberId}`);
  }

  updateCustomerContactNumber(customerContactNumberId: number, customerContactNumberData: ICustomerContactNumber){
    let url = `${this.customerContactNumberUrl}/${customerContactNumberId}`;
    customerContactNumberData.Id = customerContactNumberId;
    return this.httpService.put(url, customerContactNumberData);
  }

  deleteCustomerContactNumber(customerContactNumberId: number){
    let url = `${this.customerContactNumberUrl}/${customerContactNumberId}`;
    return this.httpService.delete(url);
  }
  //#endregion

  //#region position
  checkIfPositionNameExists(name: string, id: number) : Promise<Boolean>{
    let filter = `Name eq '${name}'`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(CUSTOMER_POSITION_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  getCustomerPositions(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.customerPositionUrl, requestOptions);
  }

  addCustomerPosition(customerPosition: ICustomerPosition){
    return this.httpService.post(this.customerPositionUrl, customerPosition);
  }

  getCustomerPositionById(customerPositionId: number){
    return this.httpService.getData(`${this.customerPositionUrl}/${customerPositionId}`);
   }

  updateCustomerPosition(customerPositionId: number, customerPosition: ICustomerPosition){
    let url = `${this.customerPositionUrl}/${customerPositionId}`;
    customerPosition.Id = customerPositionId;
    return this.httpService.put(url, customerPosition);
  }

  deleteCustomerPosition(customerPositionId: number){
    let url = `${this.customerPositionUrl}/${customerPositionId}`;
    return this.httpService.delete(url);
  }
  //#endregion

  //#region crm department
  checkIfCRMDepartmentNameExists(name: string, id: number) : Promise<Boolean>{
    let filter = `Name eq '${name}'`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(CRM_DEPARTMENT_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  checkIfDepartmentCodeExist(departmentId: any, id:number) : Promise<Boolean>{
    let filter = `Code eq '${departmentId}'`;
    if(id){
    filter += `and Id ne ${id}`;
    }
    return this.httpService
       .getODataCount(CRM_DEPARTMENT_ODATA_URL, { filter: filter })
       .then(count => !(count > 0));
  }

  getCRMDepartments(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.crmDepartmentUrl, requestOptions);
  }

  addCRMDepartment(crmDepartment: ICRMDepartment){
    return this.httpService.post(this.crmDepartmentUrl, crmDepartment);
  }

  getCRMDepartmentById(crmDepartmentId: number){
    return this.httpService.getData(`${this.crmDepartmentUrl}/${crmDepartmentId}`);
   }

  updateCRMDepartment(crmDepartmentId: number, crmDepartment: ICRMDepartment){
    let url = `${this.crmDepartmentUrl}/${crmDepartmentId}`;
    crmDepartment.Id = crmDepartmentId;
    return this.httpService.put(url, crmDepartment);
  }

  deleteCRMDepartment(crmDepartmentId: number){
    let url = `${this.crmDepartmentUrl}/${crmDepartmentId}`;
    return this.httpService.delete(url);
  }
  //#endregion

  //#region crm department function
  checkIfFunctionNameExists( name: string, departmentFunctionId: number, id:number) : Promise<Boolean>{
    let filter = `Name eq '${name}' and CRMDepartmentId eq ${id}`;

    if(departmentFunctionId){
      filter+=` and Id ne ${departmentFunctionId}`;
    }
    return this.httpService
              .getODataCount(CRM_DEPARTMENT_FUNCTION_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  getCRMDepartmentFunctions(requestOptions: RequestOptions, id:number){
    let departmentFunctionsDetailUrl = `${this.crmDepartmentUrl}/${id}/CRMDepartmentFunctions`;
    return this.httpService.getPaginatedData(departmentFunctionsDetailUrl, requestOptions);
  }

  addCRMDepartmentFunction(id:number, crmDepartmentFunctionData: ICRMDepartmentFunction){
    let departmentFunctionsDetailUrl =`${this.crmDepartmentUrl}/${id}/CRMDepartmentFunctions`;
    return this.httpService.post(departmentFunctionsDetailUrl, crmDepartmentFunctionData);
  }

  getCRMDepartmentFunctionById(departmentFunctionId: number){
    return this.httpService.getData(`${this.crmDepartmentFunctionUrl}/${departmentFunctionId}`);
  }

  updateCRMDepartmentFunction(departmentFunctionId: number, crmDepartmentFunctionData: ICRMDepartmentFunction){
    let url = `${this.crmDepartmentFunctionUrl}/${departmentFunctionId}`;
    crmDepartmentFunctionData.Id = departmentFunctionId;
    return this.httpService.put(url, crmDepartmentFunctionData);
  }

  deleteCRMDepartmentFunction(departmentFunctionId: number){
    let url = `${this.crmDepartmentFunctionUrl}/${departmentFunctionId}`;
    return this.httpService.delete(url);
  }
  //#endregion

  //#region Customer departments
  checkIfDepartmentNameExists( departmentId: any, customerDepartmentId: number, id:number) : Promise<Boolean>{
    let filter = `CRMDepartmentId eq ${departmentId} and CustomerId eq ${id}`;

    if(customerDepartmentId){
      filter+=` and Id ne ${customerDepartmentId}`;
    }
    return this.httpService
              .getODataCount(CUSTOMER_DEPARTMENT_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  getCustomerDepartments(requestOptions: RequestOptions, id:number){
    let customerDepartmentsDetailUrl = `${this.customersUrl}/${id}/CustomerDepartments`;
    return this.httpService.getPaginatedData(customerDepartmentsDetailUrl, requestOptions);
  }

  addCustomerDepartment(id:number, customerDepartmentsData: ICustomerDepartmentData){
    let customerDepartmentsDetailUrl =`${this.customersUrl}/${id}/CustomerDepartments`;
    return this.httpService.post(customerDepartmentsDetailUrl, customerDepartmentsData);
  }

  getCustomerDepartmentById(customerDepartmentId: number){
    return this.httpService.getData(`${this.customerDepartmentUrl}/${customerDepartmentId}`);
  }

  updateCustomerDepartment(customerDepartmentId: number, customerDepartmentsData: ICustomerDepartmentData){
    let url = `${this.customerDepartmentUrl}/${customerDepartmentId}`;
    customerDepartmentsData.Id = customerDepartmentId;
    return this.httpService.put(url, customerDepartmentsData);
  }

  deleteCustomerDepartment(customerDepartmentId: number){
    let url = `${this.customerDepartmentUrl}/${customerDepartmentId}`;
    return this.httpService.delete(url);
  }
  //#endregion

  //#region Customer attachments
  getCustomerAttachments(requestOptions: RequestOptions, id: number){
    let customerAttachmentsDetailUrl = `${this.customersUrl}/${id}/CustomerAttachments`;
    return this.httpService.getPaginatedData(customerAttachmentsDetailUrl, requestOptions);
  }

  getCustomerAttachmentById(customerAttachmentId:number){
    return this.httpService.getData(`${this.customerAttachmentUrl}/${customerAttachmentId}`);
  }

  addCustomerAttachment(customerId: number, attachment: Attachment){
    let customerAttachmentsDetailUrl = `${this.customersUrl}/${customerId}/CustomerAttachments`;
    return this.httpService.post(customerAttachmentsDetailUrl, { CustomerId: customerId, ...attachment });
  }

  updateCustomerAttachment(customerAttachmentId: number, customerId: number, attachment: Attachment){
    let url = `${this.customerAttachmentUrl}/${customerAttachmentId}`;
    return this.httpService.put(url, { Id: customerAttachmentId, customerId: customerId, ...attachment });
  }

  deleteCustomerAttachment(customerAttachmentId: number){
    let url = `${this.customerAttachmentUrl}/${customerAttachmentId}`;
    return this.httpService.delete(url);
  }
  //#endregion

}
