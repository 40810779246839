import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from '../core/services/http.service';
import { HttpParams } from '@angular/common/http';
import { ToISODate } from '../Utils/date-time.helper';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private readonly ordersLastMonthUrl = `${environment.baseApiUrl}/Dashboard/OrdersLastMonth`;
  private readonly purchaseOrdersLastMonthUrl = `${environment.baseApiUrl}/Dashboard/PurchaseOrdersLastMonth`;
  private readonly quotationsLastMonthUrl = `${environment.baseApiUrl}/Dashboard/QuotationsLastMonth`;
  private readonly accountsReceivableInvoicesLastMonthUrl = `${environment.baseApiUrl}/Dashboard/AccountsReceivableInvoicesLastMonth`;
  private readonly revenueByCustomersUrl = `${environment.baseApiUrl}/Dashboard/RevenueByCustomers`;
  private readonly revenueByPartsUrl = `${environment.baseApiUrl}/Dashboard/RevenueByParts`;
  private readonly revenueByLeadsUrl = `${environment.baseApiUrl}/Dashboard/RevenueByMonth`;
  private readonly salesByCustomersUrl = `${environment.baseApiUrl}/Dashboard/SalesByCustomers`;
  private readonly tokenUrl = `${environment.baseApiUrl}/Dashboard/generate/token`;
  private readonly reportUrl = `${environment.baseApiUrl}/Dashboard/report`;
  private readonly powerbiLicense = `${environment.baseApiUrl}/user/powerbilicense`;
  private readonly jobStatusesUrl = `${environment.baseApiUrl}/Dashboard/JobsByStatus`;
  private readonly machineStatusesUrl = `${environment.baseApiUrl}/Dashboard/MachinesByStatus`;
  private readonly ordersDateRangeUrl = `${environment.baseApiUrl}/Dashboard/Orders`;
  private readonly quotationsDateRangeUrl = `${environment.baseApiUrl}/Dashboard/Quotations`;
  private readonly ordersDateUrl = `${environment.baseApiUrl}/Dashboard/OrdersByDate`;
  private readonly salesByCustomersByDateRangeUrl = `${environment.baseApiUrl}/Dashboard/SalesByCustomersByRange`;
  private readonly purchaseOrdersByDateRangeUrl = `${environment.baseApiUrl}/Dashboard/PurchaseOrders`;
  private readonly purchaseOrdersDateUrl = `${environment.baseApiUrl}/Dashboard/PurchaseOrdersByDate`;
  private readonly revenueByCustomersByDateRangeUrl = `${environment.baseApiUrl}/Dashboard/RevenueByCustomersByRange`;
  private readonly revenueByPartsByDateRangeUrl = `${environment.baseApiUrl}/Dashboard/RevenueByPartsByRange`;
  private readonly revenueByLeadsByDateRangeUrl = `${environment.baseApiUrl}/Dashboard/RevenueByLeadsByRange`;
  private readonly accountsReceivableInvoicesByDateRangeUrl = `${environment.baseApiUrl}/Dashboard/AccountsReceivableInvoicesByRange`;
  private readonly accountsReceivableInvoicesByDateUrl = `${environment.baseApiUrl}/Dashboard/AccountsReceivableInvoicesByDate`;
  private readonly jobStatusesByDateRangeUrl = `${environment.baseApiUrl}/Dashboard/JobsByStatusByRange`;
  private readonly qualityUrl = `${environment.baseApiUrl}/Dashboard/QualityByRange`;
  private readonly productionsDateUrl = `${environment.baseApiUrl}/Dashboard/ProductionByDate`;
  private readonly productionByWorkstationByDateRangeUrl = `${environment.baseApiUrl}/Dashboard/ProductionByWorkstationByRange`;
  private readonly qcResultsByDateRangeUrl = `${environment.baseApiUrl}/Dashboard/QCResultsByRange`;
  private readonly availabilityByDateRangeUrl = `${environment.baseApiUrl}/Dashboard/AvailabilityByRange`;
  private readonly dashboardTabLicenseUrl = `${environment.baseApiUrl}/user/DashboardTablicense`;
  private readonly leadsDateUrl = `${environment.baseApiUrl}/Dashboard/LeadsByDate`;
  private readonly leadsByGeneratedDateUrl = `${environment.baseApiUrl}/Dashboard/LeadsByGeneratedDate`;
  private readonly leadPhaseByEmployeeDateRangeUrl = `${environment.baseApiUrl}/Dashboard/LeadsPhaseByEmployee`;
  private readonly leadSourceByEmployeeDateRangeUrl = `${environment.baseApiUrl}/Dashboard/LeadSourceByEmployee`;
  private readonly leadFollowUpByEmployeeDateRangeUrl = `${environment.baseApiUrl}/Dashboard/LeadFollowUpsByEmployee`;
  private readonly leadsByStateDateRangeUrl = `${environment.baseApiUrl}/Dashboard/LeadsByState`;
  private readonly leadsByCountryDateRangeUrl = `${environment.baseApiUrl}/Dashboard/LeadsByCountry`;
  private readonly leadsByPartDateRangeUrl = `${environment.baseApiUrl}/Dashboard/LeadsByPart`;
  private readonly quotationsByLeadDateRangeUrl = `${environment.baseApiUrl}/Dashboard/QuotationsByLeads`;
  private readonly quotationsByEmployeeDateRangeUrl = `${environment.baseApiUrl}/Dashboard/QuotationsByEmployee`;
  private readonly revenueByEmployeeDateRangeUrl = `${environment.baseApiUrl}/Dashboard/RevenueByEmployee`;
  private readonly confirmedQuotationsByLeadDateRangeUrl = `${environment.baseApiUrl}/Dashboard/ConfirmedQuotationsByLeads`;

  constructor(private httpService: HttpService) { }

  getOrdersLastMonth(){
    return this.httpService.getData(this.ordersLastMonthUrl);
  }

  getPurchaseOrdersLastMonth(){
    return this.httpService.getData(this.purchaseOrdersLastMonthUrl);
  }

  getQuotationsLastMonth(){
    return this.httpService.getData(this.quotationsLastMonthUrl);
  }

  getAccountsReceivableInvoicesLastMonth(){
    return this.httpService.getData(this.accountsReceivableInvoicesLastMonthUrl);
  }

  getRevenueByCustomers(){
    return this.httpService.getData(this.revenueByCustomersUrl);
  }

  getRevenueByParts(){
    return this.httpService.getData(this.revenueByPartsUrl);
  }

  getRevenueByLeads(){
    return this.httpService.getData(this.revenueByLeadsUrl);
  }

  getSalesByCustomers(){
    return this.httpService.getData(this.salesByCustomersUrl);
  }

  getAccessToken(){
    return this.httpService.getText(this.tokenUrl).toPromise();
  }

  getTenantDashboard()
  {
    return this.httpService.get(this.reportUrl).toPromise();
  }

  checkPowerbiLicence(){
    return this.httpService.getData(this.powerbiLicense);
  }

  loadJobStatuses(){
    return this.httpService.getData(this.jobStatusesUrl);
  }

  loadMachineStatuses(){
    return this.httpService.getData(this.machineStatusesUrl);
  }

  getOrders(startRange : any, endRange: any){
    let params = new HttpParams().append('startDate', ToISODate(startRange).toISOString()).append('endDate', ToISODate(endRange).toISOString());
    return this.httpService.get(this.ordersDateRangeUrl, params).toPromise();
  }

  getQuotations(startRange : any, endRange: any){
    let params = new HttpParams().append('startDate', ToISODate(startRange).toISOString()).append('endDate', ToISODate(endRange).toISOString());
    return this.httpService.get(this.quotationsDateRangeUrl, params).toPromise();
  }

  getSalesByCustomersByRange(startRange : any, endRange: any){
    let params = new HttpParams().append('startDate', ToISODate(startRange).toISOString()).append('endDate', ToISODate(endRange).toISOString());
    return this.httpService.get(this.salesByCustomersByDateRangeUrl, params).toPromise();
  }

  getAllOrders(startRange : any, endRange: any){
    let params = new HttpParams().append('startDate', ToISODate(startRange).toISOString()).append('endDate', ToISODate(endRange).toISOString());
    return this.httpService.get(this.ordersDateUrl, params).toPromise();
  }

  getPurchaseOrders(startRange : any, endRange: any){
    let params = new HttpParams().append('startDate', ToISODate(startRange).toISOString()).append('endDate', ToISODate(endRange).toISOString());
    return this.httpService.get(this.purchaseOrdersByDateRangeUrl, params).toPromise();
  }

  getAllPurchaseOrders(startRange : any, endRange: any){
    let params = new HttpParams().append('startDate', ToISODate(startRange).toISOString()).append('endDate', ToISODate(endRange).toISOString());
    return this.httpService.get(this.purchaseOrdersDateUrl, params).toPromise();
  }

  getRevenueByCustomersByDateRange(startRange : any, endRange: any){
    let params = new HttpParams().append('startDate', ToISODate(startRange).toISOString()).append('endDate', ToISODate(endRange).toISOString());
    return this.httpService.get(this.revenueByCustomersByDateRangeUrl, params).toPromise();
  }

  getRevenueByPartsByDateRange(startRange : any, endRange: any){
    let params = new HttpParams().append('startDate', ToISODate(startRange).toISOString()).append('endDate', ToISODate(endRange).toISOString());
    return this.httpService.get(this.revenueByPartsByDateRangeUrl, params).toPromise();
  }

  getRevenueByLeadsByDateRange(startRange : any, endRange: any, employeeIds: number[]){
    let params = new HttpParams().append('startDate', ToISODate(startRange).toISOString()).append('endDate', ToISODate(endRange).toISOString());
    employeeIds.forEach(id => {
      params = params.append('employeeIds', id.toString());
    });
    return this.httpService.get(this.revenueByLeadsByDateRangeUrl, params).toPromise();
  }

  getAccountsReceivableInvoicesByDateRange(startRange : any, endRange: any){
    let params = new HttpParams().append('startDate', ToISODate(startRange).toISOString()).append('endDate', ToISODate(endRange).toISOString());
    return this.httpService.get(this.accountsReceivableInvoicesByDateRangeUrl, params).toPromise();
  }

  getAccountsReceivableInvoicesByDate(startRange : any, endRange: any){
    let params = new HttpParams().append('startDate', ToISODate(startRange).toISOString()).append('endDate', ToISODate(endRange).toISOString());
    return this.httpService.get(this.accountsReceivableInvoicesByDateUrl, params).toPromise();
  }

  getJobStatusesByDateRange(startRange : any, endRange: any){
    let params = new HttpParams().append('startDate', ToISODate(startRange).toISOString()).append('endDate', ToISODate(endRange).toISOString());
    return this.httpService.get(this.jobStatusesByDateRangeUrl, params).toPromise();
  }

  getQuality(startRange : any, endRange: any){
    let params = new HttpParams().append('startDate', ToISODate(startRange).toISOString()).append('endDate', ToISODate(endRange).toISOString());
    return this.httpService.get(this.qualityUrl, params).toPromise();;
  }

  getAllProductionsByDate(startRange : any, endRange: any){
    let params = new HttpParams().append('startDate', ToISODate(startRange).toISOString()).append('endDate', ToISODate(endRange).toISOString());
    return this.httpService.get(this.productionsDateUrl, params).toPromise();
  }

  getProductionByWorkstationByDateRange(startRange : any, endRange: any){
    let params = new HttpParams().append('startDate', ToISODate(startRange).toISOString()).append('endDate', ToISODate(endRange).toISOString());
    return this.httpService.get(this.productionByWorkstationByDateRangeUrl, params).toPromise();
  }

  getQCResultsByDateRange(startRange : any, endRange: any){
    let params = new HttpParams().append('startDate', ToISODate(startRange).toISOString()).append('endDate', ToISODate(endRange).toISOString());
    return this.httpService.get(this.qcResultsByDateRangeUrl, params).toPromise();
  }

  getAvailabilityByDateRange(startRange : any, endRange: any){
    let params = new HttpParams().append('startDate', ToISODate(startRange).toISOString()).append('endDate', ToISODate(endRange).toISOString());
    return this.httpService.get(this.availabilityByDateRangeUrl, params).toPromise();;
  }

  getDashboardTabPanelLicenses()
  {
    return this.httpService.getData(this.dashboardTabLicenseUrl);
  }

  getAllLeads(startRange : any, endRange: any){
    let params = new HttpParams().append('startDate', ToISODate(startRange).toISOString()).append('endDate', ToISODate(endRange).toISOString());
    return this.httpService.get(this.leadsDateUrl, params).toPromise();
  }

  getLeadsByGeneratedDate(startRange : any, endRange: any, employeeIds: number[]){
    let params = new HttpParams().append('startDate', ToISODate(startRange).toISOString()).append('endDate', ToISODate(endRange).toISOString());
    employeeIds.forEach(id => {
      params = params.append('employeeIds', id.toString());
    });
    return this.httpService.get(this.leadsByGeneratedDateUrl, params).toPromise();
  }

  getLeadPhaseByEmployeeDateRange(startRange : any, endRange: any, employeeIds: number[]){
    let params = new HttpParams().append('startDate', ToISODate(startRange).toISOString()).append('endDate', ToISODate(endRange).toISOString());
    employeeIds.forEach(id => {
      params = params.append('employeeIds', id.toString());
    });
    return this.httpService.get(this.leadPhaseByEmployeeDateRangeUrl, params).toPromise();
  }

  getLeadSourceByEmployeeDateRange(startRange : any, endRange: any, employeeIds: number[]){
    let params = new HttpParams().append('startDate', ToISODate(startRange).toISOString()).append('endDate', ToISODate(endRange).toISOString());
    employeeIds.forEach(id => {
      params = params.append('employeeIds', id.toString());
    });
    return this.httpService.get(this.leadSourceByEmployeeDateRangeUrl, params).toPromise();
  }

  getFollowUpsByEmployeeByRange(startRange : any, endRange: any, employeeIds: number[]){
    let params = new HttpParams().append('startDate', ToISODate(startRange).toISOString()).append('endDate', ToISODate(endRange).toISOString());
    employeeIds.forEach(id => {
      params = params.append('employeeIds', id.toString());
    });
    return this.httpService.get(this.leadFollowUpByEmployeeDateRangeUrl, params).toPromise();
  }

  getLeadsByStateByDateRange(startRange : any, endRange: any, employeeIds: number[]){
    let params = new HttpParams().append('startDate', ToISODate(startRange).toISOString()).append('endDate', ToISODate(endRange).toISOString());
    employeeIds.forEach(id => {
      params = params.append('employeeIds', id.toString());
    });
    return this.httpService.get(this.leadsByStateDateRangeUrl, params).toPromise();
  }

  getLeadsByCountryByDateRange(startRange : any, endRange: any, employeeIds: number[]){
    let params = new HttpParams().append('startDate', ToISODate(startRange).toISOString()).append('endDate', ToISODate(endRange).toISOString());
    employeeIds.forEach(id => {
      params = params.append('employeeIds', id.toString());
    });
    return this.httpService.get(this.leadsByCountryDateRangeUrl, params).toPromise();
  }

  getLeadsByPartByDateRange(startRange : any, endRange: any, employeeIds: number[]){
    let params = new HttpParams().append('startDate', ToISODate(startRange).toISOString()).append('endDate', ToISODate(endRange).toISOString());
    employeeIds.forEach(id => {
      params = params.append('employeeIds', id.toString());
    });
    return this.httpService.get(this.leadsByPartDateRangeUrl, params).toPromise();
  }

  getQuotationsByLeadByDateRange(startRange : any, endRange: any, employeeIds: number[]){
    let params = new HttpParams().append('startDate', ToISODate(startRange).toISOString()).append('endDate', ToISODate(endRange).toISOString());
    employeeIds.forEach(id => {
      params = params.append('employeeIds', id.toString());
    });
    return this.httpService.get(this.quotationsByLeadDateRangeUrl, params).toPromise();;
  }

  getQuotationsByEmployeeByDateRange(startRange : any, endRange: any, employeeIds: number[]){
    let params = new HttpParams().append('startDate', ToISODate(startRange).toISOString()).append('endDate', ToISODate(endRange).toISOString());
    employeeIds.forEach(id => {
      params = params.append('employeeIds', id.toString());
    });
    return this.httpService.get(this.quotationsByEmployeeDateRangeUrl, params).toPromise();
  }

  getRevenueByEmployeeByDateRange(startRange : any, endRange: any, employeeIds: number[]){
    let params = new HttpParams().append('startDate', ToISODate(startRange).toISOString()).append('endDate', ToISODate(endRange).toISOString());
    employeeIds.forEach(id => {
      params = params.append('employeeIds', id.toString());
    });
    return this.httpService.get(this.revenueByEmployeeDateRangeUrl, params).toPromise();
  }

  getConfirmedQuotationsByLeadByDateRange(startRange : any, endRange: any, employeeIds: number[]){
    let params = new HttpParams().append('startDate', ToISODate(startRange).toISOString()).append('endDate', ToISODate(endRange).toISOString());
    employeeIds.forEach(id => {
      params = params.append('employeeIds', id.toString());
    });
    return this.httpService.get(this.confirmedQuotationsByLeadDateRangeUrl, params).toPromise();;
  }
}
