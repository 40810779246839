import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ODataOptions, RequestOptions } from '../core';
import { HttpService } from '../core/services/http.service';
import { ACCOUNTS_PAYABLE_INVOICE_ODATA_URL, ACCOUNTS_PAYABLE_INVOICE_STATUS_URL, ACCOUNTS_PAYABLE_INVOICE_TERM_ODATA_URL, ACCOUNTS_RECEIVABLE_INVOICE_ODATA_URL, ACCOUNTS_RECEIVABLE_INVOICE_STATUS_URL, ACCOUNTS_RECEIVABLE_INVOICE_TERM_ODATA_URL, BANK_ODATA_URL, COMPANY_ODATA_URL, COSTING_ODATA_URL, IAccountsPayableInvoice, IAccountsPayableInvoiceLine, IAccountsPayableInvoiceStatus, IAccountsPayableInvoiceTerm, IAccountsReceivableInvoice, IAccountsReceivableInvoiceLine, IAccountsReceivableInvoiceStatus, IAccountsReceivableInvoiceTerm, IBank, IBankAccount, ICosting, ITaxSlab, ITransaction, IWorkflowStep, TAX_SLAB_ODATA_URL, TRANSACTION_ODATA_URL } from './accounting-model';
import { Cost } from '../shared/components/additional-costs/add-edit-additional-cost/add-edit-cost.component';
import { Attachment } from '../shared/components/attach-document/add-edit-attachment/add-edit-attachment.component';
import { HttpParams } from '@angular/common/http';
import { EmailService } from '../core/services/email.service';

@Injectable({
  providedIn: 'root'
})
export class AccountingService {

  private readonly accountsReceivableInvoiceUrl = `${environment.baseApiUrl}/AccountsReceivableInvoice`;
  private readonly accountsReceivableInvoiceLineUrl = `${environment.baseApiUrl}/AccountsReceivableInvoiceLine`;
  private readonly accountsReceivableInvoiceStatusUrl = `${environment.baseApiUrl}/AccountsReceivableInvoiceStatus`;
  private readonly accountsReceivableInvoiceTermUrl = `${environment.baseApiUrl}/AccountsReceivableInvoiceTerm`;
  private readonly accountsPayableInvoiceUrl = `${environment.baseApiUrl}/AccountsPayableInvoice`;
  private readonly accountsPayableInvoiceLineUrl = `${environment.baseApiUrl}/AccountsPayableInvoiceLine`;
  private readonly accountsPayableInvoiceStatusUrl = `${environment.baseApiUrl}/AccountsPayableInvoiceStatus`;
  private readonly accountsPayableInvoiceTermUrl = `${environment.baseApiUrl}/AccountsPayableInvoiceTerm`;
  private readonly bankUrl = `${environment.baseApiUrl}/Bank`;
  private readonly bankAttachmentUrl = `${environment.baseApiUrl}/BankAttachment`;
  private readonly bankAccountUrl = `${environment.baseApiUrl}/BankAccount`;
  private readonly bankAccountAttachmentUrl = `${environment.baseApiUrl}/BankAccountAttachment`;
  private readonly transactionUrl = `${environment.baseApiUrl}/Transaction`;
  private readonly accountsReceivableInvoiceAttachmentUrl = `${environment.baseApiUrl}/AccountsReceivableInvoiceAttachment`;
  private readonly accountsPayableInvoiceAttachmentUrl = `${environment.baseApiUrl}/AccountsPayableInvoiceAttachment`;
  private readonly transactionAttachmentUrl = `${environment.baseApiUrl}/TransactionAttachment`;
  private readonly accountsReceivableInvoiceAdditionalCostUrl = `${environment.baseApiUrl}/AccountsReceivableInvoiceAdditionalCost`;
  private readonly accountsPayableInvoiceAdditionalCostUrl = `${environment.baseApiUrl}/AccountsPayableInvoiceAdditionalCost`;
  private readonly costingUrl = `${environment.baseApiUrl}/Costing`; 
  private readonly taxSlabUrl = `${environment.baseApiUrl}/TaxSlab`;
  private readonly accountsReceivableInvoiceDetails = `${environment.baseApiUrl}/AccountsReceivableInvoiceDetail`;
  private readonly accountsPayableInvoiceDetails = `${environment.baseApiUrl}/AccountsPayableInvoiceDetail`;  

  constructor(private httpService: HttpService,private emailService: EmailService) { }

  //#region Accounts receivable invoice
  getAccountsReceivableInvoices(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.accountsReceivableInvoiceUrl, requestOptions);
  }

  getAccountsReceivableInvoiceStatuses(){
    return this.httpService.getOData(ACCOUNTS_RECEIVABLE_INVOICE_STATUS_URL, {});
  }

  getAccountsReceivableInvoiceById(accountsReceivableInvoiceId: number){
    return this.httpService.getData(`${this.accountsReceivableInvoiceUrl}/${accountsReceivableInvoiceId}`);
  }

  getAccountsReceivableInvoiceNumber(customerId: number){
    let params = new HttpParams()
                        .append("customerId", customerId);
    let url = `${this.accountsReceivableInvoiceUrl}/Generate/Number`;
    return this.httpService.getText(url, params);
  }

  addAccountsReceivableInvoice(accountsReceivableInvoice: IAccountsReceivableInvoice){
    return this.httpService.post(this.accountsReceivableInvoiceUrl, accountsReceivableInvoice);
  }

  updateAccountsReceivableInvoice(accountsReceivableInvoiceId: number, accountsReceivableInvoice: IAccountsReceivableInvoice){
    let url = `${this.accountsReceivableInvoiceUrl}/${accountsReceivableInvoiceId}`;
    accountsReceivableInvoice.Id = accountsReceivableInvoiceId;
    return this.httpService.put(url, accountsReceivableInvoice);
  }

  deleteAccountsReceivableInvoice(accountsReceivableInvoiceId: number){
    let url = `${this.accountsReceivableInvoiceUrl}/${accountsReceivableInvoiceId}`;
    return this.httpService.delete(url);
  } 

  public emailInvoice(invoiceId: number){
    const emailInvoiceUrl = `${environment.baseApiUrl}/Email/invoice/${invoiceId}`;
    return this.httpService.getData(emailInvoiceUrl);

  }

  getProformaInvoiceNumber(customerId: number){
    let params = new HttpParams()
                        .append("customerId", customerId);
    let url = `${this.accountsReceivableInvoiceUrl}/Generate/ProformaInvoiceNumber`;
    return this.httpService.getText(url, params);
  }

  getAccountsReceivableInvoiceTaxRule(invoiceId: number){
    return this.httpService.getData(`${this.accountsReceivableInvoiceUrl}/${invoiceId}/taxrule`);
  } 

  getReceivableInvoiceNextWorkflowSteps(invoiceId: number){
    let url = `${this.accountsReceivableInvoiceUrl}/${invoiceId}/workflow-steps`;
    return this.httpService.getData(url);
  }

  UpdateTransitionReceivableInvoiceAsync(invoiceId: number, workflowStepDTO: IWorkflowStep){
    let url = `${this.accountsReceivableInvoiceUrl}/${invoiceId}/workflow/transition`;
    return this.httpService.post(url,workflowStepDTO);
  }

  checkIfReceivableInvoiceNumberExists(invoiceNumber:string, id: number) : Promise<Boolean>{
    let filter = `InvoiceNumber eq '${invoiceNumber}'`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(ACCOUNTS_RECEIVABLE_INVOICE_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  getCustomerById(customerId: number){
    let options : ODataOptions = {
      filter: `Id eq ${customerId}`,
      select: "Id, Name, GstNumber, BaseCountryCode, State"
    };
    return new Promise(resolve =>{
      this.httpService.getOData(COMPANY_ODATA_URL, options)
      .then((response: any) =>{
        resolve(response.value[0]);
      })
    }) 
  }
  //#endregion 

  //#region Accounts receivable invoice lines
  getAccountsReceivableInvoiceLines(requestOptions: RequestOptions, id: number){
    let accountsReceivableInvoiceLinesDetailUrl = `${this.accountsReceivableInvoiceUrl}/${id}/AccountsReceivableInvoiceLines`;
    return this.httpService.getPaginatedData(accountsReceivableInvoiceLinesDetailUrl, requestOptions);
  }

  getCustomerIdByAccountsReceivableInvoiceId(accountsReceivableInvoiceId: number){
    let url = `${environment.baseOdataUrl}/AccountsReceivableInvoices/${accountsReceivableInvoiceId}`;
    return this.httpService.getOData(url, {select: "CustomerId" });
  }

  addAccountsReceivableInvoiceLines(id: number| any, accountsReceivableInvoiceLine: IAccountsReceivableInvoiceLine){
    let url = `${this.accountsReceivableInvoiceUrl}/${id}/AccountsReceivableInvoiceLines`;
    return this.httpService.post(url, accountsReceivableInvoiceLine);
  }

  getAccountsReceivableInvoiceLineById(accountsReceivableInvoiceLineId: number){
    return this.httpService.getData(`${this.accountsReceivableInvoiceLineUrl}/${accountsReceivableInvoiceLineId}`);
   }

  updateAccountsReceivableInvoiceLine(accountsReceivableInvoiceLineId: number, accountsReceivableInvoiceLine: IAccountsReceivableInvoiceLine){
    let url = `${this.accountsReceivableInvoiceLineUrl}/${accountsReceivableInvoiceLineId}`;
    accountsReceivableInvoiceLine.Id = accountsReceivableInvoiceLineId;
    return this.httpService.put(url, accountsReceivableInvoiceLine);
  }

  deleteAccountsReceivableInvoiceLine(accountsReceivableInvoiceLineId: number){
    let url = `${this.accountsReceivableInvoiceLineUrl}/${accountsReceivableInvoiceLineId}`;
    return this.httpService.delete(url);
  }
  //#endregion 

  //#region Accounts receivable invoice terms
  checkIfTermNameExists( termId: any, accountsReceivableInvoiceTermId: number, id: number) : Promise<Boolean>{
    let filter = `TermId eq ${termId} and AccountsReceivableInvoiceId eq ${id}`;

    if(accountsReceivableInvoiceTermId){
      filter+=` and Id ne ${accountsReceivableInvoiceTermId}`;
    }
    return this.httpService
              .getODataCount(ACCOUNTS_RECEIVABLE_INVOICE_TERM_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  checkIfPriorityOrderExists(priorityOrder: string,  accountsReceivableInvoiceTermId: number, id: number) : Promise<Boolean>{
    let filter = `PriorityOrder eq ${priorityOrder} and AccountsReceivableInvoiceId eq ${id}`;

    if(accountsReceivableInvoiceTermId){
      filter+=` and Id ne ${accountsReceivableInvoiceTermId}`;
    }
    return this.httpService
              .getODataCount(ACCOUNTS_RECEIVABLE_INVOICE_TERM_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  getAccountsReceivableInvoiceTerms(requestOptions: RequestOptions, id: number){
    let accountsReceivableInvoiceTermsDetailUrl = `${this.accountsReceivableInvoiceUrl}/${id}/AccountsReceivableInvoiceTerms`;
    return this.httpService.getPaginatedData(accountsReceivableInvoiceTermsDetailUrl, requestOptions);
  }

  addAccountsReceivableInvoiceTerms(id: number| any, accountsReceivableInvoiceTerm: IAccountsReceivableInvoiceTerm){
    let url = `${this.accountsReceivableInvoiceUrl}/${id}/AccountsReceivableInvoiceTerms`;
    return this.httpService.post(url, accountsReceivableInvoiceTerm);
  }

  getAccountsReceivableInvoiceTermById(accountsReceivableInvoiceTermId: number){
    return this.httpService.getData(`${this.accountsReceivableInvoiceTermUrl}/${accountsReceivableInvoiceTermId}`);
   }

  updateAccountsReceivableInvoiceTerm(accountsReceivableInvoiceTermId: number, accountsReceivableInvoiceTerm: IAccountsReceivableInvoiceTerm){
    let url = `${this.accountsReceivableInvoiceTermUrl}/${accountsReceivableInvoiceTermId}`;
    accountsReceivableInvoiceTerm.Id = accountsReceivableInvoiceTermId;
    return this.httpService.put(url, accountsReceivableInvoiceTerm);
  }

  deleteAccountsReceivableInvoiceTerm(accountsReceivableInvoiceTermId: number){
    let url = `${this.accountsReceivableInvoiceTermUrl}/${accountsReceivableInvoiceTermId}`;
    return this.httpService.delete(url);
  }
  //#endregion 

  //#region Accounts receivable invoice attachments
  getAccountsReceivableInvoiceAttachments(requestOptions: RequestOptions, id: number){
    let accountsReceivableInvoiceAttachmentsDetailUrl = `${this.accountsReceivableInvoiceUrl}/${id}/AccountsReceivableInvoiceAttachments`;
    return this.httpService.getPaginatedData(accountsReceivableInvoiceAttachmentsDetailUrl, requestOptions);
  }
  
  getAccountsReceivableInvoiceAttachmentById(accountsReceivableInvoiceAttachmentId:number){
    return this.httpService.getData(`${this.accountsReceivableInvoiceAttachmentUrl}/${accountsReceivableInvoiceAttachmentId}`);
  }
  
  addAccountsReceivableInvoiceAttachment(accountsReceivableInvoiceId: number, attachment: Attachment){
    let accountsReceivableInvoiceAttachmentsDetailUrl = `${this.accountsReceivableInvoiceUrl}/${accountsReceivableInvoiceId}/AccountsReceivableInvoiceAttachments`;
    return this.httpService.post(accountsReceivableInvoiceAttachmentsDetailUrl, { AccountsReceivableInvoiceId: accountsReceivableInvoiceId, ...attachment });
  }
  
  updateAccountsReceivableInvoiceAttachment(accountsReceivableInvoiceAttachmentId: number, accountsReceivableInvoiceId: number, attachment: Attachment){
    let url = `${this.accountsReceivableInvoiceAttachmentUrl}/${accountsReceivableInvoiceAttachmentId}`;
    return this.httpService.put(url, { Id: accountsReceivableInvoiceAttachmentId, accountsReceivableInvoiceId: accountsReceivableInvoiceId, ...attachment });
  }
  
  deleteAccountsReceivableInvoiceAttachment(accountsReceivableInvoiceAttachmentId: number){
    let url = `${this.accountsReceivableInvoiceAttachmentUrl}/${accountsReceivableInvoiceAttachmentId}`;
    return this.httpService.delete(url);
  }
  //#endregion

  //#region Accounts receivable invoice additional costs
  getAccountsReceivableInvoiceAdditionalCosts(requestOptions: RequestOptions, accountsReceivableInvoiceId: number){
    let accountsReceivableInvoiceAdditionalCostDetailUrl = `${this.accountsReceivableInvoiceUrl}/${accountsReceivableInvoiceId}/AdditionalCosts`;
    return this.httpService.getPaginatedData(accountsReceivableInvoiceAdditionalCostDetailUrl, requestOptions);
  }

  getAccountsReceivableInvoiceAdditionalCostById(accountsReceivableInvoiceAdditionalCostId: number) {
    return this.httpService.getData(`${this.accountsReceivableInvoiceAdditionalCostUrl}/${accountsReceivableInvoiceAdditionalCostId}`);
  }

  addAccountsReceivableInvoiceAdditionalCost(accountsReceivableInvoiceId: number, cost: Cost){
    let accountsReceivableInvoiceAdditionalCostDetailUrl = `${this.accountsReceivableInvoiceUrl}/${accountsReceivableInvoiceId}/AdditionalCosts`;
    return this.httpService.post(accountsReceivableInvoiceAdditionalCostDetailUrl, { AccountsReceivableInvoiceId: accountsReceivableInvoiceId, ...cost });
  }

  updateAccountsReceivableInvoiceAdditionalCost(accountsReceivableInvoiceAdditionalCostId: number, accountsReceivableInvoiceId: number, cost: Cost){
    let url = `${this.accountsReceivableInvoiceAdditionalCostUrl}/${accountsReceivableInvoiceAdditionalCostId}`;
    return this.httpService.put(url, { Id: accountsReceivableInvoiceAdditionalCostId, accountsReceivableInvoiceId: accountsReceivableInvoiceId, ...cost });
  }

  deleteAccountsReceivableInvoiceAdditionalCost(accountsReceivableInvoiceAdditionalCostId: number){
    let url = `${this.accountsReceivableInvoiceAdditionalCostUrl}/${accountsReceivableInvoiceAdditionalCostId}`;
    return this.httpService.delete(url);
  }
  //#endregion

  //#region Accounts receivable invoice tax slabs
  getAccountsReceivableInvoiceTaxSlabs(requestOptions: RequestOptions, accountsReceivableInvoiceId: number){
    let accountsReceivableInvoiceTaxSlabsUrl = `${this.accountsReceivableInvoiceUrl}/${accountsReceivableInvoiceId}/TaxSlabs`;
    return this.httpService.getPaginatedData(accountsReceivableInvoiceTaxSlabsUrl, requestOptions);
  }
  //#endregion

  //#region Accounts payable invoice

  getAccountsPayableInvoiceStatuses(){
    return this.httpService.getOData(ACCOUNTS_PAYABLE_INVOICE_STATUS_URL, {});
  }

  getAccountsPayableInvoices(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.accountsPayableInvoiceUrl, requestOptions);
  }

  getAccountsPayableInvoiceById(accountsPayableInvoiceId: number){
    return this.httpService.getData(`${this.accountsPayableInvoiceUrl}/${accountsPayableInvoiceId}`);
   }

  addAccountsPayableInvoice(accountsPayableInvoice: IAccountsPayableInvoice){
    return this.httpService.post(this.accountsPayableInvoiceUrl, accountsPayableInvoice);
  }

  updateAccountsPayableInvoice(accountsPayableInvoiceId: number, accountsPayableInvoice: IAccountsPayableInvoice){
    let url = `${this.accountsPayableInvoiceUrl}/${accountsPayableInvoiceId}`;
    accountsPayableInvoice.Id = accountsPayableInvoiceId;
    return this.httpService.put(url, accountsPayableInvoice);
  }

  deleteAccountsPayableInvoice(accountsPayableInvoiceId: number){
    let url = `${this.accountsPayableInvoiceUrl}/${accountsPayableInvoiceId}`;
    return this.httpService.delete(url);
  }

  getAccountsPayableInvoiceTaxRule(invoiceId: number){
    return this.httpService.getData(`${this.accountsPayableInvoiceUrl}/${invoiceId}/taxrule`);
  }

  getPayableInvoiceNextWorkflowSteps(invoiceId: number){
    let url = `${this.accountsPayableInvoiceUrl}/${invoiceId}/workflow-steps`;
    return this.httpService.getData(url);
  }
  
  UpdateTransitionPayableInvoiceAsync(invoiceId: number, workflowStepDTO: IWorkflowStep){
    let url = `${this.accountsPayableInvoiceUrl}/${invoiceId}/workflow/transition`;
    return this.httpService.post(url,workflowStepDTO);
  }

  checkIfPayableInvoiceNumberExists(invoiceNumber:string, id: number) : Promise<Boolean>{
    let filter = `InvoiceNumber eq '${invoiceNumber}'`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(ACCOUNTS_PAYABLE_INVOICE_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }
  //#endregion 

  //#region Accounts payable invoice lines
  getAccountsPayableInvoiceLines(requestOptions: RequestOptions, id: number){
    let accountsPayableInvoiceLinesDetailUrl = `${this.accountsPayableInvoiceUrl}/${id}/AccountsPayableInvoiceLines`;
    return this.httpService.getPaginatedData(accountsPayableInvoiceLinesDetailUrl, requestOptions);
  }

  getSupplierIdByAccountsPayableInvoiceId(accountsPayableInvoiceId: number){
    let url = `${environment.baseOdataUrl}/AccountsPayableInvoices/${accountsPayableInvoiceId}`;
    return this.httpService.getOData(url, {select: "SupplierId" });
  }

  addAccountsPayableInvoiceLines(id: number| any, accountsPayableInvoiceLine: IAccountsPayableInvoiceLine){
    let url = `${this.accountsPayableInvoiceUrl}/${id}/AccountsPayableInvoiceLines`;
    return this.httpService.post(url, accountsPayableInvoiceLine);
  }

  getAccountsPayableInvoiceLineById(accountsPayableInvoiceLineId: number){
    return this.httpService.getData(`${this.accountsPayableInvoiceLineUrl}/${accountsPayableInvoiceLineId}`);
   }

  updateAccountsPayableInvoiceLine(accountsPayableInvoiceLineId: number, accountsPayableInvoiceLine: IAccountsPayableInvoiceLine){
    let url = `${this.accountsPayableInvoiceLineUrl}/${accountsPayableInvoiceLineId}`;
    accountsPayableInvoiceLine.Id = accountsPayableInvoiceLineId;
    return this.httpService.put(url, accountsPayableInvoiceLine);
  }

  deleteAccountsPayableInvoiceLine(accountsPayableInvoiceLineId: number){
    let url = `${this.accountsPayableInvoiceLineUrl}/${accountsPayableInvoiceLineId}`;
    return this.httpService.delete(url);
  }
  //#endregion 

  //#region Accounts payable invoice terms
  checkIfInvoiceTermNameExists( termId: any, accountsPayableInvoiceTermId: number, id: number) : Promise<Boolean>{
    let filter = `TermId eq ${termId} and AccountsPayableInvoiceId eq ${id}`;

    if(accountsPayableInvoiceTermId){
      filter+=` and Id ne ${accountsPayableInvoiceTermId}`;
    }
    return this.httpService
              .getODataCount(ACCOUNTS_PAYABLE_INVOICE_TERM_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  checkIfInvoicePriorityOrderExists(priorityOrder: string,  accountsPayableInvoiceTermId: number, id: number) : Promise<Boolean>{
    let filter = `PriorityOrder eq ${priorityOrder} and AccountsPayableInvoiceId eq ${id}`;

    if(accountsPayableInvoiceTermId){
      filter+=` and Id ne ${accountsPayableInvoiceTermId}`;
    }
    return this.httpService
              .getODataCount(ACCOUNTS_PAYABLE_INVOICE_TERM_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  getAccountsPayableInvoiceTerms(requestOptions: RequestOptions, id: number){
    let accountsPayableInvoiceTermsDetailUrl = `${this.accountsPayableInvoiceUrl}/${id}/AccountsPayableInvoiceTerms`;
    return this.httpService.getPaginatedData(accountsPayableInvoiceTermsDetailUrl, requestOptions);
  }

  addAccountsPayableInvoiceTerms(id: number| any, accountsPayableInvoiceTerm: IAccountsPayableInvoiceTerm){
    let url = `${this.accountsPayableInvoiceUrl}/${id}/AccountsPayableInvoiceTerms`;
    return this.httpService.post(url, accountsPayableInvoiceTerm);
  }

  getAccountsPayableInvoiceTermById(accountsPayableInvoiceTermId: number){
    return this.httpService.getData(`${this.accountsPayableInvoiceTermUrl}/${accountsPayableInvoiceTermId}`);
   }

  updateAccountsPayableInvoiceTerm(accountsPayableInvoiceTermId: number, accountsPayableInvoiceTerm: IAccountsPayableInvoiceTerm){
    let url = `${this.accountsPayableInvoiceTermUrl}/${accountsPayableInvoiceTermId}`;
    accountsPayableInvoiceTerm.Id = accountsPayableInvoiceTermId;
    return this.httpService.put(url, accountsPayableInvoiceTerm);
  }

  deleteAccountsPayableInvoiceTerm(accountsPayableInvoiceTermId: number){
    let url = `${this.accountsPayableInvoiceTermUrl}/${accountsPayableInvoiceTermId}`;
    return this.httpService.delete(url);
  }
  //#endregion 

  //#region Accounts payable invoice attachments
  getAccountsPayableInvoiceAttachments(requestOptions: RequestOptions, id: number){
    let accountsPayableInvoiceAttachmentsDetailUrl = `${this.accountsPayableInvoiceUrl}/${id}/AccountsPayableInvoiceAttachments`;
    return this.httpService.getPaginatedData(accountsPayableInvoiceAttachmentsDetailUrl, requestOptions);
  }
    
  getAccountsPayableInvoiceAttachmentById(accountsPayableInvoiceAttachmentId:number){
    return this.httpService.getData(`${this.accountsPayableInvoiceAttachmentUrl}/${accountsPayableInvoiceAttachmentId}`);
  }
    
  addAccountsPayableInvoiceAttachment(accountsPayableInvoiceId: number, attachment: Attachment){
    let accountsPayableInvoiceAttachmentsDetailUrl = `${this.accountsPayableInvoiceUrl}/${accountsPayableInvoiceId}/AccountsPayableInvoiceAttachments`;
    return this.httpService.post(accountsPayableInvoiceAttachmentsDetailUrl, { AccountsPayableInvoiceId: accountsPayableInvoiceId, ...attachment });
  }
    
  updateAccountsPayableInvoiceAttachment(accountsPayableInvoiceAttachmentId: number, accountsPayableInvoiceId: number, attachment: Attachment){
    let url = `${this.accountsPayableInvoiceAttachmentUrl}/${accountsPayableInvoiceAttachmentId}`;
    return this.httpService.put(url, { Id: accountsPayableInvoiceAttachmentId, accountsPayableInvoiceId: accountsPayableInvoiceId, ...attachment });
  }
    
  deleteAccountsPayableInvoiceAttachment(accountsPayableInvoiceAttachmentId: number){
    let url = `${this.accountsPayableInvoiceAttachmentUrl}/${accountsPayableInvoiceAttachmentId}`;
    return this.httpService.delete(url);
  }
  //#endregion

  //#region bank
  checkIfBankNameExists(name: string, id: number) : Promise<Boolean>{
    let filter = `Name eq '${name}'`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(BANK_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  getBanks(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.bankUrl, requestOptions);
  }

  addBank(bank: IBank){
    return this.httpService.post(this.bankUrl, bank);
  }

  getBankById(bankId: number){
    return this.httpService.getData(`${this.bankUrl}/${bankId}`);
   }

  updateBank(bankId: number, bank: IBank){
    let url = `${this.bankUrl}/${bankId}`;
    bank.Id = bankId;
    return this.httpService.put(url, bank);
  }

  deleteBank(bankId: number){
    let url = `${this.bankUrl}/${bankId}`;
    return this.httpService.delete(url);
  }
  //#endregion

  //#region Accounts payable invoice additional costs
  getAccountsPayableInvoiceAdditionalCosts(requestOptions: RequestOptions, accountsPayableInvoiceId: number){
    let accountsPayableInvoiceAdditionalCostDetailUrl = `${this.accountsPayableInvoiceUrl}/${accountsPayableInvoiceId}/AdditionalCosts`;
    return this.httpService.getPaginatedData(accountsPayableInvoiceAdditionalCostDetailUrl, requestOptions);
  }

  getAccountsPayableInvoiceAdditionalCostById(accountsPayableInvoiceAdditionalCostId: number) {
    return this.httpService.getData(`${this.accountsPayableInvoiceAdditionalCostUrl}/${accountsPayableInvoiceAdditionalCostId}`);
  }

  addAccountsPayableInvoiceAdditionalCost(accountsPayableInvoiceId: number, cost: Cost){
    let accountsPayableInvoiceAdditionalCostDetailUrl = `${this.accountsPayableInvoiceUrl}/${accountsPayableInvoiceId}/AdditionalCosts`;
    return this.httpService.post(accountsPayableInvoiceAdditionalCostDetailUrl, { AccountsPayableInvoiceId: accountsPayableInvoiceId, ...cost });
  }

  updateAccountsPayableInvoiceAdditionalCost(accountsPayableInvoiceAdditionalCostId: number, accountsPayableInvoiceId: number, cost: Cost){
    let url = `${this.accountsPayableInvoiceAdditionalCostUrl}/${accountsPayableInvoiceAdditionalCostId}`;
    return this.httpService.put(url, { Id: accountsPayableInvoiceAdditionalCostId, accountsPayableInvoiceId: accountsPayableInvoiceId, ...cost });
  }

  deleteAccountsPayableInvoiceAdditionalCost(accountsPayableInvoiceAdditionalCostId: number){
    let url = `${this.accountsPayableInvoiceAdditionalCostUrl}/${accountsPayableInvoiceAdditionalCostId}`;
    return this.httpService.delete(url);
  }
  //#endregion

  //#region Accounts payable invoice tax slabs
    getAccountsPayableInvoiceTaxSlabs(requestOptions: RequestOptions, accountsPayableInvoiceId: number){
      let accountsPayableInvoiceTaxSlabsUrl = `${this.accountsPayableInvoiceUrl}/${accountsPayableInvoiceId}/TaxSlabs`;
      return this.httpService.getPaginatedData(accountsPayableInvoiceTaxSlabsUrl, requestOptions);
  }
  //#endregion

  //#region bank account
  getBankAccounts(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.bankAccountUrl, requestOptions);
  }

  addBankAccount(bankAccount: IBankAccount){
    return this.httpService.post(this.bankAccountUrl, bankAccount);
  }

  getBankAccountById(bankAccountId: number){
    return this.httpService.getData(`${this.bankAccountUrl}/${bankAccountId}`);
   }

  updateBankAccount(bankAccountId: number, bankAccount: IBankAccount){
    let url = `${this.bankAccountUrl}/${bankAccountId}`;
    bankAccount.Id = bankAccountId;
    return this.httpService.put(url, bankAccount);
  }

  deleteBankAccount(bankAccountId: number){
    let url = `${this.bankAccountUrl}/${bankAccountId}`;
    return this.httpService.delete(url);
  }
  //#endregion

  //#region transaction
  getAllAccountsReceivableInvoices() {
    return this.httpService.getData(`${this.transactionUrl}/receivableInvoices`);
  }

  getAllAccountsPayableInvoices() {
    return this.httpService.getData(`${this.transactionUrl}/payableInvoices`);
  }

  createTransactionByAccountsReceivableInvoiceIds(transaction: ITransaction) { 
    let url = `${this.accountsReceivableInvoiceUrl}/accountsReceivableInvoiceTransaction`; 
    return this.httpService.post(url, transaction); 
  }

  createTransactionByAccountsPayableInvoiceId(transaction: ITransaction){
    let url = `${this.accountsPayableInvoiceUrl}/accountsPayableInvoiceTransaction`;
    return this.httpService.post(url,transaction ); 
  }

  getTransactions(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.transactionUrl, requestOptions);
  }

  addTransaction(transaction: ITransaction){
    return this.httpService.post(this.transactionUrl, transaction);
  }

  getTransactionById(transactionId: number){
    return this.httpService.getData(`${this.transactionUrl}/${transactionId}`);
   }

  updateTransaction(transactionId: number, transaction: ITransaction){
    let url = `${this.transactionUrl}/${transactionId}`;
    transaction.Id = transactionId;
    return this.httpService.put(url, transaction);
  }

  deleteTransaction(transactionId: number){
    let url = `${this.transactionUrl}/${transactionId}`;
    return this.httpService.delete(url);
  }

  createTransactionByProformaInvoiceId(transaction: ITransaction){
    let url = `${this.accountsReceivableInvoiceUrl}/proformaInvoiceTransaction`;
    return this.httpService.post(url, transaction);
  } 

   CreateTransactionByProformaInvoicesGenerateIndividualReceivableInvoices(transaction: ITransaction){
    let url = `${this.accountsReceivableInvoiceUrl}/individualReceivableInvoiceForEachProformaInvoice`;
    return this.httpService.post(url, transaction);
  } 

  checkIfTransactionNumberExists(transactionNumber:string, id: number) : Promise<Boolean>{
    let filter = `TransactionNumber eq '${transactionNumber}'`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(TRANSACTION_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  importTransaction(bankAccountId: number, data: any) {
    let url = `${environment.baseApiUrl}/Import/transactions/${bankAccountId}`;
    return this.httpService.post(url,data);
  }

  saveImportedTransactions(data: any) {
    let url = `${environment.baseApiUrl}/Import/transactions/save`;
    return this.httpService.post(url,data);
  }

  checkTransactionExist(transactionNumber: any) {
    let url = `${this.transactionUrl}/check/${transactionNumber}`;
    return this.httpService.getData(url);
  }
  //#endregion

  //#region Transaction attachments
  getTransactionAttachments(requestOptions: RequestOptions, id: number){
    let transactionAttachmentsDetailUrl = `${this.transactionUrl}/${id}/TransactionAttachments`;
    return this.httpService.getPaginatedData(transactionAttachmentsDetailUrl, requestOptions);
  }
    
  getTransactionAttachmentById(transactionAttachmentId:number){
    return this.httpService.getData(`${this.transactionAttachmentUrl}/${transactionAttachmentId}`);
  }
    
  addTransactionAttachment(transactionId: number, attachment: Attachment){
    let transactionAttachmentsDetailUrl = `${this.transactionUrl}/${transactionId}/TransactionAttachments`;
    return this.httpService.post(transactionAttachmentsDetailUrl, { TransactionId: transactionId, ...attachment });
  }
    
  updateTransactionAttachment(transactionAttachmentId: number, transactionId: number, attachment: Attachment){
    let url = `${this.transactionAttachmentUrl}/${transactionAttachmentId}`;
    return this.httpService.put(url, { Id: transactionAttachmentId, transactionId: transactionId, ...attachment });
  }
    
  deleteTransactionAttachment(transactionAttachmentId: number){
    let url = `${this.transactionAttachmentUrl}/${transactionAttachmentId}`;
    return this.httpService.delete(url)
  }
  //#endregion

  //#region costing
  checkIfCostingNameExists(name: string, id: number) : Promise<Boolean>{
    let filter = `Name eq '${name}'`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(COSTING_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  getCostings(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.costingUrl, requestOptions);
  }

  addCosting(costing: ICosting){
    return this.httpService.post(this.costingUrl, costing);
  }

  getCostingById(costingId: number){
    return this.httpService.getData(`${this.costingUrl}/${costingId}`);
   }

  updateCosting(costingId: number, costing: ICosting){
    let url = `${this.costingUrl}/${costingId}`;
    costing.Id = costingId;
    return this.httpService.put(url, costing);
  }

  deleteCosting(costingId: number){
    let url = `${this.costingUrl}/${costingId}`;
    return this.httpService.delete(url);
  }
  //#endregion

  //#region tax slab
  checkIfTaxSlabNameExists(name: string, id: number) : Promise<Boolean>{
    let filter = `Name eq '${name}'`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(TAX_SLAB_ODATA_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  getTaxSlabs(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.taxSlabUrl, requestOptions);
  }

  addTaxSlab(taxSlab: ITaxSlab){
    return this.httpService.post(this.taxSlabUrl, taxSlab);
  }

  getTaxSlabById(taxSlabId: number){
    return this.httpService.getData(`${this.taxSlabUrl}/${taxSlabId}`);
   }

  updateTaxSlab(taxSlabId: number, taxSlab: ITaxSlab){
    let url = `${this.taxSlabUrl}/${taxSlabId}`;
    taxSlab.Id = taxSlabId;
    return this.httpService.put(url, taxSlab);
  }

  deleteTaxSlab(taxSlabId: number){
    let url = `${this.taxSlabUrl}/${taxSlabId}`;
    return this.httpService.delete(url);
  }
  //#endregion

   //#region Bank attachments

   getBankAttachments(requestOptions: RequestOptions, id: number){
    let bankAttachmentsDetailUrl = `${this.bankUrl}/${id}/BankAttachments`;
    return this.httpService.getPaginatedData(bankAttachmentsDetailUrl, requestOptions);
  }

  getBankAttachmentById(bankAttachmentId:number){
    return this.httpService.getData(`${this.bankAttachmentUrl}/${bankAttachmentId}`);
  }

  addBankAttachment(bankId: number, attachment: Attachment){
    let bankAttachmentsDetailUrl = `${this.bankUrl}/${bankId}/BankAttachments`;
    return this.httpService.post(bankAttachmentsDetailUrl, { BankId: bankId, ...attachment });
  }

  updateBankAttachment(bankAttachmentId: number, bankId: number, attachment: Attachment){
    let url = `${this.bankAttachmentUrl}/${bankAttachmentId}`;
    return this.httpService.put(url, { Id: bankAttachmentId, BankId: bankId, ...attachment });
  }

  deleteBankAttachment(bankAttachmentId: number){
    let url = `${this.bankAttachmentUrl}/${bankAttachmentId}`;
    return this.httpService.delete(url);
  }

  //#endregion
   //#region Bank attachments

   getBankAccountAttachments(requestOptions: RequestOptions, id: number){
    let bankAccountAttachmentsDetailUrl = `${this.bankAccountUrl}/${id}/BankAccountAttachments`;
    return this.httpService.getPaginatedData(bankAccountAttachmentsDetailUrl, requestOptions);
  }

  getBankAccountAttachmentById(bankAccountAttachmentId:number){
    return this.httpService.getData(`${this.bankAccountAttachmentUrl}/${bankAccountAttachmentId}`);
  }

  addBankAccountAttachment(bankAccountId: number, attachment: Attachment){
    let bankAccountAttachmentsDetailUrl = `${this.bankAccountUrl}/${bankAccountId}/BankAccountAttachments`;
    return this.httpService.post(bankAccountAttachmentsDetailUrl, { BankAccountId: bankAccountId, ...attachment });
  }

  updateBankAccountAttachment(bankAccountAttachmentId: number, bankAccountId: number, attachment: Attachment){
    let url = `${this.bankAccountAttachmentUrl}/${bankAccountAttachmentId}`;
    return this.httpService.put(url, { Id: bankAccountAttachmentId, BankAccountId: bankAccountId, ...attachment });
  }

  deleteBankAccountAttachment(bankAccountAttachmentId: number){
    let url = `${this.bankAccountAttachmentUrl}/${bankAccountAttachmentId}`;
    return this.httpService.delete(url);
  }

  //#endregion

  //#region Accounts payable invoices status

  checkIfAccountsPayableInvoiceStatusNameExists(name: string, id: number) : Promise<Boolean>{
    let filter = `Name eq '${name}'`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(ACCOUNTS_PAYABLE_INVOICE_STATUS_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  getAccountsPayableInvoiceStatus(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.accountsPayableInvoiceStatusUrl, requestOptions);
  }

  getAccountsPayableInvoiceStatusById(accountsPayableInvoiceStatusId:number){
    return this.httpService.getData(`${this.accountsPayableInvoiceStatusUrl}/${accountsPayableInvoiceStatusId}`);
  }

  addAccountsPayableInvoiceStatus(accountsPayableInvoiceStatus: IAccountsPayableInvoiceStatus){
    return this.httpService.post(this.accountsPayableInvoiceStatusUrl, accountsPayableInvoiceStatus);
  }

  updateAccountsPayableInvoiceStatus(accountsPayableInvoiceStatusId: number, accountsPayableInvoiceStatus: IAccountsPayableInvoiceStatus){
    let url = `${this.accountsPayableInvoiceStatusUrl}/${accountsPayableInvoiceStatusId}`;
    accountsPayableInvoiceStatus.Id = accountsPayableInvoiceStatusId;
    return this.httpService.put(url, accountsPayableInvoiceStatus);
  }

  deleteAccountsPayableInvoiceStatus(accountsPayableInvoiceStatusId: number)
  {
    let url = `${this.accountsPayableInvoiceStatusUrl}/${accountsPayableInvoiceStatusId}`;
    return this.httpService.delete(url);
  }

  //#endregion

  //#region Accounts receivable invoices status

  checkIfAccountsReceivableInvoiceStatusNameExists(name: string, id: number) : Promise<Boolean>{
    let filter = `Name eq '${name}'`;

    if(id){
      filter+=` and Id ne ${id}`;
    }
    return this.httpService
              .getODataCount(ACCOUNTS_RECEIVABLE_INVOICE_STATUS_URL, { filter: filter })
              .then(count => !(count > 0));
  }

  getAccountsReceivableInvoiceStatus(requestOptions: RequestOptions){
    return this.httpService.getPaginatedData(this.accountsReceivableInvoiceStatusUrl, requestOptions);
  }

  getAccountsReceivableInvoiceStatusById(accountsReceivableInvoiceStatusId:number){
    return this.httpService.getData(`${this.accountsReceivableInvoiceStatusUrl}/${accountsReceivableInvoiceStatusId}`);
  }

  addAccountsReceivableInvoiceStatus(accountsReceivableInvoiceStatus: IAccountsReceivableInvoiceStatus){
    return this.httpService.post(this.accountsReceivableInvoiceStatusUrl, accountsReceivableInvoiceStatus);
  }

  updateAccountsReceivableInvoiceStatus(accountsReceivableInvoiceStatusId: number, accountsReceivableInvoiceStatus: IAccountsReceivableInvoiceStatus){
    let url = `${this.accountsReceivableInvoiceStatusUrl}/${accountsReceivableInvoiceStatusId}`;
    accountsReceivableInvoiceStatus.Id = accountsReceivableInvoiceStatusId;
    return this.httpService.put(url, accountsReceivableInvoiceStatus);
  }

  deleteAccountsReceivableInvoiceStatus(accountsReceivableInvoiceStatusId: number)
  {
    let url = `${this.accountsReceivableInvoiceStatusUrl}/${accountsReceivableInvoiceStatusId}`;
    return this.httpService.delete(url);
  }

  //#endregion

  //#region Accounts receivable invoices details
  getAccountsReceivableInvoiceDetailsById(accountsReceivableInvoiceId:number){
    return this.httpService.getData(`${this.accountsReceivableInvoiceDetails}/${accountsReceivableInvoiceId}`);
  }
  //#endregion

  //#region Accounts payable invoices details
  getAccountsPayableInvoiceDetailsById(accountsPayableInvoiceId:number){
    return this.httpService.getData(`${this.accountsPayableInvoiceDetails}/${accountsPayableInvoiceId}`);
  }
  //#endregion

  //#region Link Transactions
  linkTransactionsWithInvoices(data: any) {
    let url = `${this.transactionUrl}/linkTransactions`
    return this.httpService.post(url, data);
  }
  //#endregion

  //#region Kanban Board
  getAccountsReceivableInvoicesForKanbanBoard(requestOptions: RequestOptions){
    let url = `${this.accountsReceivableInvoiceUrl}/KanbanBoard`;
    return this.httpService.getPaginatedData(url, requestOptions);
  }

  getAccountsPayableInvoicesForKanbanBoard(requestOptions: RequestOptions){
    let url = `${this.accountsPayableInvoiceUrl}/KanbanBoard`;
    return this.httpService.getPaginatedData(url, requestOptions);
  }

  //#endregion
}
