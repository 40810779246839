
export function disableComponent(component: any){
    if(component){
        component.option("disabled", true);
    }
}

export function enableComponent(component: any){
    if(component){
        component.option("disabled", false);
    }
}

export function showComponent(component: any){
    component?.option("visible", true);
}

export function hideComponent(component: any){
    component?.option("visible", false);
}