import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RequestOptions } from '../core';
import { HttpService } from '../core/services/http.service';
import { IRemainderActivity, IRemainderData, IRemainderType, REMAINDER_ACTIVITY_ODATA_URL, REMAINDER_ODATA_URL, REMAINDER_TYPE_ODATA_URL } from './remainder-model';

@Injectable({
  providedIn: 'root'
})
export class RemainderService {
  private readonly remainderUrl = `${environment.baseApiUrl}/Remainder`;
  private readonly remainderTypeUrl = `${environment.baseApiUrl}/RemainderType`;
  private readonly remainderActivityUrl = `${environment.baseApiUrl}/RemainderActivity`;

  constructor(private httpService: HttpService) {}

  //#region lead

  getRemainders(requestOptions: RequestOptions) {
    return this.httpService.getPaginatedData(this.remainderUrl, requestOptions);
  }

  addRemainder(remainder: IRemainderData) {
    return this.httpService.post(this.remainderUrl, remainder);
  }

  getRemainderdById(remainderId: number) {
    return this.httpService.getData(`${this.remainderUrl}/${remainderId}`);
  }

  updateRemainder(remainderId: number, remainder: IRemainderData) {
    let url = `${this.remainderUrl}/${remainderId}`;
    remainder.Id = remainderId;
    return this.httpService.put(url, remainder);
  }

  deleteRemainder(remainderId: number) {
    let url = `${this.remainderUrl}/${remainderId}`;
    return this.httpService.delete(url);
  }

  checkIfRemainderExists(remainderDate: any,moduleId:number|null, moduleTypeId:number |null, id: number) : Promise<Boolean>{
    let remainder_date =new Date(remainderDate).toISOString();
     let filter = `ModuleId eq ${moduleId} and ModuleTypeId eq ${moduleTypeId} and RemainderDateTime eq ${remainder_date}`;
     if(id){
       filter+=` and Id ne ${id}`;
     }
     return this.httpService
               .getODataCount(REMAINDER_ODATA_URL, { filter: filter })
               .then(count => !(count > 0));
   }
  //#endregion

  //#region remainder type

  getRemainderTypes(requestOptions: RequestOptions) {
    return this.httpService.getPaginatedData(this.remainderTypeUrl, requestOptions);
  }

  addRemainderType(remainderType: IRemainderType) {
    return this.httpService.post(this.remainderTypeUrl, remainderType);
  }

  getRemainderTypeById(id: number) {
    return this.httpService.getData(`${this.remainderTypeUrl}/${id}`);
  }

  updateRemainderType(id: number, remainderType: IRemainderType) {
    let url = `${this.remainderTypeUrl}/${id}`;
    remainderType.Id = id;
    return this.httpService.put(url, remainderType);
  }

  deleteRemainderType(id: number) {
    let url = `${this.remainderTypeUrl}/${id}`;
    return this.httpService.delete(url);
  }

  checkIfTypeExists(name: string, id: number): Promise<Boolean> {
    let filter = `Name eq '${name}'`;

    if (id) {
      filter += ` and Id ne ${id}`;
    }
    return this.httpService
      .getODataCount(REMAINDER_TYPE_ODATA_URL, { filter: filter })
      .then((count) => !(count > 0));
  }
  //#endregion

  //#region Remainder activity

  getRemainderActivity(requestOptions: RequestOptions, id: number) {
    let remainderActivityUrl = `${this.remainderUrl}/${id}/Activities`;
    return this.httpService.getPaginatedData(remainderActivityUrl,requestOptions);
  }

  getRemainderActivityById(RemainderActivityId: number) {
    return this.httpService.getData(`${this.remainderActivityUrl}/${RemainderActivityId}`);
  }

  addRemainderActivity(id: number | any, RemainderActivity: IRemainderActivity) {
    let url = `${this.remainderUrl}/${id}/Activities`;
    return this.httpService.post(url, RemainderActivity);
  }

  updateRemainderActivity(RemainderActivityId: number, RemainderActivity: IRemainderActivity) {
    let url = `${this.remainderActivityUrl}/${RemainderActivityId}`;
    RemainderActivity.Id = RemainderActivityId;
    return this.httpService.put(url, RemainderActivity);
  }

  deleteRemainderActivity(RemainderActivityId: number) {
    let url = `${this.remainderActivityUrl}/${RemainderActivityId}`;
    return this.httpService.delete(url);
  }

  getActivitiesByRemainderId(remainderId: number) {
    let url = `${environment.baseOdataUrl}/RemainderActivities`;
    let filter = `RemainderId eq ${remainderId}`;
    let options = {
    select: "Id,ActivityDate",
    filter: filter,
    };
    return this.httpService.getOData(url, options);
    }

    checkIfRemainderActivityExists(activityDate: any, id: number,remainderId:number) : Promise<Boolean>{
      let activity_date =new Date(activityDate).toISOString();
       let filter = `ActivityDate eq ${activity_date} and RemainderId eq ${remainderId}`;
       if(id){
         filter+=` and Id ne ${id}`;
       }
       return this.httpService
                 .getODataCount(REMAINDER_ACTIVITY_ODATA_URL, { filter: filter })
                 .then(count => !(count > 0));
     }
  //#endregion

  getChildRemainderdByParentId(parentId: number) {
    return this.httpService.getData(`${this.remainderUrl}/${parentId}/Parent`);
  }

}
