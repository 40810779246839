<app-data-grid id="additional-cost" [title]='"grid_title_additional_costs" | translate'  [showAdd]="!isCreditNoteRoute" (onAdd)="onAddNewAdditionalCost()">
    <dx-data-grid class="dx-card wide-card" [dataSource]="dataSource" [showBorders]="false" [columnAutoWidth]="true"
        [columnHidingEnabled]="true" (onSelectionChanged)="selectionChangeHandler($event)">
        <dxo-selection mode="single" showCheckBoxesMode="always"></dxo-selection>
       
        <dxi-column *ngIf="!isCreditNoteRoute" [caption]='"grid_column_actions" | translate' cellTemplate="cellTemplate" width="75px" >
            <div *dxTemplate="let data of 'cellTemplate'">
                <dx-button style="margin-right: 10px;" stylingMode="outlined" type="default" icon="edit"  (onClick)="editAdditionalCostDetail($event, data)"  > </dx-button>
                <dx-button icon="trash" stylingMode="outlined" type="danger" (onClick)="deleteAdditionalCostDetail($event, data)" > </dx-button>
            </div>
        </dxi-column>

        <dxi-column dataField="Costing.Name" [caption]='"grid_column_costing" | translate'>
        </dxi-column>
        <dxi-column dataField="AdditionalDescription" [caption]='"grid_column_additional_description" | translate'
        type ="htmlEditor">
        </dxi-column>
        <dxi-column dataField="TaxSlab.Name" [caption]='"form_tax_slab" | translate'>
        </dxi-column>
        <dxi-column dataField="UnitPrice" 
            [caption]='"grid_column_unit_price" | translate'
            format="decimal"
            format="#0.##">
        </dxi-column>
        <dxi-column dataField="NetAmount" 
            [caption]='"grid_column_net_amount" | translate'
            format="decimal"
            format="#0.##">
        </dxi-column>
        <dxi-column dataField="Discount" 
            [caption]='"grid_column_discount" | translate'
            format="decimal"
            format="#0.##">
        </dxi-column>
        <dxi-column dataField="DiscountedAmount" 
            [caption]='"grid_column_discounted_amount" | translate'
            format="decimal"
            format="#0.##">
        </dxi-column>
        <dxi-column dataField="Tax" 
            [caption]='"grid_column_tax" | translate'
            format="decimal"
            format="#0.##">
        </dxi-column>
        <dxi-column dataField="GrossAmount" 
            [caption]='"grid_column_gross_amount" | translate'
            format="decimal"
            format="#0.##">
        </dxi-column>
        <dxi-column 
        dataField="CreatedDate" 
        [caption]='"grid_column_created_date" | translate' 
        dataType="date">
    </dxi-column>
    <dxi-column 
        dataField="UpdatedDate" 
        [caption]='"grid_column_updated_date" | translate' 
        dataType="date">
        </dxi-column>
        <dxi-column dataField="Remarks" [caption]='"grid_column_remarks" | translate'>
        </dxi-column>
        <dxi-column 
            dataField="CreatedDate" 
            [caption]='"grid_column_created_date" | translate' 
            dataType="date">
        </dxi-column>
        <dxi-column 
            dataField="UpdatedDate" 
            [caption]='"grid_column_updated_date" | translate' 
            dataType="date">
</dxi-column>
    </dx-data-grid>
</app-data-grid>