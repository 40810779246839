<div class="form-container">
    <section [id]="id" class="content-block dx-card responsive-paddings form-section"  [ngClass]="multipleColumn ? 'small-screen' : isLarge ? 'large-screen' : 'small-screen'">
        <dx-form
            id="form"
            colCount="1"
            [readOnly]="readOnly"
            [formData]="dataModel"
            [showColonAfterLabel]="true"
            (onFieldDataChanged)="onFormValueChanged($event)"
            labelLocation="left">
            <dxi-item itemType="group" >
                <dxi-item *ngFor="let item of formItems"
                [name]="item.name"
                    [colCountByScreen]="item.colCountByScreen"
                    [colSpan]="item.colSpan"
                    [caption]="item.caption"
                    [itemType]="item.itemType"
                    [id]="item.dataField"
                    [dataField]="item.dataField"
                    [editorType]="item.editorType"
                    [items]="item.items"
                    [label]="item.label"
                    [editorOptions]="item.editorOptions">
                </dxi-item>
                <dxi-item
                    itemType="group">
                    <div *dxTemplate>
                        <div class="toolbar-container-center-justified">
                            <div class="toolbar-container-left-justified">
                                <dx-button type="danger" 
                                    id="btn-cancel"
                                    [visible]="showCancel && !readOnly"
                                    [text]="formatText('form_cancel')"
                                    icon="close"
                                    (onClick)="this.onCancelClicked($event)"
                                    class="form-button cancel-button"></dx-button>
                                <dx-button
                                    id="btn-update"
                                    [visible]="showUpdate"
                                    class="form-button update-button" 
                                    [text]="formatText('button_update')" 
                                    type="default" 
                                    stylingMode="outlined"
                                    icon="edit" 
                                    (onClick)="onUpdateClicked($event)"></dx-button>
                            </div>
                            <div class="toolbar-container-right-justified">
                                <dx-button type="success"
                                    id="btn-save"
                                    [visible]="showSave && !readOnly" 
                                    [text]="formatText('form_save')" 
                                    icon="save"
                                    (onClick)="this.validateAndCall($event, this.onSaveClicked)"
                                    class="form-button save-button"></dx-button>
                                <dx-button type="success" 
                                    id="btn-add-more"
                                    [visible]="showAddMore && !readOnly"
                                    icon="save"
                                    [text]="formatText('form_add_more')" 
                                    (onClick)="this.validateAndCall($event, this.onAddMoreClicked);"
                                    class="form-button success-button"></dx-button>
                            </div>
                        </div>
                    </div>
                </dxi-item>
        </dxi-item>
        </dx-form>
    </section>
    <dx-tooltip class="dx-swatch-additional" [target]="target" [visible]="isVisible && !!itemContent">
        <div *dxTemplate="let data = data; of: 'content'">{{itemContent}}</div>
    </dx-tooltip>
</div>
