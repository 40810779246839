<app-grid-filter [dataModel]="auditTrailFilter" [filterItems]="filterItems" (onSearch)="searchClicked($event)"></app-grid-filter>
<app-data-grid [title]='"grid_title_audit_log" | translate' [open]="true">
    <dx-data-grid class="dx-card wide-card" [wordWrapEnabled]="true" [showBorders]="false" [dataSource]="auditTrails" [columnAutoWidth]="true">
    <dxo-selection mode="single" showCheckBoxesMode="always"></dxo-selection>
    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true" [showInfo]="true" [visible]="true"></dxo-pager>
    <dxo-filter-row [visible]="false"></dxo-filter-row>
    <dxo-remote-operations [paging]="true" [sorting]="true"></dxo-remote-operations>
<dxi-column
    dataField="DateTime"
    [caption]='"form_date" | translate'
    dataType="datetime">
</dxi-column>
<dxi-column
    dataField="UserId"
    [caption]='"form_user" | translate'>
</dxi-column>
<dxi-column
    dataField="Type"
    [caption]='"grid_column_operation" | translate'>
</dxi-column>
<dxi-column minWidth="150px"
    [caption]='"grid_column_feature" | translate'
    [calculateDisplayValue]="getTableNameWithIdentifier">
</dxi-column>
<dxi-column cssClass="field-container" [caption]="'grid_column_values' | translate" alignment="center" cellTemplate="cellTemplateNewValue">
    <ng-container *dxTemplate="let row of 'cellTemplateNewValue'" >
        <dx-data-grid class="dx-card wide-card fields-grid" [wordWrapEnabled]="true" [showBorders]="false" [dataSource]="row.data.properties" [columnAutoWidth]="true">
            <dxi-column dataField="key" width="150px"
                [caption]='"grid_column_field_name" | translate'></dxi-column>
            <dxi-column dataField="oldValue"
                [caption]='"grid_column_old_value" | translate' cellTemplate="oldValueCellTemplate">
                <ng-container *dxTemplate="let row of 'oldValueCellTemplate'" >
                    <div class="inner-html-container" *ngIf="row.data.oldValue && row.data.isHtml; else simpleViewer" [innerHTML]="row.data.oldValue">
                    </div>
                    <ng-template #simpleViewer>
                        <span>{{row.data.oldValue}}</span>
                    </ng-template>
                </ng-container>
            </dxi-column>
            <dxi-column dataField="newValue"
                [caption]='"grid_column_new_value" | translate' cellTemplate="newValueCellTemplate">
                <ng-container *dxTemplate="let row of 'newValueCellTemplate'" >
                    <div class="inner-html-container" *ngIf="row.data.newValue && row.data.isHtml; else simpleViewer" [innerHTML]="row.data.newValue">
                    </div>
                    <ng-template #simpleViewer>
                        <span>{{row.data.newValue}}</span>
                    </ng-template>
                </ng-container>
            </dxi-column>
        </dx-data-grid>
    </ng-container>
</dxi-column>
<dxi-column
    dataField="Identifier"
    [caption]='"grid_column_identifier" | translate' cellTemplate="cellTemplateIdentifier">
    <div *dxTemplate="let row of 'cellTemplateIdentifier'" >
        <ng-container *ngIf="row.data.ParentIdentifier; else identifier">
            {{ row.data.ParentIdentifier }}
        </ng-container>
        <ng-template #identifier>
            {{ row.data.Identifier }}
        </ng-template>
    </div>
</dxi-column>
</dx-data-grid>
</app-data-grid>