import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationCallbackData } from 'devextreme/ui/validation_rules';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import { FormActionEvent, FormItem } from 'src/app/shared/components';
import { DescriptionMaxLength, NameMaxLength } from 'src/app/Utils/constant';
import { Location } from '@angular/common';
import { ICosting } from '../../accounting-model';
import { AccountingService } from '../../accounting.service';

@Component({
  selector: 'app-add-edit-costing',
  templateUrl: './add-edit-costing.component.html',
  styleUrls: ['./add-edit-costing.component.scss'],
})
export class AddEditCostingComponent implements OnInit {
  @Input()
  public popupVisible!: boolean;
  @Output()
  public onSave: EventEmitter<number> = new EventEmitter();
  @Output()
  onSaveClick: EventEmitter<FormActionEvent> =
    new EventEmitter<FormActionEvent>();

  public costingData: ICosting = {
    Name: '',
    Description: '',
  };

  private validateName = (obj: ValidationCallbackData) => {
    return obj.value
      ? this.accountingService.checkIfCostingNameExists(
          obj.value?.toString(),
          this.id
        )
      : Promise.resolve(false);
  };

  public costingFormItems: Array<FormItem> = [
    {
      itemType: 'group',
      caption: this.translationService.translate('dialog_message_costing'),
      items: [
        {
          dataField: 'Name',
          label: {
            text: this.translationService.translate('form_name'),
          },
          editorOptions: {
            showClearButton: true,
          },
          validationRules: [
            {
              type: 'required',
              message: this.translationService.translate(
                'validation_message_required',
                this.translationService.translate('form_name')
              ),
            },
            {
              type: 'stringLength',
              max: NameMaxLength,
              message: this.translationService.translate(
                'validation_string_length',
                this.translationService.translate('form_name'),
                NameMaxLength.toString()
              ),
            },
            {
              type: 'async',
              validationCallback: this.validateName,
              message: this.translationService.translate(
                'validation_message_exists',
                this.translationService.translate('form_name')
              ),
            },
          ],
        },
        {
          dataField: 'Description',
          editorType: 'dxTextArea',
          label: {
            text: this.translationService.translate('form_description'),
          },
          validationRules: [
            {
              type: 'stringLength',
              max: DescriptionMaxLength,
              message: this.translationService.translate(
                'validation_string_length',
                this.translationService.translate('form_description'),
                DescriptionMaxLength.toString()
              ),
            },
          ],
        },
      ],
    },
  ];

  private id: number | any;

  constructor(
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private accountingService: AccountingService,
    public translationService: TranslationService,
    private toastService: ToastService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = +params['id'];
      if (this.id) {
        this.getData();
      }
    });
  }

  public onSaveClicked = (e: FormActionEvent) => {
    if (this.id) {
      this.showSpinner();
      return this.accountingService
        .updateCosting(this.id, e.data)
        .then((response: any) => {
          this.spinnerService.hideSpinner();
          let toastMessage = this.translationService.translate(
            'toast_message_updated',
            this.translationService.translate('dialog_message_costing')
          );
          this.toastService.success(toastMessage);
          this.router.navigate(['/accounting/costings']);
        });
    } else {
      this.showSpinner();
      return this.accountingService.addCosting(e.data).then((response: any) => {
        this.spinnerService.hideSpinner();
        let toastMessage = this.translationService.translate(
          'toast_message_added',
          this.translationService.translate('dialog_message_costing')
        );
        this.toastService.success(toastMessage);
        let redirectUrl = history.state.redirectUrl;
        if (redirectUrl) {
          this.router.navigateByUrl(redirectUrl, {
            state: { id: response.Data.Id },
          });
        } else {
          this.router.navigate(['/accounting/costings']);
        }
      });
    }
  };

  private showSpinner() {
    this.spinnerService.showSpinner();
  }

  public onCancelClicked = (e: FormActionEvent) => {
    this.location.back();
  };

  public getData() {
    this.accountingService.getCostingById(this.id).then((response: any) => {
      this.costingData = response.Data;
    });
  }
}
