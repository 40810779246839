import { Component, OnInit, NgModule, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from "@angular/common";
import {
  DxFormModule, DxButtonModule, DxSelectBoxModule, DxFormComponent, DxToolbarComponent, DxToolbarModule
} from 'devextreme-angular';
import { formatMessage } from 'devextreme/localization';
import { PageInfoService } from 'src/app/core/services/page-info.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import { ContainsFilter } from 'src/app/Utils/filter-builder';
import { EnterKeyEvent } from 'devextreme/ui/text_box';


@Component({
  selector: 'app-grid-filter',
  templateUrl: './grid-filter.component.html',
  styleUrls: ['./grid-filter.component.scss']
})
export class GridFilterComponent implements OnInit, OnChanges {

  @ViewChild(DxFormComponent)
  public form!: DxFormComponent;
  @Input()
  public dataModel: any;
  @Input()
  public filterItems!: Array<any>;
  @Output()
  public onSearch = new EventEmitter();
  public colCountByScreen: object = {};

  public title: string | any = [];

  private columnRangeByScreen = {
    lg: <ColumnCountRange> {
      max: 4,
      min: 3
    },
    md: <ColumnCountRange> {
      max: 3,
      min:2,
    },
    sm: <ColumnCountRange> {
      max: 3,
      min: 2
    }
  };

  public onSearchClick = (e:any) => {
    let validationResult = this.form.instance.validate();
      if (validationResult.status === "pending") {
        validationResult.complete?.then((asyncValidationResult) => {
          asyncValidationResult.isValid && this.onValidationSuccessful(e);
        });
      }
      else {
        validationResult.isValid && this.onValidationSuccessful(e);
      }
  }

  public searchOptions: any = {
    text: formatMessage("filter_search"),
    type: 'default',
    onClick: (e: any) => {
      this.onSearchClick(e);
    }
  };

  public defaultOptions: any = {
    text: formatMessage("filter_save_as_default"),
    type: 'text',
    onClick: (e: any) => {
      let data = JSON.stringify(this.dataModel);
      localStorage.setItem(this.title, data);
      this.onSearchClick(e);
    }
  };

  public clearDefaultOptions: any = {
    text: formatMessage("filter_clear_default"),
    type: 'text',
    onClick: (e: any) => {
      localStorage.removeItem(this.title);
      this.form.instance.resetValues();
      this.onSearchClick(e);
    }
  };

  constructor(private pageInfoService: PageInfoService, private translationService: TranslationService) {
    
  }


  getFieldName(field: any){
    return field.name || field.dataField;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.filterItems.currentValue){
      let currentCount = changes.filterItems.currentValue.length;
      this.colCountByScreen = {
        lg: this.calculateColumnCount(currentCount, this.columnRangeByScreen.lg),
        md: this.calculateColumnCount(currentCount, this.columnRangeByScreen.md),
        sm: this.calculateColumnCount(currentCount, this.columnRangeByScreen.sm)
      }
      this.setKeyPressHandler(changes.filterItems.currentValue);
      this.setDefaultSelectBoxOptions(changes.filterItems.currentValue);
    }
  }

  ngOnInit (): void {
  }

  setKeyPressHandler(filterItems: Array<any>){
    filterItems.forEach(item => {
      if(!item.editorType || item.editorType === "dxTextBox" || item.editorType === "dxNumberBox" || 
          item.editorType === "dxDateBox" || item.editorType === "dxSelectBox"){
        if(item.editorOptions){
          item.editorOptions.onEnterKey = this.enterKeyPressed;
        }
        else{
          item.editorOptions = {
            onEnterKey: this.enterKeyPressed
          };
        }
      }
    });
  }

  setDefaultSelectBoxOptions(filterItems: Array<any>){
    filterItems.forEach(item => {
      if(item.editorType === "dxSelectBox"){
        if(item.editorOptions){
          item.editorOptions.wrapItemText = true;
        }
        else{
          item.editorOptions = {
            wrapItemText: true
          };
        }
      }
    });
  }

  enterKeyPressed = (e: EnterKeyEvent) =>{
    let isValid = this.form.instance.validate();
    if(isValid){
      this.onSearchClick(e);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.title = this.pageInfoService.getCurrentPageTitle();
      if(this.title){
        let data = localStorage.getItem(this.title);
        if (data != null && data != "") {
          let savedFilter =  JSON.parse(data);
          this.checkFilterItems(savedFilter);
          this.onSearchClick(null);
        }
      }
    }, 0);  
  }
 
  checkFilterItems = (savedFilter: any) => {
    let rangeFilter = this.filterItems.filter(p => p.editorType == "dxDateRangeBox");
    if(rangeFilter != null && rangeFilter.length > 0){
      rangeFilter.forEach((dateRange: any) => {
        let currentValue = savedFilter[dateRange.dataField];
        if(!(currentValue instanceof Array)){
          savedFilter[dateRange.dataField] = [];
        }      
      });
    }
   
    this.dataModel = savedFilter;
  }

  private calculateColumnCount(filterCount: number, range: ColumnCountRange){
    return filterCount <= range.min ? range.min
                        : filterCount % range.max == 0 ? range.max
                        : filterCount % range.min == 0 ? range.min
                        : (filterCount % range.max) > (filterCount % range.min) ? range.max
                        : range.min;
  }

  private onValidationSuccessful(originalEvent: any) {
    let filter = this.createFilter();
    this.onSearch.emit({ filterString: filter, event: originalEvent });
  }

  private createFilter() {
    if(this.dataModel != null) {
    let filterFields = Object.keys(this.dataModel)
      .reduce((result: Array<IFilterField>, propertyName) => {
        if (this.dataModel[propertyName] != null && this.dataModel[propertyName] != '') {
          let filterItem = this.filterItems.find(p => p.dataField == propertyName);
          if(filterItem && filterItem.filterPropertyName){
            return result.concat({
              PropertyName: filterItem.filterPropertyName,
              Value: this.dataModel[propertyName],
              Formatter: filterItem.formatter
            });
          }
          return result;
        }
        return result;
      }, []);
      
    return this.createApiFilter(filterFields);
    } else {
      return "";
    }
  }

  private createApiFilter(filterItems: Array<IFilterField>): string {
    return filterItems
      .map<string>(filterItem => {
        if(filterItem.Formatter != null && typeof filterItem.Formatter === "function"){
          return filterItem.Formatter(filterItem.PropertyName, filterItem.Value);
        } 
        if(typeof filterItem.Value === "string"){
          return ContainsFilter(filterItem.PropertyName, filterItem.Value);
        }
        return `${filterItem.PropertyName} == ${filterItem.Value}`;
      })
      .filter(value => value)
      .join(" && ");
  }

}



type ColumnCountRange = {
  max: number,
  min: number
}

@NgModule({
  imports: [
    CommonModule,
    DxFormModule,
    DxButtonModule,
    DxSelectBoxModule,
    DxToolbarModule
  ],
  declarations: [GridFilterComponent],
  exports: [GridFilterComponent]
})
export class GridFilterModule {

}

interface IFilterField {
  PropertyName: string;
  Value: any;
  Formatter: any;
}