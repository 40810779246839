import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslationService } from 'src/app/core/services/translation.service';
import { FormActionEvent } from '../../form/model';
import { Location } from '@angular/common';
import dxCheckBox, { InitializedEvent } from 'devextreme/ui/check_box';
import { AdditionalDescriptionLength, DescriptionMaxLength, NameMaxLength } from 'src/app/Utils/constant';
import { DxFormComponent } from 'devextreme-angular';
import { formatMessage } from 'devextreme/localization';
import { ClickEvent, Properties } from 'devextreme/ui/button';
import { HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { NaivgationService } from 'src/app/core/services/navigation.service';
import { IsNullOrEmtpy } from 'src/app/Utils/utils';
import { ToastService } from 'src/app/core/services/toast.service';
import { ScreenService } from 'src/app/shared/services';
import { IAuditableEntity, IEntityBase } from 'src/app/shared/models';

export type Attachment = IEntityBase & IAuditableEntity & {
  Id?: number,
  Name: string,
  Description: string,
  IsDefault: boolean,
  FileName: string,
  FilePath: string
}

@Component({
  selector: 'app-add-edit-attachment',
  templateUrl: './add-edit-attachment.component.html',
  styleUrls: ['./add-edit-attachment.component.scss']
})
export class AddEditAttachmentComponent implements OnInit, OnChanges{

  @Input()
  attachment: Attachment = {
    Name: "",
    Description: "",
    IsDefault: false,
    FileName: "",
    FilePath: ""
  }

  @Input()
  isEditedValue: boolean = true;

  public isLargeScreen: boolean = false;
  public class:any;
  public nameMaxLength = NameMaxLength;
  public additionalDescriptionMaxLength = AdditionalDescriptionLength;
  @ViewChild(DxFormComponent)
  public form!: DxFormComponent;
  private defaultCheck!: dxCheckBox;
  public document: any;
  public file: File|any;
  public documentSource: string = "";
  public textVisible: boolean = true;
  private originalDataModel: any;
  private fieldChanges: Map<string, boolean> = new Map();
  public  editorOptions: any={ 
    height: "300px",
    showClearButton: true,
    toolbar: {
      items: [  
        "undo", "redo", "separator",  
        {  
          name: "header",
          acceptedValues: [false, 1, 2, 3, 4, 5]  
        },   
        "separator",  
        "bold", "italic", "strike", "underline", "separator",  
        "alignLeft", "alignCenter", "alignRight", "alignJustify", "separator", "orderedList","bulletList","separator",
        "color", "background","separator","clear","codeBlock","separator","link",
      ]  
      },
  }


  @Output()
  onSaveClicked: EventEmitter<Attachment> = new EventEmitter<Attachment>();

  @Output()
  onCancelClicked: EventEmitter<FormActionEvent> = new EventEmitter<FormActionEvent>();
  
   fileIconMap: { [extension: string]: string } = {
    'pdf': 'dx-icon-pdffile',
    'doc': 'dx-icon-docfile',
    'docx': 'dx-icon-docxfile',
    'xls': 'dx-icon-xlsfile',
    'xlsx': 'dx-icon-xlsxfile',
    'pptx': 'dx-icon-pptxfile',
    'ppt': 'dx-icon-pptfile',
    };

  constructor( 
    private spinnerService: SpinnerService,
    private toastService: ToastService,
    private location: Location, 
    public translationService: TranslationService,
    private httpClient: HttpClient,
    private navigationService: NaivgationService,
    private screenService: ScreenService) {  
      this.isLargeScreen = this.screenService.isLargeScreen();
      this.screenService.isLargeScreenChanged.subscribe(isLargeScreen =>{
        this.isLargeScreen = isLargeScreen;
      })
    }

  public saveClickHandler = (e: FormActionEvent) => {

    if(this.isEditedValue == true ) {
      if(!this.document){
        this.toastService.warning(this.translationService.translate("form_attachment"));
        return;
      }
    const formData = new FormData();
    formData.append('file', this.document,this.document.name);
    this.spinnerService.showSpinner();
    return this.httpClient.post(`${environment.baseApiUrl}/FileUpload/document`, formData)
    .toPromise()
    .then((res: any) => {
      this.attachment.FilePath = res.FilePath;
      this.attachment.FileName = res.FileName;
      this.onSaveClicked?.emit(this.attachment);
    });
  }
  else {
    this.onSaveClicked?.emit(this.attachment);
    
    return;
  }
}

  public cancelClickHandler = (e: ClickEvent) => {
    this.location.back();
  }

  onCheckBoxInitialized = (e: InitializedEvent) => {
    if (e.component) {
      this.defaultCheck = e.component;
    }
  }

  isImageType(source: any): boolean {
    return source && /\jpe?g|png|gif|bmp$/i.test(source);
    }

  uploadFile = (file: File) => {
    this.document = file;
    let extension = this.document.name.split('.');
    const fileReader = new FileReader();
    fileReader.onload = () => {
        if(this.isImageType(extension[extension.length-1])){
      this.documentSource = fileReader.result as string;
      }
      else{
        this.documentSource = this.fileIconMap[extension[extension.length-1]] ? this.fileIconMap[extension[extension.length-1]] : 'dx-icon-file' ;
        this.class = this.fileIconMap[extension[extension.length-1]] ? this.fileIconMap[extension[extension.length-1]] : 'dx-icon-file' ;
        console.log(this.class);
      }
    };
    fileReader.readAsDataURL(file);
    this.textVisible = false;
  }

  public saveButtonOptions: Properties = {
    text: formatMessage("form_save"),
    icon: "save",
    type: 'success',
    onClick: (e: ClickEvent) => {
      this.navigationService.isFormDirty = false;
      this.validateAndCall(e, this.saveClickHandler);
    } 
  }

  public cancelButtonOptions : Properties = {
    text: formatMessage("form_cancel"),
    type: 'danger',
    icon: "close",
    onClick: (e: ClickEvent) => {
      this.cancelClickHandler(e);
    }
  }

  ngOnInit(): void {
    this.navigationService.isForm = true;
    this.navigationService.isFormDirty = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.attachment?.currentValue != null) {
      this.originalDataModel = { ...changes.attachment.currentValue };
    }
  }

  onFormValueChanged = (e: any) => {
    if (e != null && this.originalDataModel != null) {
      if ((IsNullOrEmtpy(e.value) && IsNullOrEmtpy(this.originalDataModel[e.dataField]) === IsNullOrEmtpy(e.value)) || this.originalDataModel[e.dataField] === e.value) {
        if (this.fieldChanges.has(e.dataField)) {
          this.fieldChanges.delete(e.dataField);
        }
      }
      else {
        this.fieldChanges.set(e.dataField, true);
      }
      this.navigationService.isFormDirty = this.fieldChanges.size > 0;
    }
  }


  private validateAndCall = (e: ClickEvent, handler: Function) => {
    let validationResult = this.form.instance.validate();
    if (validationResult.status === "pending") {
      validationResult.complete?.then((asyncValidationResult) => {
        asyncValidationResult.isValid && handler(e);
      });
    }
    else {
      validationResult.isValid && handler(e);
    }
  }

}
