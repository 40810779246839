//#Purchasing
export function getPurchaseOrderDetailsLink (id: number) {
    return `/purchasing/orders/${id}/details`;    
}

export function getSupplierRequestDetailsLink(id: number){
    return `/purchasing/requests/${id}/lines`; 
}

export function getRequestQuotationDetailsLink(supplierRequestId: number, id: number){ 
    return `/purchasing/requests/${supplierRequestId}/quotations/${id}/details`;
}
//#endregion

//#Sales
export function getOrderDetailsLink(id: number){
    return `/sales/orders/${id}/details`;
}

export function getQuotationDetailsLink(id: number){
    return `/sales/quotation/${id}/details`; 
}

export function getOrderReturnDetailsLink(id: number){
    return `/sales/orderReturns/${id}/lines`;
}

export function getOrderReturnLinesDetailsLink(id: number){
    return `/sales/orderReturns/${id}/lines`;
}

export function getCustomerContactNumberDetailsLink(customerId: number, customerAddressId : number, id : number){
    return `/customer/${customerId}/address/${customerAddressId}/contact/${id}/customerContactNumber`;
}
//#endregion

//#Inventory
export function getReleaseDetailsLink(id: number){
    return `/inventory/release/${id}/lines`;
}

export function getReceiveDetailsLink(id: number){
    return `/inventory/receive/${id}/lines`;
}

//#endregion

//#Part
export function getPartDetailsLink(id: number){
    return `/parts/${id}/details`;
}

export function getPartCategoryDetailLink(id: number){
    return `/parts/categories/${id}/details`;
}

export function getPartAttributeMeasuresLink(id: number){
    return `/parts/attributemeasures/${id}`;
}

export function getManufacturerDetailsLink(id: number){
    return `/manufacturer/${id}/attachments`;
}

//#endregion

//#CRM
export function getSupplierDetailsLink(id: number){
    return `/supplier/${id}/address`;
}

export function getCustomerDetailsLink(id: number){
    return `/customer/${id}/address`;
}

export function getCompanyDetailsLink(id: number){
    return `/lead/company/${id}/address`;
}

export function getSupplierContactNumberDetailsLink(supplierId: number, supplierAddressId : number, id : number){
    return `/supplier/${supplierId}/address/${supplierAddressId}/contact/${id}/supplierContactNumber`;
}
//#endregion

//#Lot
export function getLotDetailsLink(id: number){
    return `/lot/lotAttributes/${id}`
}
//#endregion

//#Quality
export function getChecksheetDetailsLink(id: number){
    return `/quality/checksheet/${id}/details`
}

export function getControlPlanDetailsLink(id: number){
    return `/controlPlan/plans/${id}/details`
}
//#endregion

//#Production
export function getProcessRoutingDetailsLink(id: number){
    return `/production/process/routing/${id}/details`
}

export function getProductionDetailsLink(id: number){
    return `/production/${id}/details`
}

export function getProductionOrderDetailsLink(id: number){
    return `/production/productionOrder/${id}/details`;
}
//#endregion

//#Resource
export function getWorkStationDetailsLink(id: number){
    return `/resource/workstation/${id}/workstationConfiguration`
}
//#endregion

//#Human Resource
export function getEmployeeDetailsLink(id: number){
    return `/humanResource/employees/${id}/details`;
}

export function getTeamDetailsLink(id: number){
    return `/humanResource/teams/${id}/teamEmployees`;
}

export function getWeekOffPolicyDetailsLink(id: number){
    return `/humanResource/weekOffPolicy/${id}/weekOffs`;
}

//#endregion

//#Accounting
export function getReceivableInvoiceDetailsLink(id: number){
    return `/accounting/accountsReceivableInvoices/${id}/details`;
}

export function getProformaInvoiceDetailsLink(id: number){
    return `/accounting/proformaInvoices/${id}/details`;
}

export function getCreditNoteDetailsLink(id: number){
    return `/accounting/creditNotes/${id}/details`;
}

export function getPayableInvoiceDetailsLink(id: number){
    return `/accounting/accountsPayableInvoices/${id}/details`;
}

export function getTransactionDetailsLink(id: number){
    return `/accounting/transactions/${id}/details`;
}
//#endregion

//#Lead Management
export function getLeadAttributeDetailsLink(id: number){
    return `/lead/attributes/${id}/attributeOptions`;
}

export function getLeadDetailsLink(id: number){
    return `/lead/${id}/details`;
}

export function getLeadContactDetailsLink(id: number){
    return `/lead/${id}/details`;
}

export function getLeadContactNumberDetailLink(leadId: number, leadAddressId: number, id: number){
    return `/lead/${leadId}/address/${leadAddressId}/contact/${id}/customerContactNumber`;
}
//#endregion

//#Service order
export function getServiceOrderDetailsLink(id: number){
    return `/serviceOrder/serviceOrders/${id}/details`;
}

export function getServiceOrderCategoryDetailLink(id: number){
    return `/serviceOrder/categories/${id}/details`;
}

export function getServiceOrderTypeTermsLink(id: number){
    return `/serviceOrder/types/${id}/terms`;
}

export function getServiceOrderAttributeDetailsLink(id: number){
    return `/serviceOrder/attributes/${id}/attributeOptions`;
}

export function getServiceOrderIssueDetailsLink(id: number){
    return `/serviceOrder/issues/${id}/details`;
}

export function getServiceOrderDiagnosisDetailsLink(id: number){
    return `/serviceOrder/diagnosis/${id}/details`;
}
//#endregion

//#Job request
export function getJobRequestDetailsLink(id: number){
    return `/jobRequest/jobRequests/${id}/details`;
}

export function getJobRequestTypeTermsLink(id: number){
    return `/jobRequest/types/${id}/terms`;
}
//#endregion

//#Shipping
export function getShippingCompanyDetailsLink(id: number){
    return `/shipping/companies/${id}/details`;
}

export function getShipmentDetailsLink(id: number){
    return `/shipping/shipments/${id}/details`;
}

export function getShippingContactNumberDetailLink(supplierId : number,  shippingCompanyAddressId : number, id : number, ){
    return `shipping/companies/${supplierId}/details/${shippingCompanyAddressId}/contact/${id}/shippingCompanyContactNumber`
}
//#endregion

//#Project management
export function getProjectDetailsLink(id: number){
    return `/projectManagement/projects/${id}/details`;
}

export function getProjectTaskDetailsLink(id: number){
    return `/projectManagement/projectTasks/${id}/details`;
}
//#endregion

//#Schedule Job
export function getJobDetailsLink(id: number,jobId:number){
    return `/planning/schedule/${id}/job/${jobId}/details`;
}
//#endregion

//# Reminder
export function getReminderDetailsLink(id: number){
    return `/remainder/${id}/activities`;
}
//#endregion

//#Tenant
export function getTenantContactNumberDetailLink(tenantAddressId : number, id : number){
    return `admin/companyInformation/tenantAddresses/${tenantAddressId}/contact/${id}/tenantContactNumber`;
}
//#endregion

//#ContractManagement
export function getContractDetailsLink (id: number) {
    return `/contractManagement/contracts/${id}/details`;    
}
//#endregion

//#ModuleAction
export function getViewActionDetailsLink(id: number) {
    return `/action/${id}`;
}

//#endregion