import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { FormActionEvent, FormItem } from 'src/app/shared/components';
import { DxDataGridComponent } from 'devextreme-angular';
import dxSelectBox, { InitializedEvent,InitializedEvent as SelectBoxInitializedEvent, Properties as SelectBoxProperties, SelectionChangedEvent, ValueChangedEvent as SelectBoxValueChangedEvent, ValueChangedEvent } from 'devextreme/ui/select_box';
import dxNumberBox ,{InitializedEvent as NumberBoxInitializedEvent, Properties as NumberBoxProperties} from 'devextreme/ui/number_box';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { environment } from 'src/environments/environment';
import { TranslationService } from 'src/app/core/services/translation.service';
import { CalculateService } from 'src/app/core/services/calculate.service';
import { PriceItem } from 'src/app/core';
import dxButton, { ClickEvent, Properties as ButtonProperties, InitializedEvent as ButtonInitializedEvent} from 'devextreme/ui/button';
import { AddEditCostingComponent } from 'src/app/accounting/costing/add-edit-costing/add-edit-costing.component';
import { Router, ActivatedRoute} from '@angular/router';
import { RemainderService } from 'src/app/remainder/remainder.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { PopupService } from 'src/app/core/services/popup.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { LeadManagementService } from 'src/app/lead-management/lead-management.service';
import { disableComponent, enableComponent, hideComponent, showComponent } from 'src/app/Utils/dev-extreme.helper';



export type Cost = {
  Id?: number | null,
  CostingId: number | null,
  AddtionalDescription: string,
  TaxSlabId: number | null,
  UnitPrice: number,
  NetAmount: number,
  Discount: number | null,
  DiscountedAmount: number | null,
  Tax: number,
  GrossAmount: number,
  Remarks: string
}

export const COSTING_ODATA_URL = `${environment.baseOdataUrl}/Costings`;
export const TAX_SLAB_ODATA_URL = `${environment.baseOdataUrl}/TaxSlabs`;

@Component({
  selector: 'app-add-edit-cost',
  templateUrl: './add-edit-cost.component.html',
  styleUrls: ['./add-edit-cost.component.scss']
})
export class AddEditCostComponent {

  public popupVisible!: boolean;
  private CostingId!:number | any;
  private addCostingSelectBox!: dxSelectBox;
  private unitPriceNumberBox!: dxNumberBox;
  private discountNumberBox!: dxNumberBox;
  private taxSlabSelectBox!: dxSelectBox;
  public addCostButton!: dxButton;

  @ViewChild(AddEditCostingComponent)
  addEditCostingPoupup! : AddEditCostingComponent;

  @Input()
  cost: Cost = {
    Id :null,
    CostingId: null,
    AddtionalDescription: "",
    TaxSlabId: null,
    UnitPrice: 0,
    NetAmount: 0,
    Discount: null,
    DiscountedAmount: null,
    Tax: 0,
    GrossAmount: 0,
    Remarks: ""
  }

  @Output()
  onSaveClicked: EventEmitter<FormActionEvent> = new EventEmitter<FormActionEvent>();

  @Output()
  onCancelClicked: EventEmitter<FormActionEvent> = new EventEmitter<FormActionEvent>();

  @ViewChild(DxDataGridComponent)
  grid!: DxDataGridComponent;
  
  ngOnInit(){
    this.cost.CostingId = history.state.id;
     
   }

  constructor( 
    private location: Location, 
    public translationService: TranslationService,
    public calculateService: CalculateService,
    private router: Router,
    private route: ActivatedRoute,
    private leadService: LeadManagementService,
    private reminderService : RemainderService,
    private popupService: PopupService,
    private toastService: ToastService,
    private spinnerService: SpinnerService
    ) { }

  public saveClickHandler = (e: FormActionEvent) =>{
    this.calculateService.calculatePrice(this.getPriceItem()).then(result =>{
      this.updatePriceModel(result);
      e.data.Discount = result.Discount
      e.data.GrossAmount = result.GrossAmount
      e.data.DiscountedAmount = result.DiscountedAmount
      e.data.Tax = result.Tax
      e.data.TaxSlabId = result.TaxSlabId
      e.data.NetAmount = result.NetAmount
      
      this.onSaveClicked?.emit(e);
    }) 
  }

  public cancelClickHandler = (e: FormActionEvent) =>{
    this.location.back();
  }
  private showSpinner() {
    this.spinnerService.showSpinner();
  }

  public calculatePrice = () => {
    this.calculateService.calculatePrice(this.getPriceItem()).then(result =>{
      this.updatePriceModel(result);
    })
  }

  public getPriceItem = () =>{
    let priceItem: PriceItem = {
      Quantity: 1,
      UnitOfMeasureId: 0,
      UnitPrice: this.cost.UnitPrice || 0,
      TaxSlabId: this.cost.TaxSlabId || 0 ,
      Discount: this.cost.Discount || 0
     }
     return priceItem;
  }

  public updatePriceModel = (price: any) =>{
    this.cost.NetAmount = price.NetAmount;
    this.cost.DiscountedAmount = price.DiscountedAmount;
    this.cost.GrossAmount = price.GrossAmount;
    this.cost.Tax = price.Tax;
  }

  
  ngAfterViewChecked(){
    if(this.cost.Id){
      hideComponent(this.addCostButton);
    }
    else{
      showComponent(this.addCostButton);
    }
  }
  
  public formItems: Array<FormItem> = [
    {
      itemType: "group",
      caption: this.translationService.translate("dialog_message_additional_cost"),
      items: [
        {
          itemType: "group",
          //@ts-ignore
          isAddNewGroup: true,
          items: [
            {
              dataField: "CostingId",
              editorType: "dxSelectBox",
              label: {
                text: this.translationService.translate("form_costing")
              },
              validationRules: [ 
                { type: "required", message: this.translationService.translate("validation_message_required", this.translationService.translate("form_costing")) },
              ],
              editorOptions: <SelectBoxProperties> {
                dataSource: new DataSource({
                  store: new ODataStore({
                    url: COSTING_ODATA_URL,
                    key: "Id",
                    version: 4
                  }),
                  select: ["Name", "Id"]
                }),
                searchEnabled: true,
                showClearButton: true,
                searchMode: "contains",
                displayExpr: "Name",
                valueExpr: "Id",
                onInitialized: (e: InitializedEvent) => {
                  if (e.component) {
                    this.addCostingSelectBox = e.component;
                  }
                }, 
                onSelectionChanged: (e: SelectionChangedEvent) => {
                  if(e.selectedItem != null){
                    enableComponent(this.unitPriceNumberBox);
                    enableComponent(this.discountNumberBox);
                    enableComponent(this.taxSlabSelectBox);
                  } else {
                    disableComponent(this.unitPriceNumberBox);
                    disableComponent(this.discountNumberBox);
                    disableComponent(this.taxSlabSelectBox);
                    this.cost.UnitPrice = 0;
                    this.cost.Discount = 0;
                    this.cost.TaxSlabId = null;
                  }
                },
              },
            },
            {
              itemType: "button",
              horizontalAlignment: "right",
              buttonOptions: <ButtonProperties> {
              stylingMode :"text",
              text:  this.translationService.translate("action_add" , this.translationService.translate("form_costing")),
              hoverStateEnabled : false,
              focusStateEnabled: false,
              type: "default",
              onInitialized: (e: ButtonInitializedEvent) =>{
                if(e.component) {
                  this.addCostButton = e.component;
                }
              },
              onClick: (e: ClickEvent) =>{
                this.router.navigateByUrl(`/accounting/costings/add`, { state: {redirectUrl:this.router.url} });  
              } 
              }
            },
          ]
        },    
        {
          dataField: "UnitPrice",
          editorType: "dxNumberBox",
          label: {
            text: this.translationService.translate("form_unit_price")
          },
          editorOptions: <NumberBoxProperties> { 
            format: "decimal",
            showClearButton: true,
            onInitialized: (e:NumberBoxInitializedEvent) =>{
              if(e.component) {
                this.unitPriceNumberBox = e.component;
                disableComponent(e.component);
              }
            },
            onValueChanged: (e: ValueChangedEvent) =>{
              if(e.value != null) {
                e.value != null && this.calculatePrice();
                enableComponent(this.taxSlabSelectBox);
              } else {
                disableComponent(this.taxSlabSelectBox);
                this.cost.NetAmount = 0;
                this.cost.GrossAmount = 0;
                this.cost.DiscountedAmount = 0;
                this.cost.Tax = 0;
              }
            } 
          },
          validationRules: [
            { type: "required", message: this.translationService.translate("validation_message_required", this.translationService.translate("form_unit_price")) },
            { type: 'range', min: 1, message: this.translationService.translate("validation_form_greater_date", this.translationService.translate("form_unit_price"), "0") }
          ]
        },
        {
          dataField: "Discount",
          editorType: "dxNumberBox",
          label: {
            text: this.translationService.translate("form_discount")
          },
          editorOptions: <NumberBoxProperties> {  
            format: "decimal",
            showClearButton: true,
            onInitialized: (e:NumberBoxInitializedEvent) =>{
              if(e.component) {
                this.discountNumberBox = e.component;
                disableComponent(e.component);
              }
            },
            onValueChanged: this.calculatePrice
          },
          validationRules: [
            { type: 'range', min: 0, max: 100, message: this.translationService.translate("validation_mesasge_between", this.translationService.translate("form_discount"), "0", "100") }
          ]
        },
        {
          dataField: "TaxSlabId",
          editorType: "dxSelectBox",
          label: {
            text: this.translationService.translate("form_tax_slab")
          },
          validationRules: [ 
            { type: "required", message: this.translationService.translate("validation_message_required", this.translationService.translate("form_tax_slab"), "0", "100") },
          ],
          editorOptions: <SelectBoxProperties> {
            dataSource: new DataSource({
              store: new ODataStore({
                url: TAX_SLAB_ODATA_URL,
                key: "Id",
                version: 4
              }),
              select: ["Name", "Id"]
            }),
            onInitialized: (e:SelectBoxInitializedEvent) =>{
              if(e.component) {
                this.taxSlabSelectBox = e.component;
                disableComponent(e.component);
              }
            },
            onValueChanged: (e: SelectBoxValueChangedEvent) =>{
              e.value != null && this.calculatePrice();
            },
            searchEnabled: true,
            showClearButton: true,
            searchMode: "contains",
            displayExpr: "Name",
            valueExpr: "Id"
          } 
        },
        {
          dataField: "NetAmount",
          editorType: "dxNumberBox",
          label: {
            text: this.translationService.translate("form_net_amount")
          },
          editorOptions: <NumberBoxProperties> { 
            format: "decimal",
            showClearButton: true,
            disabled: true,
          }
        },
        {
          dataField: "DiscountedAmount",
          editorType: "dxNumberBox",
          label: {
            text: this.translationService.translate("form_discounted_amount")
          },
          editorOptions: <NumberBoxProperties> { 
            format: "decimal",
            showClearButton: true,
            disabled: true,
          },
        },
        {
          dataField: "Tax",
          editorType: "dxNumberBox",
          label: {
            text: this.translationService.translate("form_tax")
          },
          editorOptions: <NumberBoxProperties> { 
            format: "decimal",
            showClearButton: true,
            disabled: true
          },
        },
        {
          dataField: "GrossAmount",
          editorType: "dxNumberBox",
          label: {
            text: this.translationService.translate("form_gross_amount")
          },
          editorOptions: <NumberBoxProperties> { 
            format: "decimal",
            showClearButton: true,
            disabled: true
          }
        },
        {
          dataField: "Remarks",
          editorType: "dxTextArea",
          editorOptions:{
            maxLength: 500
          },
          label: {
            text: this.translationService.translate("form_remarks")
          }
        },
        {
          dataField: "AdditionalDescription",
          editorType: "dxHtmlEditor",
          label: {
            text: this.translationService.translate("grid_column_additional_description")
          },
        },
      ]
    }
  ]
} 
