import { CommonModule } from '@angular/common';
import { AfterContentInit, Component, ContentChild, Input, NgModule, OnInit, Output,EventEmitter } from '@angular/core';
import { DxTreeListComponent } from 'devextreme-angular';
import { defaultTreeGridOptions } from '../../models';
import { ClickEvent } from 'devextreme/ui/button';
import { DxButtonModule,DxDataGridModule,DxFormModule,DxTemplateModule,DxTextAreaModule} from 'devextreme-angular';

@Component({
  selector: 'app-tree-grid',
  templateUrl: './tree-grid.component.html',
  styleUrls: ['./tree-grid.component.scss']
})
export class TreeGridComponent implements OnInit ,AfterContentInit{

  @Input()
  public title!: string;

  @Input()
  public showAdd!: boolean;

  @Output()
  public onAdd: EventEmitter<ClickEvent> = new EventEmitter<ClickEvent>();

  @ContentChild(DxTreeListComponent)
  private treeGrid!: DxTreeListComponent;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
    this.treeGrid.instance.option(defaultTreeGridOptions);
  }
  onAddClicked = (event: ClickEvent) =>{
    this.onAdd && this.onAdd.emit(event);
  }
}

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
  ],
  declarations: [ TreeGridComponent ],
  exports: [ TreeGridComponent ]
})
export class TreeGridModule {

}
