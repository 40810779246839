import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { PageInfoService } from 'src/app/core/services/page-info.service';
import { TranslationService } from 'src/app/core/services/translation.service';

@Component({
  selector: 'app-title-bar',
  templateUrl: './title-bar.component.html',
  styleUrls: ['./title-bar.component.scss']
})
export class TitleBarComponent implements OnDestroy {

  public title!: string;
  private pageInfoSubscription: Subscription;
  constructor(private pageInfoService: PageInfoService, private translationService: TranslationService) { 
    this.pageInfoSubscription = pageInfoService.titleObservable.subscribe(title => {
      this.title = title;
    })
  }

  private setCurrentPage = (pages: Record<string, unknown>[], currentUrl: string) =>{
    let currentMenuItem = pages.find(p => p.RelativePath === currentUrl);
    this.title = this.translationService.translate(currentMenuItem?.Title as string);
  }

  ngOnDestroy(): void {
   this.pageInfoSubscription.unsubscribe(); 
  }
}
