<div class="email-container full-screen">
  <div class="email-header" *ngIf="!isReadOnly">
    <dx-button text="Send" stylingMode="contained" type="default" (onClick)="OnSendClick($event)" class="send-button"></dx-button>
  </div>
  <dx-form [formData]="email" labelLocation="top">
    <dxi-item itemType="group" colCount="1">
      <dxi-item dataField="To" cssClass="customClass" editorType="dxTextBox" [editorOptions]="{  
        showClearButton: true,
        placeholder: 'Add recipient',
        readOnly: isReadOnly }">
            <dxi-validation-rule
                type="required"
                [message]='"form_add_recipient" | translate'>
            </dxi-validation-rule>
            <dxi-validation-rule 
            type="custom" 
            [validationCallback]="customEmailValidation" 
            [message]='"validation_form_email" | translate'>
            </dxi-validation-rule>
            <dxo-label [text]='"form_to" | translate' labelLocation="left"></dxo-label>
    </dxi-item>

    <dxi-item dataField="Cc" cssClass="customClass" editorType="dxTextBox" [editorOptions]="{ 
        placeholder: 'Add cc',
        readOnly: isReadOnly }">
        <dxi-validation-rule 
        type="custom" 
        [validationCallback]="customEmailValidation" 
        [ignoreEmptyValue]="true"
        [message]='"validation_form_email" | translate'>
        </dxi-validation-rule>
        <dxo-label [text]='"form_cc" | translate' ></dxo-label>
    </dxi-item>

    <dxi-item *ngIf="showBcc" dataField="Bcc" cssClass="customClass" editorType="dxTextBox" [editorOptions]="{ 
        placeholder: 'Add bcc',
        readOnly: isReadOnly }">
        <dxi-validation-rule 
        type="custom" 
        [validationCallback]="customEmailValidation" 
        [ignoreEmptyValue]="true"
        [message]='"validation_form_email" | translate'>
        </dxi-validation-rule>
        <dxo-label [text]='"form_bcc" | translate' labelLocation="left" ></dxo-label>
    </dxi-item>
    <dxi-item class="form-group bcc-button" *ngIf="!showBcc">
      <dx-button text="Bcc" stylingMode="text" type="default" (onClick)="toggleBcc()"></dx-button>
  </dxi-item>
  <dxi-item *ngIf="!isReadOnly">
    <div *dxTemplate="let data">
      <div class="attachments-container">
        <dx-button *ngFor="let attachment of attachments; let i = index" class="attachment-button"
          [text]="attachment.Name"
          (onClick)="removeAttachment(i)"
          icon="clear"
          stylingMode="text"
        ></dx-button>
      </div>
    </div>
  </dxi-item>
  <dxi-item cssClass="customClass" *ngIf="!isReadOnly">
    <dxo-label [text]='"grid_title_attachments" | translate' ></dxo-label>
    <div *dxTemplate>
        <div id="dropzone">
            <div class="upload-container">
              <dx-button id="upload-button" stylingMode="outlined" type="normal" icon="upload" text="Upload File">
              </dx-button>
              <div id="dropzone-text-small">
                  <span>Drag & Drop the desired file</span>
                  <span>…or click to browse for a file instead.</span>
              </div>
          </div>
        </div>
        
        <dx-file-uploader [uploadFile]="uploadFile" dialogTrigger="#upload-button" 
            dropZone="#dropzone" [visible]="false"
            [multiple]="true"  [maxFileSize]="5000000"  accept="*"
            uploadMode="instantly"></dx-file-uploader>
        </div>
        <dxi-item><dxo-label [text]='"validation_file_size" | translate'></dxo-label></dxi-item>
</dxi-item>
      
      <dxi-item dataField="Subject" cssClass="customClass" editorType="dxTextBox" [editorOptions]="{ placeholder: 'Add a subject',readOnly: isReadOnly }">
        <dxo-label [text]='"dialog_message_subject" | translate'></dxo-label>
      </dxi-item>

      <dxi-item dataField="Body" cssClass="customClass" editorType="dxHtmlEditor" [editorOptions]="{
        height: '600px',
        placeholder: 'Message body',
        onContentReady: onContentReady,
        readOnly: isReadOnly,
        toolbar: {
          items: [
            'undo', 'redo', 'separator',
            {
              name: 'size',
              acceptedValues: ['8pt', '10pt', '12pt', '14pt', '16pt', '18pt', '20pt', '24pt', '26pt', '28pt', '36pt', '48pt', '72pt'],
              options: { value: '12pt' },
            },
            {
              name: 'font',
              acceptedValues: ['Arial', 'Cambria', 'Calibri', 'Courier New', 'Georgia', 'Helvetica', 'Impact', 'Tahoma', 'Times New Roman', 'Trebuchet MS', 'Verdana'],
              options: { value: 'Calibri' },
            },
            'separator', 'bold', 'italic', 'strike', 'underline', 'separator',
            'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'separator',
            'orderedList', 'bulletList', 'separator',
            {
              name: 'header',
              acceptedValues: [false, 1, 2, 3, 4, 5],

            },
            'separator', 'color', 'background', 'separator',
            'link', 'separator', 'clear', 'codeBlock', 'blockquote', 'separator',
            'insertTable', 'deleteTable', 'insertRowAbove', 'insertRowBelow', 'deleteRow',
            'insertColumnLeft', 'insertColumnRight', 'deleteColumn'
          ]
        }
      }">
       <dxo-label [text]='"dialog_message_meassage_body" | translate'></dxo-label>
      </dxi-item>
    </dxi-item>
  </dx-form>
</div>

