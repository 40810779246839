import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { TranslationService } from '../services/translation.service';
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpLanguageInterceptor implements HttpInterceptor {

    constructor(private translationService: TranslationService){

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let locale = this.translationService.getLocale();
        const localizedRequest = request.clone({
            headers: new HttpHeaders({
              'Accept-Language':  locale
            })
          });
        return next.handle(localizedRequest);
    }

}