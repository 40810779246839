import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { SelectionChangedEvent } from 'devextreme/ui/data_grid';
import { ClickEvent } from 'devextreme/ui/button';
import { RemainderService } from 'src/app/remainder/remainder.service';
import { SpinnerService } from 'src/app/core/services/spinner.service';
import { PopupService } from 'src/app/core/services/popup.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import { LeadManagementService } from 'src/app/lead-management/lead-management.service';
import { GridActionClickEvent } from 'src/app/shared/models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-additional-costs',
  templateUrl: './view-additional-costs.component.html',
  styleUrls: ['./view-additional-costs.component.scss']
})
export class ViewAdditionalCostsComponent {

  @Input()
  dataSource!: DataSource | any;

  @Input()
  showAdd: boolean = false;

  @ViewChild(DxDataGridComponent)
  grid!: DxDataGridComponent;
  
  @Output()
  onSelectionChanged: EventEmitter<SelectionChangedEvent> = new EventEmitter<SelectionChangedEvent>();

  @Output()
  onEditClicked: EventEmitter<GridActionClickEvent> = new EventEmitter<GridActionClickEvent>();

  @Output()
  onDeleteClicked: EventEmitter<GridActionClickEvent> = new EventEmitter<GridActionClickEvent>();
 
  @Output()
  onAddClicked: EventEmitter<any> = new EventEmitter();

  getQuantitiyWithUnitOfMeasure(rowData: any) {
    return `${rowData.Quantity}  ${rowData.UnitOfMeasure?.Key}`;
  }
   
  constructor(    
    private router: Router
  ) 
  { this.currentRoute = this.router.url;
    this.isCreditNoteRoute = this.currentRoute.includes('creditNotes');
  }

  isCreditNoteRoute: boolean = false;
  public currentRoute: string | any;
  
  public selectionChangeHandler = (e: SelectionChangedEvent) => { 
    this.onSelectionChanged?.emit(e);
  }

  public refreshGrid(){
    this.grid.instance.clearSelection();
    this.grid.instance.refresh();
  }

  editAdditionalCostDetail = (event: ClickEvent, rowData: any) => {
    event.event?.stopPropagation();
    if(rowData.key){
      this.onEditClicked?.emit({...event, rowData: rowData});
    }
  }
  deleteAdditionalCostDetail = (event: ClickEvent, rowData: any) => {
    event.event?.stopPropagation();
    if(rowData.key){
      this.onDeleteClicked?.emit({...event, rowData: rowData});
    }
  }

  onAddNewAdditionalCost = () =>{
    this.onAddClicked?.emit();
  }

}
