import { Injectable } from '@angular/core';
import { Email } from '../model';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';
import { IEmail } from 'src/app/shared/components/view-template-for-email/view-template-for-email.component';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  private readonly SEND_EMAIL_URL = `${environment.baseApiUrl}/Email`;

  constructor(private httpService: HttpService) { }

  public openEmail(email: Email) {
    window.open('mailto:'+email.To+'?subject='+email.Subject+'&body='+email.Body);
  }

  public sendEmail(email: Email){
    let url = `${this.SEND_EMAIL_URL}/send`;
    return this.httpService.post(url, email);
  }

}
