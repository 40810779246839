<ng-content></ng-content>

<div class="menu-container">
  <dx-tree-view
    [visible]="visible"
    [dataSource]="menuItems"
    keyExpr="Id"
    parentIdExpr="ParentNavigationId"
    [displayExpr]="getTransalatedMenu"
    dataStructure="plain"
    rootValue="null"
    [selectByClick]="true"
    selectionMode="single"
    [focusStateEnabled]="false"
    expandEvent="click"
    (onItemClick)="onItemClick($event)"
    width="100%">
  </dx-tree-view>
</div>
