import { Pipe, PipeTransform } from '@angular/core';
import { parseISO } from 'date-fns';
import { formatDate } from 'devextreme/localization';
import { CurrencyService } from 'src/app/core/services/currency.service';

@Pipe({
  name: 'datetime'
})
export class DateTimePipe implements PipeTransform {

  constructor(private currencyService: CurrencyService){

  }

  transform(value: string, format: string): string {
    if(value != null){
        let date = parseISO(value);
      return formatDate(date, format);
    }
    return value;
  }
}
