<app-action-bar [actionItems]="actionItems" [visible]="actionItems != null && actionItems.length > 0"></app-action-bar>
<app-form [formItems]="formItems"
    [dataModel]="formDataModel"
    [showSave]="showSave"
    [showCancel]="showCancel"
    [showUpdate]="showUpdate"
    [showAddMore]="showAddMore"
    [readOnly]="readOnly"
    [fullScreen]="fullScreen"
    (onSave)="onSaveClicked($event)"
    (onCancel)="onCancelClicked($event)"
    (onUpdate)="onUpdateClicked($event)"
    (onAddMore)="onAddMoreClicked($event)"></app-form>
