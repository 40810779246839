import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/core/services/http.service';
import { environment } from 'src/environments/environment';
import { IModuleReminder } from '../models';
import { RequestOptions } from 'src/app/core';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ModuleRemainderService {

  private readonly remainderUrl = `${environment.baseApiUrl}/Remainder`;

  constructor(private httpService: HttpService) {}

    getRemainders(moduleType: IModuleReminder, requestOptions: RequestOptions) {
      const url = `${this.remainderUrl}/${moduleType.ModuleTypeId}/${moduleType.ModuleId}/ModuleReminders`; 
      return this.httpService.getPaginatedData(url, requestOptions);
      }
}
