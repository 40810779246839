import { Component } from '@angular/core';

@Component({
  selector: 'app-view-lead-quotations-confirm',
  templateUrl: './view-lead-quotations-confirm.component.html',
  styleUrls: ['./view-lead-quotations-confirm.component.scss']
})
export class ViewLeadQuotationsConfirmComponent {

}
