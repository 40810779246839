import { Component, OnDestroy, OnInit } from '@angular/core';
import { ClickEvent, Properties as ButtonProperties } from 'devextreme/ui/button';
import { Subscription } from 'rxjs';
import { PopupService } from 'src/app/core/services/popup.service';
import { TranslationService } from 'src/app/core/services/translation.service';
import { ConfirmPopup, InformationPopup, Popup, PopupBase, WarningPopup } from '../../models';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit, OnDestroy {
  
  public popup: Popup = {
    visible: false
  };
  private popupSubscription!: Subscription;
  
  public noButtonOptions: ButtonProperties = {
    text: (this.popup as ConfirmPopup)?.noButtonText || this.translationService.translate("popup_button_no"),
    type: 'danger',
    onClick: (e: ClickEvent) => {
      (this.popup as ConfirmPopup)?.onNoClicked?.(e);
    }
  };

  public yesButtonOptions: ButtonProperties = {
    text: (this.popup as ConfirmPopup)?.yesButtonText || this.translationService.translate("popup_button_yes"),
    type: 'success',
    onClick: (e: ClickEvent) => {
      (this.popup as ConfirmPopup)?.onYesClicked?.(e);
    }
  };

  public okButtonOptions: ButtonProperties = {
    text: this.translationService.translate("popup_button_ok"),
    type: 'success',
    onClick: (e: ClickEvent) => {
      (this.popup as InformationPopup)?.onOkClicked?.(e);
    }
  };

  public okButtonOption: ButtonProperties = {
    text: this.translationService.translate("popup_button_ok"),
    type: 'success',
    onClick: (e: ClickEvent) => {
      (this.popup as WarningPopup)?.onOkClicked?.(e);
    }
  };

  constructor(private popupService: PopupService, private translationService: TranslationService) {
    this.popupSubscription = popupService.popup.subscribe(popup =>{
      this.popup = popup;
    });
  }

  ngOnDestroy(): void {
    this.popupSubscription.unsubscribe();
  }

  ngOnInit(): void {

  }

}
