<app-popup></app-popup>

<app-header
  class="layout-header"
  [menuToggleEnabled]="true"
  (menuToggle)="menuOpened = !menuOpened;"
  [title]="title">
</app-header>

<dx-drawer
  [visible]="showMenu"
  class="layout-body"
  position="before"
  [closeOnOutsideClick]="shaderEnabled"
  [openedStateMode]="menuMode"
  [revealMode]="menuRevealMode"
  [minSize]="minMenuSize"
  [shading]="shaderEnabled"
  [(opened)]="menuOpened">

  <app-side-navigation-menu
    [visible]="isLicensed"
    [menuItems]="dataSource"
    [compactMode]="!menuOpened"
    [selectedItem]="selectedRoute"
    class="dx-swatch-additional"
    *dxTemplate="let dataMenu of 'panel'"
    (selectedItemChanged)="navigationChanged($event)"
    (openMenu)="navigationClick()">
    <div>  
    <dx-button
      class="dx-icon"
      icon="arrow_back"
      [hint]= '"go_back_icon" | translate'
      stylingMode="text"  (onClick)="onBackClick()">
      <div *dxTemplate="let data of 'content'">
        <span class="material-icons-round"  style="font-size: x-large; width: 42px !important;  margin: 0 !important; color: white">
          arrow_back
          </span>
          <span style="margin-left: 11px; color: white; font-weight: bold">
            {{"go_back_icon"| translate}}
          </span>
      </div>
      
    </dx-button>
  </div>
  </app-side-navigation-menu>

   

  <dx-scroll-view class="with-footer" id="content">
    <div class="content">
      <ng-content></ng-content>
    </div>

    <div class="content-block">
      <ng-content select="app-footer"></ng-content>
    </div>
  </dx-scroll-view>
</dx-drawer>
<dx-load-panel
  shadingColor="rgba(0,0,0,0.4)"
  [position]="{ of: '#content' }"
  [visible]="showSpinner"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [closeOnOutsideClick]="false"
></dx-load-panel>
