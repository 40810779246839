<app-data-grid id="tax-breakdown" [title]='"grid_title_tax_breakdown" | translate'>
    <dx-data-grid class="dx-card wide-card" [dataSource]="dataSource" [showBorders]="false" [columnAutoWidth]="true"
        [columnHidingEnabled]="true">
        <dxo-selection mode="single" showCheckBoxesMode="always"></dxo-selection>
        <dxi-column
            [caption]='"form_tax_slab" | translate'
            cellTemplate="cellTemplateValue">
                <div *dxTemplate="let row of 'cellTemplateValue'" class="slab-container">
                    <div>
                        <span>{{row.data.TaxSlab.Name}}</span><span *ngIf="breakdownProperty && row.data[breakdownProperty]?.length > 0"> - &nbsp;</span>
                    </div>
                    <div *ngIf="breakdownProperty && row.data[breakdownProperty]?.length > 0">
                        <p *ngFor="let breakDown of row.data[breakdownProperty]" style="margin: 0px;">
                             {{breakDown.TaxBreakdown?.TaxAuthority?.Name}} {{breakDown.TaxBreakdown?.TaxRate?.Rate}}
                        </p>
                    </div>
                    <!-- <a class="grid-cell-detail-link" (click)="getCustomerDetailsLink(row.data.CustomerId)" [text]="row.value"></a>   -->
                </div>
        </dxi-column>
        <dxi-column dataField="NetAmount" 
            [caption]='"grid_column_net_amount" | translate'
            format="decimal"
            format="#0.##">
        </dxi-column>
        <dxi-column dataField="Discount" 
            [caption]='"grid_column_discount" | translate'
            format="decimal"
            format="#0.##">
        </dxi-column>
        <dxi-column dataField="DiscountedAmount" 
            [caption]='"grid_column_discounted_amount" | translate'
            format="decimal"
            format="#0.##">
        </dxi-column>
        <dxi-column dataField="Tax" 
            [caption]='"grid_column_tax" | translate'
            format="decimal"
            format="#0.##">
        </dxi-column>
        <dxi-column dataField="GrossAmount" 
            [caption]='"grid_column_gross_amount" | translate'
            format="decimal"
            format="#0.##">
        </dxi-column>
        <dxi-column 
        dataField="CreatedDate" 
        [caption]='"grid_column_created_date" | translate' 
        dataType="date">
        </dxi-column>
        <dxi-column 
        dataField="UpdatedDate" 
        [caption]='"grid_column_updated_date" | translate' 
        dataType="date">
    </dxi-column>
    </dx-data-grid>
</app-data-grid>