<dx-popup
    [showTitle]="true"
    [title]='popup?.title || ("popup_title" | translate)'
    [dragEnabled]="false"
    [closeOnOutsideClick]="false"
    [showCloseButton]="false"
    [visible]="popup.visible"
    height="50%"
    width="50%"
    maxHeight="180px"
    maxWidth="500px">
    <dxi-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="before"
      [visible]="popup.type == 0"
      [options]="noButtonOptions"
    >
    </dxi-toolbar-item>
    <dxi-toolbar-item
      widget="dxButton"
      toolbar="bottom"
      location="after"
      [visible]="popup.type == 0"
      [options]="yesButtonOptions"
    >
    </dxi-toolbar-item>
    <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="after"
    [visible]="popup.type == 1"
    [options]="okButtonOptions"
  >
  </dxi-toolbar-item>
  <dxi-toolbar-item
  widget="dxButton"
  toolbar="bottom"
  location="after"
  [visible]="popup.type == 2"
  [options]="okButtonOption"
>
</dxi-toolbar-item>
    <div *dxTemplate="let data of 'content'" class="popup-content">
        <dx-scroll-view width="100%" height="100%">
            <p>
                {{popup?.message || ('popup_message_confirm' | translate)}}
                </p>
        </dx-scroll-view>
    </div>
  </dx-popup>