<app-form-view [formDataModel]="tenantAddressesData"
    [formItems]="tenantAddressesFormItems"
    (onSave)="onSaveClicked($event)"
    (onCancel)="onCancelClicked($event)"></app-form-view>
    <dx-popup
    maxHeight="100%"
    maxWidth="100%"
    [showTitle]="false"
    [dragEnabled]="false"
    container=".dx-viewport"
    [(visible)]="popupVisibleAddressType"
    *ngIf="popupVisibleAddressType"
  >
  <dx-scroll-view width="100%" height="100%">
    <div class="popup-content">
      <app-add-edit-address-type
      [popupVisibleAddressType]="popupVisibleAddressType"
      (onSave)="onaddresstypeSaveClick($event)"
      (onCancel)="onaddresstypeCancelClick()"
      >
      </app-add-edit-address-type>
     
    </div>
  </dx-scroll-view>
  </dx-popup>
