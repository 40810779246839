<section [id]="id" class="grid-container">
    <dx-accordion [dataSource]="grids" [collapsible]="true" [multiple]="true" [selectedIndex]="selectedIndex"
        class="grid-accordion">
        <div class="grid-title-container" *dxTemplate="let grid of 'title'">
            <span class="dx-form-group-caption grid-title">{{title}}</span>
        </div>
        <div *dxTemplate="let grid of 'item'">
            <ng-content select="dx-data-grid"></ng-content>
            <dx-button class="add-new-button" [text]='"button_add_new" | translate' *ngIf="showAdd"
                stylingMode="outlined" type="default" icon="add" (onClick)="onAddClicked($event)"> </dx-button>
        </div>
    </dx-accordion>
</section>