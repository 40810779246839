import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpService } from "./http.service";

@Injectable({providedIn: 'root'})
export class CurrencyService {

    private readonly customersUrl = `${environment.baseApiUrl}/Customer`;
    private readonly companyUrl = `${environment.baseApiUrl}/Company`;
    private readonly supplierUrl = `${environment.baseApiUrl}/Supplier`;

    public companyCurrency = "INR";
    constructor(private httpService: HttpService) { }

    public setDefaultCompanyCurrency = () =>{
      return new Promise(resolve =>{
        this.getCompanyCurrency().then((currency: any) =>{
          this.companyCurrency = currency?.CurrencyCode;
          resolve(this.companyCurrency);
        })
      })
    }
    public getCompanyCurrency = () => {
        let currencyUrl = `${this.companyUrl}/currency`;
        return this.httpService.getData(currencyUrl);
      }

      public setCompanyDefaultCurrencyById = (companyId: number | any) =>{
        return new Promise(resolve =>{
          this.getCompanyDefaultCurrencyById(companyId).then((currency: any) =>{
            this.companyCurrency = currency?.CurrencyCode;
            resolve(this.companyCurrency);
          })
        })
      }
      public getCompanyDefaultCurrencyById = (companyId: number | any) => {
          let currencyUrl = `${this.companyUrl}/${companyId}/baseCurrency`;
          return this.httpService.getData(currencyUrl);
        }

      public getCompanyCurrencyById = (companyId: number) => {
        let currencyUrl = `${this.companyUrl}/${companyId}/currency`;
        return this.httpService.getData(currencyUrl);
      }
    
      public getCustomerCurrency = (customerId: number) => {
        let currencyUrl = `${this.customersUrl}/${customerId}/currency`;
        return this.httpService.getData(currencyUrl);
      }

      public getSupplierCurrency = (supplierId: number) => {
        let currencyUrl = `${this.supplierUrl}/${supplierId}/currency`;
        return this.httpService.getData(currencyUrl);
      }
}