import { environment } from "src/environments/environment";
import { IAuditableEntity, IEntityBase } from "../shared/models";

export const CUSTOMERS_ODATA_URL = `${environment.baseOdataUrl}/Customers`;
export const CUSTOMER_STATUS_ODATA_URL = `${environment.baseOdataUrl}/CustomerStatuses`;
export const CURRENCY_ODATA_URL = `${environment.baseOdataUrl}/Currencies`;
export const PARTS_ODATA_URL = `${environment.baseOdataUrl}/Parts`;
export const CUSTOMER_PARTS_ODATA_URL = `${environment.baseOdataUrl}/CustomerParts`;
export const TAX_SLAB_ODATA_URL = `${environment.baseOdataUrl}/TaxSlabs`;
export const ADDRESS_TYPES_ODATA_URL = `${environment.baseOdataUrl}/AddressTypes`;
export const CUSTOMER_ADDRESS_ODATA_URL = `${environment.baseOdataUrl}/CustomerAddresses`;
export const COUNTRY_ODATA_URL = `${environment.baseOdataUrl}/Countries`;
export const CONTACT_TYPE_ODATA_URL = `${environment.baseOdataUrl}/ContactTypes`;
export const CUSTOMER_CONTACT_ODATA_URL = `${environment.baseOdataUrl}/CustomerContacts`;
export const TIME_PERIOD_TYPE_ODATA_URL = `${environment.baseOdataUrl}/TimePeriodTypes`;
export const TITLE_TYPE_ODATA_URL = `${environment.baseOdataUrl}/TitleTypes`;
export const CUSTOMER_POSITION_ODATA_URL = `${environment.baseOdataUrl}/CRMPositions`;
export const CRM_DEPARTMENT_ODATA_URL = `${environment.baseOdataUrl}/CRMDepartments`;
export const CUSTOMER_DEPARTMENT_ODATA_URL = `${environment.baseOdataUrl}/CustomerDepartments`;
export const CRM_DEPARTMENT_FUNCTION_ODATA_URL = `${environment.baseOdataUrl}/CRMDepartmentFunctions`;
export const COMPANY_ODATA_URL = `${environment.baseOdataUrl}/Companies`;
export const COMPANY_STATUS_ODATA_URL = `${environment.baseOdataUrl}/CompanyStatuses`;


export interface ICustomerFilter {
    CompanyId : number | null,
    CompanyNumber : string,
    SupplierNumber : string,
    GstNumber : string,
    TaxNumber : String,
    CompanyStatusId : number | null,
}

export interface ICustomerData {
    Id? : number,
    Name : string,
    CustomerNumber : string,
    SupplierNumber : string,
    GstNumber : string,
    TaxNumber : string,
    CustomerStatusId : number | null,
    Description: string,
    BaseCurrencyCode?: string
}

export interface ICustomerPartsFilter {
    CustomerId : number | null,
    PartId : number | null,
    CustomerPartNumber : string,
    ManufacturerPartNumber: string,
    PartTypeId: number | any,
    PartCategoryId: number | any
}

export interface ICustomerPartsData {
    Id? : number,
    CustomerId : number | null,
    PartId : number | null,
    CustomerPartNumber : string,
    ManufacturerPartNumber : string,
    LeadTime : number | any,
    TimePeriodTypeId : number | any,
    PricePerUnit: number | null,
    TaxSlabId: number | null,
    Discount: number | null,
    UnitOfMeasureId: number | null,
    AdditionalDescription: string,
}

export interface ICustomerAddressesData {
    Id? : number,
    AddressTypeId : number | null,
    AddressLine1 : string,
    AddressLine2 : string,
    IsDefault: false,
    City : string,
    State: string,
    Country: string,
    ZipCode: string,
    Email: string,
    PhoneNumber: number | null,
    MobileNumber: number | null,
    FaxNumber: number | null,
    Website: string,
    CustomerId : number | any
}

export interface ICustomerContactsData {
    Id? : number,
    TitleTypeId : number | null,
    DepartmentId: number | null,
    DepartmentFunctionId: number | null,
    ContactPerson: string,
    IsDefault: false,
    Email: string,
    PositionId: number | null,
}

export interface ICustomerContactNumber {
    Id? : number,
    ContactTypeId : number | null,
    ContactNumber: number | null,
    Extension: string,
    CountryCode: string,
}

export interface ICustomerPartPrice  extends IEntityBase,IAuditableEntity{
    PartIds :any,
    PartFieldId: number | null,
    OperatorId: number | null,
    Value: number | null,
    TypeOfValueId: number | null,
}

export interface ICustomerPositionFilter {
    Name : string,   
}

export interface ICustomerPosition extends IEntityBase,IAuditableEntity{
    Id? : number,
    Name : string,
    Description : string,
}

export interface  ICRMDepartmentFilter{
    Name: string,
    Code: string,
}

export interface ICRMDepartment extends IEntityBase,IAuditableEntity{
    Id? : number,
    Name: string,
    Description: string,
    Code: string,
}

export interface ICRMDepartmentFunction extends IEntityBase,IAuditableEntity{
    Id? : number,
    Name: string,
    Description: string,
    DepartmentId : number | any
}

export interface ICustomerDepartmentData{
    Id? : number,
    CRMDepartmentId : number | null,
    ReponsiblePerson: string,
    PhoneNumber: number | null,
    MobileNumber: number | null,
    FaxNumber: number | null,
    Email: string,
}